import styled from "styled-components";

export const Container = styled.h3`
  background-color: #fff;
  border-radius: 1rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 1080px !important;
  padding-top: 5rem;
  padding-bottom: 20rem;
  z-index: -1;

  .background-body {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    position: relative;
  }

  .notFound {
    color: #14213d;
    font-size: 1.4rem;
    text-align: center;
  }

  .footer-service-list {
    position: absolute;
    bottom: 30px;
    left: 0;
  }
`;

export const ContainerNoFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 50vw;

  & + & {
    margin-top: 3rem;
  }
  @media (max-width: 660px) {
    width: 80vw;
  }
`;

export const Text = styled.h2`
  text-align: center;
  color: #707070;
  font-size: 1.1rem;
`;

export const ContainerDate = styled.div`
  width: 50vw;
  padding: 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1280px) {
    width: 70vw;
  }
  @media (max-width: 700px) {
    width: 90vw;
  }
`;

export const DateArrowRight = styled.img`
  width: 0.5rem;
`;

export const DateArrowLeft = styled.img`
  width: 0.5rem;
`;

export const DatesView = styled.div`
  width: 40vw;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  overflow-x: scroll;
  justify-content: flex-start;
  background: #f1f1f1;
  @media (max-width: 1280px) {
    width: 50vw;
  }
  @media (max-width: 700px) {
    width: 70vw;
  }
`;

export const TypesView = styled.div`
  display: flex;
  overflow-x: scroll;
  justify-content: flex-start;
  text-align: center;
  width: 85%;
  ::-webkit-scrollbar-track {
    background: #fff;
  }
`;

export const ContainerHour = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: -20px;
`;

export const SelectView = styled.div`
  border-radius: 1rem;
  border-width: 1px;
  border-color: #c4c4c4;
  margin-top: 10px;
  text-align: center;
  width: 50vw;
  margin-bottom: 2rem;
  select {
    width: 15rem;
    padding: 1rem 1rem;
    border-radius: 1rem;
    option {
      padding: 1rem;
    }
  }
`;

export const ViewBox = styled.div`
  margin: 0 10px;
  align-items: center;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
`;

export const ButtonImg = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 18px;
  background-color: #fff;
  border: 2px solid
    ${(props) => (props.label === props.type ? "#FA9F2A" : "#C4C4C4")};
`;

export const ButtonView = styled.div`
  display: flex;
`;

export const ButtonText = styled.span`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 5px;
  flex: 1;
  flex-wrap: wrap;
  width: 0.8px;
  text-align: center;
  color: ${(props) => (props.label === props.type ? "#FA9F2A" : "#14213D")};
`;

export const NotFound = styled.span`
  text-align: center;
  color: #14213d;
`;

export const ButtonCheck = styled.button`
  display: flex;
  border-radius: 20px;
  width: 200px;
  height: 42px;
  font-size: 1.2rem;
  background-color: #fa9f2a;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

export const TextCheck = styled.h3`
  color: #fff;
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 300;
`;
