import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import InputMask from 'react-input-mask';
import { Dimensions } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import ScratchCardComponent from 'react-scratchcard';
import { toast } from 'react-toastify';
import { confirmScratchPrize, getScratchPrize } from '../../api/prizes';
import logo from "../../assets/Logo-Posher-Prize.png";
import HeaderSimple from '../../components/HeaderSimple';
import LoaderSpinner from '../../components/LoaderSpinner';
import { Button, Container, Input, ScratchCardContainer } from "./styles";

const ScratchCard = () => {
  let query = useQuery();
  const campaignId = query.get("campaign_id");
  const [customerCpf, setCustomerCpf] = useState('');
  const [prize, setPrize] = useState('');
  const [scratchMore, setScratchMore] = useState(false);
  const [scratchComplete, setScratchComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigation = useHistory()

  function useQuery() {
    let { search } = useLocation();

    search = search.replace('%26', '&');

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const screenWidth = Dimensions.get("screen").width;
  const width = screenWidth > 700 ? 720 : screenWidth > 400 ? 380 : 300
  const height = screenWidth > 700 ? width / 2.4 : screenWidth > 400 ? width / 2.4 : 150;


  const settings = {
    width: width,
    height: height,
    image: logo,
    finishPercent: 60,
    onComplete: () => confirmPrize()
  };

  const getPrize = async () => {
    setLoading(true)
    try {
      const response = await getScratchPrize(campaignId, customerCpf)
      setPrize(response)
    } catch (error) {
      toast.error(error.message)
      setCustomerCpf('')
    }
    setLoading(false)
  }

  const confirmPrize = async () => {
    setLoading(true)
    try {
      await confirmScratchPrize(campaignId, prize.id, customerCpf)
      toast.success("Prêmio resgatado com sucesso!")
    } catch (error) {
      toast.error(error.message)
    }
    setScratchComplete(true)
    setLoading(false)
  }

  useEffect(() => {
    if (scratchMore) {
      setTimeout(() => {
        setScratchMore(!scratchMore)
      }, 2000)
    }
  }, [scratchMore])

  useEffect(() => {
    if (prize) {
      var canvas = document.getElementsByClassName("ScratchCard__Canvas")[0];

      var ctx = canvas.getContext("2d");
      var img = new Image();
      img.onload = function () {
        ctx.globalCompositeOperation = "xor"
        ctx.drawImage(img, 0, 0);
        ctx.globalCompositeOperation = "and"
        ctx.drawImage(img, 0, 0, width, height);
      };
      img.src = logo;
    }
  }, [prize])

  useEffect(() => {
    if (!campaignId) {
      navigation.push('/home')
    }
  }, [])

  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <HeaderSimple />
      <LoaderSpinner loading={loading} />
      <Container>
        {prize ?
          <ScratchCardContainer >
            <span className="text-title" style={{ bottom: 100 }}>{prize.campaign_name}</span>
            {!scratchComplete &&
              <span className='text-info'>
                Pressione e arraste o {screenWidth > 700 ? "o mouse" : "o dedo na tela"} para raspar a imagem abaixo até poder visualizar seu prêmio!
              </span>
            }
            {(scratchMore && !scratchComplete) &&
              <span className='text-info'>
                continue raspando
              </span>
            }
            {scratchComplete &&
              <span className='text-title' style={{ left: 0 }}>Parabéns {prize.customer_name}, você ganhou: </span>
            }
            <div onClick={() => { setScratchMore(true) }} className="image-container">
              <ScratchCardComponent {...settings}>
                {!prize.image_url &&
                  <div style={{ height: 190, overflow: 'hidden' }}>
                    <div className='text-container'>
                      <span>
                        {prize.name}
                      </span>
                    </div>
                  </div>
                }
                {prize.image_url &&
                  <div style={{ height: height, overflow: 'hidden' }}>
                    <div className='text-container' style={{left: 5, top: 5}}>
                      <img src={scratchComplete ? prize.success_image_url : prize.image_url} alt={prize.name} height={width / 2.4} width={width}/>
                    </div>
                  </div>
                }
              </ScratchCardComponent>
            </div>
          </ScratchCardContainer>
          :
          <>
            <span className='text-title'>
              Digite seu CPF
            </span>
            <Input >
              <InputMask
                className="input"
                placeholder="999.999.999-99"
                value={customerCpf}
                mask="999.999.999-99"

                onChange={e => setCustomerCpf(e.target.value)}
              />
            </Input>
            <Button>
              <button
                type="button"
                disabled={customerCpf.length < 11 ? true : false}
                className={customerCpf.length < 11 ? "button-disabled" : "button-actived"}
                onClick={getPrize}
              >
                <span>confirmar</span>
              </button>
            </Button>
          </>

        }
      </Container>
    </div>
  )
}

export default ScratchCard;