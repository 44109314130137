import React, { useState } from 'react';
import { formatCurrencyBRL } from '../../../../../utils/utils';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { Container } from './styles';

const ProductCheckout = ({ product, doUpdate, showModal }) => {
    const [quantity, setQuantity] = useState(product.quantity);

    return (
        <Container>
            <div className="container-content">
                <img src={product.image_url} className="image" alt="content" />

                <div className="content">
                    <span className="title">{product.name}</span>
                    <span className="description">{product.description}</span>
                    <button
                        type="button"
                        onClick={() => showModal(true)}
                        className="button-more"
                    >
                        <span className="text-button-more">+ informações</span>
                    </button>
                </div>

                <div className="price">
                    <span className="text-price">R$ <span className="price-value">{formatCurrencyBRL(product.price).substring(2)}</span></span>
                </div>
            </div>

            <div className="back-quantity">
                <button className="button" onClick={() => { doUpdate('minus'); quantity > 0 && setQuantity(quantity => quantity - 1) }}>
                    <FiMinus width={17} color="#fff" />
                </button>
                <span className="quantity">{quantity}</span>
                <button className="button" onClick={() => { doUpdate('plus'); setQuantity(quantity => quantity + 1) }}>
                    <FiPlus width={17} color="#fff" />
                </button>
            </div>
        </Container>
    );
}

export default ProductCheckout;