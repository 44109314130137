import styled from "styled-components";

export const Title = styled.h1`
  color: #14213d;
  text-align: center;
  background: #fff;
  border-radius: 22px;
  overflow: hidden;
  opacity: 1;
  padding: 14px;
  width: 400px;
  font-size: 1.4rem;
  text-transform: uppercase;
  border: 1px solid #F7F7F7;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

  @media (max-width: 920px) {
    width: 20rem;
    font-size: 1.2rem;
  }

  @media (max-width: 560px) {
    font-size: 1rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1080px !important;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  max-height: 200px;
  background-color: #C4C4C4;
  margin-bottom: 60px;

  .title {
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .image-service-list {
    max-width: 1080px;
    max-height: 200px;
    width: auto;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #F7F7F7;
  }

  .image-service-list .service-banner {
    width: 1080px;
  }

  .online-view {
    position: absolute;
    top: 20px;
    left: 30px;
    background-color: #14213D;
    border-radius: 20px;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .online-view .online-text {
    margin-left: 5px;
  }

  @media(max-width: 1080px) {
    .image-service-list .service-banner {
      max-width: 100%;
    }
  }

  @media(max-width: 640px) {
    .image-service-list {
      height: 150px;
    }

    .online-view {
      top: 10px;
      left: 10px;
      width: 80px;
      height: 25px;
      font-size: 0.8rem;
    }
  }

  @media(max-width: 375px) {
    .image-service-list {
      height: 120px;
    }
  }
`;
