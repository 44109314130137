import styled from "styled-components";

export const ModalContainer = styled.div``;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f7f7;
`;

export const HeaderModal = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    padding: 10px 30px;
    text-align: left;
    font-size: 1.5rem;
    color: #000000;
`;

export const HeaderTitle = styled.span`
    font-size: 0.8rem;
`;

export const HeaderSubtitle = styled.span`
    font-weight: 700;
    font-size: 1.1rem;
`;

export const BodyModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 70vh;
    padding-bottom: 3rem;
`;

export const Input = styled.input`
    background: #cfcfcf;
    color: #000000;
    padding: 1rem 1.2rem;
    border-radius: 1rem;
`;

export const InputFile = styled.input`
    display: none;
`;

export const FileContainer = styled.div`
    label {
        background: #fa9f2a;
        color: #F3F3F3;
        padding: 1rem 2rem;
        border-radius: 1rem;
        display: block;
        cursor: pointer;
        transition: 0.3s ease-in;
    }
    &:hover {
        opacity: 0.8;
    }
`;

export const ContainerFooterModal = styled.div`
    width: 100%;
`;

export const CloseModal = styled.button`
    width: 50%;
    padding: 1rem;
    color: #fa9f2a;
    background-color: #FFFFFF;
    font-size: 1rem;
`;

export const SendModal = styled.button`
    width: 50%;
    padding: 1rem;
    font-size: 1rem;
    background-color: ${(props) => (props.disabled ? "#CFCFCF" : "#FA9F2A")};
`;

export const Warning = styled.span`
    margin-bottom: 0.2rem;
    color: #e63946;
`;
