import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    SERVICE_PACKAGES,
    SERVICE_PACKAGES_METHODS,
    SERVICE_PACKAGES_PURCHARSE,
    PAYMENTS,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

/*  Get */

const getServicePackages = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_PACKAGES}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else if (response.status === 404) {

            callback([]);
        } else {
            throw new Error('erro na chamada getServicePackages: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de pacotes');
    }
}

const getPackagePaymentsMethods = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PAYMENTS}${SERVICE_PACKAGES_METHODS}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getPaymentsMethods: ' + response.status);
        }

    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta dos métodos de pagamentos');
    }
}

const getPackageList = async (idCategory, callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_PACKAGES_PURCHARSE}?category_id=${idCategory}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getPackageList: ' + response.status);
        }

    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta da lista de pacotes');
    }
}

/*  Post */

const createOrderPackage = async (idPackage, paymentMethod, cardId) => {
    let validationError = false;
    try {
        let data = {
            payment_method: paymentMethod,
            card_id: cardId,
        };

        const { post_bearer } = header(data);

        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_PACKAGES}/${idPackage}/orders`, post_bearer);

        if (response.ok) {
            const responseJson = response.data;
            return responseJson;

        } else if (response.status === 400) {
            const responseJson = response.data;
            validationError = true;
            throw new Error(responseJson.message);

        } else {
            throw new Error('erro na chamada createOrderPackage: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar criação do pedido de pacote');
        }
    }
}

export {
    getServicePackages,
    getPackagePaymentsMethods,
    getPackageList,
    createOrderPackage,
}