import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    position: fixed;
    z-index: 10;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    bottom: 30px;

    .text-view {
        display: flex;
        border: 1px solid #FA9F2A;
        align-items: center;
        background-color: #FFFFFF;
        width: 600px;
        min-height: 80px;
        padding: 15px;
        border-radius: 30px 0 0 30px;
        justify-content: center;
    }

    .close-button {
        width: 40px;
        height: 40px;
        border: 1px solid #B7B7B7;
        border-radius: 100%;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -55px;
        background-color: #FFFFFF;
        color: #B7B7B7;
    }

    .close-button span {
        margin-top: -3px;
    }

    .text {
        margin-left: 20px;
        font-size: 1rem;
        color: #14213D;
    }

    .text span {
        font-weight: 700;
    }

    .button-go-to-service {
        width: 40px;
        border: 1px solid #FA9F2A;
        background-color: #FA9F2A;
        border-radius: 0 30px 30px 0;
        justify-content: center;
        margin-left: -2px;
    }

    @media(max-width: 960px) {
        bottom: 80px;
    }


    @media(max-width: 760px) {
        .text-view {
            width: 450px;
        }

        .close-button {
            margin-left: 0;
        }

        .text {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 620px) {
        .text-view {
            width: 350px;
        }

        .close-button {
            width: 40px;
            height: 30px;
        }

        .text {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 425px) {
        .text-view {
            width: 280px;
            min-height: 40px;
        }

        .close-button {
            width: 50px;
            height: 30px;
        }

        .text {
            font-size: 0.9rem;
        }
    }
`;