import {
  API_BASE,
  API_VERSION,
  CONFIRMATION,
  CUSTOMER_ORDERS,
  PAYMENT,
  
} from "../../helper/api_urls";
import { validateGetResponse } from "../../helper/validateGetResponse";
import { validatePutResponse } from "../../helper/validatePutResponse";

const getPaymentsConfirmed = async () => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}${PAYMENT}${CONFIRMATION}?page=0&limit=10`;

    return await validateGetResponse(
      getUrl,
      "Erro ao verificar pagamentos confirmados"
      );
  } catch (error) {
    throw error;
  }
};

const putPaymentsConfirmationUpdate = async (order_id) => {
  try {
    const putUrl = `${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${order_id}${PAYMENT}${CONFIRMATION}`;

    return await validatePutResponse(
      putUrl,
      "Erro ao atualizar pagamento confirmado"
    );
  } catch (error) {
    throw error;
  }
};

export { getPaymentsConfirmed, putPaymentsConfirmationUpdate };
