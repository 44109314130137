import React, { useEffect, useState } from "react";
import moment from "moment";
import { PixPageContainer, PixPageInfo } from "./styles";
import QRCode from "react-qr-code";
import { useResponsiveValue } from "@theme-ui/match-media";
import { FiCopy, FiAlertTriangle } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NeedPayTimer } from "../NeedPayTimer";
import { getDebouncePixPayment, getDebouncePixPaymentDemand } from "../../../../../api/service_orders";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export function PixPage({
  paymentExpirationMinutes,
  pixCode,
  slot,
  dayOfWeek,
  hourBegin,
  waitingList,
  offer,
  offerPix,
  order
}) {
  const [copy, setCopy] = useState(false);
  const history = useHistory()
  const numberSizes = useResponsiveValue(["100", "150", "180", "220"]);

  const copyPixCode = () => {
    setCopy(true);
    let timeout = setTimeout(() => setCopy(false), 2000);
    return () => clearTimeout(timeout);
  };

  async function debouncePaymentStatus() {
    try {
      let paidStatus;
      if (offerPix?.on_demand_order) {
        const { paid } = await getDebouncePixPaymentDemand(order.id)
        paidStatus = paid
      }
      if (!offerPix?.on_demand_order) {
        const { paid } = await getDebouncePixPayment(order.id)
        paidStatus = paid
      }
      if (paidStatus) {
        history.replace('/home')
      }
    } catch (err) {
      toast.error(err.message)
    }
  }

  useEffect(() => {
    let debounce = setInterval(() => debouncePaymentStatus(), 15000)
    return () => clearInterval(debounce)
  }, [])

  return (
    <main className="background-body">
      <PixPageContainer>
        <figure>
          <FiAlertTriangle size={55} color="#FA9F2A" />
        </figure>
        <CopyToClipboard
          text={pixCode}
          className="pix-view"
          onCopy={() => copyPixCode(pixCode)}
        >
          {copy ? (
            <div>
              <span className="copy-text">Código copiado!</span>
            </div>
          ) : (
            <div>
              <span className="pix-text">
                ou use o código Pix Copia e Cola!
              </span>
              <FiCopy size={20} color="#FFF" />
            </div>
          )}
        </CopyToClipboard>
        <h2 className="info-one-two-text">Pague via PIX em até</h2>
        <NeedPayTimer paymentExpirationMinutes={paymentExpirationMinutes} />
        <PixPageInfo>
          <h2>
            para CONFIRMAR O AGENDAMENTO{" "}
            <span>
              do serviço {offerPix ? offerPix.title : offer.title}{" "}
              {offerPix ? "em" : "com"}{" "}
              {offerPix ? offerPix.place_description : slot.professional.name}{" "}
              {!offerPix && dayOfWeek}
              {moment(hourBegin).tz("America/Sao_Paulo").format(" DD/MM")}
              {!waitingList
                ? ` às ${moment(hourBegin)
                  .tz("America/Sao_Paulo")
                  .format("HH:mm")}`
                : " para a lista de encaixe."}
            </span>
          </h2>
        </PixPageInfo>
        <div className="qr-code">
          <QRCode value={`${pixCode}`} size={numberSizes} />
        </div>
        <aside>
          <span>O AGENDAMENTO SERÁ CANCELADO</span>
          <p> se o pagamento não for realizado neste prazo. </p>
        </aside>
      </PixPageContainer>
    </main>
  );
}
