import * as Sentry from '@sentry/react';

const sentry = (error) => {
    if (
        !error.toString().includes('400') &&
        !error.toString().includes('403') &&
        !error.toString().includes('401') &&
        !error.toString().includes('429') &&
        !error.toString().includes('Network') &&
        !error.toString().includes('senha') &&
        !error.toString().includes('suporte')
    ) {
        Sentry.captureException(error);
    }
}

export default sentry;