import React from 'react';
import { FiClock, FiSend, FiCheck } from 'react-icons/fi';
import Header from '../../../components/Header';
import { InfoView, AndNowBanner, BackgroundImage } from './styles';

const PartnerCompletedOrder = ({ location }) => {
    const { partner } = location.state;

    return (
        <div>
            <Header screen="home" orderType="residential" />

            <BackgroundImage>
                <div className="background-body">
                    <div>
                        <div className="green-check-view">
                            <FiCheck color="#fff" size={70} />
                        </div>

                        <span className="text-ok">Tudo certo :)</span>
                    </div>

                    <div className="info-tags">
                        <InfoView>
                            <span className="info-text">Seu pedido foi concluído</span>
                        </InfoView>

                        <AndNowBanner>
                            <div className="and-now-banner">
                                <span className="and-now-text">E AGORA?</span>
                            </div>
                        </AndNowBanner>

                        <div className="one-two-banner">
                            <span className="one-two-number-text">1</span>

                            <div className="mail-clock-view">
                                <FiSend color="#000" size={15} />
                            </div>

                            <span className="one-two-text">Enviamos todas as informações do pedido para seu email.</span>
                        </div>

                        <div className="one-two-banner">
                            <span className="one-two-number-text">2</span>

                            <div className="mail-clock-view">
                                <FiClock color="#000" size={16} />
                            </div>

                            <span className="one-two-text">Seus produtos serão enviados em {partner.delivery_term}</span>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </div>
    );
}

export default PartnerCompletedOrder;