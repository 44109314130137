import styled from "styled-components";

export const Container = styled.div`
  .slot-body-content {
    display: none;
  }

  .choose-date {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .choose-one-date {
    text-align: center; 
    color: #707070; 
    font-size: 1.1rem;
  }

  .not-found-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .notFound {
    text-align: center; 
    color: #707070; 
    font-size: 1rem;
  }

  .notFoundVideo {
    color: #14213D;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
  }

  .container-dates-view {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .payment-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payment-view-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
  }

  .not-found-view {
    margin-top: 30px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-service-list {
    position: absolute;
    bottom: 30px;
    left: 0;
  }

  .background-body {
    padding-bottom: 150px;
  }

  .container-slot-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .not-found-view,
  .sessions-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .not-found-video,
  .text-title-session {
    color: #14213D;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .button-perfil {
    align-items: center;
    justify-content: center;
    position: relative;
    background: none;
  }

  .container-perfil {
    background: #FFF;
    position: absolute;
    padding: 0 20px;
    bottom: 0;
    right: 0;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px 0 18px 0;
  }
    
  .text-perfil {
    color: #E6860B;
    font-size: 1.2rem;
    font-weight: 300;
  }

  @media(max-width: 960px) {
    padding-bottom: 50px;

    .footer-service-list .line-separator {
      display: none;
    }
  }

  @media(max-width: 850px) {
    .background-body {
      padding-bottom: 50px;
    }
  }

  @media(max-width: 620px) {
    .not-found-video,
    .text-title-session {
      font-size: 1rem;
    }
  }
`;

export const ButtonVideoSection = styled.button`
  border: 1px solid #FA9F2A;
  border-radius: 18px;
  padding: 10px 20px;
  background-color: #FFF;
  text-align: center;
  text-transform: lowercase;
  color: #FA9F2A;
  font-size: 1rem;
  margin: 20px 0;
  transition: .2s background;

  :hover {
    background-color: rgba(250, 159, 42, 0.1);
  }
`;

export const DatesViewContainer = styled.div`
  margin-top: 5px;
  background: #f1f1f1;
  width: 700px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 15px 20px 30px;
  border-radius: 20px 20px 5px 5px;
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  ::-webkit-scrollbar {
    height: 10px;
    background: #f8f8f8;
    border-radius: 0 0 20px 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  ::-webkit-scrollbar-corner {
    border-radius: 20px;
  }

  @media(max-width: 870px) {
    width: 500px;
  }

  @media(max-width: 550px) {
    width: 400px;
  }

  @media(max-width: 450px) {
    width: 350px;
  }

  @media(max-width: 395px) {
    width: 300px;
  }

  @media(max-width: 325px) {
    width: 280px;
  }
`;

export const SlotView = styled.div`
  width: 700px;
  border: 1px solid #F7F7F7;
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  border-radius: 18px;
  padding: 5px 0;
  background: #fff;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

  .slot-professional-img {
    width: 130px;
    height: 130px;
    border-radius: 18px;
    margin-left: 5px;
  }

  .slot-price-view {
    position: absolute;
    border: 1px solid #14213D;
    border-radius: 17px 17px 0 17px;
    top: 0;
    right: 0;
    margin-left: 26px;
    background-color: #FFF;
    color: #14213D;
    padding: 5px 10px;
    font-size: 0.9rem;
    text-align: center;
  }

  .slot-professional-view {
    margin-left: 10px;
    margin-top: 5px;
  }

  .slot-info-professional {
    display: flex;
    align-items: center;
  }

  .slot-professional-name {
    color: #14213D;
    font-size: 1.2rem;
    font-weight: 700;
    width: 350px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
    margin-left: 10px;
  }

  .slot-info-hour {
    color: #707070;
    font-size: 0.9rem;
    margin-left: 10px;
  }

  @media(max-width: 870px) {
    width: 500px;

    .slot-professional-name {
      width: 180px;
      font-size: 0.95rem;
    }

    .professional-rating-view svg {
      width: 12px;
    }

    .slot-info-hour {
      font-size: 0.8rem;
    }

    .slot-professional-img {
      width: 110px;
      height: 110px;
    }
  }

  @media (max-width: 550px) {
    width: 400px;

    .slot-professional-name {
      width: 100px;
      font-size: 0.9rem;
    }

    .professional-rating-view svg {
      width: 11px;
    }

    .slot-info-hour {
      font-size: 0.8rem;
    }

    .slot-professional-img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 450px) {
    width: 350px;

    .slot-professional-name {
      width: 45px;
      margin-right: 10px;
    }

    .professional-rating-view svg {
      width: 11px;
    }

    .slot-info-hour {
      font-size: 0.8rem;
    }
  }

  @media(max-width: 375px) {
    width: 300px;

    .slot-professional-name {
      width: 45px;
      margin-right: 10px;
    }

    .slot-professional-img {
      width: 80px;
      height: 80px;
    }

    .professional-rating-view svg {
      width: 11px;
    }

    .slot-info-hour {
      font-size: 0.8rem;
    }
  } 
`;

export const SlotTimeView = styled.div`
  width: 550px;
  overflow: auto;
  display: flex;
  background: #f8f8f8;
  border-radius: 16px;
  padding: 5px;
  margin-top: 10px;

  ::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  ::-webkit-scrollbar {
    height: 10px;
    background: #f8f8f8;
    border-radius: 0 0 20px 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  ::-webkit-scrollbar-corner {
    border-radius: 20px;
  }

  @media (max-width: 870px) {
    width: 360px;
    padding: 3px;
    margin-top: 5px;
  }

  @media (max-width: 550px) {
    width: 280px;
    margin-top: 3px;
  }

  @media (max-width: 450px) {
    width: 230px;
  }

  @media (max-width: 375px) {
    width: 200px;
  }
`;

export const TextAreaEnabled = styled.textarea`
  height: 10rem;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  color: #14213D;
  border: 2px solid #FA9F2A;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  padding: 1rem;
`;