import React from "react";
import { FiGlobe } from "react-icons/fi";
import { Container, Title } from "./styles";

const TimeClockBanner = ({ bannerUrl, title, serviceType, isUrl }) => {
  return (
    <Container>
      <div className="image-service-list">
        <img
          src={!isUrl ? { uri: bannerUrl } : bannerUrl}
          className="service-banner"
          alt="service-banner"
        />
      </div>

      <div className="title">
        <Title>{title}</Title>
      </div>

      {"online" === serviceType && (
        <div className="online-view">
          <FiGlobe width={12} />
          <span className="online-text">online</span>
        </div>
      )}
    </Container>
  );
};

export default TimeClockBanner;
