import styled from "styled-components";

export const FilterInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  
  background-color: #f1f1f1;
  border-radius: 1rem;
`;

export const TextArea = styled.textarea`
  color: #14213d;
  background-color: #f1f1f1;
  width: 30rem;
  height: 10rem;
  padding: 0.8rem;
  border-radius: 1rem;

  resize: none;

  @media(max-width: 600px){
    width: 90vw;
  }
`;