import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    CUSTOMER_USERNAME,
    CUSTOMER_TOKEN,
    CUSTOMER_REFRESH_TOKEN,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

const makeUsernameLogin = async user => {
    let validationError = false;
    try {
        const { post } = header({
            username: user,
        });
        const response = await fetchWithTimeout(
            `${API_BASE}${API_VERSION}${CUSTOMER_USERNAME}`,
            post,
        );

        if (response.ok) {
            const data = response.data
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            throw new Error(`não foi possível fazer login: ${response.status}`);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar login');
        }
    }
};

const makePasswordLogin = async (username, password) => {
    let validationError = false;
    try {
        const { post } = header({ username, password });
        const response = await fetchWithTimeout(
            `${API_BASE}${API_VERSION}${CUSTOMER_TOKEN}`,
            post,
        );

        if (response.ok) {
            const data = await response.data

            return data;
        }
        if (response.status === 401) {
            const data = await response.data

            validationError = true;

            throw new Error(data.message);
        } else if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            throw new Error(`Erro ao realizar login ${response.status}`);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar login');
        }
    }
};

const refreshToken = async () => {
    try {
        const { post_bearer } = header();
        const response = await fetchWithTimeout(
            `${API_BASE}${API_VERSION}${CUSTOMER_REFRESH_TOKEN}`,
            post_bearer,
        );

        const responseJson = response.data;
        if (response.ok) {
            return {
                user: responseJson,
                success: true,
            };
        }
        if (response.status === 401 || response.status === 403) {
            return {
                success: false,
            };
        }
        throw new Error(responseJson.message);
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao obter token');
    }
};

export { makeUsernameLogin, makePasswordLogin, refreshToken };