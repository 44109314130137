import React, { useEffect, useState } from "react";
import { getOpenOrders } from "../../../api/service_orders";
import { getConcludedOrders } from "../../../api/service_orders";
import { getUser } from "../../../utils/auth";
import SwitchSelector from "react-switch-selector";
import OrderCard from "./OrderCard";
import { getDatesMinuteDifference } from "../../../utils/utils";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import TabButton from "../../../components/TabButton";
import { Container } from "./styles";
import LoaderSpinner from "../../../components/LoaderSpinner";
import SkeletonServiceOrderHistory from "./SkeletonServiceOrderHistory";
import Footer from "../../../components/Footer";
import { debounce } from 'throttle-debounce';

import { getAllChatsApi } from '../../../api/chats';

const ServiceOrderHistory = ({ location }) => {
  const selectedOrder = location.state ? location.state.selectedOrder : null;
  const [loading, setLoading] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [type, setType] = useState("open");
  const company = getUser()?.company;
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [allChats, setAllChats] = useState([]);

  const cancelLoading = () => {
    setTimeout(() => {
      if (document.getElementById('bodyServiceHistory')) {
        document.getElementById('bodyServiceHistory').style.display = 'flex';
      }
      setLoadingSkeleton(false);
      setLoading(false);
    }, 1000);
  }

  const searchOrders = async (type, setAllOrders, isMounted, setLoadingSkeleton, setRefreshing) => {
    try {
      setLoadingSkeleton(true);

      if ("open" === type) {
        await getOpenOrders(setAllOrders, 0, 30);
      } else if ("concluded" === type) {
        await getConcludedOrders(setAllOrders, 0, 30);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setAllOrders([]);
    } finally {
      cancelLoading();
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (document.getElementById('bodyServiceHistory')) {
        document.getElementById('bodyServiceHistory').style.display = 'none';
      }
      setLoadingSkeleton(true);
      setTimeout(() => {
        debounce(5000, searchOrders(type, setAllOrders, isMounted, setLoadingSkeleton))
        getAllChats(setAllChats);
      }, 1500);
    }

    return () => {
      isMounted = false;
    };
  }, [type]);

  let ordersComponents = allOrders?.map((order, orderIndex) => {
    let openStreamingModal = false;

    if (null != selectedOrder && selectedOrder.id === order.id) {
      openStreamingModal =
        getDatesMinuteDifference(order.scheduled_date, new Date()) <= 5;
    }
    return (
      <div className="container-order-card" key={orderIndex}>
        <OrderCard
          order={order}
          company={company}
          type={type}
          setAllOrders={setAllOrders}
          setLoading={setLoading}
          searchOrders={searchOrders}
          openStreamingModal={openStreamingModal}
          allChats={allChats}
        />
      </div>
    );
  });

  const getAllChats = async (setAllChats) => {
    try {
      await getAllChatsApi().then(val => setAllChats(val));
    } catch (error) {
      toast.error(error.message, 5000);
    }
  }

  useEffect(() => {
    if (allChats.length > 0) {
      let info = allChats.find(x => x.last_message_delivered === false && x.last_message_content !== null);
      if (info) {
        localStorage.setItem('hasMessage', true);
      } else {
        localStorage.setItem('hasMessage', false);
      }
    }
  }, [allChats]);

  return (
    <div>
      <Header screen="orders" orderType="corporate" />
      <LoaderSpinner loading={loading} />

      <Container>
        <div className="background-body">
          <div className="content-service-order-history" id="bodyServiceHistory">
            <div className="choose-type-view">
              <SwitchSelector
                initialSelectedIndex={0}
                onChange={(value) => {
                  setType(value);
                }}
                fontColor="#FA9F2A"
                selectedBackgroundColor="#FA9F2A"
                selectedFontColor="#FFF"
                backgroundColor="#FFF"
                buttonColor="#FA9F2A"
                borderColor="#FA9F2A"
                options={[
                  {
                    label: "serviços agendados",
                    value: "open",
                  },
                  {
                    label: "serviços concluídos",
                    value: "concluded",
                  },
                ]}
              />
            </div>
            {ordersComponents.length > 0 ?
              (<div className="order-list">
                {ordersComponents}
              </div>
              ) : (
                <span className="not-found-text">Nenhum serviço agendado.</span>
              )
            }
          </div>

          {loadingSkeleton &&
            <SkeletonServiceOrderHistory />
          }

          {!loadingSkeleton &&
            <div className="footer-service-list">
              <div className="line-separator"></div>
              <Footer />
            </div>
          }
        </div>
      </Container>

      <TabButton screen="orders" />
    </div>
  );
};

export default ServiceOrderHistory;
