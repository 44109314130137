import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalSite = ({ showModal, setShowModal, site }) => {
    return (
        <div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                overlayClassName="modal-overlay"
                className="modal-content site-url"
                ariaHideApp={false}
            >

                <iframe src={site} title="Site" />

                <button className="close-button" type="type" onClick={() => setShowModal(false)}>
                    <span>fechar</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalSite;