import { FiDollarSign } from "react-icons/fi";
import { Container } from "./style";
import Moment from 'moment-timezone';


const CashbackCard = ({ cashback }) => {

  const hasService = cashback.service_name ? true : false

  const orderTypeDict = {
    'corporate': 'Serviços no escritório',
    'residential': 'Serviços em casa (delivery)',
    'global': 'Serviços online'
  }

  return (
    <Container>
      <span className="cashback-title">{cashback.name}</span>
      <FiDollarSign width={15} className="card-icon"></FiDollarSign>
      <span className="cashback-text">{cashback.cashback_percentage}% OFF {hasService ? `no serviço ${cashback.service_name}` : 'em todos os serviços'}</span>
      <span className="validate">validade: {Moment(cashback.valid_from).format('DD/MM/YYYY')} até {Moment(cashback.valid_to).format('DD/MM/YYYY')}</span>
      <span className="cashback-text">{orderTypeDict[cashback.order_type]}</span>
    </Container>
  )
}

export default CashbackCard;