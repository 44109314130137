import React, { useState, useEffect } from 'react';
import HeaderBanner from '../components/HeaderBanner';
import ProductCheckout from './components/ProductCheckout';
import { formatCurrencyBRL } from '../../../utils/utils';
import PaymentView from '../../ServiceOrder/components/PaymentView';
import { makeCreatePartnerOrder } from '../../../api/partners';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import ModalMoreInformation from '../../../components/Modals/ModalMoreInformation';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import TabButton from '../../../components/TabButton';
import LoaderSpinner from '../../../components/LoaderSpinner';

const PartnersCheckout = ({ location }) => {
    const navigation = useHistory();
    const [loading, setLoading] = useState(false);
    const { partner, address } = location.state;
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [total, setTotal] = useState(0.0);
    const [descriptionSelected, setDescriptionSelected] = useState();
    const [methodPayment, setMethodPayment] = useState({});

    const makePaymentProduct = async () => {
        try {
            setLoading(true);
            const arrayProd = [];

            products.map(product => {
                arrayProd.push({ id: product.id, quantity: product.quantity });
            });

            let response = await makeCreatePartnerOrder(partner.id, address.id, methodPayment.method, methodPayment.card, arrayProd);

            if (response.id) {
                setLoading(false);
                toast.success('Pedido feito com sucesso!');

                navigation.push('/partnerCompletedOrder', { partner: partner });
            }
            setLoading(false);

        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const getProductsBySync = () => {
        setProducts(JSON.parse(localStorage.getItem('products')) || []);
    }

    const updateQuantity = (product, sign) => {
        let value = products.findIndex(prod => prod.id === product.id);

        if (sign === 'plus') {
            product.quantity += 1;
            products.splice(value, 1, product);
            localStorage.setItem('products', JSON.stringify(products));
        } else if (product.quantity > 1) {
            product.quantity -= 1;
            products.splice(value, 1, product);
            localStorage.setItem('products', JSON.stringify(products));
        } else {
            products.splice(value, 1);
            localStorage.setItem('products', JSON.stringify(products));
        }

        setTotal(totalCalculate(products));
    }

    const totalCalculate = () => {
        var value = 0;

        products.forEach(product => {
            value += (product.price * product.quantity);
        });

        return value;
    }

    const goBack = () => {
        navigation.push("/partner", { partner: partner, address: address });
    }

    useEffect(() => {
        getProductsBySync();
    }, []);

    useEffect(() => {
        if (products.length > 0 && total === 0.0) {
            setTotal(totalCalculate(products));
        }
    }, [products]);

    return (
        <Container>
            <Header screen="home" orderType="residential" />
            <LoaderSpinner loading={loading} />

            {showModal &&
                <ModalMoreInformation
                    showModal={showModal}
                    setShowModal={val => setShowModal(val)}
                    title={descriptionSelected?.name}
                    body={descriptionSelected?.description}
                />
            }

            <div className="background-body">
                <HeaderBanner partner={partner} showShoppingCard={false} />

                <div className="container-body">
                    <div className="container-products">
                        {products.length > 0 ?
                            products.map(product => {
                                return (
                                    <ProductCheckout
                                        key={product.id}
                                        product={product}
                                        doUpdate={sign => updateQuantity(product, sign)}
                                        showModal={val => { setDescriptionSelected(product); setShowModal(val) }}
                                    />
                                );
                            })
                            :
                            <span className="message-text">não há produtos no seu carrinho!</span>
                        }
                    </div>

                    {products.length > 0 ?
                        <button
                            type="button"
                            className="button-continue"
                            onClick={goBack}
                        >
                            <span className="text-continue">continue comprando</span>
                        </button>
                        :
                        <button
                            type="button"
                            className="button-go-back"
                            onClick={goBack}
                        >
                            <span className="text-go-back">voltar para produtos</span>
                        </button>
                    }

                    {products.length > 0 &&
                        <div className="container-payments">
                            <span className="title-payment">PAGAMENTO</span>

                            <span className="address-text">Envio para: <span className="text-description">{address.description}</span></span>

                            <PaymentView
                                partner={partner}
                                orderType={'residential'}
                                setCheckedPaymentMethod={setMethodPayment}
                                checkedPaymentMethod={methodPayment}
                                setLoading={setLoading}
                            />

                            <span className="total">Total: R$<span className="total-value">{formatCurrencyBRL(total).substring(2)}</span></span>

                            <button
                                type="button"
                                disabled={methodPayment.card !== null ? false : true}
                                className={methodPayment.card !== null ? "button-finish" : "button-disabled"}
                                onClick={makePaymentProduct}
                            >
                                <span className="text-finish">concluir pedido</span>
                            </button>
                        </div>
                    }
                </div>

                <div className="footer-service-list">
                    <div className="line-separator"></div>
                    <Footer />
                </div>
            </div>

            <TabButton screen="home" />
        </Container>
    );
}

export default PartnersCheckout;