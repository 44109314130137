import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    width: 800px;
    background-color: #FFF;
    border: 1px solid #F2F2F2;
    border-radius: 20px;
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
    padding: 10px;
    position: relative;
    transition: .2s background;

    :hover {
        background-color: #F7F7F7;
    }

    :hover .view-button {
        background-color: #E6860B;
    }

    .button-view img{
        width: 100px;
        height: 100px;
    }

    .button-view {
        margin: 0 20px 0 0;
    }

    .container-info-about {
        display: flex;
        flex-direction: column;
        color: #14213D;
        text-align: left;
    }

    .button-title {
        font-size: 1.2rem;
        font-weight: 700;
        max-width: 550px;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
    }

    .recorded-at-view {
        font-size: 1rem;
        margin-top: 10px;
        display: flex;
        align-items: center;
    }

    .recorded-at-view span {
        margin-left: 5px;
    }

    .view-button {
        position: absolute;
        background-color: #FA9F2A;
        right: -2px;
        top: -2px;
        bottom: -2px;
        border-radius: 0 20px 20px 0;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        transition: .2s background;
    }

    @media(max-width: 850px) {
        width: 600px;

        .button-title {
            width: 350px;
        }
    }

    @media(max-width: 640px) {
        width: 500px;

        .button-title {
            width: 320px;
        }

        .button-view img {
            width: 80px;
            height: 80px;
        }

        .button-view {
            margin: 0 10px 0 0;
        }

        .view-button {
            width: 60px;
        }
    }

    @media(max-width: 520px) {
        width: 400px;
        padding: 5px;

        .button-title {
            width: 230px;
        }
    }

    @media(max-width: 425px) {
        width: 350px;

        .button-title {
            width: 200px;
        }

        .view-button {
            width: 45px;
        }
    }

    @media(max-width: 375px) {
        width: 300px;

        .button-title {
            width: 150px;
        }
    }
`;