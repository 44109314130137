import styled from 'styled-components';

export const Container = styled.div`
    .payment-cupon-button {
        background-color: #FA9F2A;
        display: flex;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        padding: 8px 10px;
        transition: .3s background-color;
    }

    .payment-cupon-button svg {
        margin-top: 2px;
    }

    .payment-cupon-button span {
        margin-left: 5px;
        font-size: 1rem;
    }

    .payment-cupon-button:hover {
        background-color: #E6860B;
    }

    @media(max-width: 450px) {
        .payment-cupon-button svg {
            width: 14px;
        }

        .payment-cupon-button span {
            font-size: 0.85rem;
        }
    }

`;