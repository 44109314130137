import React from "react";
import { FiMail, FiClock, FiUserCheck, FiKey } from "react-icons/fi";
import AvailableNow from "../../components/AvailableNow";
import { getDayName } from "../../../../utils/utils";
import Moment from "moment-timezone";
import { Container, InfoView, AndNowBanner } from './styles';
import Header from "../../../../components/Header";
import TabButton from "../../../../components/TabButton";
import { useHistory } from "react-router-dom";
import { PixPage } from "../../Order/components/PixPage";

const CompleteOnDemandOrder = ({ location }) => {
  const history = useHistory();

  if (!location.state) {
    history.goBack();
  }

  const {
    state: {
      orderType,
      scheduledFrom,
      scheduledUntil,
      offer,
      address,
      paymentMethod,
      pixCode,
      paymentExpirationMinutes,
      order
    } = {}
  } = location;

  let dayOfWeek = getDayName(scheduledFrom);

  return (
    <Container>
      <Header orderType={orderType} address={address} screen="home" />

      {paymentMethod === "pix" ?
        <PixPage
          paymentExpirationMinutes={paymentExpirationMinutes}
          pixCode={pixCode}
          offerPix={offer}
          dayOfWeek={scheduledFrom}
          hourBegin={scheduledFrom}
          offer={offer}
          order={order}
        />
        :
        <div className="background-body">

          <div>
            <div className="yellow-clock-view">
              <FiClock color="#707070" size={70} />
            </div>

            <span className="text-waiting">Recebemos sua solicitação</span>
          </div>

          {"online" === offer?.service_type && (
            <div className="info-tags">
              <InfoView>
                <span className="info-text">Sua solicitação de {offer?.title} para {dayOfWeek} está aguardando confirmação.</span>
              </InfoView>


              <AndNowBanner>
                <div className="and-now-banner">
                  <span className="and-now-text">E AGORA?</span>
                </div>
              </AndNowBanner>

              <div className="one-two-banner">
                <span className="one-two-number-text">1</span>
                {paymentMethod !== "pix" ? (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiMail color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Enviamos um email com um resumo do seu pedido.
                    </span>
                  </div>
                ) : (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiKey color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Pague com PIX usando a chave fornecida ou o QR Code enviado em seu email.
                    </span>
                  </div>
                )}
              </div>

              <div className="one-two-banner">
                <span className="one-two-number-text">2</span>
                {paymentMethod !== "pix" ? (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Enviaremos em breve a confirmação do horário e profissional para seu serviço por email.
                    </span>
                  </div>
                ) : (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiUserCheck color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      O agendamento será cancelado se o pagamento não for realizado em até 10 minutos.
                    </span>
                  </div>
                )}
              </div>

              {paymentMethod !== "pix" && (
                <div className="one-two-banner">
                  <span className="one-two-number-text">3</span>
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      No horário da sessão, clique no link (no email, agenda ou app POSHER) e boa sessão.
                    </span>
                  </div>
                </div>
              )}

              <div className="avaliable-now">
                <AvailableNow
                  orderType={orderType}
                  address={address}
                  title="DISPONÍVEL AGORA!"
                />
              </div>
            </div>
          )}

          {"online" !== offer?.service_type && (
            <div className="info-tags">
              {paymentMethod !== "pix" ? (
                <InfoView>
                  <span className="info-text">Sua solicitação de {offer?.title} para {dayOfWeek} {Moment(scheduledFrom).format("DD/MM")} está aguardando confirmação.</span>
                </InfoView>
              ) : (
                <InfoView>
                  <span className="info-text">Sua solicitação de {offer?.title} para {dayOfWeek} {Moment(scheduledFrom).format("DD/MM")} está aguardando o pagamento.</span>
                </InfoView>
              )}

              <AndNowBanner>
                <div className="and-now-banner">
                  <span className="and-now-text">E AGORA?</span>
                </div>
              </AndNowBanner>

              <div className="one-two-banner">
                <span className="one-two-number-text">1</span>
                {paymentMethod !== "pix" ? (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiMail color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Enviamos um email com um resumo do seu pedido
                    </span>
                  </div>
                ) : (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiKey color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Pague com PIX usando a chave fornecida ou o QR Code enviado em seu email.
                    </span>
                  </div>
                )}
              </div>

              <div className="one-two-banner">
                <span className="one-two-number-text">2</span>
                {paymentMethod !== "pix" ? (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Enviaremos em breve um email com a confirmação do horário e profissional para seu serviço.
                    </span>
                  </div>
                ) : (
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      O agendamento será cancelado se o pagamento não for realizado em até 10 minutos.
                    </span>
                  </div>
                )}
              </div>

              {paymentMethod !== "pix" && (
                <div className="one-two-banner">
                  <span className="one-two-number-text">3</span>
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      No horário agendado o profissional fará seu atendimento.
                    </span>
                  </div>
                </div>
              )}

              <div className="avaliable-now">
                <AvailableNow
                  orderType={orderType}
                  address={address}
                  title="DISPONÍVEL AGORA!"
                />
              </div>
            </div>
          )}
        </div>
      }
      <TabButton screen="home" />
    </Container>
  );
};

export default CompleteOnDemandOrder;
