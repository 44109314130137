import styled from "styled-components";

export const PixPageContainer = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
  margin-bottom: 10rem;

  .copy-text {
    font-size: 1.4rem;
    color: #FFF;
  }
  
  .pix-view {
    background-color: #FA9F2A;
    width: 550px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    height: 60px;
    align-self: center;
    align-items: center;
    margin: 20px 0;
    cursor: pointer;
  }

  .pix-text {
    font-size: 1.4rem;
    color: #FFF;
    margin-right: 20px;
  }
  
  .qr-code {
    margin: 20px 0 0;
    background-color: #FFF;
    width: 550px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-self: center;
    align-items: center;
  }

  .info-one-two-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  figure {
    padding: 0.6rem;
    border-radius: 50%;
    background-color: #ffff00;
  }

  h2 {
    margin-top: 1rem;
  }

  span {
    font-weight: bold;
    font-size: 1.4rem;
  }

  p {
    font-size: 1.1rem;
  }

  aside {
    padding: 1rem;
    border-radius: 1rem;
    width: 550px;
    margin-top: 2rem;
    background: #FFFFFF;
    color: #000;
  }
`;

export const PixPageInfo = styled.div`
  margin: 1rem 0;
  span {
    font-size: 1.1rem;
  }
`;
