import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPartnerAddressValidation } from '../../api/partners';
import { Container } from './styles';
import ModalAddress from '../Modals/ModalAddress';

const ButtonsPartner = (props) => {
    const navigation = useHistory();
    const partner = props.partner;
    const [selectAddress, setSelectAddress] = useState(false);
    const [selectAddressMessage, setSelectAddressMessage] = useState('');

    const navigatePartner = (address) => {
        localStorage.removeItem('products');
        navigation.push("/partner", { partner: partner, address: address });
    }

    const afterSelectAddress = async (address) => {
        try {
            let available = await getPartnerAddressValidation(partner.id, address.id);

            if (available.available) {
                setSelectAddress(false);

                navigatePartner(address);
                setSelectAddressMessage('');
            } else {
                setSelectAddressMessage('Parceiro indisponível no endereço selecionado');
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    return (
        <div>
            <ModalAddress
                showModalAddress={selectAddress}
                setShowModalAddress={setSelectAddress}
                afterSelect={address => afterSelectAddress(address)}
                warningMessage={selectAddressMessage}
                checkResidentialAvailability={true}
                message={`Selecione o endereço onde deseja receber os produtos do nosso parceiro `}
                partner={partner.name}
            />

            <Container type="button" onClick={() => { setSelectAddress(true); setSelectAddressMessage('') }}>
                <div className="img-button">
                    <img src={partner.thumb_url} alt={partner.name} />
                </div>
                
                <h3 className="name-button">{partner.name}</h3>
            </Container>
        </div>
    );
}

export default ButtonsPartner;