import styled from 'styled-components';

export const Container = styled.footer`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 960px) {
        display: none;
    }
`;

export const LeftInfos = styled.div`
    display: flex;
    align-items: center;
    max-width: 500px;

    .logo-posher > img {
        width: 35px;
    }

    .logo-posher {
        margin: 0 30px;
    }
`;

export const RightInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
`;

export const About = styled.div`
    display: flex;
    flex-direction: column;  
    
    .copyright,
    .address {
        color: #717171;
        font-size: 0.8125rem;
        line-height: 1.35;
        font-weight: 300;
    }
`;

export const GridTerms = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    margin-bottom: 20px;
    text-align: center;

    .button-helpers {
        text-decoration: none;
    }

    .button-helpers > span {
        color: #717171;
        font-size: 0.95rem;
        font-weight: 500;
        transition: .5s color;
    }

    .button-helpers:hover > span {
        color: #14213D;
    }
`;

export const GridSuport = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    text-align: center;
    margin-right: 20px;

    .title-suport {
        color: #717171;
        font-size: 0.95rem;
        font-weight: 500;
        margin-bottom: 2px;
        transition: .5s color;
        text-decoration: none;
    }

    .title-suport:hover {
        color: #14213D;
    }
`;