import styled from 'styled-components';
import imgSrc from "../../../assets/bg-login.png";

export const Container = styled.div`
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${imgSrc});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 100vh;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .content-register {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 70px 0;
    }

    .login-text {
        font-size: 2rem;
        color: #fff;
        margin-bottom: 20px;
    }

    .select-input,
    .inputs {
        width: 600px;
        text-align: center;
        margin: 10px 0;
        font-size: 1.2rem;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #CFCFCF;
        padding: 0 40px;
        background-color: #fff;
        color: #707070;
    }

    .select-input:focus,
    .inputs:focus {
        border: 2px solid #FA9F2A;
    }

    .input-container-select {
        position: relative;
    }

    .icon-select-input {
        position: absolute;
        top: 25px;
        right: 20px;
    }

    .select-input::-ms-expand {
        display: none;
    }

    .select-input {
        text-align: center;
        text-align-last: center;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }

    .select-input::-webkit-scrollbar-track {
        background-color: #f7f7f7;
        border-radius: 20px;
        -webkit-border-radius: 20px;
    }

    .select-input::-webkit-scrollbar {
        width: 7px;
        background: #f7f7f7;
        border-radius: 0 0 20px 20px;
    }

    .select-input::-webkit-scrollbar-thumb {
        background: #dad7d7;
        border-radius: 20px;
        -webkit-border-radius: 20px;
    }

    .select-input::-webkit-scrollbar-corner {
        border-radius: 20px;
    }

    .input-disabled {
        background-color: #CFCFCF;
        color: #FFF;
    }

    .input-password-container {
        position: relative;
    }

    .border-icon-input {
        position: absolute;
        right: 15px;
        top: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background-color: #FA9F2A;
        border-radius: 100%;
    }

    .buttom-view {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        background-color: #FA9F2A;
        border-radius: 25px;
        padding: 15px 0;
        width: 400px;
        font-size: 1.2rem;
        transition: .2s background;
    }

    .buttom-view:hover {
        background-color: #E6860B;
    }

    .recaptcha {
        margin-top: 30px;
    }

    @media(max-width: 820px) {
        .select-input,
        .inputs {
            width: 500px;
            font-size: 1rem;
        }

        .buttom-view {
            width: 350px;
            font-size: 1rem;
        }

        .login-text {
            font-size: 1.5rem;
        }
    }

    @media(max-width: 560px) {
        .select-input,
        .inputs {
            width: 400px;
        }

        .buttom-view {
            width: 300px;
        }

        .login-text {
            font-size: 1.4rem;
        }
    }

    @media(max-width: 425px) {
        .select-input,
        .inputs {
            width: 300px;
            height: 50px;
            font-size: 1rem;
        }

        .buttom-view {
            width: 250px;
            font-size: 1rem;
        }

        .login-text {
            font-size: 1.2rem;
        }

        .icon-select-input {
            top: 20px;
            right: 10px;
        }

        .border-icon-input {
            top: 20px;
            right: 10px;
            padding: 5px 8px;
        }

        .border-icon-input svg {
            width: 15px;
        }
    }
`;