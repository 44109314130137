import React, { useState, useEffect } from 'react';
import Moment from 'moment-timezone';
import { ImageBackground, BackgroundImageColor, BackgroundView, GreenCheck, GreenCheckView, InfoText, InfoView, AllOk } from "./styles";
import { FiCheck } from 'react-icons/fi';
import { ButtonCheck, TextCheck } from '../SchedulePosition/styles';
import { useHistory } from 'react-router-dom';
import Header from '../../../../../components/Header';
import TabButton from '../../../../../components/TabButton';
import { ButtonOpacity } from '../components/ButtonOpacity';
import ModalReview from '../../../../../components/Modals/ModalReview';
import { t } from 'i18next'

const Resume = ({ location }) => {
    const history = useHistory();
    if(!location?.state?.data){
      history.replace('/home')
    }

    const [schedule, setSchedule] = useState({});
    
    useEffect(()=>{
      let isMounted = true;
      if(isMounted){
          window.scrollTo(0,0)
        setSchedule(location?.state?.data)
      }
      return () => { isMounted = false }
    },[])
    
    return (
        <>
            <Header screen="home" />

            <ImageBackground>
                <BackgroundView >
                    <BackgroundImageColor >
                        <div>
                            <GreenCheckView>
                                <GreenCheck>
                                    <FiCheck width="100px" height="100px" />
                                </GreenCheck>
                            </GreenCheckView>

                            <AllOk>{t("allRightText")}</AllOk>
                        </div>

                        <div>
                            <InfoView>
                                <InfoText style={{width: "100vw"}}>{t("schedulingOfText")}
                                    <span style={{ fontWeight: "700" }}> {schedule.type === 'parking_space' ? t("vacancyWord") : schedule.type === 'room' ? t("roomWord") : t("positionWord")}
                                        {schedule.positionCode} </span>
                                    {t("inBuilding")}
                                    <span style={{ fontWeight: "700" }}> {schedule.building}</span> {t("hasBeenConfirmedFor")}
                                    <span style={{ fontWeight: "700" }}> {Moment(schedule.date).tz(Moment.tz.guess()).format('DD/MM')}</span>, {t("fromTimePicker").toLowerCase()}
                                    <span style={{ fontWeight: "700" }}> {schedule.hourFrom}</span> {t("toTimePicker").toLowerCase()}
                                    <span style={{ fontWeight: "700" }}> {schedule.hourTo}.</span>
                                </InfoText>

                                <ModalReview/>

                                <ButtonOpacity>
                                    <ButtonCheck
                                        onClick={() => history.push('/home')}
                                    >
                                        <TextCheck>{t("homeTabBar")}</TextCheck>
                                    </ButtonCheck>
                                </ButtonOpacity>

                            </InfoView>
                        </div>
                    </BackgroundImageColor>
                    <TabButton screen="home" />
                </BackgroundView>
            </ImageBackground>
        </>
    );
};

export default Resume;
