import styled from "styled-components";

export const ContainerButtonOrange = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    height: 100%;
    position: absolute;
`;