import React, { useEffect, useState } from "react";
import { Container, InputContainer, InputContainerCPF } from "./styles";
import { getUser } from "../../../utils/auth";
import { getCustomer, makeUpdateCustomer, updateLanguage } from "../../../api/customer";
import { toast } from "react-toastify";
import Header from "../../../components/Header";
import LoaderSpinner from "../../../components/LoaderSpinner";
import InputMask from 'react-input-mask';
import { FiEdit2, FiArrowLeftCircle } from 'react-icons/fi';
import { RiArrowDownSFill } from 'react-icons/ri';
import Footer from "../../../components/Footer";
import SkeletonPersonalData from "./SkeletonPersonalData";
import TabButton from "../../../components/TabButton";
import { debounce } from 'throttle-debounce';
import ModalCancelAccount from "../../../components/Modals/ModalCancelAccount";
import { t } from 'i18next'
import i18n from "i18next";

const PersonalData = () => {
  const [loading, setLoading] = useState('');
  const [email, setEmail] = useState('');
  const [name, setFullName] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [gender, setGender] = useState('');
  const [birthdayDate, setBirthdayDate] = useState('');
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [language, setLanguage] = useState(i18n.language);

  let customer = getUser().customer;

  const cancelLoading = () => {
    setTimeout(() => {
      if (document.getElementById('personalDataBody')) {
        document.getElementById('personalDataBody').style.display = 'flex';
      }
      setLoadingSkeleton(false);
    }, 1000);
  }

  const changeLanguage = async (selectedLanguage) => {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("userSelectedLanguage", selectedLanguage);
    await updateLanguage(selectedLanguage);
  }

  const updateCustomer = async () => {
    try {
      setLoading(true);

      await makeUpdateCustomer(email, name, mobilePhone, gender, birthdayDate ? birthdayDate : "");
      await changeLanguage(language);

      getUser().customer.email = email;
      getUser().customer.name = name;
      getUser().customer.mobile_phone = mobilePhone;
      getUser().customer.gender = gender;
      getUser().customer.birthday_date = birthdayDate;

      getCustomers();

      setLoading(false);
      toast.success("Dados atualizados com sucesso", { autoClose: 3000 });
    } catch (error) {
      setLoading(false);
      let errorMessage = error.message;
      let validationMessages = [];

      if (errorMessage.indexOf("email") >= 0) {
        validationMessages.push("Email");
      }

      if (errorMessage.indexOf("name") >= 0) {
        validationMessages.push("Nome");
      }

      if (errorMessage.indexOf("mobilePhone") >= 0) {
        validationMessages.push("Telefone");
      }

      if (validationMessages.length > 0) {
        toast.warn(
          "Favor preencher os campos obrigatórios:\n- " +
          validationMessages.join("\n- ")
        );
      } else {
        toast.error("Erro ao atualizar dados: " + errorMessage);
      }
    }
  };

  const getCustomers = async () => {
    try {
      if (document.getElementById('personalDataBody')) {
        document.getElementById('personalDataBody').style.display = 'none';
      }
      setLoadingSkeleton(true);

      const data = await getCustomer();

      setFullName(data.name);
      setGender(data.gender);
      setEmail(data.email);
      setMobilePhone(data.mobile_phone);
      setBirthdayDate(data.birthday_date);

      if (data.id) {
        if (data.invalid_email) {
          sessionStorage.setItem('invalid_email', true);
        } else {
          sessionStorage.removeItem('invalid_email');
        }
      }

      cancelLoading();

    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (document.getElementById('personalDataBody')) {
        document.getElementById('personalDataBody').style.display = 'none';
      }
      setLoadingSkeleton(true);
      setTimeout(() => debounce(5000, getCustomers()), 1500);
    }

    return () => { isMounted = false };
  }, []);

  return (
    <Container>
      <Header orderType="corporate" screen="user" />
      <LoaderSpinner loading={loading} />

      {showModalCancel &&
        <ModalCancelAccount
          setShowModalCancel={setShowModalCancel}
          showModalCancel={showModalCancel}
        />
      }

      <div className="background-body">
        {loadingSkeleton &&
          <SkeletonPersonalData />
        }

        <div className="content-body" id="personalDataBody">
          <div className="title-personal-data">
            <span>{t("toChangeYourData")}</span>
            <span>{t("justClickAndChangeData")}</span>
          </div>

          {"BR" === customer.country && (
            <InputContainerCPF disabled>
              <span className="input-name-text">CPF</span>
              <InputMask
                className="input"
                placeholder="CPF"
                disabled
                value={customer.cpf}
                mask="999.999.999-99"
              />
            </InputContainerCPF>
          )}

          {"BR" !== customer.country && (
            <InputContainerCPF disabled>
              <span className="input-name-text">Documento de identificação</span>
              <input
                className="input"
                placeholder="Documento de identificação"
                disabled
                value={customer.identification_document}
              />
            </InputContainerCPF>
          )}

          <InputContainer>
            <span className="input-name-text">{t("name")}</span>
            <input
              className="input-active"
              value={name}
              placeholder="nome completo"
              onChange={e => setFullName(e.target.value)}
            />
            <div className="icon-personal-input">
              <FiEdit2 size={20} color="#FA9F2A" />
            </div>
          </InputContainer>

          <InputContainer>
            <span className="input-name-text">{t("usernamePlaceholder")}</span>
            <input
              className="input-active"
              placeholder="email@cliente.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <div className="icon-personal-input">
              <FiEdit2 size={20} color="#FA9F2A" />
            </div>
          </InputContainer>

          <InputContainer>
            <span className="input-name-text">{t("phoneNumberPlaceholder")}</span>
            <InputMask
              className="input-active"
              value={mobilePhone}
              placeholder="número de telefone"
              onChange={e => setMobilePhone(e.target.value)}
              mask="(99) 9 9999-9999"
              maskChar=""
            />
            <div className="icon-personal-input">
              <FiEdit2 size={20} color="#FA9F2A" />
            </div>
          </InputContainer>

          <InputContainer>
            <span className="input-name-text">
              {t("gender")}
            </span>
            <div className="styled">
              <select id="selectTimeTo" value={gender} onChange={gen => setGender(gen.target.value)}>
                <option disabled selected={gender ? false : true}>Com qual gênero você se identifica?</option>
                <option label="Masculino">Masculino</option>
                <option label="Feminino">Feminino</option>
                <option label="Outro">Outro</option>
              </select>
            </div>
            <div className="icon-personal-input select">
              <RiArrowDownSFill size={25} color="#FA9F2A" />
            </div>
          </InputContainer>

          <InputContainer>
            <span className="input-name-text">
              {t("birthDate")}
            </span>
            <input
              className="input-active date"
              type="date"
              value={birthdayDate}
              placeholder="data de nascimento"
              onChange={e => setBirthdayDate(e.target.value)}
            />
            <div className="icon-personal-input date">
              <FiArrowLeftCircle size={22} color="#FA9F2A" />
            </div>
          </InputContainer>

          <InputContainer>
            <span className="input-name-text">
              {t("languageText")}
            </span>
            <div className="styled">
              <select id="selectLanguage" value={language} onChange={lang => changeLanguage(lang.target.value)}>
                {!language && (
                  <option label={t("selectALanguage")} />
                )}
                <option label={t("portuguese")}>ptBR</option>
                <option label={t("english")}>en</option>
                <option label={t("spanish")}>es</option>
              </select>
            </div>
            <div className="icon-personal-input select">
              <FiEdit2 size={20} color="#FA9F2A" />
            </div>
          </InputContainer>

          <button type="button" className="buttom-view" onClick={updateCustomer}>
            <span>
              {t("updateAccount")}
            </span>
          </button>
          { customer?.can_remove_registration && (
            <button type="button" className="button-remove-account" onClick={() => {setShowModalCancel(true)}}>
              <span>
                {t("deleteAccount")}
              </span>
            </button>
          )}
        </div>

        {!loadingSkeleton &&
          <div className="footer-service-list">
            <div className="line-separator"></div>
            <Footer />
          </div>
        }
      </div>

      <TabButton screen="account" />
    </Container>
  );
};

export default PersonalData;
