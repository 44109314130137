import React, { useEffect, useState } from 'react';
import { getDiscountCoupons } from '../../../../api/discount_coupons';
import { FiTag } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, ButtonCoupon } from './styles';
import { getAvailableCashbacks } from '../../../../api/cashback';
import {t} from 'i18next'

const DiscountCouponsBanner = () => {
    const history = useHistory();
    const [allCoupons, setAllCoupons] = useState([]);
    const [allCashbacks, setAllCashbacks] = useState([]);

    const couponsCount = allCoupons.length;
    const cashbackCount = allCashbacks.length;

    const searchDiscountCoupons = async (setAllCoupons) => {
        try {
            await getDiscountCoupons(setAllCoupons)
        } catch (error) {
            toast.error(error.message);
            setAllCoupons([]);
        }
    }

    const searchAvailableCashbacks = async (setAllCashbacks) => {
        try {
            await getAvailableCashbacks().then(cashback => setAllCashbacks(cashback));
        } catch (error) {
            toast.error(error.message);
            setAllCashbacks([]);
        }
    }

    const goToCoupons = () => {
        history.push("/discountCoupons")
    }

    const buildBannerText = (couponsCount, cashbackCount) => {
        let text;
        if (couponsCount < 1 && cashbackCount < 1) {
            text = t("youDontHaveCouponsAvailable");
        } else if (couponsCount > 0 && cashbackCount > 0) {
            text = t("youHaveCouponsAndCashbacks");
        } else {
            text = t("youHaveText");
            text += (couponsCount > 0 ? t("couponsWord") : t("cashbackWord")) + t("availableWord")
        }

        return text;
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            searchDiscountCoupons(setAllCoupons);
            searchAvailableCashbacks(setAllCashbacks);
        }
        return () => { isMounted = false; };
    }, []);

    return (
        <Container>

            {buildBannerText(couponsCount, cashbackCount)}

            {(couponsCount > 0 || cashbackCount > 0) &&
                <ButtonCoupon onClick={goToCoupons}>
                    <FiTag size={20} color="#fff" />
                    <span className="text-coupon">{t("showWord")}</span>
                </ButtonCoupon>
            }
        </Container>
    );
}

export default DiscountCouponsBanner;