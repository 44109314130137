import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    PARTNERS_ACTIVE,
    PARTNERS
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

/*get*/

const getPartnersActive = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PARTNERS_ACTIVE}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error("erro na chamada getPartnersActive: " + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de parceiros');
    }
}

const getProductsPartners = async (id, callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PARTNERS}/${id}/products`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error("erro na chamada getProductsPartners: " + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de produtos do parceiro');
    }
}

const getPartnerAddressValidation = async (idPartner, idAddress) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PARTNERS}/${idPartner}/addresses/${idAddress}/available`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else {
            throw new Error("erro na chamada getPartnerAddressValidation: " + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de validação de endereço');
    }
}

/*post*/

const makeCreatePartnerOrder = async (idPartner, idAddress, payMethod, idCard, products) => {
    let validationError = false;
    const data = {
        address_id: idAddress,
        payment_method: payMethod,
        card_id: idCard,
        products: products
    }

    try {
        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PARTNERS}/${idPartner}/orders`, post_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;

        } else if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            throw new Error("erro na chamada makeCreatePartnerOrder: " + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao criar pedido ');
        }
    }
}

export {
    getPartnersActive,
    getProductsPartners,
    getPartnerAddressValidation,
    makeCreatePartnerOrder
}