import styled from 'styled-components';

export const Container = styled.div`
    .content-body-video-view {
        width: 100%;
        max-width: 1080px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 200px;
    }
    
    .video-title-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #14213D;
        width: 100%;
        margin-top: 40px;
    }

    .video-title-view-right,
    .video-title-view-left {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 40px;
        font-size: 1.2rem;
    }

    .video-title-view-left span:nth-child(2),
    .video-title-view-right span:nth-child(2) {
        font-weight: 700;
    }

    .video-container {
        width: 100%;
        max-width: 1080px;
        height: 500px;
        margin: 20px 0;
        background-color: #000;
    }

    .button-go-back {
        background-color: #FA9F2A;
        padding: 20px 200px;
        border-radius: 20px;
        font-size: 1.2rem;
        margin: 20px 0;
        transition: .2s background;
        box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
    }

    .button-go-back:hover {
        background-color: #E6860B; 
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }
    }

    @media(max-width: 480px) {
        .button-go-back {
            padding: 20px 150px;
        }

        .video-container {
            height: 300px;
        }
    }

    @media(max-width: 425px) {
        .video-title-view-right,
        .video-title-view-left {
            font-size: 1rem;
            margin: 0 15px;
        }
        
        .button-go-back {
            padding: 15px 150px;
        }
    }

    @media(max-width: 375px) {
        .video-title-view-right,
        .video-title-view-left {
            font-size: 0.9rem;
            margin: 0 10px;
        }
        
        .button-go-back {
            padding: 15px 100px;
        }
    }
`;