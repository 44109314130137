import styled from "styled-components";

export const ButtonsFeedback = styled.section`
  display: flex;
  align-items: center;
  gap: 5vw;

  button {
    background: #e63946;
    border-radius: 1rem;
    height: 5rem;
    width: 19rem;
    color: white;

    &.positive {
      height: 6rem;
      width: 20rem;
      background: #2a9d8f;
    }

    transition: 0.2s ease-in;
    &:hover {
      filter: brightness(0.9);
    }
  }

  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      animation: animate 2s infinite;
    }
  }

  @keyframes animate {
    from {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(15deg);
    }

    80% {
      transform: rotate(-15deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @media (max-width: 630px) {
    flex-direction: column;
  }
`;
