import { header, fetchWithTimeout } from './helper/request';
import {
    API_SCHEDULE,
    CHECKIN,
    CHECKOUT,
    IN_PROGRESS,
    PLACES,
    POSITIONS,
    SCHEDULED,
    SCHEDULES,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

/* Checkin */

const getScheduled = async () => {
    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}${POSITIONS}${SCHEDULES}${SCHEDULED}`,
            get_bearer_common,
        );
        if (response.ok) {
            const data = response.data;
            return data;
        }
        throw new Error(`erro na chamada getScheduled: ${response.status}`);
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de agendamentos');
    }
};

const putCheckin = async schedule_id => {
    let validationError;
    try {
        const { put_bearer_common } = header({
            latitude: null,
            longitude: null,
        });
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}${POSITIONS}${SCHEDULES}/${schedule_id}${CHECKIN}`,
            put_bearer_common,
        );

        if (response.status === 200) {
            return;
        }
        if (response.status === 400) {
            const data = response.data;
            validationError = true;
            throw new Error(data.message);
        } else {
            throw new Error(`erro na chamada putCheckin: ${response.status}`);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao fazer checkin');
        }
    }
};

const deleteCheckin = async schedule_id => {
    try {
        const { delete_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}${POSITIONS}${SCHEDULES}/${schedule_id}`,
            delete_bearer_common,
        );

        if (response.status === 200) {
            return;
        }
        throw new Error(`erro na chamada putCheckin: ${response.status}`);
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao fazer cancelamento');
    }
};

/* Checkout */

const getChecked = async () => {
    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}${POSITIONS}${SCHEDULES}${IN_PROGRESS}`,
            get_bearer_common,
        );
        if (response.ok) {
            const data = response.data;
            return data;
        }
        throw new Error(`erro na chamada getScheduled: ${response.status}`);
    } catch (error) {
        getSectionsCatchErrors(
            error,
            'Erro ao realizar consulta de agendamentos com checkin',
        );
    }
};

const putCheckout = async schedule_id => {
    try {
        const { put_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}${POSITIONS}${SCHEDULES}/${schedule_id}${CHECKOUT}`,
            put_bearer_common,
        );
        if (response.status === 200) {
            return;
        }
        throw new Error(`erro na chamada putCheckout: ${response.status}`);
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao fazer checkout');
    }
};

export { getScheduled, putCheckin, deleteCheckin, getChecked, putCheckout };
