import { API_BASE, API_VERSION, CUSTOMERS, ONE_USE_TOKENS, ORDERS } from "./helper/api_urls";
import { validateGetResponseBasic } from './helper/validateGetResponse';
import { validatePostResponseBasic } from "./helper/validatePostResponse";

const getOneUseTokenOrder = async (token, email) => {
  const getUrl = `${API_BASE}${API_VERSION}${CUSTOMERS}${ONE_USE_TOKENS}/${token}?email=${email}`;

  return await validateGetResponseBasic(
    getUrl,
    'Erro ao consultar os dados do pedido',
  );
};

const postOneUseTokenOrder = async (data) => {
  const postUrl = `${API_BASE}${API_VERSION}${CUSTOMERS}${ORDERS}/${data.orderType}`;
  const body = {
    hour_begin: data.hourBegin,
    job_day_id: data.jobDayId,
    payment_method: data.paymentMethod,
    service_id: data.serviceId,
    one_use_token: data.token
  };

  return await validatePostResponseBasic(
    postUrl,
    'Erro ao realizar criação do pedido',
    body
  );
}

export {
  getOneUseTokenOrder,
  postOneUseTokenOrder
};

