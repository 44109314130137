import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    CUSTOMER_ORDERS,
    CUSTOMER_ORDERS_NEXT_OPEN,
    CUSTOMER_ORDERS_OPEN,
    CUSTOMER_ORDERS_CONCLUDED,
    CUSTOMER_ORDERS_ON_DEMAND,
    CUSTOMER_ORDERS_REVIEW,
    CUSTOMER_ORDERS_PAYMENT,
    CUSTOMER_ORDERS_CURRENT_ONLINE,
    PENDING_ACTION_COUNT,
    CUSTOMER_ORDERS_ESTIMATE_APPROVAL,
    CUSTOMER_ORDERS_ESTIMATE_DISAPPROVAL,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';
import { validateDeleteResponse } from './helper/validateDeleteResponse';

const getNextOpenOrder = async (callback) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_NEXT_OPEN}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);

        } else if (response.status === 404) {
            const responseJson = response.data;

            validationError = true;

            return responseJson.message;
        } else {
            throw new Error('erro na chamada getNextOpenOrder: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta do próximo pedido agendado');
        }
    }
}

const getCurrentOnline = async (callback) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_CURRENT_ONLINE}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);

        } else if (response.status === 404) {
            const responseJson = response.data;

            validationError = true;

            return responseJson.message;
        } else {
            throw new Error('erro na chamada getCurrentOnline: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta do próximo pedido online agendado');
        }
    }
}

const getOpenOrders = async (callback, page, limit) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_OPEN}?page=${page}&limit=${limit}`, get_bearer);
        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson);
        } else {
            throw new Error('erro na chamada getOpenOrders: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de pedidos agendados');
    }
}

const getConcludedOrders = async (callback, page, limit) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_CONCLUDED}?page=${page}&limit=${limit}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getConcludedOrders: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de pedidos concluídos');
    }
}

const getPendingPayment = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_PAYMENT}?page=0&limit=1`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getPendingPayment: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de pagamentos pendentes');
    }
}

const getRatingOrder = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_REVIEW}?page=0&limit=1`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson);

        } else {
            throw new Error('erro na chamada getRatingOrder: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de pedidos para avaliação');
    }
}


const getPendingAction = async (callback) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}${PENDING_ACTION_COUNT}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson.count);
        } else if (response.status === 400) {
            const message = response.data;
            validationError = true;
            return message;
        } else {
            throw new Error('Erro na chamada getPendingAction: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao consultar as ações pendentes');
        }
    }
}

const cancelOrder = async (orderId) => {
    try {
        const deleteUrl = `${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${orderId}`;
        return await validateDeleteResponse(deleteUrl, 'Erro ao realizar cancelamento do pedido')
    } catch (error) {
       throw error 
    }
}

const cancelOnDemandOrder = async (orderId) => {
    try {
        const { delete_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_ON_DEMAND}/${orderId}`, delete_bearer);

        if (!response.ok) {
            throw new Error('erro na chamada cancelOnDemandOrder: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar cancelamento do pedido em demanda');
    }
}

const doCustomerOrderDisapproval = async (idOrder) => {
    let validationError = false;

    try {
        const { put_bearer } = header();

        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${idOrder}${CUSTOMER_ORDERS_ESTIMATE_DISAPPROVAL}`, put_bearer);
        if (response.ok) {
            return response.status;
        } else if (response.status === 400) {
            const message = response.data;
            validationError = true;
            return message;
        } else {
            throw new Error('erro na chamada doCustomerOrderDisapproval: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar reprovação');
        }
    }
}

const disapproveEstimateCall = async (order, setCloseAllModals, setAllOrders, setLoad, searchOrders, type, setLoading) => {
    try {
        setLoading(true);
        await doCustomerOrderDisapproval(order.id);
        setLoading(false);
        setLoad(true);
        searchOrders(type, setAllOrders, true, setLoad);
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao recusar serviço');
    } finally {
        setCloseAllModals(true);
    }
}


const approveEstimateCall = async (order, setCloseAllModals, setAllOrders, setLoad, searchOrders, type, setLoading, history) => {
    try {
        setLoading(true);

        const response = await doCustomerOrderApproval(order.id);

        history.push('/completeOrderApproval', {
            pixCode: response.pix_qr_code,
            orderType: order.order_type,
            paymentMethod: order.payment_type,
            serviceName: order.service.name,
            scheduledDate: order.scheduled_date,
        }
        );

        setLoading(false);
        setLoad(true);
        searchOrders(type, setAllOrders, true, setLoad);

    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao recusar serviço');
    } finally {
        setCloseAllModals(true);
    }
}

const doCustomerOrderApproval = async (idOrder) => {
    let validationError = false;

    try {
        const { put_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${idOrder}${CUSTOMER_ORDERS_ESTIMATE_APPROVAL}`, put_bearer);
        if (response.ok) {
            const responseJson = response.data;
            return responseJson;
        } else if (response.status === 400) {
            const message = response.data;

            validationError = true;

            return message;
        } else {
            throw new Error('erro na chamada doCustomerOrderApproval: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar aprovação');
        }
    }
}

const createOrder = async (hourBegin, jobDayId, paymentMethod, paymentSignature, serviceId, cardId, discountCouponId, addressId, orderType, walletSelected, note) => {
    let validationError = false;

    try {
        let data = {
            hour_begin: hourBegin,
            job_day_id: jobDayId,
            payment_method: paymentMethod,
            payment_signature: paymentSignature,
            service_id: serviceId,
            card_id: cardId,
            discount_coupon_id: discountCouponId,
            address_id: addressId,
            use_wallet_balance: walletSelected,
            customer_notes: note
        };

        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${orderType}`, post_bearer);

        if (response.ok) {
            const data = response.data;

            return data
        } else if (response.status === 400) {
            const data = response.data;

            validationError = true;

            throw new Error(data.message);
        } else {
            throw new Error('erro na chamada createOrder: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar criação do pedido');
        }
    }
}

const createExpressOrder = async (hourBegin, jobDayId, serviceId, orderType) => {
    let validationError = false;

    try {
        let data = {
            hour_begin: hourBegin,
            job_day_id: jobDayId,
            service_id: serviceId
        };

        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/express/${orderType}`, post_bearer);

        if (response.ok) {
            const data = response.data;

            return data
        } else if (response.status === 400) {
            const data = response.data;

            validationError = true;

            throw new Error(data.message);
        } else {
            throw new Error('erro na chamada createExpressOrder: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar checkin');
        }
    }
}

const createOnDemandOrder = async (serviceId, scheduledFrom, scheduledUntil, addressId, paymentMethod, creditCardId, discountCouponId, orderType, walletSelected) => {
    let validationError = false;

    try {
        let data = {
            service_id: serviceId,
            scheduled_from: scheduledFrom,
            scheduled_until: scheduledUntil,
            address_id: addressId,
            payment_method: paymentMethod,
            credit_card_id: creditCardId,
            discount_coupon_id: discountCouponId,
            use_wallet_balance: walletSelected
        };

        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_ON_DEMAND}/${orderType}`, post_bearer);
        if (response.ok) {
            const data = response.data;

            return data
        } else if (response.status === 400) {
            const data = response.data;
            validationError = true;

            throw new Error(data.message);
        } else {
            throw new Error('erro na chamada createOnDemandOrder: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar criação do pedido sob demanda');
        }
    }
}

const putRatingOrder = async (idOrder, rating, comment) => {
    try {
        let data = {
            rating: rating,
            comment: comment,
        }

        const { put_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${idOrder}/review`, put_bearer);

        if (response.ok) {
            return response.status;

        } else {
            throw new Error('erro na chamada putRatingOrder: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao enviar avaliação');
    }
}

const putPaymentOrder = async (idOrder, paymentMethod, cardId) => {
    let validationError = false;
    try {
        let data = {
            payment_method: paymentMethod,
            card_id: cardId,
        }

        const { put_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${idOrder}/payment`, put_bearer);

        if (response.ok) {
            return response.status;

        } else if (response.status === 400) {
            const message = response.data;

            validationError = true;

            return message;
        } else {
            throw new Error('erro na chamada putPaymentOrder: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao enviar o pagamento');
        }
    }
}

const getDebouncePixPayment = async (idOrder) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${idOrder}/payment`, get_bearer);

        if (response.ok) {
            const data = response.data;

            return data
        } else if (response.status === 400) {
            const message = response.data;

            validationError = true;

            return message;
        } else {
            throw new Error('erro na chamada getDebouncePixPayment: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao verificar o pix');
        }
    }
}

const getDebouncePixPaymentDemand = async (idOrder) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ORDERS_ON_DEMAND}/${idOrder}/payment`, get_bearer);

        if (response.ok) {
            const data = response.data;

            return data
        } else if (response.status === 400) {
            const message = response.data;

            validationError = true;

            return message;
        } else {
            throw new Error('erro na chamada getDebouncePixPaymentDemand: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao verificar o pix em demanda');
        }
    }
}

export {
    getNextOpenOrder,
    getCurrentOnline,
    getOpenOrders,
    getConcludedOrders,
    getPendingPayment,
    getPendingAction,
    cancelOrder,
    cancelOnDemandOrder,
    disapproveEstimateCall,
    doCustomerOrderDisapproval,
    approveEstimateCall,
    doCustomerOrderApproval,
    createOrder,
    createExpressOrder,
    createOnDemandOrder,
    getRatingOrder,
    putRatingOrder,
    putPaymentOrder,
    getDebouncePixPayment,
    getDebouncePixPaymentDemand
}