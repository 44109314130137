import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 1280px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 4rem 0;

    .container-choose {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .choose-location-text {
        color: #14213D;
        font-size: 1.4rem;
        margin: 0 10px;
    }

    .choose-location-view {
        margin: 10px 0;
    }

    .react-switch-selector-wrapper {
        border: 1px solid #FA9F2A;
    }

    .react-switch-selector-wrapper::before{
        border: none;
        margin: 0 2px;
    }

    .react-switch-selector-option label {
        font-size: 1rem;
        padding: 10px 30px;
    }

    @media(max-width: 780px) {
        .choose-location-text {
            font-size: 1.2rem;
        }

        .react-switch-selector-option label {
            padding: 8px 20px;
            font-size: 1rem;
        }
    }

    @media(max-width: 375px) {
        .choose-location-text {
            font-size: 1rem;
        }

        .react-switch-selector-option label {
            padding: 8px 15px;
            font-size: 0.9rem;
        }
    }
`;