import styled from 'styled-components';
import imgSrc from '../../../assets/bg-login.png';

export const Container = styled.div`
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${imgSrc});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
    position: relative;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .recaptcha {
        margin-top: 30px;
    }

    .logo-img-login {
        position: absolute;
        top: 100px;
        width: 500px;
    }

    .password-forms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
    }

    .password-forms .login-text {
        font-size: 1rem;
        color: #fff;
    }

    .password-forms form input {
        width: 500px;
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #000;
        border-radius: 30px;
        height: 45px;
        border: 1px solid #CFCFCF;
        background-color: #fff;
        padding: 0 20px;
    }

    @media(max-height: 760px) {
        .logo-img-login {
            top: 50px;
        }
    } 

    @media(max-height: 670px) {
        .logo-img-login {
            top: 20px;
        }
    } 

    @media(max-width: 640px) {
        .password-forms .login-text {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 520px) {
        .password-forms form input {
            width: 350px;
        }

        .logo-img-login {
            width: 350px;
        }
    }

    @media(max-width: 450px) {
        .password-forms .login-text {
            font-size: 0.8rem;
        }
    }

    @media(max-width: 375px) {
        .password-forms .login-text {
            font-size: 0.6rem;
        }

        .password-forms form input {
            width: 300px;
        }

        .logo-img-login {
            width: 300px;
        }
    }
`;

export const ButtonSubmit = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    background-color: #FA9F2A;
    border-radius: 20px;
    width: 300px;
    font-size: 1.2rem;
    height: 40px;
    transition: .2s background-color;

    :hover {
        background-color: #D68A28;
    }

    @media(max-width: 520px) {
        width: 200px;
    }

    @media(max-width: 375px) {
        margin-top: 15px;
    }
`;

export const ButtonForgot = styled.button`
    background: none;
    text-decoration: underline;
    margin-top: 15px;
    transition: .2s color;
    font-size: 1.1rem;

    :hover {
        color: #C9C9C9;
    }
`;