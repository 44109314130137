import styled from 'styled-components';

export const ButtonContainer = styled.div`
    position: relative;

    .button-more {
        background: none;
        color: #FA9F2A;
        text-decoration: underline;
        font-size: 1.1rem;
        position: absolute;
        bottom: 20px;
        left: 165px;
    };

    @media(max-width: 830px) {
        .button-more {
            left: 145px;
        }
    }

    @media(max-width: 700px) {
        .button-more {
            left: 125px;
            bottom: 10px;
        }
    }

    @media(max-width: 500px) {
        .button-more {
            left: 110px;
            bottom: 10px;
            font-size: 1rem;
        }
    }

    @media(max-width: 425px) {
        .button-more {
            left: 98px;
            bottom: 10px;
            font-size: 1rem;
        }
    }

    @media(max-width: 375px) {
        .button-more {
            left: 97px;
            bottom: 10px;
            font-size: 0.9rem;
        }
    }
`;

export const Container = styled.button`
    background-color: #FFFFFF;
    border-radius: 18px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    height: 160px;
    border: 1px solid #FA9F2A;

    :hover {
        background: #F8F8F8;
    }

    .image {
        width: 140px;
        height: 140px;
        border-radius: 16px;
        border: 1px solid #f2f2f2;
        background-color: #f2f2f2;
        margin: 10px 0 10px 10px;
    };

    .content {
        margin-left: 13px;
        margin-right: 17px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 15px;
    };

    .title {
        color: #14213D;
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 3px;
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    };

    .description {
        text-transform: uppercase;
        color: #C4C4C4;
        font-size: 0.9rem;
        margin: 10px 0 5px;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 500px;
    };

    .buy-container {
        width: 100px !important;
    };

    .buy {
        background-color: #FA9F2A;
        border-radius: 0 16px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        overflow: hidden;
        margin-left: 13px;
        height: 120px;
        width: 87px;
    };

    .icon-buy {
        color: #FFFFFF;
        width: 20px;
        height: 20px;
    };

    .text-buy {
        color: #FFFFFF;
        font-size: 0.9rem;
    };

    .price {
        background-color: #E6860B;
        border-radius: 16px 0 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 39px;
        width: 100px;
    };

    .text-price {
        color: #FFFFFF;
        font-size: 0.9rem;
    };

    .price-value {
        font-size: 1rem;
    };

    @media(max-width: 830px) {
        height: 140px;

        .image {
            width: 120px;
            height: 120px;
            align-self: center;
        }

        .title {
            width: 400px;
        }

        .description {
            width: 400px;
        }

        .buy {
            height: 100px;
        }
    }

    @media(max-width: 700px) {
        height: 120px;

        .image {
            width: 100px;
            height: 100px;
            align-self: center;
        }

        .title {
            width: 350px;
            margin-bottom: 0;
        }

        .description {
            width: 350px;
        }

        .buy-container {
            width: 85px !important;
        };

        .buy {
            height: 90px;
            width: 72px;
        }

        .price {
            height: 29px;
            width: 85px;
        }
    }

    @media(max-width: 600px) {
        height: 120px;

        .image {
            width: 100px;
            height: 100px;
            align-self: center;
        }

        .title {
            width: 250px;
        }

        .description {
            width: 250px;
        }

        .buy-container {
            width: 85px !important;
        };

        .buy {
            height: 90px;
            width: 72px;
        }

        .price {
            height: 29px;
            width: 85px;
        }
    }

    @media(max-width: 500px) {
        height: 110px;

        .image {
            width: 90px;
            height: 90px;
            align-self: center;
        }

        .title {
            width: 200px;
        }

        .description {
            width: 200px;
        }

        .buy-container {
            width: 75px !important;
        };

        .buy {
            height: 80px;
            width: 62px;
        }

        .price {
            height: 29px;
            width: 75px;
        }
    }

    @media(max-width: 425px) {
        height: 100px;

        .image {
            width: 80px;
            height: 80px;
            align-self: center;
            margin: 5px 0 5px 5px;
        }

        .content {
            margin-top: 10px;
            margin-left: 10px;
        }

        .title {
            font-size: 1rem;
            width: 180px;
        }

        .description {
            font-size: 0.85rem;
            margin-top: 5px;
            width: 180px;
        }

        .buy-container {
            width: 65px !important;
        };

        .buy {
            height: 70px;
            width: 52px;
        }

        .price {
            height: 29px;
            width: 65px;
        }

        .text-price {
            color: #FFFFFF;
            font-size: 0.8rem;
        };

        .price-value {
            font-size: 0.85rem;
        };

        .icon-buy {
            color: #FFFFFF;
            width: 15px;
            height: 15px;
        };

        .text-buy {
            color: #FFFFFF;
            font-size: 0.8rem;
        };
    }

    @media(max-width: 375px) {
        height: 100px;

        .image {
            width: 80px;
            height: 80px;
            align-self: center;
            margin: 5px 0 5px 5px;
        }

        .content {
            margin-top: 10px;
            margin-left: 10px;
        }

        .title {
            font-size: 1rem;
            width: 150px;
        }

        .description {
            font-size: 0.85rem;
            margin-top: 5px;
            width: 150px;
        }

        .buy-container {
            width: 65px !important;
        };

        .buy {
            height: 70px;
            width: 52px;
        }

        .price {
            height: 29px;
            width: 65px;
        }

        .text-price {
            color: #FFFFFF;
            font-size: 0.8rem;
        };

        .price-value {
            font-size: 0.85rem;
        };

        .icon-buy {
            color: #FFFFFF;
            width: 15px;
            height: 15px;
        };

        .text-buy {
            color: #FFFFFF;
            font-size: 0.8rem;
        };
    }

    @media(max-width: 340px) {
        height: 90px;

        .image {
            width: 80px;
            height: 80px;
            align-self: center;
            margin: 5px 0 5px 5px;
        }

        .content {
            margin-top: 10px;
            margin-left: 10px;
        }

        .title {
            font-size: 1rem;
            width: 120px;
        }

        .description {
            font-size: 0.85rem;
            margin-top: 5px;
            width: 120px;
        }

        .buy-container {
            width: 65px !important;
        };

        .buy {
            height: 60px;
            width: 52px;
        }

        .price {
            height: 29px;
            width: 65px;
        }

        .text-price {
            color: #FFFFFF;
            font-size: 0.8rem;
        };

        .price-value {
            font-size: 0.85rem;
        };

        .icon-buy {
            color: #FFFFFF;
            width: 15px;
            height: 15px;
        };

        .text-buy {
            color: #FFFFFF;
            font-size: 0.8rem;
        };
    }
`;