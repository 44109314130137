import { v4 as uuid_v4 } from 'uuid';

export const USER = 'posher_logged_user';
export const PUSH_TOKEN = 'push_token';
export const PROVIDER = 'provider';
export const EXTERNAL_TOKEN = 'external-token';
export const EXTERNAL_REQUEST_CODE = 'external-request-code';

export const onSignIn = user => {
    localStorage.setItem(USER, JSON.stringify(user));
    sessionStorage.setItem(USER, JSON.stringify(user));
};

export const onSignOut = () => {
    const language = localStorage.getItem("userSelectedLanguage");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("userSelectedLanguage", language);
};

export const isSignedIn = () => {
    return localStorage.getItem(USER) != null;
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem(USER));
};

export const getUserToken = () => {
    return isSignedIn() && getUser().token;
};

export const updatePushToken = pushToken => {
    sessionStorage.setItem(PUSH_TOKEN, JSON.stringify(pushToken));
};

export const getPushToken = () => {
    return sessionStorage.getItem(PUSH_TOKEN);
};

export const refreshUUID = () => {
    const id = uuid_v4();
    localStorage.setItem('uuid', id);
};