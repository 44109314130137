import { getSectionsCatchErrors } from '../getSectionsCatchErrors';
import { fetchWithTimeout, header } from './request';

export const validatePostResponse = async (postUrl, errorMessage, body) => {
  const { post_bearer_common } = header(body);
  let isValidation = false;
  try {
    const response = await fetchWithTimeout(`${postUrl}`, post_bearer_common);

    if (response.status === 400) {
      const responseJson = response.data;

      isValidation = true;

      throw new Error(responseJson.message);
    } else if (response.status !== 200) {
      const data = response.data;
      throw new Error(`${data.message} ${response.status}`);
    }
  } catch (error) {
    if (isValidation) throw error;
    getSectionsCatchErrors(error, errorMessage);
  }
};