import React, { useEffect, useState } from "react";
import { FiCalendar, FiClock } from "react-icons/fi";
import { getTimeDiff } from "../../utils/getTimeDiff";

import { ButtonOrange } from "../ButtonOrange";
import { ContainerAbout } from "../ContainerAbout";
import { ContainerButtonInfo } from "../ContainerButtonInfo";
import { ContainerButtonOrange } from "../ContainerButtonOrange";
import { ContainerOpacity } from "../ContainerOpacity";
import { ContainerTimeClockInfo } from "../ContainerTimeClockInfo";
import { TextAbout } from "../TextAbout";
import { TextAboutEmphasis } from "../TextAboutEmphasis";

export function CardHistoryTimeClock({
  timeClock,
  handleOpenModal,
  actualCheckin,
}) {
  const [actualTime, setActualTime] = useState(timeClock.total_time);

  useEffect(() => {
    let interval;
    if (timeClock.state === "in_progress") {
      interval = setInterval(() => {
        getTimeDiff(setActualTime, actualCheckin?.started_at);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [actualCheckin]);

  return (
    <ContainerButtonInfo>
      <ContainerTimeClockInfo>
        <ContainerAbout>
          <div>
            <FiCalendar width={15} height={15} color={"#14213D"} />
            <TextAboutEmphasis>
              <span>{timeClock.started_at}</span>
              <span>{timeClock.finished_at}</span>
            </TextAboutEmphasis>
          </div>
          <TextAbout>
            <FiClock width={15} height={15} color={"#14213D"} />
            {timeClock.state === "in_progress"
              ? actualTime
              : timeClock.total_time}
          </TextAbout>
        </ContainerAbout>
      </ContainerTimeClockInfo>

      {timeClock.state === "in_progress" && (
        <ContainerOpacity onClick={() => handleOpenModal()}>
          <ContainerButtonOrange>
            <ButtonOrange>cancelar</ButtonOrange>
          </ContainerButtonOrange>
        </ContainerOpacity>
      )}
    </ContainerButtonInfo>
  );
}
