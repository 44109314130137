import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 20px;
    background: none;
    margin: 0 20px;

    .img-button img {
        width: 120px;
        height: 120px;
        border-radius: 25px;
        border: 1px solid #f2f2f2;
        background-color: #f2f2f2;
    }

    .info-button {
        margin: 0 15px;
        color: #14213D;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .name-button {
        text-transform: uppercase;
        font-weight: 700;
        color: #14213D;
        font-size: 0.813rem;
        margin: 10px 0 5px;
        text-align: center;
        height: 52px;
        font-size: 14px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }

    .more-info {
        display: flex;
        align-items: center;
        margin: 5px 0;
    }

    .more-info > span {
        margin-left: 2px;
    }

    .icon-button {
        position: absolute;
        right: 0px;
        top: 90px;
    }


    .icon-button .button-free {
        background: #FFF;
        font-size: 0.8rem;
        color: #FA9F2A;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 30px;
        border-radius: 18px 0 15px 0;
    }

    .last-used {
        color: #FFF;
    }

    .one-click-hour-view {
        background-color: #FFF;
        position: absolute;
        top: 0;
        color: #14213D;
        width: 100%;
        border-radius: 20px 20px 0 0;
        padding: 5px 0;
        font-size: 0.8rem;
        font-weight: 700;
        border: 1px solid #F2F2f2;
    }

    @media(max-width: 910px) {
        .img-button img {
            width: 100px;
            height: 100px;
        }

        .name-button {
            font-size: 0.75rem;
        }

        .icon-button {
            top: 70px;
        }

        .icon-button .button-free {
            font-size: 0.7rem;
            width: 65px;
            height: 30px;
            border-radius: 12px 0 15px 0;
        }
    }

    @media(max-width: 640px) {
        .img-button img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }

        .one-click-hour-view {
            border-radius: 10px 10px 0 0;
        }

        .icon-button {
            top: 55px;
            right: -3px;
        }

        .icon-button .button-free {
            font-size: 0.7rem;
            width: 55px;
            height: 25px;
        }
    }

    @media(max-width: 425px) {
        .img-button img {
            width: 80px;
            height: 80px;
        }

    }

    @media(max-width: 360px) {
        .img-button img {
            width: 75px;
            height: 75px;
        }
    }

    @media(max-width: 320px) {
        .img-button img {
            width: 70px;
            height: 70px;
        }
    }
`;