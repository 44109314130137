import React from 'react';
import { getDayName, getMonthName } from '../../../../utils/utils';
import Moment from 'moment-timezone';
import { ButtonDate } from './styles';
import 'moment/locale/pt-br';

export const DateButton = (props) => {
    const date = props.date;
    const selected = props.selected;
    const doSelectDate = props.doSelectDate;

    let dayOfWeek = getDayName(date)?.split('-')[0];
    let monthName = getMonthName(date);

    return (
        <ButtonDate type="button" onClick={doSelectDate}>
            <div className={`button-view ${selected && "button-selected-view"}`}>
                <div className="day-of-week-view">
                    <span>{dayOfWeek}</span>
                </div>

                <div className="container-date">
                    <div className="day-view">
                        <span className={`day-text ${selected && "day-selected-text"}`}>
                            {Moment(date).format('DD')}
                        </span>
                    </div>

                    <div className="month-view">
                        <span className={`month-text ${selected && "month-selected-text"}`}>
                            /{monthName}
                        </span>
                    </div>
                </div>
            </div>
        </ButtonDate>
    )
}

export default DateButton;