import ReactModal from "react-modal";
import styled from "styled-components";
import { COLORS } from "../../../utils/colors";

export const Modal = styled(ReactModal)`
    width: 90%;
    max-width: 500px;
    height: 50%;
    max-height: 180px;
    background: #fff;
    color: #000;
    position: relative;
    border-radius: 20px;

    .header-modal {
        display: flex;
        flex-direction: column;
        background-color: #f7f7f7;
        border-radius: 20px 20px 0 0;
        padding: 10px 30px;
        padding-bottom: 0;
    }

    .title-header {
        text-transform: uppercase;
        font-weight: 700;
    }

    .subtitle-header {
        font-size: 0.8rem;
    }
`

export const ReviewContainer = styled.div`

  span {
    word-break: normal;
  }

  a {
    color: ${COLORS.black};
    transition: font-size .1s ease-out 10ms
  }

  a:visited {
    color: ${COLORS.black};
  }

  a:hover {
    font-size: 1.3rem;
  }
`;

export const ContentBody = styled.div`
  word-break: break-all;
  padding: 0 3rem;
  text-align: center;
  max-height: 65vh;
  line-height: 1.5;
  color: #707070;
  width: 100%;

  position: relative;
  top: 40px;

  display: flex;
  flex-direction: column;
`

export const ButtonClose = styled.button`
  position: absolute;
  right: -5px;
  top: -8px;
  width: 35px;
  height: 35px;
  display: flex;
  border: 2px solid ${COLORS.border};
  background-color: ${COLORS.white};
  color: #fa9f2a;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  border-radius: 100%;

  &:active {
    opacity: 0.9;
  }

  span {
    position: relative;
    font-size: 1.2rem;
    bottom: 2px;
  }
`