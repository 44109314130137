import styled from 'styled-components';

export const ButtonMicrosoftLogin = styled.button`
  margin-top: 1.5rem;
  padding-left: 25px;
  padding-right: 25px;
  height: 40px;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  border-radius: 20px;

  transition: ease-in-out 0.3s;

  span {
    font-size: 14px;
  }

  img {
    width: 24px;
    position: relative;
    left: 10px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
