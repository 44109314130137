import { useEffect, useState } from "react";

import Header from "../../../../../components/Header";
import TabButton from "../../../../../components/TabButton";
import moment from "moment-timezone";

import { FiBookmark, FiCalendar, FiClock, FiMapPin } from "react-icons/fi";

import { ContainerOpacity } from "../components/ContainerOpacity";
import { ContainerButtonInfo } from "../components/ContainerButtonInfo";
import { ImageInfo } from "../components/ImageInfo";
import { ContainerAbout } from "../components/ContainerAbout";
import { ContainerInfo } from "../components/ContainerInfo";
import { TextAbout } from "../components/TextAbout";
import { ContainerButtonOrange } from "../components/ContainerButtonOrange";
import { ButtonOrange } from "../components/ButtonOrange";
import { NotFound } from "../components/NotFound";
import { PrincipalContainer } from "../components/PrincipalContainer";
import ModalConfirmation from "../../../../../components/Modals/ModalConfirmation";
import { getChecked } from "../../../../../api/check";
import { ContainerCheck } from "../components/ContainerCheck";
import Footer from "../../../../../components/Footer";
import LoaderSpinner from "../../../../../components/LoaderSpinner";
import SchedulePlaceBanner from "../SchedulePlaceBanner";
import { PageInfoWhatToDo } from "../components/PageInfoWhatToDo";
import { useHistory } from "react-router-dom";
import { getUser } from "../../../../../utils/auth";
import { t } from 'i18next';

export const Checkout = () => {
  const history = useHistory();

  if (!getUser()?.company?.position_scheduling_enabled) {
    history.replace("/home");
  }

  const [showModal, setShowModal] = useState(false);
  const [scheduleChoosed, setScheduleChoosed] = useState({});
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState([]);

  const doGetChecked = async (isMounted) => {
    setLoading(true);
    await getChecked().then((places) =>
      isMounted ? setChecked(places) : null
    );
    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      doGetChecked(isMounted);
    }

    return () => (isMounted = false);
  }, [showModal]);

  return (
    <PrincipalContainer>
      {showModal && (
        <ModalConfirmation
          mode="checkout"
          position={scheduleChoosed.company_place_description}
          setShow={(val) => setShowModal(val)}
          setOption={(op) => op}
          schedule_id={scheduleChoosed.id}
          positionCode={scheduleChoosed.company_place_position_code}
        />
      )}
      <Header screen="home" isNotSchedule={false} />

      <SchedulePlaceBanner
        bannerUrl="https://posher.s3.sa-east-1.amazonaws.com/production/company_places/images/banner_checkin.jpg"
        isUrl={true}
        title="CHECK-OUT"
        serviceType="presential"
      />
      <LoaderSpinner loading={loading} />

      <ContainerCheck className="background-body">
        {checked.length === 0 ? (
          <NotFound>Nenhum check-out disponível no momento</NotFound>
        ) : (
          <>
            <PageInfoWhatToDo>{t("yourReservations")}</PageInfoWhatToDo>

            {checked.map((schedule, index) => {
              var timeFrom = moment(schedule.date_from)
                .tz(moment.tz.guess())
                .format("HH:mm");
              var timeTo = moment(schedule.date_to)
                .tz(moment.tz.guess())
                .format("HH:mm");
              return (
                <ContainerButtonInfo key={index}>
                  <ImageInfo
                    src={schedule.company_place_position_type_thumb_url}
                  />

                  <ContainerInfo>
                    <ContainerAbout>
                      <FiMapPin width={15} height={15} color={"#14213D"} />
                      <TextAbout>
                        {schedule.company_place_description}
                      </TextAbout>
                    </ContainerAbout>

                    <ContainerAbout>
                      <FiBookmark width={15} height={15} color={"#14213D"} />
                      <TextAbout>
                        {schedule.company_place_position_type_description} -{" "}
                        {schedule.company_place_position_code} -{" "}
                        {schedule.company_place_position_description}
                      </TextAbout>
                    </ContainerAbout>

                    <ContainerAbout>
                      <FiCalendar width={15} height={15} color={"#14213D"} />
                      <TextAbout>
                        {moment(schedule.date_from)
                          .tz(moment.tz.guess())
                          .format("DD/MM/YYYY")}
                      </TextAbout>
                    </ContainerAbout>

                    <ContainerAbout>
                      <FiClock width={15} height={15} color={"#14213D"} />
                      <TextAbout>
                        das {timeFrom} às {timeTo}
                      </TextAbout>
                    </ContainerAbout>
                  </ContainerInfo>

                  <ContainerOpacity
                    onClick={() => {
                      setScheduleChoosed(schedule);
                      setShowModal(true);
                    }}
                  >
                    <ContainerButtonOrange>
                      <ButtonOrange>Check-out</ButtonOrange>
                    </ContainerButtonOrange>
                  </ContainerOpacity>
                </ContainerButtonInfo>
              );
            })}
          </>
        )}

        <div className="footer-service-list">
          <div className="line-separator"></div>
          <Footer />
        </div>
      </ContainerCheck>

      <TabButton screen="home" />
    </PrincipalContainer>
  );
};
