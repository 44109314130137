import React from 'react';
import { formatCurrencyBRL } from '../../../../utils/utils';
import Moment from 'moment-timezone';
import { Container } from './styles';

const PackageUnit = ({ pack, descriptionSelected }) => {
    var purchased = 0;
    var available = 0;

    for (let i = 0; i < pack.items.length; i++) {
        purchased += parseInt(pack.items[i].purchased_quantity);
        available += parseInt(pack.items[i].available_quantity);
    }

    return (
        <Container key={pack.id}>
            <img src={pack.thumb_url} className="image" />

            <div className="info">
                <div className="title">
                    <span className="title-text">{pack.name}</span>
                </div>

                <span className="quantity">Disponível
                    <span className={available > 0 && "quantity-bold"}> {available}</span>
                    <span className={purchased > 0 && "quantity-bold"}>/{purchased}</span>
                </span>

                <span className="expire">Válido até: {Moment(pack.expires_at).format('DD/MM/YYYY')}</span>

                <span className={pack.state === 'active' ? "status" : "status-disabled"}>{pack.stateName}</span>

                <button className="button-more" type="button" onClick={() => descriptionSelected(pack)}>
                    <span className="more-info">+ informações</span>
                </button>
            </div>

            <div className="price">
                <span className="price-text">R$ <span className="price-value">{formatCurrencyBRL(pack.price).substr(2)}</span></span>
            </div>
        </Container>
    );
}

export default PackageUnit;