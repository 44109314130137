import React, { useEffect } from "react";
import { FiLogIn } from "react-icons/fi";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo.svg";
import {
  Container,
  HeaderContainer
} from "./styles";


const HeaderSimple = () => {

  useEffect(() => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <Container>
      <div id="header" className="header">
        <HeaderContainer>
          <Link to="/home" className="logo">
            <img src={Logo} alt="Logo Posher" />
          </Link>
          <Link to="/home" className="enter">
            <FiLogIn size={25} />
            <div className="title-tag">
              <span>Entrar</span>
            </div>
          </Link>
        </HeaderContainer>
      </div>
    </Container>
  );
};

export default HeaderSimple;
