import React, { useEffect, useState } from "react";
import { getOffersByCategory } from "../../../api/service_offer";
import ServiceBanner from "../components/ServiceBanner";
import { toast } from "react-toastify";
import { Container, Content } from './styles';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ButtonLarge from "../../../components/ButtonLarge";
import TabButton from "../../../components/TabButton";
import LoaderSpinner from '../../../components/LoaderSpinner';
import SkeletonServiceList from "./SkeletonServiceList";
import { debounce } from 'throttle-debounce';
import { getPackageList } from "../../../api/service_packages";
import { getSignaturesByCategory } from "../../../api/requests/orders/signature/signaturePlanApi";

const ServiceList = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const address = location?.state?.address;
  let offer = location?.state?.offer;
  let orderType = location?.state?.orderType;
  const [allServices, setAllServices] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);

  if (!offer && location.search !== '') {
    let search = location.search.substring(1);
    offer = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

    sessionStorage.removeItem('deepLink');
    sessionStorage.setItem('alreadyDidDeep', true);

    orderType = offer.order_type;
  }

  const cancelLoading = () => {
    setTimeout(() => {
      if (document.getElementById('bodyService')) {
        document.getElementById('bodyService').style.display = 'block';
      }
      setLoadingSkeleton(false);
    }, 1000);
  }

  const searchOffers = async () => {
    try {
      await getOffersByCategory(setAllServices, offer.order_type, offer.category_id);
      await getPackageList(offer.category_id, setAllPackages);
      await getSignaturesByCategory(setAllSubscriptions, offer.category_id);

      cancelLoading();
    } catch (error) {
      toast.error(error.message);
      setAllServices([]);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (document.getElementById('bodyService')) {
        document.getElementById('bodyService').style.display = 'none';
      }
      setLoadingSkeleton(true);
      setTimeout(() => debounce(5000, searchOffers()), 1500);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Container>
      <LoaderSpinner loading={loading} />

      <Header orderType={orderType} screen="home" address={address} />
      <div className="background-body">

        {loadingSkeleton &&
          <SkeletonServiceList />
        }

        <Content id="bodyService">
          <ServiceBanner
            bannerUrl={offer?.header_url}
            title={offer?.title}
            serviceType={offer?.service_type}
          />

          {allServices.length === 0 && (
            <span className="notFound">
              Nenhum serviço disponível no momento
            </span>
          )}

          <div className="container-buttons-service">
            {allServices.map((service, serviceIndex) => {
              if (offer.begin_at) {
                service.begin_at = offer.begin_at;
              }

              if (offer.job_day_id) {
                service.job_day_id = offer.job_day_id;
              }

              return (
                <div key={serviceIndex} className="button-service">
                  <ButtonLarge
                    offer={service}
                    card="serviceList"
                    address={address}
                    setLoading={setLoading}
                  />
                </div>
              );
            })}

            {allPackages.length > 0 &&
              allPackages.map((pack, packIndex) => {
                return (
                  <div key={packIndex} className="button-service">
                    <ButtonLarge
                      offer={pack}
                      card="packages"
                      setLoading={setLoading}
                    />
                  </div>
                )
              })}
            
            {allSubscriptions.length > 0 &&
              allSubscriptions.map(subscription => {
                return (
                  <div key={subscription.id} className="button-service">
                    <ButtonLarge
                      offer={subscription}
                      card="subscriptions"
                      setLoading={setLoading}
                    />
                  </div>
                )
              })}
          </div>
        </Content>

        {!loadingSkeleton &&
          <div className="footer-service-list">
            <div className="line-separator"></div>
            <Footer />
          </div>
        }

        <TabButton screen="home" />
      </div>
    </Container>
  );
};

export default ServiceList;
