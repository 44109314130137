import styled from 'styled-components';

export const Container = styled.div`
    .choose-period,
    .choose-one-date {
        text-align: center; 
        color: #707070; 
        font-size: 1.1rem;
    }

    .container-dates-view {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .content-view {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .body-ondemand {
        display: none;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-bottom: 140px;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        .body-ondemand {
            padding-bottom: 60px;
        }

        .footer-service-list .line-separator {
            display: none;
        }
    }
`;

export const DatesViewContainer = styled.div`
    margin-top: 5px;
    background: #f1f1f1;
    width: 700px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 15px 20px 30px;
    border-radius: 20px 20px 5px 5px;
    overflow: auto;

    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 20px;
        -webkit-border-radius: 20px;
    }

    ::-webkit-scrollbar {
        height: 10px;
        background: #f8f8f8;
        border-radius: 0 0 20px 20px;
    }

    ::-webkit-scrollbar-thumb {
        background: #dad7d7;
        border-radius: 20px;
        -webkit-border-radius: 20px;
    }

    ::-webkit-scrollbar-corner {
        border-radius: 20px;
    }

    @media(max-width: 870px) {
        width: 500px;
    }

    @media(max-width: 550px) {
        width: 400px;
    }

    @media(max-width: 450px) {
        width: 350px;
    }

    @media(max-width: 395px) {
        width: 300px;
    }

    @media(max-width: 325px) {
        width: 280px;
    }
`;