import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    SERVICE_OFFERS_TODAY,
    SERVICE_OFFERS_HIGHLIGHTED,
    SERVICE_OFFERS_GROUPED,
    SERVICE_OFFERS_LAST,
    SERVICE_OFFERS_BY_CATEGORY,
    CATEGORY_BY_ID
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

const getGroupedServiceOffers = async (callback, orderType) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_GROUPED}/${orderType}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getGroupedServiceOffers: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de ofertas de serviço');
    }
}

const getLastServiceOffers = async (callback, orderType) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_LAST}/${orderType}?limit=3`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getLastServiceOffers: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta dos últimos serviços agendados');
    }
}

const getTodayOffers = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_TODAY}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson);
        } else {
            throw new Error('erro na chamada getTodayOffers: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta das ofertas de serviço do dia');
    }
}

const getHighLightedOffers = async (callback, orderType) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_HIGHLIGHTED}/${orderType}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getHighLightedOffers: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta das ofertas de serviço em destaque');
    }
}

const getOffersByCategory = async (callback, orderType, categoryId) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_BY_CATEGORY}/${categoryId}/services/${orderType}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getOffersByCategory: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de serviços');
    }
}

const getCategory = async (callback, categoryId) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CATEGORY_BY_ID}/${categoryId}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getCategory: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta da categoria de serviço');
    }
}

const getService = async (callback, categoryId, serviceId) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CATEGORY_BY_ID}/${categoryId}/services/${serviceId}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getService: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta do serviço');
    }
}

const getDatesByService = async (callback, orderType, categoryId, serviceId) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_BY_CATEGORY}/${categoryId}/services/${serviceId}/${orderType}/dates`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getDatesByService: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de dias disponíveis');
    }
}

const getSlotsByServiceAndDate = async (callback, orderType, categoryId, serviceId, date) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_BY_CATEGORY}/${categoryId}/services/${serviceId}/${orderType}/dates/${date}/slots`, get_bearer, 5000);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getSlotsByServiceAndDate: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de horários disponíveis');
    }
}

const videoClick = async (categoryId, serviceId, videoId) => {
    try {
        const { post_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CATEGORY_BY_ID}/${categoryId}/services/${serviceId}/videos/${videoId}/click`, post_bearer);

        if (!response.ok) {
            throw new Error('erro na chamada videoClick: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar seleção do vídeo');
    }
}

const selectTimeSlot = async (orderType, categoryId, serviceId, professionalId, slotTime, addressId) => {
    try {
        let data = {
            professional_id: professionalId,
            address_id: addressId,
            schedule_date: slotTime
        };

        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${SERVICE_OFFERS_BY_CATEGORY}/${categoryId}/services/${serviceId}/${orderType}/slots/select`, post_bearer);

        if (!response.ok) {
            throw new Error('erro na chamada selectTimeSlot: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar seleção do horário');
    }
}

export {
    getTodayOffers
    , getHighLightedOffers
    , getGroupedServiceOffers
    , getLastServiceOffers
    , getOffersByCategory
    , getCategory
    , getService
    , getDatesByService
    , getSlotsByServiceAndDate
    , videoClick
    , selectTimeSlot
}