import React from 'react';
import { createOnDemandOrder } from '../../../../../../api/service_orders';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from './styles';

const FinishOrderButton = (props) => {
    const offer = props.offer;
    const orderType = props.orderType;
    const scheduledFrom = props.scheduledFrom;
    const scheduledUntil = props.scheduledUntil;
    const paymentMethod = props.paymentMethod;
    const serviceId = props.serviceId;
    const cardId = props.cardId;
    const discountCouponId = props.discountCouponId;
    const address = props.address;
    const setLoading = props.setLoading;
    const free = props.free;
    const walletSelected = props.walletSelected;
    const navigation = useHistory();

    const create = async () => {
        try {
            if (!paymentMethod && !free) {
                toast.error('Favor selecionar a forma de pagamento');
                return;
            }

            if (scheduledFrom >= scheduledUntil) {
                toast.error('Favor selecionar os horarios corretamente');
                return;
            }

            if (!scheduledFrom || !scheduledUntil) {
                toast.error('Os horários devem estar preenchidos');
                return;
            }

            setLoading(true);

            const order = await createOnDemandOrder(serviceId, scheduledFrom, scheduledUntil, (address ? address.id : null), (free ? 'cash' : paymentMethod), (cardId ? cardId : null), discountCouponId, offer.order_type, walletSelected);

            setLoading(false);
            navigation.push("/completeOnDemandOrder", { orderType: orderType, scheduledFrom: scheduledFrom, scheduledUntil: scheduledUntil, offer: offer, address: address, paymentMethod: paymentMethod, pixCode: order.pix_qr_code, paymentExpirationMinutes: order.payment_expiration_minutes, order });

        } catch (error) {
            setLoading(false);
            toast.error(error.message)
        }
    }

    return (
        <Container>
            <button className="finish-order-button" onClick={create}>
                <span>concluir pedido</span>
            </button>
        </Container>
    )
}

export default FinishOrderButton;