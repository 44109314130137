import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAddresses, residentialAvailable } from '../../../api/customer';
import InputMask from 'react-input-mask';
import './modalAddressStyles.css';
import './modalVerifyZip.css';
import LoaderSpinner from '../../LoaderSpinner';

const ModalAddress = (props) => {
    const partner = props.partner;
    const message = props.message;
    const [selectedAddress, setSelectedAddress] = useState();
    const afterSelect = props.afterSelect;
    const warningMessage = props.warningMessage;
    const showModal = props.showModalAddress;
    const setShowModal = props.setShowModalAddress;
    const checkResidentialAvailability = props.checkResidentialAvailability;
    const [showVerifyResidentialZipCode, setShowVerifyResidentialZipCode] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [zipCode, setZipCode] = useState('');
    const [residential, setResidentialAvailable] = useState(true);

    const navigation = useHistory();

    const verifyResidentialAddress = async () => {
        try {
            setLoading(true);
            let available = await residentialAvailable(zipCode.match(/\d/g).join(""));

            if (available.available) {
                setShowVerifyResidentialZipCode(false);
                setLoading(false);
                navigation.push('/NewAddress', { data: available.data });

            } else {
                setResidentialAvailable(false);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    const searchAddresses = async () => {
        try {
            setLoading(true);

            await getAddresses().then(val => setAddresses(val));

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    const selectAddress = (address) => {
        setSelectedAddress(address);
        afterSelect(address);
    }

    useEffect(() => {
        if (showModal) {
            searchAddresses();

            if (checkResidentialAvailability && setShowVerifyResidentialZipCode.length === 0) {
                setShowVerifyResidentialZipCode(true);
                setShowModal(false);
            }
        } else {
            setAddresses([]);
        }

        if (showVerifyResidentialZipCode) {
            setZipCode('');
        }

    }, [showModal, showVerifyResidentialZipCode]);

    return (
        <div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                overlayClassName="modal-overlay address"
                className="modal-content address"
                ariaHideApp={false}
            >
                <LoaderSpinner loading={loading} />
                
                <button className="cancel-button" type="button" onClick={() => setShowModal(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">escolha seu</span>
                    <span className="title-header">endereço</span>
                </div>

                <div className="content-body">
                    {message && partner ?
                        <span className="text-body">{message}<span>{partner}</span></span>
                        :
                        <span className="text-body">Selecione o endereço onde deseja receber os serviços <span>POSHER Home</span></span>
                    }

                    <div className="selects-address">
                        {addresses.map((address, addressIndex) => {
                            return (
                                <div key={addressIndex} className="container-radio-address">
                                    <div className="container-radio-buttons">
                                        <input
                                            type="radio"
                                            value={address}
                                            checked={selectedAddress === address}
                                            onChange={() => selectAddress(address)}
                                        />

                                        <label onClick={() => selectAddress(address)}>{address.description}</label>
                                    </div>

                                    <span className="address-description">{address.address}</span>
                                </div>
                            )
                        })}
                    </div>

                    <div className="message-view">
                        <span className="text-warning-message">{warningMessage}</span>
                    </div>

                    <div className="new-address-view">
                        <span className="text-other-address">Outro endereço?</span>

                        {partner ?
                            <button
                                type="button"
                                className="button-go-to-address"
                                onClick={() => {
                                    setShowModal(false);
                                    navigation.push('/newAddress', { data: '' })
                                }}
                            >
                                <span>adicionar endereço</span>
                            </button>
                            :
                            <button
                                type="button"
                                className="button-add-new-address"
                                onClick={() => {
                                    setShowModal(false);
                                    setShowVerifyResidentialZipCode(true);
                                }}
                            >
                                <span>adicionar endereço</span>
                            </button>
                        }
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={showVerifyResidentialZipCode}
                onRequestClose={() => setShowVerifyResidentialZipCode(false)}
                overlayClassName="modal-overlay verify-address"
                className="modal-content verify-address"
                ariaHideApp={false}
            >
                <LoaderSpinner loading={loading} />

                <button className="cancel-button" type="button" onClick={() => setShowVerifyResidentialZipCode(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">verificação de</span>
                    <span className="title-header">cobertura</span>
                </div>

                {residential &&
                    <div className="container-body-verify-zip-code">
                        <span className="message-posher-address">Precisamos saber se os serviços POSHER Home estão disponíveis no seu endereço</span>

                        <InputMask
                            maskChar=""
                            mask="99999-999"
                            className="input-cep"
                            placeholder="informe seu CEP"
                            onChange={e => setZipCode(e.target.value)}
                        />

                        <button
                            type="button"
                            disabled={zipCode.length < 9 ? true : false}
                            className={zipCode.length < 9 ? "button-disabled" : "verify-button"}
                            onClick={verifyResidentialAddress}
                        >
                            <span className="verify-text">verificar</span>
                        </button>
                    </div>
                }

                {!residential &&
                    <div>
                        <div className="error-message-avaliable">
                            <span>Poxa, ainda não estamos disponíveis neste endereço.</span>
                        </div>

                        <span className="message-online-services">Mas você pode aproveitar todos nossos <span>serviços Online</span>.</span>

                        <button
                            type="button"
                            className="verify-button"
                            onClick={() => {
                                setShowVerifyResidentialZipCode(false);
                                setResidentialAvailable(true);
                                navigation.push("/home");
                            }}
                        >
                            <span>voltar para a tela principal</span>
                        </button>
                    </div>
                }
            </Modal >
        </div >
    );
}

export default ModalAddress;