import React, { useState } from "react";
import { FiCheck, FiMail, FiClock, FiKey, FiCopy } from "react-icons/fi";
import AvailableNow from "../../components/AvailableNow";
import { getDayName } from "../../../../utils/utils";
import Moment from "moment-timezone";
import { AndNowBanner, BackgroundImage, InfoView } from './styles';
import Header from "../../../../components/Header";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TabButton from "../../../../components/TabButton";
import QRCode from "react-qr-code";
import { useResponsiveValue } from '@theme-ui/match-media';
import { useHistory } from "react-router-dom";

const CompleteOrderApproval = ({ location }) => {
    const history = useHistory();

    if (!location.state) {
        history.goBack();
    }

    const [copy, setCopy] = useState(false);
    const numberSizes = useResponsiveValue(['100', '150', '180', '220']);

    const {
        state: {
            pixCode,
            orderType,
            paymentMethod,
            serviceName,
            scheduledDate
        } = {}
    } = location;


    const dayOfWeek = getDayName(scheduledDate);

    const copyPixCode = () => {
        setCopy(true);
        setTimeout(() => setCopy(false), 2000);
    };

    return (
        <>
            <Header screen="home" orderType={orderType} />
            <BackgroundImage>

                <div className="background-body">
                    {paymentMethod === "pix" ? (
                        <div className="text-message">
                            <div className="yellow-clock-view">
                                <FiClock color="#707070" size={70} />
                            </div>

                            <span className="text-pix">Pague AGORA com o Pix para confirmar o agendamento</span>
                        </div>
                    ) : (
                        <div>
                            <div className="green-check-view">
                                <FiCheck color="#fff" size={70} />
                            </div>

                            <span className="text-ok">Tudo certo!</span>
                        </div>
                    )}

                    {paymentMethod === 'pix' && pixCode && (
                        <div className="qr-code">
                            <QRCode
                                value={`${pixCode}`}
                                size={numberSizes}
                            />
                        </div>
                    )}

                    {paymentMethod === "pix" && pixCode && (
                        <CopyToClipboard text={pixCode} className="pix-code-view" onCopy={() => copyPixCode()}>
                            {copy ? (
                                <div>
                                    <span className="copy-text">Código copiado!</span>
                                </div>
                            ) : (
                                <div>
                                    <span className="pix-code-text">ou use o código Pix Copia e Cola!</span>
                                    <FiCopy size={15} color="#FA9F2A" />
                                </div>
                            )}
                        </CopyToClipboard>
                    )}

                    {paymentMethod === 'pix' &&
                        <div className="info-tags">
                            <InfoView>
                                <span className="info-text">
                                    <span>Sua solicitação de {serviceName} foi confirmada para {dayOfWeek} &nbsp;
                                        {Moment(scheduledDate).tz("America/Sao_Paulo").format('DD/MM')} está aguardando pagamento.
                                    </span>
                                </span>
                            </InfoView>
                        </div>
                    }

                    <AndNowBanner>
                        <div className="and-now-banner">
                            <span className="and-now-text">E AGORA?</span>
                        </div>
                    </AndNowBanner>

                    <div className="one-two-banner">
                        <span className="one-two-number-text">1</span>
                        {paymentMethod !== "pix" ? (
                            <div className="info-one-two-text">
                                <div className="mail-clock-view">
                                    <FiMail color="#000" size={20} />
                                </div>

                                <span className="one-two-text">
                                    Enviamos todas as informações do agendamento para seu email.
                                </span>
                            </div>
                        ) : (
                            <div className="info-one-two-text">
                                <div className="mail-clock-view">
                                    <FiKey color="#000" size={20} />
                                </div>

                                <span className="one-two-text">
                                    Pague com PIX usando a chave fornecida ou o QR Code enviado em seu email.
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="one-two-banner">
                        <span className="one-two-number-text">2</span>
                        {paymentMethod !== "pix" ? (
                            <div className="info-one-two-text">
                                <div className="mail-clock-view">
                                    <FiClock color="#000" size={20} />
                                </div>

                                <span className="one-two-text">
                                    No horário combinado se dirija ao local do serviço. O profissional estará te aguardando.</span>
                            </div>
                        ) : (
                            <div className="info-one-two-text">
                                <div className="mail-clock-view">
                                    <FiClock color="#000" size={20} />
                                </div>

                                <span className="one-two-text">
                                    O agendamento será cancelado se o pagamento não for realizado em até 10 minutos.
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="avaliable-now">
                        <AvailableNow
                            orderType={orderType}
                            title="DISPONÍVEL AGORA!"
                        />
                    </div>
                </div>

                <TabButton screen="home" />
            </BackgroundImage >
        </>
    )
}

export default CompleteOrderApproval;