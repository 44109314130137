import React, { useState } from 'react';
import { makeCreditCard, makeDebitCard } from '../../../../../../api/payments';
import { toast } from 'react-toastify';
import ModalAddNewCard from '../../../../../../components/Modals/ModalAddNewCard';
import { Container } from './styles';

const CreateNewCard = ({ addNewCreditCard, setAddNewCreditCard, doExecuteSearchs, setLoading }) => {
  const [holderName, setHolderName] = useState("");
  const [number, setNumber] = useState("");
  const [expirationMonthYear, setExpirationMonthYear] = useState("");
  const [securityCode, setSecurityCode] = useState("");

  const createCreditCards = async () => {
    try {
      setLoading(true);

      let expiration_month = expirationMonthYear.substring(0, 2);
      let expiration_year = expirationMonthYear.substring(3, 5);

      await makeCreditCard(holderName, number, expiration_month, expiration_year, securityCode);
      toast.success("Cartão adicionado com sucesso", { autoClose: 3000 })

      doExecuteSearchs();

      setLoading(false);
      setAddNewCreditCard(false);

    } catch (error) {
      setLoading(false);
      toast.error('Erro ao adicionar cartão: Dados inválidos')
    }
  }

  const createDebitCards = async (card_type, brand) => {
    try {
      setLoading(true);

      await makeDebitCard(holderName, number, expirationMonthYear, securityCode, card_type, brand);
      toast.success('Cartão adicionado com sucesso', { autoClose: 5000 });

      doExecuteSearchs();

      setLoading(false);
      setAddNewCreditCard(false);

    } catch (error) {
      setLoading(false);
      toast.error("Erro ao adicionar cartão: Dados inválidos");
    }
  }

  return (
    <Container>
      <ModalAddNewCard
        showModalNewCard={addNewCreditCard}
        setShowModalNewCard={val => setAddNewCreditCard(val)}
        setSecurityCode={setSecurityCode}
        setNumber={setNumber}
        setHolderName={setHolderName}
        setExpirationMonthYear={setExpirationMonthYear}
        clicked={option => option && createCreditCards()}
      />

    </Container>
  )
}

export default CreateNewCard;