export const API_BASE = process.env.REACT_APP_CUSTOMER_WEB_API_BASE;
export const API_SCHEDULE = process.env.REACT_APP_CUSTOMER_WEB_API_SCHEDULE;
export const API_CHAT = process.env.REACT_APP_CUSTOMER_WEB_API_CHAT;
export const API_TIME_CLOCK = process.env.REACT_APP_CUSTOMER_WEB_API_TIME_CLOCK;

export const API_VERSION = 'v1';

export const COMPANIES = '/companies';

export const CUSTOMERS = '/customers';
export const CUSTOMERS_TERMS = '/customers/terms';
export const CUSTOMERS_PASSWORD_RECOVER = '/customers/password/recover';
export const CUSTOMERS_RECOVER_CODE = '/customers/password/recover_code';
export const CUSTOMERS_PASSWORD = '/customers/password';
export const CUSTOMER_USERNAME = '/customers/username';
export const CUSTOMER_TOKEN = '/customers/token';
export const CUSTOMER_REFRESH_TOKEN = '/customers/refreshToken';
export const CUSTOMER_COUNTRIES = '/customers/countries';
export const CUSTOMER_COMPANY = '/customers/company';
export const CUSTOMER_COMPANY_OFFER_HEADER_STATUS =
  '/customers/advices/company_offer_header/status';
export const CUSTOMER_COMPANY_OFFER_HEADER_INTERACT =
  '/customers/advices/company_offer_header/interact';
export const CUSTOMER_HOME_SECTIONS = '/customers/home/sections';
export const CUSTOMER_FRIENDS_RECOMMENDATION =
  '/customers/friends_recommendation';
export const CUSTOMER_ADDRESSES = '/addresses/customer';
export const ADDRESS_ZIP = '/addresses/zip';

export const DISCOUNT_COUPONS_AVAILABLE = '/discount_coupons/available';
export const DISCOUNT_COUPONS_BY_CODE = '/discount_coupons';

export const FEEDBACK = '/feedback';
export const CUSTOMER_ORDERS = '/customers/orders';
export const CUSTOMER_ORDERS_NEXT_OPEN = '/customers/orders/open/next';
export const CUSTOMER_ORDERS_OPEN = '/customers/orders/open';
export const CUSTOMER_ORDERS_CONCLUDED = '/customers/orders/concluded';
export const CUSTOMER_ORDERS_REVIEW = '/customers/orders/review';
export const CUSTOMER_ORDERS_PAYMENT = '/customers/orders/payment';
export const CUSTOMER_ORDERS_CURRENT_ONLINE =
  '/customers/orders/current/online';
export const CUSTOMER_ORDERS_ESTIMATE_APPROVAL = '/estimate/approval';
export const CUSTOMER_ORDERS_ESTIMATE_DISAPPROVAL = '/estimate/disapproval';

export const GROUP = '/group';
export const CUSTOMER_ORDERS_ON_DEMAND = '/customers/orders/on_demand';
export const SERVICE_OFFERS_TODAY = '/service_offers/today';
export const SERVICE_OFFERS_HIGHLIGHTED = '/service_offers/highlighted';
export const SERVICE_OFFERS_GROUPED = '/service_offers/grouped';
export const SERVICE_OFFERS_LAST = '/service_offers/last';
export const SERVICE_OFFERS_BY_CATEGORY = '/service_offers/categories';

export const SERVICE_PACKAGES = '/service_packages';
export const SERVICE_PACKAGES_METHODS = '/service_packages/methods';
export const SERVICE_PACKAGES_PURCHARSE = '/service_packages/purchase';

export const SUBSCRIPTION_PLANS_METHODS = '/subscription_plans/methods';
export const SUBSCRIPTION_PLANS_PURCHASE = '/subscription_plans/purchase';
export const SUBSCRIPTION_PLANS = '/subscription_plans';
export const SUBSCRIPTIONS = '/subscriptions';
export const ORDERS = '/orders';

export const MARKETING_HR_RECOMMENDATION = '/marketing/hr_recommendation';
export const MARKETING_BANNERS_HEADER = '/marketing/banners/header';
export const MARKETING_BANNERS_COMPANY_OFFER =
  '/marketing/banners/company_offer';

export const PARTNERS_ACTIVE = '/partners/active';
export const PARTNERS = '/partners';

export const CATEGORY_BY_ID = '/categories';

export const PAYMENT = '/payment';
export const PAYMENTS = '/payments';
export const CONFIRMATION = '/confirmation';
export const PAYMENTS_WALLET_STATEMENT = '/payments/wallet/statement';
export const PAYMENTS_DIGITAL_WALLET = '/payments/wallet/balance';
export const PAYMENTS_ORDERS = '/payments/orders';
export const PAYMENTS_ON_DEMAND_ORDERS = '/payments/onDemandOrders';
export const PAYMENTS_PARTNERS = '/payments/partners/methods';
export const CUSTOMER_CREDIT_CARDS = '/customers/credit_cards';
export const CUSTOMER_DEBIT_CARDS = '/customers/debit_cards';

export const CUSTOMER_MESSAGES_NEXT = '/customers/messages/next';

export const PENDING_ACTION_COUNT = '/pending_action/count';

export const PLACES = '/places';
export const POSITION_TYPES = '/positions/types';
export const POSITIONS = '/positions';
export const SCHEDULES = '/schedules';
export const SCHEDULED = '/scheduled';
export const CHECKIN = '/checkin';
export const CHECKOUT = '/checkout';
export const IN_PROGRESS = '/in_progress';
export const DATES = '/dates';
export const MAP = '/map';
export const TIMES = '/times';

export const CHATS = '/chats';

export const VACCINATIONS = '/vaccinations';
export const TYPES = '/types';

export const MESSAGES = '/messages';
export const ACTIVE = '/active';
export const ACCEPT = '/accept'

export const CURRENT = '/current';
export const HISTORY = '/history';

export const CASHBACKS_AVAILABLE = "/cashback_campaigns/available"

export const ONE_USE_TOKENS = "/one_use_tokens/token"

export const QUESTIONNAIRES_NEXT = "/questionnaires/next"
export const QUESTIONNAIRES_ANSWERS = "/questionnaires/answers"

export const PRIZE_CAMPAIGNS = "/prize_campaigns"
export const RAFFLE = "/raffle"
export const CONFIRM = "/confirm"
export const LANGUAGE = "/language"