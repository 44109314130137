import React, { useState } from 'react';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';
import { makeNewAddress } from '../../../../../api/customer';
import { findZipCode } from '../../../../../api/customer'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../../../components/Header';
import { Container } from './styles';
import LoaderSpinner from '../../../../../components/LoaderSpinner';
import InputMask from 'react-input-mask';
import Footer from '../../../../../components/Footer';
import TabButton from '../../../../../components/TabButton';

const NewAddress = ({ location }) => {
    const data = location.state ? location.state?.data : {};
    const [loading, setLoading] = useState(false);
    const [zipCode, setZipCode] = useState(data?.zipCode || '');
    const [st, setSt] = useState(data?.state || '');
    const [city, setCity] = useState(data?.cityName || '');
    const [cityCode, setCityCode] = useState(data?.cityCode || '');
    const [district, setDistrict] = useState(data?.district || '');
    const [address, setAddress] = useState(data?.address || '');
    const [ok, setOk] = useState(false);
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [nickAddress, setNickAddress] = useState('');
    const navigation = useHistory();

    const verifyAddress = async () => {
        try {
            setLoading(true);

            if (zipCode !== '') {
                let data = await findZipCode(zipCode.match(/\d/g).join(""));

                setSt(data.state);
                setCity(data.cityName);
                setCityCode(data.cityCode);
                setDistrict(data.district);
                setAddress(data.address);
                setOk(true);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message, { autoClose: 3000 });
        }
    }

    const setNewAddress = async () => {
        try {
            setLoading(true);

            await makeNewAddress(nickAddress, zipCode, st, address, complement, number, district, city, cityCode);

            toast.success("Endereço cadastrado com sucesso", { autoClose: 3000 });

            setLoading(false);

            navigation.push("/addresses");

        } catch (error) {
            setLoading(false);

            let errorMessage = error.message;
            let validationMessages = [];

            if (errorMessage.indexOf('zipCode') >= 0) {
                validationMessages.push("CEP");
            }

            if (errorMessage.indexOf('district') >= 0) {
                validationMessages.push("Bairro");
            }

            if (errorMessage.indexOf('number') >= 0) {
                validationMessages.push("Número");
            }

            if (errorMessage.indexOf('address') >= 0) {
                validationMessages.push("Rua");
            }

            if (errorMessage.indexOf('description') >= 0) {
                validationMessages.push("Apelido");
            }

            if (validationMessages.length > 0) {
                toast.warn("Favor preencher os campos obrigatórios:\n- " + validationMessages.join("\n- "), { autoClose: 5000 });
            } else {
                toast.error("Erro ao realizar cadastro de: " + errorMessage)
            }
        }
    }

    return (
        <Container>
            <Header screen="user" orderType="corporate" />
            <LoaderSpinner loading={loading} />

            <div className="background-body">

                <div className="content-new-address">
                    {(ok || data !== '') ?
                        <span className="subtitle">Agora é só terminar de cadastrar seu endereço</span>
                        :
                        <span className="subtitle">Insira um CEP para validação</span>
                    }

                    <div className="input-container">
                        <div className="container-cep">
                            <InputMask
                                className={data?.zipCode ? "input-inactive" : "input-one"}
                                placeholder="CEP"
                                value={data?.zipCode ? data?.zipCode : zipCode}
                                onChange={e => setZipCode(e.target.value)}
                                disabled={data?.zipCode ? true : false}
                                mask="99999-999"
                                maskChar=""
                            />

                            {!data?.zipCode &&
                                <div>
                                    <button
                                        type="button"
                                        className={zipCode.length < 9 ? "buttom-disabled" : "border-icon"}
                                        disabled={zipCode.length < 9 ? true : false}
                                        onClick={verifyAddress}
                                    >
                                        <FaSearch size={20} color={zipCode.length < 9 ? "#cfcfcf" : '#FA9F2A'} />
                                    </button>

                                    <a
                                        className="forgot-my-cep"
                                        target="_blank"
                                        href={'https://buscacepinter.correios.com.br/app/endereco/index.php?t'}
                                    >
                                        <span className="forgot-cep">não sei meu cep</span>
                                    </a>
                                </div>
                            }
                        </div>

                        <div className="inputs">
                            <div className="input">
                                <input
                                    type="text"
                                    placeholder="estado"
                                    className="input-second"
                                    disabled
                                    value={st}
                                    onChange={e => setCity(e.target.value)}
                                />
                            </div>

                            <div className="input">
                                <input
                                    type="text"
                                    className="input-second"
                                    placeholder="cidade"
                                    disabled
                                    value={city}
                                    onChange={e => setCity(e.target.value)}
                                />
                            </div>

                            <div className="input">
                                <input
                                    type="text"
                                    className="input-one"
                                    placeholder="bairro"
                                    value={district}
                                    onChange={e => setDistrict(e.target.value)}
                                />
                                <div className="border-icon-input">
                                    <FaArrowLeft size={15} color='#FA9F2A' />
                                </div>
                            </div>

                            <div className="input">
                                <input
                                    type="text"
                                    className="input-one"
                                    placeholder="rua"
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                                <div className="border-icon-input">
                                    <FaArrowLeft size={15} color='#FA9F2A' />
                                </div>
                            </div>

                            <div className="input">
                                <input
                                    type="text"
                                    className="input-one"
                                    placeholder="número"
                                    onChange={e => setNumber(e.target.value)}
                                />
                                <div className="border-icon-input">
                                    <FaArrowLeft size={15} color='#FA9F2A' />
                                </div>
                            </div>

                            <div className="input">
                                <input
                                    type="text"
                                    className="input-one"
                                    placeholder="complemento"
                                    onChange={e => setComplement(e.target.value)}
                                />
                                <div className="border-icon-input">
                                    <FaArrowLeft size={15} color='#FA9F2A' />
                                </div>
                            </div>

                            <div className="input">
                                <input
                                    type="text"
                                    className="input-one"
                                    placeholder="apelido do endereço"
                                    onChange={e => setNickAddress(e.target.value)}
                                />
                                <div className="border-icon-input">
                                    <FaArrowLeft size={15} color='#FA9F2A' />
                                </div>
                            </div>
                        </div>

                        <button
                            type="button"
                            className="button-new-address"
                            onClick={setNewAddress}
                        >
                            <span>cadastrar endereço</span>
                        </button>
                    </div>
                </div>

                <div className="footer-service-list">
                    <div className="line-separator"></div>
                    <Footer />
                </div>
            </div>

            <TabButton screen="account" />
        </Container>
    );
}

export default NewAddress;