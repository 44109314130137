import styled from "styled-components";

export const ContainerAbout = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2rem;
  justify-content: flex-start;

  div {
    display: flex;
  }

  @media (max-width: 720px) {
    padding-left: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;
