import styled from 'styled-components';
import imgSrc from '../../../assets/bg-login.png';

export const Container = styled.div`
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${imgSrc});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
    position: relative;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-img-login {
        position: absolute;
        top: 100px;
        width: 500px;
    }

    .forms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .forms .login-text {
        font-size: 1.5rem;
        color: #fff;
    }

    .forms form input {
        width: 500px;
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #000;
        border-radius: 30px;
        height: 45px;
        border: 1px solid #CFCFCF;
        background-color: #fff;
        padding: 0 20px;
    }

    .after-login-text {
        font-size: 0.9rem;
        color: #fff;
        margin-top: 10px;
        opacity: 0.8;
        font-weight: 700;
        text-align: center;
    }

    @media(max-width: 520px) {
        .after-login-text {
            font-size: 0.8rem;
        }

        .forms form input {
            width: 350px;
        }

        .logo-img-login {
            width: 350px;
        }
    }

    @media(max-width: 375px) {
        .forms .login-text {
            font-size: 1.2rem;
        }

        .after-login-text {
            font-size: 0.7rem;
        }

        .forms form input {
            width: 300px;
        }

        .logo-img-login {
            width: 300px;
        }
    }
`;

export const ButtonSend = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    background-color: #FA9F2A;
    border-radius: 20px;
    width: 15rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500 !important;
    font-size: 1rem;
    height: 40px;
    transition: .2s background-color;

    :hover {
        background-color: #D68A28;
    }

    @media(max-width: 520px) {
        width: 10rem;
        height: 30px;
    }

    @media(max-width: 375px) {
        margin-top: 15px;
    }
`;