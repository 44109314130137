import styled from 'styled-components';

export const Container = styled.div`
    display: none;    
    background-color: #F7F7F7;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    box-shadow: inset 1 3px 0 #dcdcdc;
    border-radius: 20px 20px 0 0;

    @media(max-width: 960px) {
        display: inline-block;
    }
`;

export const TabButtons = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 0;


    p {
        position: absolute;
        margin-top: -2.6rem;
        margin-left: 2.8rem;
        color: #FA9F2A;
        border: 1px solid #FA9F2A;
        width: 18px;
        height: 18px;
        text-align: center;
        border-radius: 50%;
    }

    .button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: none;
        text-decoration: none;
    }

    .button > .icon {
        stroke: #8F8F8F;
    }

    .button > .icon-posher {
        width: 17px;
    }

    .button > .button-title {
        margin-top: 5px;   
        color: #3e3e3e;
        font-weight: 300;
        font-size: 0.75rem;
        line-height: 0.875rem;
    }

    .button > .active {
        color: #14213D;
        font-weight: 700;
    }

    .button > .icon.iconActive {
        stroke: #14213D;
    }
`;