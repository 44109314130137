import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    background: rgba(0,0,0,0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    height: 100vh;

    @media(max-width: 768px) {
        > div > svg {
            width: 80px;
        }
    }

    @media(max-width: 425px) {
        > div > svg {
            width: 60px;
        }
    }
`;