import styled from 'styled-components';

export const Container = styled.div`
  .content-service-order-history {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .not-found-text{
    font-size: 1.3rem;
    color: #C4C4C4;
    margin: 5rem 0;
  }

  .choose-type-view {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-top: 31px;
    width: 100%;
    max-width: 500px;
    height: 40px;
    border: 1px solid #FA9F2A;
    border-radius: 20px;
  }

  .content-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .choose-type-view label {
    font-size: 1.2rem;
  }

  .container-order-card-list {
    width: 100%;
  }

  .footer-service-list {
    position: absolute;
    bottom: 30px;
    left: 0;
  }

  .order-list {
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 50px;
  }

  .container-order-card-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .react-switch-selector-wrapper {
    border: 1px solid #FA9F2A;
    width: 500px;
  }

  .react-switch-selector-wrapper::before{
    height: calc(100% - 6px);
    width: calc(50% - 6px);
    border: none;
    margin-left: 3px;
  }

  .react-switch-selector-option label {
    font-size: 1.2rem;
    padding: 10px 20px;
  }

  .container-order-card {
    width: 100%;
  }

  @media(max-width: 960px) {
    padding-bottom: 50px;

    .footer-service-list .line-separator {
      display: none;
    }
  }

  @media(max-width: 745px) {
    .choose-type-view {
      max-width: 400px;
      height: 35px;
    }

    .react-switch-selector-option label {
      font-size: 1.1rem;
      padding: 10px;
    }

    .choose-type-view label {
      font-size: 1rem;
    }

    .order-list {
      padding-bottom: 60px;
    }
  }

  @media(max-width: 525px) {
    .choose-type-view {
      max-width: 300px;
      height: 30px;
    }

    .choose-type-view label {
      font-size: 0.9rem;
    }
  }

  @media(max-width: 375px) {
    .choose-type-view {
      max-width: 240px;
      height: 30px;
    }

    .react-switch-selector-option label {
      font-size: 1rem;
      padding: 10px 5px;
    }

    .choose-type-view label {
      font-size: 0.8rem;
    }
  }
`;