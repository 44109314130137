import styled from 'styled-components';
import imgSrc from "../../../../assets/images/bg-complete-order-light.jpg";

export const Container = styled.div`
    .background-body {
        background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${imgSrc});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .yellow-clock-view {
        display: flex;
        background-color: #ffff00;
        width: 90px;
        height: 90px;
        border-radius: 60px;
        align-items: center;
        justify-content: center;
        margin: 78px auto 30px;
    }

    .text-pix {
        text-align: center;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
    }

    .text-message {
        width: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .pix-code-view {
        background-color: #FFFFFF;
        width: 550px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        height: 60px;
        align-self: center;
        align-items: center;
        margin: 20px 0;
        cursor: pointer;
    }

    .copy-text {
        font-size: 1.2rem;
        color: #4BB543;
    }

    .pix-code-text {
        font-size: 1.2rem;
        color: #707070;
        margin-right: 20px;
    }

    .info-tags {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .one-two-banner {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 550px;
        border-radius: 20px;
        margin-bottom: 10px;
        justify-content: flex-start;
        height: 100px;
    }

    .one-two-number-text {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #14213D;
        font-size: 1.2rem;
        height: 100px;
        padding: 0 25px;
        border-radius: 19px 0 0 19px;
        font-weight: 700;
    }

    .mail-clock-view {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0 10px;
    }

    .info-one-two-text {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .one-two-text {
        font-size: 1rem;
        margin: 0 15px;
        color: #707070;
        padding-right: 10px;
    }

    .avaliable-now {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px 0;
    }

    .qr-code {
        margin: 20px 0 0;
        background-color: #FFF;
        width: 550px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        padding: 20px 0;
        align-self: center;
        align-items: center;
    }

    @media(max-width: 960px) {
        .background-body {
            padding-bottom: 60px;
        }
    }

    @media(max-width: 900px) {
        .green-check-view,
        .yellow-clock-view {
            width: 70px;
            height: 70px;
        }

        .green-check-view > svg,
        .yellow-clock-view > svg {
            width: 40px;
        }

        .text-ok,
        .text-pix {
            font-size: 1.1rem;
        }

        .pix-code-view {
            width: 450px;
            height: 50px;
        }

        .qr-code {
            width: 450px;
        }

        .copy-text,
        .pix-code-text {
            font-size: 1rem;
        }

        .one-two-banner {
            width: 450px;
        }
    }

    @media(max-width: 490px) {
        .green-check-view,
        .yellow-clock-view {
            width: 60px;
            height: 60px;
        }

        .green-check-view > svg,
        .yellow-clock-view > svg {
            width: 40px;
        }

        .text-ok,
        .text-pix {
            font-size: 1rem;
        }

        .pix-code-view {
            width: 350px;
            height: 50px;
        }

        .qr-code {
            width: 350px;
        }


        .one-two-banner {
            width: 350px;
            height: 80px;
        }

        .one-two-number-text {
            height: 80px;
            padding: 0 15px;
        }

        .one-two-text {
            font-size: 0.9rem;
            margin: 0 10px;
        }
    }

    @media(max-width: 375px) {
        .green-check-view,
        .yellow-clock-view {
            width: 50px;
            height: 50px;
        }

        .green-check-view > svg,
        .yellow-clock-view > svg {
            width: 30px;
        }

        .text-ok,
        .text-pix {
            font-size: 0.9rem;
        }

        .pix-code-view {
            width: 300px;
            height: 50px;
        }

        .qr-code {
            width: 300px;
        }

        .one-two-banner {
            width: 300px;
            height: 80px;
        }

        .one-two-text {
            font-size: 0.85rem;
            margin: 0 5px;
        }
    }
`;

export const InfoView = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .info-text {
        text-align: center;
        color: #fff;
        font-size: 1.1rem;
        width: 600px;
    }

    .info-text span {
        font-weight: 700;
    }

    @media(max-width: 900px) {
        .info-text {
            width: 450px;
            font-size: 1rem;
        }
    }

    @media(max-width: 490px) {
        .info-text {
        width: 350px;
        font-size: 0.95rem;
        }
    }

    @media(max-width: 375px) {
        .info-text {
            width: 300px;
            font-size: 0.9rem;
        }
    }
`;

export const AndNowBanner = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .and-now-banner {
        background: #14213d;
        border-radius: 20px;
        padding: 20px 0;
        width: 500px;
        text-align: center;
        font-size: 1.1rem;
    }

    @media (max-width: 900px) {
        .and-now-banner {
        width: 400px;
        padding: 15px 0;
        font-size: 1rem;
        }
    }

    @media(max-width: 490px) {
        .and-now-banner {
        width: 300px;
        padding: 15px 0;
        font-size: 0.9rem;
        }
    }

    @media(max-width: 375px) {
        .and-now-banner {
        width: 250px;
        padding: 15px 0;
        font-size: 0.85rem;
        }
    }
`;