import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 50px;
    width: 100%;
    max-width: 1080px !important;
    max-height: 200px;

    .image-service-list {
        max-width: 1080px;
        max-height: 200px;
        width: auto;
        height: auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #F7F7F7;
    }

    .image-service-list .service-banner {
        width: 1080px;
    }

    .container-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
    };

    .container-partner {
        display: flex;
        flex-direction: column;
        margin-top: -35px;
        width: 50%;
        padding: 5px 20px;
        align-self: center;
        align-items: center;
        justify-content: center;
        background-color: #FFF;
        border-radius: 22px;
        box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
    };

    .partner-name {
        color: #14213D;
        font-size: 1.4rem;
        text-align: center;
        padding: 10px 0;
    };

    .shopping-card-button {
        position: absolute;
        right: 25px;
        top: -55px;
        padding: 20px 0;
        background: none;
    };

    .shopping-card {
        background-color: #FFFFFF;
        border: 1px solid #FA9F2A;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 55px;
        height: 55px;
        box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
    };

    .icon {
        color: #FA9F2A;
        width: 20px;
        height: 20px;
    };

    .quantity-products {
        background-color: #FA9F2A;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -7px;
        right: -7px;
        width: 30px;
        height: 30px;
    };

    .quantity-text {
        color: #FFFFFF;
        font-size: 0.9rem;
        margin-left: 2px;
    }

    @media (max-width: 920px) {
        .partner-name {
            font-size: 1.2rem;
        }

        .shopping-card {
            width: 50px;
            height: 50px;
        };

        .icon {
            width: 18px;
            height: 18px;
        };

        .quantity-products {
            width: 25px;
            height: 25px;
        };

        .quantity-text {
            font-size: 0.9rem;
            margin-left: 0;
        }

        .shopping-card-button {
            top: -50px;
        }

        .container-partner {
            margin-top: -30px;
        }
    }

    @media (max-width: 560px) {
        .partner-name {
            font-size: 1rem;
        }

        .shopping-card {
            width: 40px;
            height: 40px;
        };

        .icon {
            width: 18px;
            height: 18px;
        };

        .quantity-products {
            width: 20px;
            height: 20px;
        };

        .quantity-text {
            font-size: 0.8rem;
            margin-left: 0;
        }

        .shopping-card-button {
            top: -45px;
        }
    }
`;