import React, { useState, useEffect } from 'react';
import {
    getOrderPaymentMethods,
    getWalletBalance,
    getOnDemandOrderPaymentMethods,
    getCustomerCreditCards,
    getPartnersPaymentMethods,
    getCustomerDebitCardsMultiBenefits
} from '../../../../api/payments';
import { formatCurrencyBRL } from '../../../../utils/utils';
import DeleteCardButton from './components/DeleteCardButton';
import NewCardButton from './components/NewCardButton';
import AddDiscountCouponButton from './components/AddDiscountCouponButton';
import { getPackagePaymentsMethods } from '../../../../api/service_packages';
import { toast } from 'react-toastify';
import { Container } from './styles';
import CreateNewCard from './components/CreateNewCard';
import { getSignaturesPaymentsMethods } from '../../../../api/requests/orders/signature/signaturePlanApi';
import { validateDiscountCoupon } from '../../../../api/discount_coupons';

const PaymentView = (props) => {
    const offer = props.offer;
    const partner = props.partner;
    const pack = props.pack;
    const signaturePlan = props.signaturePlan;
    const hasDiscount = props.hasDiscount;
    const orderType = props.orderType;
    const setCheckedPaymentMethod = props.setCheckedPaymentMethod;
    const setPaymentSignature = props.setPaymentSignature;
    const checkedPaymentMethod = props.checkedPaymentMethod;
    const setSelectedDiscountCoupon = props.setSelectedDiscountCoupon;
    const selectedDiscountCoupon = props.selectedDiscountCoupon;
    const setWalletSelected = props?.setWalletSelected;
    const setLoading = props.setLoading;
    const orderDate = props.selectedDate;

    const [allPaymentMethods, setAllPaymentMethods] = useState([]);
    const [allCreditCards, setAllCreditCards] = useState([]);
    const [allDebitCardsMultiBenefits, setAllDebitCardsMultiBenefits] = useState([]);
    const [balance, setBalance] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [addNewCreditCard, setAddNewCreditCard] = useState(false);

    let price;

    if (offer?.price) {
        price = offer.price;
    } else if (offer?.total_value) {
        price = offer.total_value;
    } else if (pack?.price) {
        price = pack.price;
    } else if (signaturePlan?.price) {
        price = signaturePlan.price
    }

    const executeSearchs = async () => {
        try {
            if (offer?.on_demand_order) {
                await getOnDemandOrderPaymentMethods(setAllPaymentMethods, orderType, offer.service_id);
            } else if (partner) {
                await getPartnersPaymentMethods(setAllPaymentMethods);
            } else if (pack) {
                await getPackagePaymentsMethods(setAllPaymentMethods);
            } else if (signaturePlan) {
                await getSignaturesPaymentsMethods(setAllPaymentMethods, signaturePlan.id)
            } else {
                await getOrderPaymentMethods(setAllPaymentMethods, orderType, offer.service_id);
            }
            await getCustomerCreditCards(setAllCreditCards);
            await getCustomerDebitCardsMultiBenefits(setAllDebitCardsMultiBenefits);
            await getWalletBalance(setBalance);

        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            setCheckedPaymentMethod({ method: null, card: null });

            executeSearchs();
        }

        return () => { isMounted = false; };
    }, []);

    useEffect(() => {
        if (setWalletSelected) {
            setWalletSelected(isSelected);
        }
    }, [isSelected]);

    useEffect(() => {

        async function validateCoupon() {
            try {
                const response = await validateDiscountCoupon(selectedDiscountCoupon.id, offer.service_id, checkedPaymentMethod.method, offer.orderType)
                if (!response.valid) {
                    toast.error(response.message)
                    setSelectedDiscountCoupon(null)
                }
            } catch (error) {
                toast.error(error.message)
            }
        }
        if (checkedPaymentMethod.method && selectedDiscountCoupon) {
            validateCoupon();
        }
    }, [selectedDiscountCoupon, checkedPaymentMethod, offer, setSelectedDiscountCoupon])

    let paymentMethods = Object.keys(allPaymentMethods).map(function (method, methodIndex) {
        let methodView = <div key={methodIndex}></div>;

        switch (method) {
            case 'cash':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: method, card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: method, card: null })}>Dinheiro</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'pix':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: method, card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: method, card: null })}>Pix</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'wallet':
                if (allPaymentMethods[method] && (balance.balance > 0 && balance.balance >= price)) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: method, card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: method, card: null })}>Carteira Digital {formatCurrencyBRL(balance.balance)}</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'debit':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: method, card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: method, card: null })}>Débito</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'credit':
                if (allPaymentMethods[method] && allCreditCards.length > 0) {
                    methodView =
                        <div key={methodIndex}>
                            {allCreditCards.map((creditCard, creditCardIndex) =>
                                <div key={methodIndex + '-' + creditCardIndex} className="payment-method-view">
                                    <input
                                        type="radio"
                                        value={method}
                                        checked={checkedPaymentMethod.method === method && checkedPaymentMethod.card === creditCard.id}
                                        onChange={() => setCheckedPaymentMethod({ method: method, card: creditCard.id })}
                                    />
                                    <label onClick={() => setCheckedPaymentMethod({ method: method, card: creditCard.id })}>Cartão de Crédito Final {creditCard.masked_number.slice(-4)}</label>
                                    <div className="delete-card">
                                        <DeleteCardButton cardList={allCreditCards} setAllCards={setAllCreditCards} card={creditCard}
                                            setCheckedPaymentMethod={setCheckedPaymentMethod}
                                            type="creditCard" setLoading={setLoading} />
                                    </div>
                                </div>
                            )}
                        </div>
                } if (allPaymentMethods[method] && allCreditCards.length === 0) {
                    methodView = <div key={methodIndex} className="payment-method-view">
                        <input
                            type="radio"
                            value={method}
                            onChange={() => setAddNewCreditCard(true)}
                        />
                        <label onClick={() => setAddNewCreditCard(true)}>Cartão de Crédito</label>

                        <CreateNewCard
                            addNewCreditCard={addNewCreditCard}
                            setAddNewCreditCard={setAddNewCreditCard}
                            doExecuteSearchs={executeSearchs}
                            setLoading={setLoading}
                        />
                    </div>;
                }
                break;
            case 'mercadopago':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: method, card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: method, card: null })}>Mercado Pago</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'payroll':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: method, card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: method, card: null })}>Débito em Folha</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'alelo_multibeneficios':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex}>
                            {allDebitCardsMultiBenefits.map((debitCardMulti, debitCardMultiIndex) =>
                                <div key={debitCardMultiIndex} className="payment-method-view">
                                    <input
                                        type="radio"
                                        value={method}
                                        checked={checkedPaymentMethod.method === method && checkedPaymentMethod.card === debitCardMulti.id}
                                        onChange={() => setCheckedPaymentMethod({ method: method, card: debitCardMulti.id })}
                                    />
                                    <label onClick={() => setCheckedPaymentMethod({ method: method, card: debitCardMulti.id })}>Cartão {debitCardMulti.brand} de Final {debitCardMulti.masked_number.slice(-4)}</label>
                                    <div className="delete-card">
                                        <DeleteCardButton cardList={allDebitCardsMultiBenefits} setAllCards={setAllDebitCardsMultiBenefits} card={debitCardMulti} type="debitCardMulti" />
                                    </div>
                                </div>
                            )}
                        </div>
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'bank_slip':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: method, card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: method, card: null })}>Boleto bancário</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'package_balance':
                if (allPaymentMethods[method] && allPaymentMethods[method].available > 0) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === 'package'}
                                onChange={() => setCheckedPaymentMethod({ method: 'package', card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: 'package', card: null })}>Pacote ({allPaymentMethods[method].available}/{allPaymentMethods[method].purchased})</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            case 'subscription':
                if (allPaymentMethods[method]) {
                    methodView =
                        <div key={methodIndex} className="payment-method-view">
                            <input
                                type="radio"
                                value={method}
                                checked={checkedPaymentMethod.method === method}
                                onChange={() => setCheckedPaymentMethod({ method: 'subscription', card: null })}
                            />
                            <label onClick={() => setCheckedPaymentMethod({ method: 'subscription', card: null })}>Plano de assinatura</label>
                        </div>
                        ;
                } else {
                    methodView = <div key={methodIndex}></div>;
                }
                break;
            default:
                methodView = <div key={methodIndex}></div>;
        }

        return (
            <div key={methodIndex}>
                {methodView}
            </div>
        )
    });

    let discount = null;
    if (null != selectedDiscountCoupon) {
        if (selectedDiscountCoupon.discount_percentage) {
            discount = (price / 100) * selectedDiscountCoupon.discount_percentage;
        } else {
            discount = selectedDiscountCoupon.discount_value;
        }

        if (discount > price) {
            discount = price;
        }

        price -= discount;
    }

    return (
        <Container>
            {(!partner && hasDiscount !== false) &&
                <div className="container-title-payment-view">
                    <div className="line-separator"></div>
                    <span className="title-payment-view">PAGAMENTO</span>
                    <div className="payment-cupon-view">
                        <span className="payment-cupon-text">Cupom de desconto?</span>
                        <AddDiscountCouponButton setSelectedDiscountCoupon={setSelectedDiscountCoupon} orderDate={orderDate} />
                    </div>
                </div>
            }

            {allPaymentMethods.credit &&
                <div className="new-card-container">
                    <NewCardButton doExecuteSearchs={executeSearchs} setLoading={setLoading} />
                </div>
            }

            <div className="payment-card-view">
                {paymentMethods}
            </div>

            {(setWalletSelected && checkedPaymentMethod.method === 'pix' && (price > balance.balance && balance.balance > 0) ||
                setWalletSelected && checkedPaymentMethod.method === 'credit' && (price > balance.balance && balance.balance > 0)) &&
                <div className="check-wallet">
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onClick={() => setIsSelected(!isSelected)}
                    />
                    <span className="checkmark" onClick={() => setIsSelected(!isSelected)}></span>
                    <label className="ext-check" onClick={() => setIsSelected(!isSelected)}>Utilizar saldo carteira digital: {formatCurrencyBRL(balance.balance)}</label>
                </div>
            }

            <div className="container-info-payment-view">
                {null != discount &&
                    <div className="discount-coupon-view">
                        {!offer?.estimate_required
                            && <span className="text-discount">Desconto aplicado: <span>{formatCurrencyBRL(discount)}</span></span>
                        }

                        <span className="discount-coupon-code">#{selectedDiscountCoupon.code}</span>
                        <button
                            className="remove-discount-coupon-button"
                            onClick={() => setSelectedDiscountCoupon(null)}
                        >
                            <span className="remove-discount-coupon-button-text">x</span>
                        </button>
                    </div>
                }

                {pack &&
                    <span className="text-warning-package">Este pacote é válido por {pack.validity_days} dias após a aprovação do pagamento</span>
                }

                {(checkedPaymentMethod && checkedPaymentMethod.method === 'payroll') &&
                    <input
                        type="text"
                        className="payment-signature-input"
                        placeholder={"digite " + (allPaymentMethods.payroll_signature === 'cpf' ? 'seu CPF' : 'sua matrícula')}
                        onChange={e => setPaymentSignature(e.target.value)}
                    />
                }

                {!partner &&
                    offer?.estimate_required
                    ? <span className="text-total">À orçar</span>
                    : <span className="text-total">Total: <span>{formatCurrencyBRL(price)}</span></span>
                }
            </div>
        </Container >
    )

}

export default PaymentView;