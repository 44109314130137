import React, { useEffect, useState } from 'react';
import { getBannerCompanyOffer } from '../../../../api/marketing';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUser } from '../../../../utils/auth';
import { Container } from './styles';

const InCompanyBanner = () => {
  const navigation = useHistory();
  const [company, setCompany] = useState(getUser()?.company);
  const [bannerCompanyOffer, setBannerCompanyOffer] = useState();

  const executeSearchs = async () => {
    try {
      await getBannerCompanyOffer().then(val => setBannerCompanyOffer(val));

    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      executeSearchs();
    }

    return () => (isMounted = false);
  }, []);

  return (
    <Container>
      {(bannerCompanyOffer && !company) &&
        <div className="container">
          <div className="line-separator"></div>
          <span className="title">{bannerCompanyOffer.title}</span>
          <button type="button" className="in-company-banner-button" onClick={() => navigation.push("/linkCompany", { linkSomeCompany: true })}>
            <span className="in-company-banner-button-text">conecte-se com sua empresa</span>
          </button>
          <span className="description">{bannerCompanyOffer.description}</span>
          <div>
            <img src={bannerCompanyOffer.fileUrl} className="banner-image" alt="Banner Em Compania"/>
          </div>
        </div>
      }
    </Container>
  );
}

export default InCompanyBanner;