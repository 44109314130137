import React from 'react';
import { SkeletonLoader, GroupSkeletons } from './styles';
import Skeleton from 'react-loading-skeleton';

const SkeletonWallet = () => {
    return (
        <SkeletonLoader>
            <Skeleton className="skeleton-price" />

            <GroupSkeletons>
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
            </GroupSkeletons>
        </SkeletonLoader>
    );
}

export default SkeletonWallet;