import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getTimeClockCheckinCurrent,
  getTimeClockHistory,
} from "../../../../../../api/time_clock";

import { ButtonSelectDays, SelectDaysContainer } from "../ButtonOrange";
import { CardHistoryTimeClock } from "../CardHistoryTimeClock";
import { PageInfoWhatToDo } from "../PageInfoWhatToDo";

export function HistoryTimeList({ handleOpenModal, loading, setLoading }) {
  const [timeClockHistoryList, setTimeClockHistoryList] = useState([]);
  const [actualCheckin, setActualCheckin] = useState(null);
  const [daysSelected, setDaysSelected] = useState(7);

  const doGetHistory = async (isMounted) => {
    await getTimeClockHistory(daysSelected)
      .then((currentHistory) => {
        if (isMounted) {
          setTimeClockHistoryList(
            currentHistory.map((timeClock) => {
              return {
                ...timeClock,
                started_at: moment(timeClock.started_at)
                  .tz("America/Sao_Paulo")
                  .format("DD/MM/YYYY - HH:mm:ss"),
                finished_at: !timeClock.finished_at
                  ? "Em andamento"
                  : timeClock.started_at - timeClock.finished_at === 0
                  ? moment(timeClock.finished_at)
                      .tz("America/Sao_Paulo")
                      .format("HH:mm:ss")
                  : moment(timeClock.finished_at)
                      .tz("America/Sao_Paulo")
                      .format("DD/MM/YYYY - HH:mm:ss"),
              };
            })
          );
        }
      })
      .catch(() => setTimeClockHistoryList([]))
      .finally(() => setLoading(false));
  };

  function handleChangeSelecetedDays(numberOfDays) {
    setLoading(true);
    setDaysSelected(numberOfDays);
  }

  const doGetChecked = async (isMounted) => {
    await getTimeClockCheckinCurrent()
      .then((currentCheckin) => {
        if (isMounted) {
          setActualCheckin(currentCheckin);
        }
      })
      .catch(() => setActualCheckin(""));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      doGetChecked(isMounted);
    }

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      doGetHistory(isMounted);
    }

    return () => (isMounted = false);
  }, [loading, daysSelected]);

  return timeClockHistoryList.length === 0 ? (
    <span className="notFound">{t("noCheckinAvailable")}</span>
  ) : (
    <>
      <PageInfoWhatToDo>
      {t("historyFromLastDays")} {daysSelected} {t("daysWord")}
      </PageInfoWhatToDo>
      <SelectDaysContainer>
        <ButtonSelectDays
          onClick={() => handleChangeSelecetedDays(7)}
          selected={daysSelected === 7}
        >
          7 {t("daysWord")}
        </ButtonSelectDays>
        <ButtonSelectDays
          onClick={() => handleChangeSelecetedDays(15)}
          selected={daysSelected === 15}
        >
          15 {t("daysWord")}
        </ButtonSelectDays>
        <ButtonSelectDays
          onClick={() => handleChangeSelecetedDays(30)}
          selected={daysSelected === 30}
        >
          30 {t("daysWord")}
        </ButtonSelectDays>
      </SelectDaysContainer>

      {!loading &&
        timeClockHistoryList.map((timeClock) => {
          return (
            <CardHistoryTimeClock
              key={timeClock.id}
              timeClock={timeClock}
              handleOpenModal={handleOpenModal}
              actualCheckin={actualCheckin}
            />
          );
        })}
    </>
  );
}
