import React from "react";

import {
  Container,
  InfoWrapper,
  DescriptionCard,
  ButtonRedirect,
} from "./styles";
import logo from "../../assets/Logo.svg";
import { useHistory } from "react-router-dom";

export function NotFound() {
  const history = useHistory();

  function handleRedirectHome() {
    history.replace("/home");
  }

  return (
    <Container>
      <img src={logo} alt="Logo adblock card" />
      <InfoWrapper>
        <DescriptionCard>A página requisitada não foi encontrada.</DescriptionCard>
        <ButtonRedirect onClick={() => handleRedirectHome()}>
          Voltar para o início
        </ButtonRedirect>
      </InfoWrapper>
    </Container>
  );
}
