import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { deleteCustomerAccount } from '../../../api/customer';
import LoaderSpinner from '../../LoaderSpinner';
import './styles.css';
import { onSignOut } from '../../../utils/auth';
import { useHistory } from 'react-router-dom';


const ModalCancelAccount = ({ showModalCancel, setShowModalCancel }) => {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const navigation = useHistory();


  const doCloseModal = () => {
    setShowModalCancel(false);
  }

  const verifyPassword = () => {
    return password.length <= 3
  }

  const doRemoveAccount = async () => {
    try {
      setLoading(true)
      const response = await deleteCustomerAccount(password)
      if(response.ok) {
        toast.success('Cadastro removido com sucesso')
        logout()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
    setShowModalCancel(false);
  }

  const logout = async () => {
    onSignOut();
    navigation.push('/')
  }

  return (
    <div>
      <Modal
        isOpen={showModalCancel}
        onRequestClose={() => doCloseModal(false)}
        overlayClassName="modal-overlay"
        className="modal-content cancel-account"
        ariaHideApp={false}
      >
        <LoaderSpinner loading={loading} />
        <div className="header-modal">
          <span className="subtitle-header">confirmar</span>
          <span className="title-header">remoção</span>
        </div>

        <div className="content-body">
          <span>
            Ao confirmar a deleção da sua conta, todas as suas informações cadastrais serão eliminadas
            de nossa base e você perderá o valor total acumulado em sua carteira digital e todos os pacotes
            de serviços que foram adquiridos. Não haverá reembolso dos pacotes ou do valor acumulado na
            carteira.
          </span>
          <div className='input-container'>
            <span className="input-name-text">senha</span>
            <input
              className="input-active"
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder="digite a senha"
              onChange={text => { setPassword(text.target.value) }}
            />
            <div className="icon-personal-input">
              <span onClick={() => { setShowPassword(!showPassword) }} >
                {showPassword ?
                  <FiEyeOff size={20} color="#FA9F2A" />
                  :
                  <FiEye size={20} color="#FA9F2A" />
                }
              </span>
            </div>
          </div>
        </div>

        <div className="container-buttons">
          <button className="accept-button" type="type" onClick={() => doRemoveAccount()} disabled={verifyPassword()}>
            <span>confirmar</span>
          </button>
          <button className="cancel-button" type="button" onClick={() => doCloseModal(false)}>
            <span>voltar</span>
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default ModalCancelAccount;