import { StyleSheet, Dimensions } from "react-native";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

export const style = StyleSheet.create({
    buttonNeedHelp: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },

    dark: {
        color: '#FA9F2A',
        borderColor: '#FA9F2A',
    },

    helpText: {
        color: '#FFFFFF',
        textDecorationLine: 'underline',
        fontSize: 15,
        fontFamily: 'Roboto',
        fontWeight: '300',
        marginRight: 15,
    },

    borderIconHelp: {
        borderColor: '#FFFFFF',
        borderWidth: 3,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 13,
        borderRadius: 100,
        paddingVertical: 5,
    },

    iconHelp: {
        color: '#FFFFFF',
    },
});

export const ModalStyle = StyleSheet.create({
    containerHelper: {
        justifyContent: 'center',
        alignItems: 'center',
        height: screenHeight,
        width: screenWidth,
        backgroundColor: 'rgba(0, 0, 0, 0.68)',
    },

    containerBody: {
        backgroundColor: '#F8F8F8',
        borderRadius: 18,
        alignSelf: 'center',
        position: 'relative',
        width: 280,
    },

    headerHelper: {
        paddingLeft: 25,
        paddingRight: 10,
        paddingVertical: 10,
        backgroundColor: '#FFFFFF',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 17,
        borderTopRightRadius: 17,
        shadowColor: '#0000000D',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.6,
        elevation: 5,
    },

    subtitleHeader: {
        color: '#14213D',
        fontSize: 10,
        textAlign: 'left',
        fontFamily: 'Roboto',
        fontWeight: '300',
    },

    titleHeader: {
        color: '#14213D',
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: '500',
        textAlign: 'left',
    },

    containerContent: {
        paddingHorizontal: 30,
        paddingVertical: 20,
    },

    cellphone: {
        color: '#707070',
        fontSize: 14,
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        paddingVertical: 5,
    },

    content: {
        color: '#707070',
        fontSize: 11,
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        textDecorationLine: 'underline',
    },

    buttonClose: {
        alignItems: 'center',
        backgroundColor: "#FA9F2A",
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18,
        paddingVertical: 12,
        alignSelf: 'center',
        width: 280,
    },

    textButtonModal: {
        color: '#fff',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 13,
    },
});