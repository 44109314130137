import React from 'react'
import { NotFound } from '../NotFound'
import { TableCode, TableInfoScheduled, TableLocal, TableRow, TableView } from './styles'

export function TableSchedulePositions({ positions, setPosition, position, isPositionsFiltered }) {

  return (
    <table>
      {positions.length === 0
        ? <NotFound>{isPositionsFiltered ? `O nome filtrado não foi encontrado.` : "Nenhum lugar disponível para o tipo selecionado."}</NotFound>
        : (
          <TableView>
            {positions.map((positionValue) => {
              return (
                <TableRow
                  key={positionValue.id}
                  value={positionValue}
                  onClick={() => {
                    setPosition(positionValue)
                  }
                  }
                  clicked={positionValue.id === position.id}>
                  <TableCode>{positionValue.code}</TableCode>
                  <TableInfoScheduled>
                    <TableLocal>{positionValue.description}</TableLocal>
                    <p>
                      {positionValue.scheduled_user && positionValue.scheduled_user_in_progress === true ? `${positionValue.scheduled_user}` : positionValue.scheduled_user ? `${positionValue.scheduled_user}` : ''} {positionValue.scheduled_user_description && " - " + positionValue.scheduled_user_description} </p>
                  </TableInfoScheduled>
                </TableRow>
              )
            })}
          </TableView>
        )
      }

    </table>
  )
}