import React, { useState } from 'react';
import VideosGrid from './components/VideosGrid'
import ServiceBanner from '../components/ServiceBanner';
import { Container } from './styles';
import Header from '../../../components/Header';
import { FiCamera } from 'react-icons/fi';
import Footer from '../../../components/Footer';
import TabButton from '../../../components/TabButton';
import LoaderSpinner from '../../../components/LoaderSpinner';

const VideoList = ({ location }) => {
  const { offer, category, service } = location.state;
  const [loading, setLoading] = useState(false);

  return (
    <Container>
      <Header screen="home" orderType="online" />
      <LoaderSpinner loading={loading} />

      <div className='background-body'>
        <div className="content-body-video-list">
          <ServiceBanner bannerUrl={category.header_url} title={offer.title} serviceType={''} />

          <div className="recorded-at-view-value">
            <FiCamera size={12} color="#FFF" />
            <span className="recorded-text"> gravados</span>
          </div>

          <span className="sub-title">SESSÕES GRAVADAS</span>

          <VideosGrid setLoading={setLoading} service={service} category={category} />
        </div>

        <div className="footer-service-list">
          <div className="line-separator"></div>
          <Footer />
        </div>
      </div>

      <TabButton screen="home" />
    </Container>
  );
};

export default VideoList;
