import { API_BASE, API_VERSION, CONFIRM, PRIZE_CAMPAIGNS, RAFFLE } from "./helper/api_urls";
import { validateGetResponseBasic } from "./helper/validateGetResponse";
import { validatePutResponseBasic } from "./helper/validatePutResponse";


const getScratchPrize = async (campaignId, customerCpf) => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${PRIZE_CAMPAIGNS}/${campaignId}${RAFFLE}?customer_cpf=${customerCpf}`
    return await validateGetResponseBasic(
      getUrl,
      "Erro ao consultar prêmio"
    )
  } catch (error) {
    throw error
  }
}

const confirmScratchPrize = async (campaignId, itemId, customerCpf) => {
  try {
    const putUrl = `${API_BASE}${API_VERSION}${PRIZE_CAMPAIGNS}/${campaignId}/${itemId}${CONFIRM}?customer_cpf=${customerCpf}`
    return await validatePutResponseBasic(
      putUrl,
      "Erro ao confirmar prêmio"
    )
  } catch (error) {
    throw error
  }
}

export {
  getScratchPrize,
  confirmScratchPrize
};
