import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { getUser } from "../../../../../utils/auth";

import { deleteTimeClockCurrent } from "../../../../../api/time_clock";

import Footer from "../../../../../components/Footer";
import Header from "../../../../../components/Header";
import TabButton from "../../../../../components/TabButton";
import TimeClockBanner from "../TimeClockBanner";
import ModalTimeClockConfirm from "../ModalTimeClockConfirm";

import { Container } from "./styles";
import { HistoryTimeList } from "../components/HistoryTimeList";
import LoaderSpinner from "../../../../../components/LoaderSpinner";

import {t} from 'i18next'

export const TimeClockHistory = () => {
  const history = useHistory();

  if (!getUser()?.company?.time_clock_enabled) {
    history.replace("/home");
  }

  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);

  function handleOpenModal() {
    setModalType("cancelamento");
    setShowModal(true);
  }

  async function handleCancelTimeClock() {
    try {
      setLoading(true);
      await deleteTimeClockCurrent();
      toast.success("Cancelamento realizado com sucesso", { duration: 5000 });
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Header screen="home" isNotSchedule={false} isTimeClock />

      <TimeClockBanner
        bannerUrl={
          "https://posher.s3.sa-east-1.amazonaws.com/production/time_clock/images/banner_history.jpg"
        }
        isUrl={true}
        title={t("historyWord")}
        serviceType="presential"
      />
      
      <LoaderSpinner loading={loading} />
      <Container className="background-body">
        <HistoryTimeList
          handleOpenModal={handleOpenModal}
          loading={loading}
          setLoading={setLoading}
        />

        <div className="footer-service-list">
          <div className="line-separator"></div>
          <Footer />
        </div>
      </Container>

      {modalType && (
        <ModalTimeClockConfirm
          modalType={modalType}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelTimeClock={handleCancelTimeClock}
        />
      )}

      <TabButton screen="home" />
    </>
  );
};
