import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;

    .background-terms {
        max-width: 1080px !important;
        background-color: #FFF;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
    }
`;

export const ContentBody = styled.div`
    margin-top: 2rem;
    padding: 1rem 3rem;
    font-size: 0.8rem;
    display: flex;
    color: #14213D !important;

    > div{
        overflow: auto;
        font-size: 1rem;
        margin-top: 10px;
        line-height: 1.5;
    }

    
    h1, h2, h3, h4, h5, h6, strong{
        color: #14213D !important;
    }
`;


export const ButtonAcceptTerms = styled.button`
    width: 40%;
    padding: 15px 0;
    margin-top: 3rem;
    font-size: 1rem;
    border-radius: 1rem;
    background-color: #FA9F2A;
    transition: ease-in 0.2s;
    margin-bottom: 10rem;
    &:hover{
        opacity: 0.7;
    }
`;
