import React, { useState } from 'react';
import { makeCreditCard, makeDebitCard } from '../../../../../../api/payments';
import { toast } from 'react-toastify';
import ModalAddNewCard from '../../../../../../components/Modals/ModalAddNewCard';
import { Container } from './styles';

const NewCardButton = (props) => {
    const doExecuteSearchs = props.doExecuteSearchs;
    const setLoading = props.setLoading;
    const [modalVisible, setModalVisible] = useState(false);
    const [holderName, setHolderName] = useState("");
    const [number, setNumber] = useState("");
    const [expirationMonthYear, setExpirationMonthYear] = useState("");
    const [securityCode, setSecurityCode] = useState("");

    const createCreditCards = async () => {
        try {
            setLoading(true);

            let expiration_month = expirationMonthYear.substring(0, 2);
            let expiration_year = expirationMonthYear.substring(3, 5);

            await makeCreditCard(holderName, number, expiration_month, expiration_year, securityCode);
            toast.success("Cartão adicionado com sucesso", { autoClose: 3000 })

            doExecuteSearchs();

            setLoading(false);
            setModalVisible(false);

        } catch (error) {
            setLoading(false);
            toast.error('Erro ao adicionar cartão: Dados inválidos')
        }
    }

    const createDebitCards = async (card_type, brand) => {
        try {
            setLoading(true);

            await makeDebitCard(holderName, number, expirationMonthYear, securityCode, card_type, brand);
            toast.success('Cartão adicionado com sucesso', { autoClose: 5000 });

            doExecuteSearchs();

            setLoading(false);
            setModalVisible(false);

        } catch (error) {
            setLoading(false);
            toast.error("Erro ao adicionar cartão: Dados inválidos");
        }
    }

    return (
        <Container>
            <button className="button-new-card" type="button" onClick={() => setModalVisible(true)}>
                <span className="new-card-button">novo cartão</span>
            </button>

            <ModalAddNewCard
                showModalNewCard={modalVisible}
                setShowModalNewCard={val => setModalVisible(val)}
                setSecurityCode={setSecurityCode}
                setNumber={setNumber}
                setHolderName={setHolderName}
                setExpirationMonthYear={setExpirationMonthYear}
                clicked={option => option && createCreditCards()}
            />

        </Container>
    )
}

export default NewCardButton;