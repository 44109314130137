import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { disapproveEstimateCall } from '../../../api/service_orders';
import LoaderSpinner from '../../LoaderSpinner';
import './styles.css'


export function ModalCancelApprovalOrder({ cancelApprovalModalOpen, setCancelApprovalModalOpen, order, setApprovalModalOpen, type, searchOrders, setLoad, loading, setLoading, setAllOrders }) {

    const [closeAllModals, setCloseAllModals] = useState(false);

    useEffect(() => {
        if (closeAllModals) {
            setCancelApprovalModalOpen(false)
            setApprovalModalOpen(false);
        }
        setLoading(false);
    }, [cancelApprovalModalOpen, closeAllModals, setApprovalModalOpen, setCancelApprovalModalOpen, setLoading, loading])

    return (
        <>
            <Modal
                isOpen={cancelApprovalModalOpen}
                onRequestClose={() => setCancelApprovalModalOpen(!cancelApprovalModalOpen)}
                overlayClassName="modal-overlay"
                className="modal-content reprove-order"
                ariaHideApp={false}
            >

                <LoaderSpinner loading={loading} />

                <button className="x-to-close" type="button" onClick={() => setCancelApprovalModalOpen(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">Confirmar</span>
                    <span className="title-header">REPROVAÇÃO</span>
                </div>

                <div className="body-all-content">
                    <div className="content-reprove-body">
                        <span>Confirmar reprovação do orçamento do pedido Nº {order.id}?</span>
                    </div>
                </div>

                <div className="container-buttons">
                    <button className="accept-button" type="button" onClick={() => disapproveEstimateCall(order, setCloseAllModals, setAllOrders, setLoad, searchOrders, type, setLoading)}>
                        <span>Sim, reprovar</span>
                    </button>

                    <button className="cancel-button" type="button" onClick={() => setCancelApprovalModalOpen(!cancelApprovalModalOpen)}>
                        <span>Não</span>
                    </button>
                </div>

            </Modal>

        </>
    )
}