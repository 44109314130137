import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Container, LogoImage } from "./styles";

export function SplashLogo({ location }) {
  const history = useHistory();
  const image_url = location.state.image_url;

  useEffect(() => {
    setTimeout(() => {
      history.replace("/home", "splashShowed");
    }, 2000);
  }, []);

  return (
    <Container>
      <LogoImage src={image_url} alt="Logo da empresa" />
    </Container>
  );
}
