import styled from 'styled-components';
import imgSrc  from '../../../assets/images/bg-login.png';

export const Container = styled.div`
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${imgSrc});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
    position: relative;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-img-login {
        position: absolute;
        top: 100px;
        width: 500px;
    }

    .forms-new-password {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .forms-new-password .login-text {
        font-size: 1rem;
        color: #fff;
    }

    .forms-new-password form {
        position: relative;
    }

    .forms-new-password form input {
        width: 500px;
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #000;
        border-radius: 30px;
        height: 45px;
        border: 1px solid #CFCFCF;
        background-color: #fff;
        padding: 0 60px;
    }

    .forms-new-password form .see-password {
        position: absolute;
        right: 10px;
        top: 28px;
        width: 30px;
        height: 30px;
        background: #FA9F2A;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media(max-width: 640px) {
        .forms-new-password .login-text {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 520px) {
        .forms-new-password form input {
            width: 350px;
        }

        .logo-img-login {
            width: 350px;
        }
    }

    @media(max-width: 450px) {
        .forms-new-password .login-text {
            font-size: 0.8rem;
        }
    }

    @media(max-width: 375px) {
        .forms-new-password .login-text {
            font-size: 0.6rem;
        }

        .forms-new-password form input {
            width: 300px;
        }

        .logo-img-login {
            width: 300px;
        }
    }
`;

export const ButtonSend = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    background-color: #FA9F2A;
    border-radius: 20px;
    width: 15rem;
    font-size: 1rem;
    height: 40px;
    transition: .2s background-color;

    :hover {
        background-color: #D68A28;
    }

    @media(max-width: 520px) {
        width: 10rem;
        height: 30px;
        font-size: 0.8rem;
    }

    @media(max-width: 375px) {
        margin-top: 15px;
        font-size: 0.6rem;
    }
`;