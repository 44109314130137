import React, { useEffect, useState } from 'react';
import { getServicePackages } from '../../api/service_packages';
import PackageUnit from './components/PackageUnit';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import TabButton from '../../components/TabButton';
import { Container } from './styles';
import ModalMoreInformation from '../../components/Modals/ModalMoreInformation';
import Footer from '../../components/Footer';
import { debounce } from 'throttle-debounce';
import SkeletonMyPackages from './SkeletonMyPackages';

const MyPackages = () => {
  const [allPackages, setAllPackages] = useState([]);
  const [descriptionSelected, setDescriptionSelected] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);

  const cancelLoading = () => {
    setTimeout(() => {
      if (document.getElementById('packageBody')) {
        document.getElementById('packageBody').style.display = 'flex';
      }
      setLoadingSkeleton(false);
    }, 1000);
  }

  const getAllPackages = async () => {
    try {
      await getServicePackages(setAllPackages);
      cancelLoading();
    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
      cancelLoading();
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (document.getElementById('packageBody')) {
        document.getElementById('packageBody').style.display = 'none';
      }
      setLoadingSkeleton(true);
      setTimeout(() => debounce(5000, getAllPackages()), 1500);
    }

    return () => (isMounted = false);
  }, []);

  return (
    <Container>
      <Header screen="packages" orderType="corporate" />

      {modalVisible &&
        <ModalMoreInformation title={descriptionSelected.name} showModal={true} setShowModal={val => setModalVisible(val)} body={descriptionSelected.description} />
      }

      <div className="background-body">
        {loadingSkeleton &&
          <SkeletonMyPackages />
        }

        <div className="container-pack" id="packageBody">
          {allPackages.length > 0 ?
            allPackages.map((pack, index) => {
              return (
                <PackageUnit
                  key={index}
                  pack={pack}
                  descriptionSelected={val => { setDescriptionSelected(val); setModalVisible(true) }}
                />
              );
            })
            :
            <div className="no-service">
              <span>Nenhum pacote encontrado.</span>
            </div>
          }
        </div>

        {!loadingSkeleton &&
          <div className="footer-service-list">
            <div className="line-separator"></div>
            <Footer />
          </div>
        }
      </div>

      <TabButton screen="packages" />
    </Container>
  )
};

export default MyPackages;