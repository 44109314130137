import React, { useEffect } from 'react';
import { Container } from './styles';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderSpinner = (props) => {
    let loading = props.loading;

    const hideScroll = () => {
        document.querySelector("body").classList.add("hideScroll");
    }

    const showScroll = () => {
        document.querySelector("body").classList.remove("hideScroll");
    }

    useEffect(() => {
        if (loading) {
            hideScroll();
        } else {
            showScroll();
        }
    }, [loading]);

    return (
        <div>
            {loading &&
                <Container>
                    <Loader type="Circles" color="#FA9F2A" height={100} width={100} />
                </Container>
            }
        </div>
    );
}

export default LoaderSpinner;