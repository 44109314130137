import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { toast } from "react-toastify";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { putSendClientFeedback } from "../../../../../../api/requests/orders/putFeedbackClientApi";

import { ButtonsFeedback } from "./styles";
import LoaderSpinner from "../../../../../../components/LoaderSpinner";

export function ButtonFeedbackContainer({ order_id }) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleGiveFeedback(feedback) {
    setLoading(true);
    await putSendClientFeedback(order_id, feedback)
      .then(() => {
        toast.success("Feedback enviado com sucesso");
      })
      .catch((error) => toast.error(error.message))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
          history.replace("/home");
        }, 2000);
      });
  }

  return loading ? (
    <LoaderSpinner loading={loading} />
  ) : (
    <ButtonsFeedback>
      <button
        className="positive"
        aria-label="sim, gostei"
        onClick={() => handleGiveFeedback(true)}
      >
        <div>
          <AiOutlineLike size={45} width="100%" />
        </div>
      </button>
      <button onClick={() => handleGiveFeedback(false)}>
        <div>
          <AiOutlineDislike size={45} width="100%" />
        </div>
      </button>
    </ButtonsFeedback>
  );
}
