import styled from 'styled-components';

export const SkeletonLoader = styled.div`
    width: 100%;
    max-width: 1080px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .skeleton-price {
        width: 800px;
        height: 60px;
        border-radius: 20px;
        margin: 50px 0 20px;
    }

    .button-skeleton {
        width: 400px;
        height: 80px;
        border-radius: 20px;
        margin: 20px 0;
    }

    @media(max-width: 920px) {
        .skeleton-price {
            width: 500px;
        }
    }

    @media(max-width: 700px) {
        .button-skeleton {
            width: 350px;
        }

        .skeleton-price {
            width: 400px;
        }
    }

    @media(max-width: 400px) {
        .skeleton-price {
            width: 350px;
        }
    }

    @media(max-width: 375px) {
        .button-skeleton {
            width: 300px;
        }

        .skeleton-price {
            width: 300px;
        }
    }
`;

export const GroupSkeletons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .today-skeleton {
        width: 800px;
        height: 60px;
        border-radius: 20px;
        margin: 10px 0;
    }

    @media(max-width: 920px) {
        .today-skeleton {
            width: 500px;
        }
    }

    @media(max-width: 700px) {
        .today-skeleton {
            width: 400px;
        }
    }

    @media(max-width: 400px) {
        .today-skeleton {
            width: 350px;
        }
    }

    @media(max-width: 375px) {
        .today-skeleton {
            width: 300px;
        }
    }
`;