import styled from "styled-components";

export const ModalContainer = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
`;

export const HeaderModal = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  padding: 10px 30px;
  text-align: left;
  font-size: 1.5rem;
  color: #000000;
`;

export const HeaderTitle = styled.span`
  font-size: 0.8rem;
`;

export const HeaderSubtitle = styled.span`
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
`;

export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalContent = styled.span`
  padding: 2rem;
  font-size: 1rem;
  color: #000000;
`;

export const ContainerFooterModal = styled.div`
  width: 100%;
`;

export const CloseModal = styled.button`
  width: 50%;
  padding: 1rem;
  background-color: #fa9f2a;
  font-size: 1rem;
`;

export const SendModal = styled.button`
  width: 50%;
  padding: 1rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: #fa9f2a;
`;
