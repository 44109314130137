import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

const ButtonsPackage = ({ pack }) => {
    const navigation = useHistory();

    const goToPackageCheckout = (pack) => {
        navigation.push("/packageCheckout", { pack: pack });
    }

    return (
        <Container type="button" onClick={() => goToPackageCheckout(pack, navigation)}>
            <div className="img-button">
                <img src={pack.thumb_url} alt={pack.name} />
            </div>
            
            <h3 className="name-button">{pack.name}</h3>
        </Container>
    );
}

export default ButtonsPackage;