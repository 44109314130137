import React, { useEffect, useState } from "react";
import ServiceBanner from "../../components/ServiceBanner";
import DateButton from "../../components/DateButton";
import Moment from "moment-timezone";
import PaymentView from "../../components/PaymentView";
import FinishOrderButton from "./components/FinishOrderButton";
import { getCategory } from "../../../../api/service_offer";
import Header from "../../../../components/Header";
import TabButton from "../../../../components/TabButton";
import { Container, DatesViewContainer } from './styles';
import SlotRange from './components/SlotRange';
import LoaderSpinner from "../../../../components/LoaderSpinner";
import SkeletonOnDemandOrder from "./SkeletonOnDemandOrder";
import { toast } from "react-toastify";
import Footer from "../../../../components/Footer";
import { debounce } from 'throttle-debounce';

const OnDemandOrderData = ({ location }) => {
  let offer = location.state?.offer;
  let address = location.state?.address;
  let orderType = location.state?.orderType;
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedFrom, setSelectedFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [checkedPaymentMethod, setCheckedPaymentMethod] = useState({});
  const [selectedDiscountCoupon, setSelectedDiscountCoupon] = useState();
  const [allDates, setAllDates] = useState([]);
  const [category, setCategory] = useState();
  const [walletSelected, setWalletSelected] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);

  if(!offer && location.search !== '') {
    let search = location.search.substring(1);
    offer = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

    sessionStorage.removeItem('deepLink');
    sessionStorage.setItem('alreadyDidDeep', true);
    
    orderType = offer.order_type;
  }

  let free = offer?.free;

  const cancelLoading = () => {
    setTimeout(() => {
      if(document.getElementById('bodyOnDemand')) {
        document.getElementById('bodyOnDemand').style.display = 'flex';
      }
      setLoadingSkeleton(false);
    }, 1000);
  }

  const parseDate = (selectedDate, time) => {
    if (selectedDate && time) {
      let date = new Date(Date.parse(selectedDate) + time * 60 * 60 * 1000);

      return date.toISOString();
    } else {
      return null;
    }
  }

  const doGetCategory = async () => {
    try {
      await getCategory(setCategory, offer.category_id).then(() => cancelLoading());

    } catch (error) {
      toast.error(error.message);
    }
  }

  const selectDate = async (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if(document.getElementById('bodyOnDemand')) {
        document.getElementById('bodyOnDemand').style.display = 'none';
      }
      setLoadingSkeleton(true);
      setTimeout(() => debounce(5000, doGetCategory()), 1500);

      let dates = [];

      let actualHour = new Date().getHours();

      for (let i = 1; i <= 30; i++) {
        let date = new Date();
        date.setDate(date.getDate() + i);

        if (0 !== date.getDay() && !(1 === i && ('residential' === orderType && actualHour >= 20))) {
          dates.push(Moment(date).format('YYYY-MM-DD'));
        }
      }

      setAllDates(dates);
    }

    return () => { isMounted = false; };
  }, []);

  return (
    <Container>
      <Header orderType={orderType} address={address} screen="home" />
      <LoaderSpinner loading={loading} />

      <div className="background-body">

        {loadingSkeleton &&
          <SkeletonOnDemandOrder />
        }

        <div id="bodyOnDemand" className="body-ondemand">

          <ServiceBanner
            bannerUrl={(category ? category.header_url : null)}
            title={offer.title}
            serviceType={offer.type_type}
          />

          <span className="choose-one-date">Escolha uma data</span>

          <div className="container-dates-view">
            <DatesViewContainer>
              {allDates.map((date, dateIndex) => {
                return (
                  <div key={dateIndex}>
                    <DateButton
                      date={date}
                      selected={selectedDate === date}
                      doSelectDate={() => selectDate(date)} />
                  </div>
                )
              })}
            </DatesViewContainer>
          </div>

          {selectedDate &&
            <div className="content-view">
              <span className="choose-period">Selecione um período para ser atendido</span>

              <SlotRange
                selectedFrom={selectedFrom}
                setSelectedFrom={setSelectedFrom}
                selectedTo={selectedTo}
                setSelectedTo={setSelectedTo}
                selectedDate={selectedDate}
                orderType={orderType}
              />

              {!free &&
                <PaymentView
                  offer={offer}
                  orderType={orderType}
                  setCheckedPaymentMethod={setCheckedPaymentMethod}
                  checkedPaymentMethod={checkedPaymentMethod}
                  setSelectedDiscountCoupon={setSelectedDiscountCoupon}
                  selectedDiscountCoupon={selectedDiscountCoupon}
                  setWalletSelected={setWalletSelected}
                  setLoading={setLoading}
                  selectedDate={selectedDate}
                />
              }

              <FinishOrderButton
                offer={offer}
                orderType={orderType}
                scheduledFrom={parseDate(selectedDate, selectedFrom)}
                scheduledUntil={parseDate(selectedDate, selectedTo)}
                paymentMethod={checkedPaymentMethod.method}
                serviceId={offer.service_id}
                cardId={checkedPaymentMethod.card}
                walletSelected={walletSelected}
                discountCouponId={selectedDiscountCoupon ? selectedDiscountCoupon.id : null}
                address={address}
                free={free}
                setLoading={setLoading}
              />
            </div>
          }
        </div>

        {!loadingSkeleton &&
          <div className="footer-service-list">
            <div className="line-separator"></div>
            <Footer />
          </div>
        }
      </div>

      <TabButton screen="home" />

    </Container>
  );
};

export default OnDemandOrderData;
