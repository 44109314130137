import React, { useEffect, useState } from 'react';
import { GlobalStyle } from './styles/global';
import { onSignIn, onSignOut, refreshUUID } from './utils/auth';
import { refreshToken } from './api/login';
import { getTermsConditions } from './api/customer';
import { ContextProvider } from './hooks/context';
import "@brainhubeu/react-carousel/lib/style.css";
import * as Sentry from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Routes } from './routes';

import AcceptCookies from './components/AceptCookies';

import Modal from 'react-modal';

Modal.setAppElement("#root");

export function App() {
  const [state, setState] = useState({ checkedSignIn: false, signedIn: false });

  const logout = async () => {
    onSignOut();
  }

  Sentry.init({
    dsn: 'https://9212c9bff4a44ce9a3b615fcb85dc5b3@o325506.ingest.sentry.io/5725947',
    autoSessionTracking: true,
    environment: process.env.REACT_APP_CUSTOMER_WEB_ENVIRONMENT
  });

  const doRefreshToken = async () => {
    try {
      let data = await refreshToken();

      if (data.success) {
        onSignIn(data.user);

        await getTermsConditions(doVerifyTermsAcceptance);

        if (data.user.customer.invalid_email) {
          sessionStorage.setItem('invalid_email', true);
        }

      } else {
        logout();
      }

    } catch (error) {
      toast.error(error.message)
    }
  }

  const doVerifyTermsAcceptance = async (terms) => {
    if (!terms.acceptance_token) {
      logout();
    }
  }

  useEffect(() => {
    const res = localStorage.getItem('posher_logged_user');
    if (res !== null) {
      onSignIn(JSON.parse(res));
    }
    setState({ checkedSignIn: true, signedIn: res !== null });
  }, []); 


  if (state.checkedSignIn) {
    refreshUUID();

    if (state.signedIn) {
      doRefreshToken();
    }

    return (

      <BrowserRouter>
        <ContextProvider>
          <AcceptCookies />
          <Routes authenticated={state.signedIn} />
          <GlobalStyle />
          <ToastContainer autoClose={4000} />
        </ContextProvider>
      </BrowserRouter>
    );
  } else {
    return null;
  }
}
