import styled from 'styled-components';

export const SkeletonLoader = styled.div`
    width: 100%;
    max-width: 1080px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-skt {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .banner-skeleton {
        width: 500px;
        height: 100px;
        margin-top: 40px;
        border-radius: 20px;
        margin-bottom: 10px;
    }

    @media(max-width: 700px) {
        .banner-skeleton {
            width: 300px;
        }
    }

    @media(max-width: 375px) {
        .banner-skeleton {
            width: 200px;
        }
    }
`;

export const GroupSkeletons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .today-skeleton {
        width: 600px;
        height: 120px;
        border-radius: 20px;
        margin: 10px 0;
    }

    @media(max-width: 920px) {
        .today-skeleton {
            width: 500px;
            height: 140px;
        }
    }

    @media(max-width: 700px) {
        .today-skeleton {
            width: 400px;
            height: 120px;
        }
    }

    @media(max-width: 400px) {
        .today-skeleton {
            width: 350px;
            height: 100px;
        }
    }

    @media(max-width: 375px) {
        .today-skeleton {
            width: 300px;
            height: 100px;
        }
    }
`;