import styled from "styled-components";

export const ContainerImage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
    padding: 5px 0 5px 5px;
    height
`;