import { getSectionsCatchErrors } from "../getSectionsCatchErrors";
import { fetchWithTimeout, header } from "./request";

export const validateGetResponse = async (getUrl, errorMessage, body) => {
  const { get_bearer } = header(body);
  let isValidation = false;
  try {
    const response = await fetchWithTimeout(`${getUrl}`, get_bearer);
    if (response.ok) {
      const responseJson = response.data;
      return responseJson;

    } else if (response.status === 400) {
      isValidation = true;
      const data = response.data;
      throw new Error(data.message);

    } else if (response.status === 404) {
      isValidation = true;
      throw new NotFoundError("Não encontrado!");

    } else if (response.status !== 200) {
      const data = response.data;
      throw new Error(data.message + " " + response.status);

    }
  } catch (error) {
    if (isValidation) throw error;
    getSectionsCatchErrors(error, errorMessage);
  }
};

export const validateGetResponseBasic = async (getUrl, errorMessage, body) => {
  const { get } = header(body);
  let isValidation = false;
  try {
    const response = await fetchWithTimeout(`${getUrl}`, get);
    if (response.ok) {
      const responseJson = response.data;
      return responseJson;

    } else if (response.status === 400) {
      isValidation = true;
      const data = response.data;
      throw new Error(data.message);

    } else if (response.status === 404) {
      isValidation = true;
      throw new Error("Não encontrado!");

    } else if (response.status !== 200) {
      const data = response.data;
      throw new Error(data.message + " " + response.status);

    }
  } catch (error) {
    if (isValidation) throw error;
    getSectionsCatchErrors(error, errorMessage);
  }
};

class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = "NotFoundError";
  }
}