/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Container } from './styles';
import { t } from 'i18next'

const SlotRangeSchedule = props => {
  const { selectedFrom } = props;
  const { setSelectedFrom } = props;
  const { selectedTo } = props;
  const { setSelectedTo } = props;
  const { times } = props;
  const [timeNewTo, setTimeNewTo] = useState([]);

  /* Novo array "de", incrementando o intervalo para cada horário. Caso seja = 60 minutos, acrescenta uma hora. */
  let timeHoursTo;
  let timeMinutesTo;

  useEffect(() => {
    setTimeNewTo(
      times.times.map(time => {
        timeMinutesTo = Number(time.time.split(':')[1]) + times.interval;
        timeHoursTo = Number(time.time.split(':')[0]);
        if (timeMinutesTo === 60) {
          timeHoursTo += 1;
          timeMinutesTo = '00';
        }
        if (timeHoursTo < 10) {
          timeHoursTo = `0${timeHoursTo}`
        }

        const newValue = `${String(timeHoursTo)}:${String(timeMinutesTo)}`;
        return newValue;
      }),
    );
  }, [times]);

  /* Render */
  return (
    <Container>
      <div className="container-select-time">
        <div className="slots-range-data-view">
          {selectedFrom && (
            <span className="slots-range-data-text">{t("fromTimePicker")} {selectedFrom}</span>
          )}

          {selectedTo && (
            <span className="slots-range-data-text">{t("toTimePicker")}  {selectedTo}</span>
          )}
        </div>

        <div className="select-container">
          <div className="select-time">
            <select
              id="selectTimeFrom"
              value={selectedFrom}
              onChange={timeFrom => setSelectedFrom(timeFrom.target.value)}
            >
              <option disabled selected={selectedFrom === 0}>
                Selecione um horario
              </option>
              {times.times.map(val => {
                return (
                  <option key={val.id} value={val.time}>
                    {val.time}
                  </option>
                );
              })}
            </select>

            <div className="select-icon">
              <FiChevronDown size={20} color="#14213D" />
            </div>
          </div>

          <div className="select-time">
            <select
              id="selectTimeTo"
              value={selectedTo}
              onChange={timeTo => setSelectedTo(timeTo.target.value)}
            >
              <option disabled selected={selectedTo === 0}>
                Selecione um horario
              </option>
              {timeNewTo.map(val => {
                return (
                  <option key={val.id} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>

            <div className="select-icon">
              <FiChevronDown size={20} color="#14213D" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SlotRangeSchedule;
