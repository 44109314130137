import { header, fetchWithTimeout } from './helper/request';
import { API_CHAT, CHATS } from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

const getAllChatsApi = async () => {
    try {
        const { get_bearer_chat } = header();
        const response = await fetchWithTimeout(`${API_CHAT}${CHATS}`, get_bearer_chat);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else {
            throw new Error('Erro na chamada de getAllChats: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao buscar todos os chats');
    }
}

const getChats = async (customerId, orderId) => {
    try {
        const { get_bearer_chat } = header();
        const response = await fetchWithTimeout(`${API_CHAT}${CHATS}?recipient_id=${customerId}&recipient_type=professional&subject_id=${orderId}&subject_type=order`, get_bearer_chat);

        if (response.ok) {
            const responseJson = response.data;
            return responseJson;

        } else {
            throw new Error('Erro na chamada de getChats: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao buscar chats');
    }
}

const getChatMessages = async (id) => {
    try {
        const { get_bearer_chat } = header();
        const response = await fetchWithTimeout(`${API_CHAT}${CHATS}/${id}/messages`, get_bearer_chat);

        if (response.ok) {
            const responseJson = response.data;
            return responseJson;
        } else {
            throw new Error('Erro na chamada de getChatMessages: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao buscar mensagens');
    }
}

export {
    getAllChatsApi,
    getChats,
    getChatMessages,
}