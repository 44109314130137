import React, { useState, useEffect } from 'react';
import { getProductsPartners } from '../../api/partners';
import Product from './components/Product';
import HeaderBanner from './components/HeaderBanner';
import { getPartnerAddressValidation } from '../../api/partners';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ModalAddress from '../../components/Modals/ModalAddress';
import { Container } from './styles';
import Header from '../../components/Header';
import ModalMoreInformation from '../../components/Modals/ModalMoreInformation';
import Footer from '../../components/Footer';
import TabButton from '../../components/TabButton';
import SkeletonPartner from './components/SkeletonPartner';
import { debounce } from 'throttle-debounce';

const PartnerProductOrder = ({ location }) => {
    const navigation = useHistory();
    const [address, setAddress] = useState(location.state?.address);
    let partner = location.state?.partner;
    let idPartner = partner?.id;
    const [products, setProducts] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [showModalDescription, setShowModalVisible] = useState(false);
    const [showModalAddress, setShowModalAddress] = useState(false);
    const [selectAddressMessage, setSelectAddressMessage] = useState('');
    const [descriptionSelected, setDescriptionSelected] = useState();
    const [arrayProducts, setArrayProducts] = useState([]);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);
    const [go, setGo] = useState(false);

    if (location.state.af_deeplink) {
        partner = location.state;
        idPartner = location.state.id;
    }

    const cancelLoading = () => {
        setTimeout(() => {
            if (document.getElementById('partnerBody')) {
                document.getElementById('partnerBody').style.display = 'flex';
            }
            setLoadingSkeleton(false);
        }, 1000);
    }

    const getAllProducts = async () => {
        try {
            await getProductsPartners(idPartner, setProducts);
            cancelLoading();
        } catch (error) {
            toast.error(error.message);
            cancelLoading();
        }
    }

    const addShoppingCard = (product) => {
        let value = arrayProducts.findIndex(prod => prod.id === product.id);

        if (value === -1) {
            product.quantity = 1;
            setArrayProducts(oldArray => [...oldArray, product]);
        } else {
            let prod = arrayProducts.find(prod => prod.id === product.id);
            prod.quantity += 1;
            arrayProducts.splice(value, 1, prod);
        }

        setGo(true);
    }

    const afterSelectAddress = async (address) => {
        try {
            let available = await getPartnerAddressValidation(partner.id, address.id);

            if (available.available) {
                setAddress(address);
                setShowModalAddress(false);
                setSelectAddressMessage('');
            } else {
                setSelectAddressMessage('Parceiro indisponível no endereço selecionado');
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    const countQuantity = () => {
        var value = 0;

        arrayProducts.map(product => {
            value += product.quantity;
        });

        setQuantity(value);
    }

    const getProductsBySync = () => {
        setArrayProducts(JSON.parse(localStorage.getItem('products')) || []);
    }

    useEffect(() => {
        let isMounted = true;

        if (!address && isMounted) {
            if (document.getElementById('partnerBody')) {
                document.getElementById('partnerBody').style.display = 'none';
            }
            setLoadingSkeleton(true);
            setShowModalAddress(true);
        } else if (isMounted) {
            if (document.getElementById('partnerBody')) {
                document.getElementById('partnerBody').style.display = 'none';
            }
            setLoadingSkeleton(true);
            setTimeout(() => debounce(5000, getAllProducts()), 1500);
        }

        return () => (isMounted = false);
    }, [address]);

    useEffect(() => {
        if (arrayProducts.length > 0 && go) {
            setGo(false);
            localStorage.setItem('products', JSON.stringify(arrayProducts));

            navigation.push('/partnersCheckout', { partner: partner, address: address });
        }

        if (arrayProducts.length > 0) {
            countQuantity();
        } else {
            setQuantity(0);
        }
    }, [arrayProducts, go]);

    useEffect(() => {
        getProductsBySync();
    }, []);

    return (
        <Container>
            <Header screen="home" orderType="residential" />

            <ModalAddress
                showModalAddress={showModalAddress}
                setShowModalAddress={setShowModalAddress}
                afterSelect={address => afterSelectAddress(address)}
                warningMessage={selectAddressMessage}
                checkResidentialAvailability={true}
                message={`Selecione o endereço onde deseja receber os produtos do nosso parceiro `}
                partner={partner.name}
            />

            {showModalDescription &&
                <ModalMoreInformation
                    showModal={showModalDescription}
                    setShowModal={val => setShowModalVisible(val)}
                    title={descriptionSelected?.name}
                    body={descriptionSelected?.description}
                />
            }

            <div className="background-body">
                <HeaderBanner partner={partner} showShoppingCard={true} address={address} quantity={quantity} />

                {loadingSkeleton &&
                    <SkeletonPartner />
                }

                <div id="partnerBody">

                    <div className="container-products">
                        {products.length > 0 &&
                            products.map(product => {
                                return (
                                    <Product
                                        key={product.id}
                                        product={product}
                                        addShoppingCard={val => addShoppingCard(val)}
                                        descriptionSelected={val => { setDescriptionSelected(val); setShowModalVisible(true) }}
                                    />
                                );
                            })
                        }
                    </div>
                </div>

                {!loadingSkeleton &&
                    <div className="footer-service-list">
                        <div className="line-separator"></div>
                        <Footer />
                    </div>
                }
            </div>

            <TabButton screen="home" />
        </Container>
    );
}

export default PartnerProductOrder;