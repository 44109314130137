import DailyIframe from '@daily-co/daily-js';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import './styles.css';

const ModalNextServiceOnline = ({ order }) => {
    const [showModalNextOnline, setShowModalNextOnline] = useState(true);

    const openModalVideo = async () => {
        setShowModalNextOnline(false);
        let callFrame = DailyIframe.createFrame({
            showFullscreenButton: true,
            showLeaveButton: true,
            iframeStyle: {
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: '100vw',
                height: '100vh',
            }
        });

        callFrame.join({ url: order.online_room_link }).catch(() => {
            callFrame.destroy(); toast.warn("A Sessão foi encerrada!")
        });
    }

    return (
        <div>
            <Modal
                isOpen={showModalNextOnline}
                onRequestClose={() => setShowModalNextOnline(false)}
                overlayClassName="modal-overlay next-online"
                className="modal-content next-online"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">Serviço online:</span>
                    <span className="title-header">{order.service.name}</span>
                </div>

                <div className="content-body">
                    <span className="message-text-body">O seu serviço online <span>{order.service.name}</span> irá começar em instantes, deseja acessá-lo agora?</span>
                </div>

                <div className="container-buttons">
                    <button className="accept-button" type="type" onClick={openModalVideo}>
                        <span>ir para serviço</span>
                    </button>
                    <button className="cancel-button" type="button" onClick={() => setShowModalNextOnline(false)}>
                        <span>cancelar</span>
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalNextServiceOnline;