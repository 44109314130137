import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiLogOut, FiMapPin, FiUser } from "react-icons/fi";
import { IoWalletOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { debounce } from "throttle-debounce";
import Logo from "../../assets/Logo.svg";
import Dropdown from "./Dropdown";
import {
  Container,
  HeaderContainer,
  NavBar,
  ShowServicesTo,
  MoreInfos,
  HeaderNoCompany,
} from "./styles";
import { getUser, onSignOut } from "../../utils/auth";
import { formatCurrencyBRL } from "../../utils/utils";
import {
  getUserCompanyOfferHeaderStatus,
  putUserCompanyOfferHeaderInteract,
} from "../../api/home";
import { getPendingAction } from "../../api/service_orders";
import { getWalletBalance } from "../../api/payments";
import { t } from "i18next";

const Header = ({ orderType, address, screen, isNotSchedule = true, isTimeClock = false }) => {
  const history = useHistory();
  const dropdownRef = useRef(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const customer = getUser()?.customer;
  const [presentationLocale, setPresentationLocale] = useState("");
  const [allStatements, setAllStatements] = useState({
    balance: 0,
    entries: [],
  });
  const [company, setCompany] = useState(getUser()?.company);
  const [userCompanyOfferHeaderStatus, setUserCompanyOfferHeaderStatus] =
    useState([]);
  const [numberPendingOrders, setNumberPendingOrders] = useState();

  const toggleShowDropdown = () => setShowDropDown(!showDropDown);

  const getWallet = async (setAllStatements) => {
    try {
      await getWalletBalance(setAllStatements);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const logout = () => {
    onSignOut();
    history.go("/");
  };

  const refreshUserCompanyOfferHeaderStatus = async (
    setUserCompanyOfferHeaderStatus
  ) => {
    try {
      await getUserCompanyOfferHeaderStatus(setUserCompanyOfferHeaderStatus);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const companyOfferHeaderInteract = async (
    setUserCompanyOfferHeaderStatus
  ) => {
    try {
      await putUserCompanyOfferHeaderInteract();

      await refreshUserCompanyOfferHeaderStatus(
        setUserCompanyOfferHeaderStatus
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    let timeout1 = setTimeout(
      () => debounce(5000, getWallet(setAllStatements)),
      1500
    );
    let timeout2 = setTimeout(() => setCompany(getUser()?.company), 1000);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  useEffect(() => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if ("corporate" === orderType && company) {
        setPresentationLocale(getUser()?.company?.name);
      } else if ("corporate" === orderType && !company) {
        setPresentationLocale(address ? address.description : "Home");
      } else if ("residential" === orderType) {
        setPresentationLocale(address ? address.description : "Home");
      } else {
        setPresentationLocale("Online");
      }
    }

    return () => (isMounted = false);
  }, [orderType, company]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !company) {
      refreshUserCompanyOfferHeaderStatus(setUserCompanyOfferHeaderStatus);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setShowDropDown(!showDropDown);
      }
    };

    if (showDropDown) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [showDropDown]);

  useEffect(() => {
    async function getNumberOrder(setNumberPendingOrders) {
      try {
        await getPendingAction(setNumberPendingOrders);
      } catch (error) {
        return
      }
    }
    getNumberOrder(setNumberPendingOrders);
  }, []);

  return (
    <Container>
      <div id="header" className="header">
        <HeaderContainer>
          <Link to="/home" className="logo">
            <img src={Logo} alt="Logo Posher" />
          </Link>

          <NavBar ref={dropdownRef}>
            <Link to="/home" className={`nav ${screen === "home" && "active"}`}>
              <li>{t("homeTabBar")}</li>
            </Link>

            <Link
              to="/serviceOrderHistory"
              className={`nav ${screen === "orders" && "active"}`}
            >
              {numberPendingOrders > 0 && <p>{numberPendingOrders}</p>}
              <li>{t("ordersTabBar")}</li>
            </Link>

            <Link
              to="/myPackages"
              className={`nav ${screen === "packages" && "active"}`}
            >
              <li>{t("bundlesTabBar")}</li>
            </Link>

            <Link
              to="/discountCoupons"
              className={`nav ${screen === "coupons" && "active"}`}
            >
              <li>{t("couponsTabBar")}</li>
            </Link>
          </NavBar>

          {isNotSchedule ? (
            <ShowServicesTo>
              {company || userCompanyOfferHeaderStatus?.interacted ? (
                <Link to="/linkCompany" className="button-go-to-company">
                  <p className="showService">{t("showingServicesFor")}:</p>
                  <div className="location-container">
                    <FiMapPin size={13} />
                    <span>{presentationLocale}</span>
                  </div>
                  <div className="title-tag">
                    <span style={{textTransform:"capitalize"}}>{t("changeCompany")}</span>
                  </div>
                </Link>
              ) : (
                <HeaderNoCompany>
                  <p className="questionText">{t("hasPosher")}</p>
                  <div className="yesNoText">
                    <button
                      type="button"
                      className="yesButtomView"
                      onClick={() =>
                        history.push("/LinkCompany", { linkSomeCompany: true })
                      }
                    >
                      <span className="yesButtomText">{t("yesWord").toUpperCase()}</span>
                    </button>
                    <button
                      type="button"
                      className="noButtomView"
                      onClick={() => {
                        // history.push('/RecommendToCompany');
                        companyOfferHeaderInteract(
                          setUserCompanyOfferHeaderStatus
                        );
                      }}
                    >
                      <span className="noButtomText">{t("noWord").toUpperCase()}</span>
                    </button>
                  </div>
                </HeaderNoCompany>
              )}
            </ShowServicesTo>
          ) : isTimeClock ? (
            <span className="showSchedule">{t("eletronicPoint")}</span>
          ) : (
            <span className="showSchedule">{t("schedulePlaceTitle")}</span>
          )}

          <MoreInfos>
            <div
              className={`user ${screen === "user" && "iconSelected"}`}
              onClick={toggleShowDropdown}
            >
              <FiUser size={25} />
              <span>
                {customer?.name ? (customer?.name).split(" ")[0] : ""}
              </span>
              <div className="title-tag">
                <span>{t("accountTabBar")}</span>
              </div>
              {showDropDown && <Dropdown />}
            </div>

            <Link
              to="/walletStatement"
              className={`wallet ${screen === "wallet" && "iconSelected"}`}
            >
              <IoWalletOutline size={25} />
              <span>{formatCurrencyBRL(allStatements.balance)}</span>
              <div className="title-tag">
                <span>{t("digitalWallet")}</span>
              </div>
            </Link>

            <button type="button" className="exit" onClick={() => logout()}>
              <FiLogOut size={25} />
              <div className="title-tag">
                <span>{t("exitButton")}</span>
              </div>
            </button>
          </MoreInfos>
        </HeaderContainer>
      </div>
    </Container>
  );
};

export default Header;
