import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        color: #14213D;
        font-size: 1.4rem;
        margin-bottom: 10px;
        margin-top: 30px;
        font-weight: 700;
        margin-bottom: 30px;
        margin-left: 20px;
        text-align: center;
    }

    @media(max-width: 1024px) {
        .carousel {
            max-width: 680px !important;
        }
    }

    @media(max-width: 910px) {
        .carousel {
            margin: 0 10px;
            max-width: 600px !important;
        }

        .button-left,
        .button-right {
            top: 35px;
        }
    }

    @media(max-width: 780px) {
        .title {
            font-size: 1.2rem;
        }
    }

    @media(max-width: 660px) {
        .carousel {
            max-width: 580px !important;
        }
    }

    @media(max-width: 640px) {
        .carousel {
            max-width: 400px !important;
        }

        .button-left,
        .button-right {
            top: 20px;
        }
    }

    @media(max-width: 460px) {
        .carousel {
            margin: 0 5px;
            max-width: 350px !important;
        }
    }

    @media(max-width: 390px) {
        .carousel {
            max-width: 300px !important;
        }

        .button-left,
        .button-right {
            display: none;
        }   
    }

    @media(max-width: 320px) {
        .carousel {
            max-width: 270px !important;
        }
    }

`;

export const LineSeparator = styled.div`
    width: 100%;
    max-width: 800px !important;
    border: 0.1px solid #F2F2F2;
    margin: 20px 5%;

    @media(max-width: 930px) {
        max-width: 780px !important;
    }

    @media(max-width: 910px) {
        max-width: 650px !important;
    }

    @media(max-width: 740px) {
        max-width: 560px !important;
    }

    @media(max-width: 720px) {
        max-width: 550px !important;
        margin: 10px 5%;
    }

    @media(max-width: 640px) {
        max-width: 400px !important;
    }

    @media(max-width: 540px) {
        max-width: 350px !important;
    }

    @media(max-width: 425px) {
        max-width: 300px !important;
    }

    @media(max-width: 375px) {
        max-width: 270px !important;
    }

    @media(max-width: 320px) {
        max-width: 240px !important;
    }
`;