import styled from "styled-components";

export const TableView = styled.tbody`
    border-radius: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #000;
    border: 1px solid #c4c4c4;
    width: 30vw;

    @media (max-width: 700px) {
        width: 70vw;
    }

    @media (max-width: 500px) {
        width: 90vw;
    }
`;

export const TableRow = styled.tr`
    border-bottom: 0.1px solid #c4c4d4;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    color: ${(props) =>
        props.clicked && !props.disabled ? "#FA9F2A" : "#000"};
    transition: ease-in 0.2s;
    &:hover {
        color: #fff;
        background: #fa9f2a;
    }
    opacity: ${(props) => props.disabled && "0.4"};
    cursor: ${(props) => props.disabled && "auto"};
`;

export const TableInfoScheduled = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    gap: 0.1rem;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    p {
        font-size: 0.9rem;
    }
`;
