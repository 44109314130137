import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    position: relative;
    width: 420px;
    height: 130px;
    border-radius: 20px;
    border: 1px solid #FA9F2A;
    background: #FFF;
    margin: 0 10px;
    transition: .2s background;

    :hover {
        background: #F8F8F8;
    }

    .img-button {
        align-self: center;
        margin-left: 10px;
    }

    .img-button img {
        width: 110px;
        height: 110px;
        border-radius: 25px;
        border: 1px solid #f2f2f2;
        background-color: #f2f2f2;
    }

    .info-button {
        margin: 0 15px;
        color: #14213D;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .info-button h3 {
        text-transform: uppercase;
        font-weight: 700;
        color: #14213D;
        font-size: 1rem;
        margin: 10px 0 5px;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 200px;
        height: 35px;
    }

    .more-info {
        display: flex;
        align-items: center;
        margin: 3px 0;
        font-size: 0.9rem;
    }

    .more-info > span {
        margin-left: 4px;
    }

    .icon-button {
        position: absolute;
        right: 0;
    }

    .icon-button .button-orange{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FA9F2A;
        width: 40px;
        height: 129px;
        border-radius: 0 18px 18px 0;
    }

    .icon-button .button-free {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0px;
        width: 80px;
        height: 35px;
        background: #E6860B;
        border-radius: 18px 0 18px 0;
    }

    @media(max-width: 1000px) {
        width: 395px;
    }

    @media(max-width: 920px) {
        width: 370px;

        .info-button h3 {
            width: 180px;
        }
    }

    @media(max-width: 910px) {
        width: 320px;
        height: 120px;

        .img-button img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 119px;
        }

        .info-button h3 {
            width: 150px;
            height: 28px;
            font-size: 0.713rem;
        }
    }

    @media(max-width: 780px) {
        width: 295px;

        .info-button h3 {
            width: 120px;
            height: 28px;
        }
    }

    @media(max-width: 700px) {
        width: 270px;
        height: 100px;

        .img-button img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 99px;
        }

        .icon-button .button-free {
            width: 70px;
            height: 30px;
        }

        .info-button h3 {
            width: 100px;
            height: 24px;
        }
    }

    @media(max-width: 639px) {
        width: 435px;
        height: 120px;

        .img-button img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 119px;
        }

        .info-button h3 {
            width: 230px;
            height: 32px;
            font-size: 0.813rem;
        }
    }

    @media(max-width: 520px) {
        width: 380px;
        height: 110px;

        .img-button img {
            width: 90px;
            height: 90px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 109px;
        }

        .info-button h3 {
            width: 220px;
        }
    }

    @media(max-width: 440px) {
        width: 330px;

        .info-button h3 {
            width: 150px;
            height: 28px;
            font-size: 0.753rem;
        }
    }

    @media(max-width: 390px) {
        width: 280px;
        height: 100px;

        .img-button img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 99px;
        }

        .info-button h3 {
            width: 120px;
        }
    }

    @media(max-width: 320px) {
        width: 260px;
        height: 100px;

        .img-button img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 99px;
        }

        .info-button h3 {
            width: 100px;
            height: 23px;
            font-size: 0.713rem;
        }
    }
`;