import React, { useState } from 'react';
import PaymentView from '../../ServiceOrder/components/PaymentView';
import { formatCurrencyBRL } from '../../../utils/utils';
import { createOrderPackage } from '../../../api/service_packages';
import ServiceBanner from '../../ServiceOrder/components/ServiceBanner';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import Header from '../../../components/Header';
import LoaderSpinner from '../../../components/LoaderSpinner';
import ModalMoreInformation from '../../../components/Modals/ModalMoreInformation';
import TabButton from '../../../components/TabButton';
import Footer from '../../../components/Footer';

const PackageCheckout = ({ location }) => {
    const navigation = useHistory();
    const { pack } = location.state;
    const [modalVisible, setModalVisible] = useState(false);
    const [methodPayment, setMethodPayment] = useState({});
    const [loading, setLoading] = useState(false);

    const packagePayment = async () => {
        try {
            setLoading(true);

            await createOrderPackage(pack.id, methodPayment.method, methodPayment.card);

            setLoading(false);

            navigation.push('/packageCompletedOrder', { pack: pack });
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <Container>
            <Header screen="home" orderType="corporate" />
            <LoaderSpinner loading={loading} />

            <ModalMoreInformation
                showModal={modalVisible}
                setShowModal={val => setModalVisible(val)}
                title={pack?.name}
                body={pack?.description}
            />

            <div className="background-body">
                <div className="content-body-pack">
                    <ServiceBanner title="Pacotes" bannerUrl={pack.header_url} />

                    <div className="pack-container">
                        <img src={pack.thumb_url} className="pack-image" alt ='Nome do pacote' />

                        <div className="content-pack">
                            <span className="title-pack">{pack.name}</span>
                            <span className="description-pack">{pack.description}</span>

                            <button
                                type="button"
                                onClick={() => setModalVisible(true)}
                                className="button-more"
                            >
                                <span className="text-button-more">+ informações</span>
                            </button>
                        </div>

                        <div className="price-pack">
                            <span className="text-price-pack">R$ <span>{formatCurrencyBRL(pack.price).substring(2)}</span></span>
                        </div>
                    </div>

                    <div className="divisor-pack"></div>

                    <span className="total-pack">PAGAMENTO</span>

                    <PaymentView
                        pack={pack}
                        orderType={'corporate'}
                        hasDiscount={false}
                        setCheckedPaymentMethod={setMethodPayment}
                        checkedPaymentMethod={methodPayment}
                        setLoading={setLoading}
                    />

                    <button
                        type="button"
                        disabled={methodPayment.method ? false : true}
                        className={methodPayment.method ? "button-pay" : "button-pay-disabled"}
                        onClick={packagePayment}
                    >
                        <span className="text-pay">concluir compra</span>
                    </button>
                </div>

                <div className="footer-service-list">
                    <div className="line-separator"></div>
                    <Footer />
                </div>
            </div>

            <TabButton screen="home"/>
        </Container>
    );
}

export default PackageCheckout;