/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './styles.css';
import LoaderSpinner from '../../LoaderSpinner';
import { getPositionMapWithCode, getPositionsMapUrl } from '../../../api/schedule_place';
import { toast } from 'react-toastify';
import { t } from 'i18next'

const ModalMapa = ({ setShow, buildingId, positionType, dateFrom, dateTo, setPosition }) => {
    const [showModal, setShowModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [mapUrl, setMapUrl] = useState();

    const doCloseModal = () => {
        setShowModal(false);
        setShow(false);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setLoading(true);
            setMapUrl(getPositionsMapUrl(buildingId, positionType, dateFrom, dateTo));
            setLoading(false);
        }
        return () => (isMounted = false);
    }, []);

    async function handleGetMessage(e) {
        const code = e.data.message;
        if (!!code) {
            await getPositionMapWithCode(buildingId, positionType, code)
                .then((response) => {
                    setPosition(response);
                })
                .catch((error) => {
                    toast.error(error.message);
                }).finally(() => {
                    doCloseModal();
                    window.removeEventListener('message', handleGetMessage);
                })
        }
        return;
    }

    useEffect(() => {
        if (showModal) {
            window.addEventListener('message', handleGetMessage, false);
        }
        return () => {
            window.removeEventListener("message", handleGetMessage);
        };
    }, []);

    return (
        <div>
            {mapUrl &&
                <Modal
                    isOpen={showModal}
                    onRequestClose={() => doCloseModal(false)}
                    overlayClassName="modal-overlay"
                    className="modal-content show-mapa"
                    ariaHideApp={false}
                >
                    <LoaderSpinner loading={loading} />
                    <button className="x-to-close" type="button" onClick={() => doCloseModal(false)}>
                        <span>x</span>
                    </button>

                    <div className="header-modal">
                        <span className="subtitle-header">{t("seeTheMapWord")}</span>
                        <span className="title-header">{t("mapWord")}</span>
                    </div>

                    <div className="content-body">
                        <iframe id="iframe" src={mapUrl} style={{ background: '#FFFFFF', height: '100vh' }} title="map" />
                    </div>

                    <div className="container-buttons">
                        <button className="cancel-button" type="type" onClick={() => doCloseModal(true)}>
                            <span>{t("closeButton")}</span>
                        </button>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default ModalMapa;