import { Container, NotFound, PageContainer } from "./styles";
import LoaderSpinner from "../../../components/LoaderSpinner";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import TabButton from "../../../components/TabButton";
import { useEffect } from "react";
import { getMessagesViewed } from "../../../api/messages";
import { useState } from "react";
import { toast } from "react-toastify";
import MessageCard from "./components/MessageCard";

const Messages = () => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    async function getMessages() {
      await getMessagesViewed()
        .then((res) => {
          setMessages(res);
        })
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    }
    getMessages();
  }, []);

  return (
    <>
      <Container>
        <LoaderSpinner loading={loading} />
        <Header screen="user" orderType="corporate" />
        <PageContainer>
          {messages.length === 0 ? (
            <NotFound>Nenhuma mensagem encontrada.</NotFound>
          ) : (
            <>
              {messages.map((message) => (
                <MessageCard key={message.id} message={message} />
              ))}
            </>
          )}

          <div className="footer-service-list">
            <div className="line-separator"></div>
            <Footer />
          </div>
          <TabButton screen="home" />
        </PageContainer>
      </Container>
    </>
  );
};
export default Messages;
