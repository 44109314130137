import { useEffect } from "react";
import { toast } from "react-toastify";
import { getVaccinationsTypes } from "../../../../../api/vaccinations";
import { TableInfoScheduled, TableRow, TableView } from "./styles";

export default function TableTypes({ types, setTypes, type, setType }) {
    useEffect(() => {
        let isMounted = true;

        async function getAllVaccinationsTypes() {
            await getVaccinationsTypes()
                .then((res) => {
                    if (isMounted) {
                        setTypes(res);
                    }
                })
                .catch((err) => toast.error(err.message));
        }
        getAllVaccinationsTypes();

        return () => (isMounted = false);
    }, []);

    return (
        <table>
            <TableView>
                {types.map((typeVaccination) => {
                    return (
                        <TableRow
                            key={typeVaccination.id}
                            value={typeVaccination}
                            onClick={() => {
                                setType(typeVaccination);
                            }}
                            clicked={typeVaccination === type}
                        >
                            <TableInfoScheduled>
                                {typeVaccination.description}
                            </TableInfoScheduled>
                        </TableRow>
                    );
                })}
            </TableView>
        </table>
    );
}
