import styled from 'styled-components';

export const Container = styled.div`
    .content-body-video-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .recorded-at-view-value {
        background-color: #14213D;
        position: absolute;
        top: 20px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        border-radius: 20px;
        font-size: 0.85rem;
    }

    .recorded-at-view-value span {
        margin-left: 10px;
        text-transform: uppercase;
    }

    .sub-title {
        font-size: 1.3rem;
        color: #14213D;
        font-weight: 700;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }
    }
`;