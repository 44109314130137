import { useState } from "react";
import { MessageContainer, MessageHeader, MessageTitle } from "./styles";
import ModalNextMessages from "../../../../../components/Modals/ModalNextMessages";

import moment from "moment";

export default function MessageCard({ message }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <MessageContainer
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
      >
        {modalOpen && (
          <ModalNextMessages
            showModalNext={modalOpen}
            message={message.message}
            setShowModalNext={(val) => setModalOpen(val)}
          />
        )}
        <MessageHeader>
          {moment(message.valid_from).format("DD/MM/YYYY")}
        </MessageHeader>
        <MessageTitle>{message.title}</MessageTitle>
      </MessageContainer>
    </>
  );
}
