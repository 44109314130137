import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container-address {
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        border: 1px solid #F4F4F4;
        padding: 40px;
        margin: 10px;
        border-radius: 20px;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
        width: 600px;
    }

    .about-address {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
        margin-right: 20px;
    }

    .description-address {
        color: #14213D;
        font-size: 1.2rem;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 400px;
    }

    .name-address {
        color: #707070;
        font-size: 1rem;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 400px;
    }

    .function-address {
        color: #FA9F2A;
        margin: 3px 5px 0;
        background: none;
        font-size: 1rem;
        display: flex;
        align-items: center;
        transition: .2s color;
    }

    .function-address span {
        margin-left: 5px;
    }

    .function-address:hover {
        color: #E6860B;
    }

    @media(max-width: 650px) {
        .container-address {
            width: 500px;
        }

        .name-address {
            font-size: 0.9rem;
            width: 300px;
        }

        .description-address {
            font-size: 1.1rem;
            width: 300px;
        }
        
        .function-address {
            font-size: 0.9rem;  
        }
    }

    @media(max-width: 540px) {
        .container-address {
            width: 400px;
            padding: 30px;
        }

        .name-address {
            font-size: 0.85rem;
            width: 230px;
        }

        .description-address {
            font-size: 1rem;
            width: 230px;
        }

        .function-address {
            font-size: 0.85rem;  
        }
    }

    @media(max-width: 425px) {
        .container-address {
            width: 300px;
            padding: 20px;
        }

        .name-address {
            font-size: 0.8rem;
            width: 150px;
        }

        .description-address {
            font-size: 0.9rem;
            width: 150px;
        }

        .function-address {
            font-size: 0.8rem;  
        }
    }
`;