import React, { useState } from "react";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { residentialAvailable } from "../../api/customer";
import ModalAddress from "../Modals/ModalAddress";
import Moment from "moment-timezone";
import ModalSite from "../Modals/ModalSite";

const ButtonsOffer = (props) => {
    const history = useHistory();
    const offer = props.offer;
    const card = props.card;
    const oneClick = props?.oneClick;
    const setShowModal = props?.setShowModal;
    const [selectedAddress, setSelectedAddress] = useState(props.address);
    const [selectAddress, setSelectAddress] = useState(false);
    const [selectAddressMessage, setSelectAddressMessage] = useState();
    const [modalSiteUrl, setModalSiteUrl] = useState(false);

    const navigateOffer = (offer, address) => {
        if (offer.service_id) {
            if (offer.site_url && offer.site_url.length > 0) {
                setModalSiteUrl(true);
            } else {
                if (offer.on_demand_order) {
                    history.push("/onDemandOrderData", {
                        offer: offer,
                        orderType: offer.order_type,
                        address: address,
                    });                    
                } else if (offer.express_order) {
                    if (offer.slot_suggestion) {
                        history.push("/completeExpressOrder", {
                            offer: offer
                        });
                    } else {
                        toast.warn("Checkin indisponível");
                    }
                } else {
                    history.push("/slotList", {
                        offer: offer,
                        orderType: offer.order_type,
                        address: address,
                    });
                }
            }
        } else {
            history.push("/serviceList", {
                offer: offer,
                orderType: offer.order_type,
                address: address,
            });
        }
    };

    const selectOffer = (
        offer,
        address,
        setSelectAddress,
        setSelectAddressMessage
    ) => {
        setSelectAddressMessage("");
        if (oneClick) {
            setShowModal(true);
        } else {
            if ("residential" === offer.order_type && !address) {
                setSelectAddress(true);
                return;
            }

            navigateOffer(offer, address);
        }
    };

    const afterSelectAddress = async (
        offer,
        setSelectAddress,
        setSelectAddressMessage,
        address
    ) => {
        try {
            let available = await residentialAvailable(address.zipCode);

            if (available.available) {
                setSelectAddress(false);

                navigateOffer(offer, address);
                setSelectAddressMessage("");
            } else {
                setSelectAddressMessage(
                    "Serviço indisponível no endereço selecionado"
                );
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            <ModalAddress
                showModalAddress={selectAddress}
                setShowModalAddress={setSelectAddress}
                afterSelect={(address) =>
                    afterSelectAddress(
                        offer,
                        setSelectAddress,
                        setSelectAddressMessage,
                        address
                    )
                }
                warningMessage={selectAddressMessage}
                checkResidentialAvailability={true}
            />
            <ModalSite
                showModal={modalSiteUrl}
                setShowModal={(val) => setModalSiteUrl(val)}
                site={offer.site_url}
            />

            <Container
                onClick={() =>
                    selectOffer(
                        offer,
                        selectedAddress,
                        setSelectAddress,
                        setSelectAddressMessage
                    )
                }
            >
                <div className="img-button">
                    <img src={offer.thumb_url} alt={offer.title} />
                </div>

                {oneClick?.nextSuggestion.hour_begin && (
                    <div className="one-click-hour-view">
                        <span className="one-click-hour-text">
                            {Moment(oneClick.nextSuggestion.hour_begin)
                                .tz("America/Sao_Paulo")
                                .format("DD/MM")}{" "}
                            -{" "}
                            {Moment(oneClick.nextSuggestion.hour_begin)
                                .tz("America/Sao_Paulo")
                                .format("HH:mm")}
                        </span>
                    </div>
                )}
                {offer.express_order &&
                    <div className="one-click-hour-view">
                        {offer.slot_suggestion ?
                            <span className="one-click-hour-text">
                                {Moment(offer.slot_suggestion.hour_begin)
                                    .tz("America/Sao_Paulo")
                                    .format("DD/MM")}{" "}
                                -{" "}
                                {Moment(offer.slot_suggestion.hour_begin)
                                    .tz("America/Sao_Paulo")
                                    .format("HH:mm")}
                            </span>
                        : 
                            <span className="one-click-hour-text">
                                Indisponível                            
                            </span>}
                    </div>
                }

                <h3
                    className={`name-button ${
                        card === "lastUsed" && "last-used"
                    }`}
                >
                    {offer.title}
                </h3>
                <div className="icon-button">
                    {
                        offer.express_order ? (
                            <div className="button-free">
                                <span>checkin</span>                               
                            </div>
                        ) : (
                            offer.free ? (
                                <div className="button-free">
                                    <span>grátis</span>
                                </div>
                            ) : (
                                offer.estimate_required && (
                                    <div className="button-free">
                                        <span>orçar</span>
                                    </div>
                                )
                            )
                        )
                    }
                </div>
            </Container>
        </div>
    );
};

export default ButtonsOffer;
