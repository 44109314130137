import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTermsConditionsToSee } from '../../api/termsConditions';
import { ButtonAcceptTerms, Container, ContentBody } from './styles';

const TermsConditions = () => {
    const [terms, setTerms] = useState();
    const history = useHistory();

    const doGetTerms = async (isMounted) => {
        await getTermsConditionsToSee(setTerms);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            doGetTerms(isMounted);
        }
        return () => (isMounted = false);
    }, [])

    return (
        <div>
            <Container>
                <div className="background-terms">
                    {terms && <>
                        <ContentBody>
                            <div dangerouslySetInnerHTML={{ __html: terms.service + terms.privacy }}></div>
                        </ContentBody>
                    </>


                    }
                    <ButtonAcceptTerms
                        type="button"
                        onClick={(e) => history.push('/')}
                    >
                        <span>voltar</span>
                    </ButtonAcceptTerms>

                </div>
            </Container>

        </div>
    );
}

export default TermsConditions;