import React from 'react';
import Moment from 'moment-timezone';
import { Container } from './styles';
import { FiChevronDown } from 'react-icons/fi';

const SlotRange = (props) => {
    const selectedDate = props.selectedDate;
    const selectedFrom = props.selectedFrom;
    const setSelectedFrom = props.setSelectedFrom;
    const selectedTo = props.selectedTo;
    const setSelectedTo = props.setSelectedTo;
    const orderType = props.orderType;
    let timeFrom = [];
    let timeTo = [];

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let minHour = Moment(tomorrow).format('YYYY-MM-DD') === selectedDate ? (tomorrow.getHours() + 0.5) : 11;
    let maxHour = 21;

    if ('residential' === orderType && new Date(Date.parse(selectedDate)).getDay() === 6) {
        maxHour = 20;
    }

    function parseDate(selectedDate, time) {
        if (selectedDate && time) {
            let date = new Date(Date.parse(selectedDate) + (time * 60 * 60 * 1000));

            return date.toISOString();
        } else {
            return null;
        }
    }

    for (let i = minHour; i <= maxHour; i += 0.5) {
        timeFrom.push(i);
        timeTo.push(i);
    }

    return (
        <Container>
            <div className="container-select-time">

                <div className="slots-range-data-view">
                    {selectedFrom &&
                        <span className="slots-range-data-text">
                            Das {Moment(parseDate(selectedDate, selectedFrom)).tz("America/Sao_Paulo").format('HH:mm')}
                        </span>
                    }

                    {selectedTo &&
                        <span className="slots-range-data-text">
                            Às {Moment(parseDate(selectedDate, selectedTo)).tz("America/Sao_Paulo").format('HH:mm')}
                        </span>
                    }
                </div>

                <div className="select-container">
                    <div className="select-time">
                        <select id="selectTimeFrom" defaultValue="" onChange={timeFrom => setSelectedFrom(timeFrom.target.value)}>
                            <option disabled value="">Selecione um horário</option>
                            {timeFrom.map(val => {
                                return (
                                    <option key={val} value={val}>{Moment(parseDate(selectedDate, val)).tz("America/Sao_Paulo").format('HH:mm')}</option>
                                )
                            })}
                        </select>

                        <div className="select-icon">
                            <FiChevronDown size={20} color="#14213D" />
                        </div>
                    </div>

                    <div className="select-time">
                        <select id="selectTimeTo" defaultValue="" onChange={timeTo => setSelectedTo(timeTo.target.value)}>
                            <option disabled value="">Selecione um horário</option>
                            {timeTo.map(val => {
                                return (
                                    <option key={val} value={val}>{Moment(parseDate(selectedDate, val)).tz("America/Sao_Paulo").format('HH:mm')}</option>
                                )
                            })}
                        </select>

                        <div className="select-icon">
                            <FiChevronDown size={20} color="#14213D" />
                        </div>
                    </div>
                </div>
            </div>

        </Container>
    );
}

export default SlotRange;