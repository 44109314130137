import React from 'react';
import { SkeletonLoader, GroupSkeletons } from './styles';
import Skeleton from 'react-loading-skeleton';

const SkeletonPersonalData = () => {
    return (
        <SkeletonLoader>
            <div className="banner-skt">
                <Skeleton className="banner-skeleton" />
            </div>
            <GroupSkeletons>
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton" />
            </GroupSkeletons>

            <Skeleton className="button-skeleton" />
        </SkeletonLoader>
    );
}

export default SkeletonPersonalData;