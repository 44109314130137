import React, { useState } from 'react';
import { Container } from './styles';
import { useHistory } from 'react-router-dom';
import { FiMapPin, FiChevronRight } from 'react-icons/fi';
import { FaCalendar, FaClock } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { residentialAvailable } from '../../api/customer';
import { formatCurrencyBRL } from '../../utils/utils';
import moment from 'moment';
import ModalAddress from '../Modals/ModalAddress';

const ButtonsOfferLarge = (props) => {
    const history = useHistory();
    const offer = props.offer;
    const card = props.card;
    const [selectedAddress, setSelectedAddress] = useState(props.address);
    const [selectAddress, setSelectAddress] = useState(false);
    const [selectAddressMessage, setSelectAddressMessage] = useState();

    const navigateOffer = (offer, address) => {
        if (offer.service_id) {
            if (offer.on_demand_order) {
                history.push("/onDemandOrderData", {
                    offer: offer,
                    orderType: offer.order_type,
                    address: address,
                });
            } else if (offer.express_order) {
                history.push("/completeExpressOrder", {
                    offer: offer
                });
            } else {
                history.push("/slotList", {
                    offer: offer,
                    orderType: offer.order_type,
                    address: address,
                });
            }
        } else {
            history.push("/serviceList", {
                offer: offer,
                orderType: offer.order_type,
                address: address,
            });
        }
    };

    const selectOffer = (offer, address, setSelectAddress, setSelectAddressMessage) => {
        setSelectAddressMessage("");

        if ("residential" === offer.order_type && !address) {
            setSelectAddress(true);
            return;
        }

        navigateOffer(offer, address);
    };

    const afterSelectAddress = async (offer, setSelectAddress, setSelectAddressMessage, address) => {
        try {
            let available = await residentialAvailable(address.zipCode);

            if (available.available) {
                setSelectAddress(false);

                navigateOffer(offer, address);
                setSelectAddressMessage("");
            } else {
                setSelectAddressMessage("Serviço indisponível no endereço selecionado");
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            <ModalAddress
                showModalAddress={selectAddress}
                setShowModalAddress={setSelectAddress}
                afterSelect={address => afterSelectAddress(offer, setSelectAddress, setSelectAddressMessage, address)}
                warningMessage={selectAddressMessage}
                checkResidentialAvailability={true}
            />

            <Container  onClick={() => selectOffer(offer, selectedAddress, setSelectAddress, setSelectAddressMessage)}>
                <div className="img-button">
                    <img src={offer.thumb_url} alt={offer.title} />
                </div>

                <div className="info-button">
                    <h3>{offer.title}</h3>
                    <div className="more-info">
                        <FiMapPin size={14} />
                        <span>{offer.place_description}</span>
                    </div>

                    {card === 'today' &&
                        <div className="more-info">
                            <FaClock size={14} />
                            <span>A partir das {moment(offer.begin_at).tz("America/Sao_Paulo").format("HH:mm")}</span>
                        </div>
                    }
                </div>

                <div className="icon-button">
                    {card === 'today' ?
                        <div className="button-orange">
                            <FaCalendar size={25} color={"#FFF"} />
                        </div>
                        :
                        <div className="button-orange">
                            <FiChevronRight size={25} color={"#FFF"} />
                        </div>
                    }

                    {(offer.service_id && card === 'today') &&
                        <div className="button-free">
                            <span>{offer.express_order ? 'checkin' : offer.free ? 'grátis' : formatCurrencyBRL(offer.price)}</span>
                        </div>
                    }

                    {(offer.free && card !== 'today') &&
                        <div className="button-free">
                            <span>grátis</span>
                        </div>
                    }
                </div>
            </Container>
        </div>
    );
}

export default ButtonsOfferLarge;