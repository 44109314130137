import styled from 'styled-components';

export const Container = styled.aside`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;

    img{
        width: 8rem;
    }

`;

export const DescriptionCard = styled.h2`
    color: #717171;
    margin-top: 5rem;
    width: 70%;
`;

export const ButtonCloseAdblock = styled.button`
    margin-top: 5rem;
    color: #14213D;
    background: #FA9F2A;
    font-weight: bold;
    border-radius: 1rem;
    padding: 1rem 2rem;
    font-size: 14px;
`;
