import React, { useState, useEffect } from 'react';
import { Container, ButtonSend } from './styles';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { makePasswordLogin } from '../../../api/login';
import { makeUserChangePassword } from '../../../api/customer';
import { onSignIn } from '../../../utils/auth';
import { useHistory } from 'react-router-dom';
import LoaderSpinner from '../../../components/LoaderSpinner';
import LogoImg from '../../../assets/posherlogo.png';
import { LoginFooter } from '../components/LoginFooter';

const RecoverPassword = ({ location }) => {
    const navigation = useHistory();
    let username = null;
    let recoverCode = null;
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [hideNewPassword, setHideNewPassword] = useState(true);
    const deep = JSON.parse(sessionStorage.getItem('deepLink'));

    if (location && location.state) {
        username = location.state.username;
        recoverCode = location.state.recoverCode;
    }

    const newPasswordHideAndShow = () => {
        setHideNewPassword(!hideNewPassword);
    }

    const updatePassword = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            if(!newPassword || newPassword === '') {
                return toast.warn("insira uma nova senha!");
            } else if (!username || !recoverCode) {
                return toast.warn("não foi possível fazer a alteração. Informações necessárias não passadas.");
            }

            const response = await makeUserChangePassword(username, recoverCode, newPassword);

            if (response.ok) {
                toast.success('Senha alterada com sucesso');

                const data = await makePasswordLogin(username, newPassword);

                if (data.invalid_email) {
                    sessionStorage.set('invalid_email', true);
                }

                onSignIn(data);

                setLoading(false);

                if(deep) {
                    navigation.push(`${deep.pathname}${deep.search}`, "logged");    
                } else {
                    navigation.push('/home', "logged");
                }
            }

            setLoading(false);

        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    useEffect(() => {
        if (!location || !location.state) {
            navigation.push('/');
        }
    }, []);

    useEffect(() => {
        const abortController = new AbortController()

        return () =>  abortController.abort() 
    }, [])

    return (
        <Container>
            <LoaderSpinner loading={loading} />
            <img src={LogoImg} alt="POSHER" className="logo-img-login" />

            <div className="forms-new-password">
                <span className="login-text">Insira uma senha nova</span>

                <form onSubmit={updatePassword}>
                    <input
                        type={hideNewPassword ? 'password' : 'text'}
                        placeholder="insira sua senha"
                        id="newPassword"
                        name="newPassword"
                        onChange={e => setNewPassword(e.target.value)}
                    />
                    
                    <button type="button" className="see-password" onClick={newPasswordHideAndShow}>
                        {hideNewPassword ?
                            <FiEye size={20} color="#FFF"/>
                            :
                            <FiEyeOff size={20} color="#FFF"/>
                        }
                    </button>
                </form>

                <ButtonSend type="submit" onClick={updatePassword}>
                    <span>atualizar senha</span>
                </ButtonSend>
            </div>

            <LoginFooter />
        </Container>
    );
}

export default RecoverPassword;