import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalCompanies = ({ showModalCompany, setShowModalCompany, companies, choosed }) => {
    const array = [];
    const sendChoose = (company) => {
        array.push(company);
        choosed(array);
        setShowModalCompany(false);
    }

    return showModalCompany && (
        <div>
            <Modal
                isOpen={showModalCompany}
                onRequestClose={() => setShowModalCompany(false)}
                overlayClassName="modal-overlay company"
                className="modal-content company"
                ariaHideApp={false}
            >
                <button className="cancel-button" type="button" onClick={() => setShowModalCompany(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">Selecione seu</span>
                    <span className="title-header">ESCRITÓRIO</span>
                </div>

                <div className="content-body">
                    <span className="text-body">Oba! Vimos aqui que você trabalha em um empresa que tem POSHER..</span>
                    <span className="text-body">Selecione abaixo em qual escritório você está:</span>

                    <div className="separator"></div>
                    <div className="selects-companies">
                        {companies[0]?.map((company, companyIndex) => {
                            return (
                                <div key={companyIndex} className="container-radio-coupons">
                                    <div className="container-radio-buttons">
                                        <input
                                            type="radio"
                                            value={company}
                                            onChange={() => sendChoose(company)}
                                        />
                                        <label onClick={() => sendChoose(company)}>{company.name}</label>
                                    </div>

                                    <span className="company-description">{company.address.address}, {company.address.number}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ModalCompanies;