import { getSectionsCatchErrors } from "../getSectionsCatchErrors";
import { fetchWithTimeout, header } from "./request";

export const validatePostResponse = async (postUrl, errorMessage, body) => {
  const { post_bearer } = header(body);
  let isValidation = false;
  try {
    const response = await fetchWithTimeout(`${postUrl}`, post_bearer);

    if (response.status === 400) {
      const responseJson = response.data;

      isValidation = true;

      throw new Error(responseJson.message);
    } else {
      if (response.status !== 200) {
        const data = response.data;
        throw new Error(data.message + " " + response.status);
      }
    }

    return response.data;

  } catch (error) {
    if (isValidation) throw error;
    getSectionsCatchErrors(error, errorMessage);
  }
};

export const validatePostResponseBasic = async (postUrl, errorMessage, body) => {
  const { post } = header(body);
  let isValidation = false;
  try {
    const response = await fetchWithTimeout(`${postUrl}`, post);

    if (response.status === 400) {
      const responseJson = response.data;

      isValidation = true;

      throw new Error(responseJson.message);
    } else {
      if (response.status !== 200) {
        const data = response.data;
        throw new Error(data.message + " " + response.status);
      }
    }

    return response.data;

  } catch (error) {
    if (isValidation) throw error;
    getSectionsCatchErrors(error, errorMessage);
  }
};