export const COLORS = {
  black:           '#000000',
  main:            '#14213D',
  darkOrange:      '#E6860B',
  orange:          '#FA9F2A',
  red:             '#FF0000',
  green:           '#009000',
  disabled:        '#8F8F8F',
  subtitle:        '#707070',
  border:          '#C4C4C4',
  placeholder:     '#CFCFCF',
  background:      '#F4F0F4',
  backgroundModal: '#F8F8F8',
  white:           '#FFFFFF',
  success:         '#5cb85c',
  info:            '#5bc0de',
  warning:         '#f0ad4e',
  danger:          '#d9534f',
};