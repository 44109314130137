import styled from 'styled-components';

export const Container = styled.div`
  .content-coupon {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    padding-bottom: 200px;
  }

  .container-titles {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.3rem;
    color: #707070;
  }

  .container-cashback-discount {
    margin-top: 1rem;
  }

  .footer-service-list {
    position: absolute;
    bottom: 30px;
    left: 0;
  }

  .coupon-list {
    margin-top: 25px;
  }

  .not-found-text{
    font-size: 1.2rem;
    color: #C4C4C4;
    margin: 10px 0;
  }

  @media(max-width: 960px) {
    .footer-service-list .line-separator {
      display: none;
    }

    .content-coupon {
      padding-bottom: 100px;
    }
  }

  @media(max-width: 520px) {
    .contianer-titles {
      font-size: 1.1rem;
    }
  }
`;