import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalBannerHome = ({ showModalBanner, setShowModalBanner, body }) => {
    return (
        <div>
            <Modal
                isOpen={showModalBanner}
                onRequestClose={() => setShowModalBanner(false)}
                overlayClassName="modal-overlay"
                className="modal-content banner-home"
                ariaHideApp={false}
            >
                <div className="content-body">
                    <div dangerouslySetInnerHTML={{__html: body.description }}></div>
                </div>

                <button className="close-button" type="type" onClick={() => setShowModalBanner(false)}>
                    <span>fechar</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalBannerHome;