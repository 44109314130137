import React, { useState, useEffect } from 'react';
import { getSections } from '../../api/home';
import { getNextOpenOrder, getRatingOrder, putRatingOrder, getPendingPayment, getCurrentOnline } from '../../api/service_orders';
import TodayOffers from './components/TodayOffers';
import DiscountCouponsBanner from './components/DiscountCouponsBanner';
import HighLighted from './components/HighLighted';
import GroupedServiceOffers from './components/GroupedServiceOffers';
import LastServicesOffers from './components/LastServicesOffers';
import InCompanyBanner from './components/InCompanyBanner';
import Partners from './components/Partners';
import NextOpenOrder from './components/NextOpenOrder';
import LocationSelector from './components/LocationSelector';
import { getMessagesNext } from '../../api/messages';
import { getUser, onSignIn } from '../../utils/auth';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import { Container, LineSeparator, ContainerMain, Divider } from './styles';
import TabButton from '../../components/TabButton';
import Footer from '../../components/Footer';
import Banners from './components/Banners';
import SkeletonLoaderHome from './components/SkeletonLoaderHome';
import ModalNextMessages from '../../components/Modals/ModalNextMessages';
import ModalNextServiceOnline from '../../components/Modals/ModalNextServiceOnlline';
import ModalProfessionalEvaluation from '../../components/Modals/ModalProfessionalEvaluation';
import ModalReSendPayment from '../../components/Modals/ModalReSendPayment';
import LoaderSpinner from '../../components/LoaderSpinner';
import { debounce } from 'throttle-debounce';
import ModalInvalidEmail from '../../components/Modals/ModalInvalidEmail';
import { useHistory } from 'react-router-dom';
import { getCustomerCompany } from '../../api/customer';
import SchedulePlace from './components/SchedulePlace';
import TimeClock from './components/TimeClock';
import { getPaymentsConfirmed } from '../../api/requests/orders/paymentConfirmedApi';
import { getNextQuestionnaires } from '../../api/questionnaires';
import ModalQuestionnaires from '../../components/Modals/ModalQuestionnaires';

const Home = ({ location }) => {
  const history = useHistory();
  const [invalidEmail, setInvalidEmail] = useState();
  const hasCompany = getUser()?.company;
  const recognitionRequired = getUser()?.company?.time_clock_require_facial_recognition;
  const [loading, setLoading] = useState(false);
  const [allCards, setAllCards] = useState([]);
  const [locationType, setLocationType] = useState(hasCompany ? "corporate" : "residential");
  const [ratingOrder, setRatingOrder] = useState([]);
  const [rated, setRated] = useState({});
  const [orderPending, setOrderPending] = useState([]);
  const [showModalResending, setShowModalResending] = useState(true);
  const [nextOpenOrder, setNextOpenOrder] = useState({});
  const [currentOrderOnline, setCurrentOrderOnline] = useState({});
  const [messagesNext, setMessagesNext] = useState({});
  const [modalMessageVisible, setModalMessageVisible] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [isLoadingNav, setIsLoadingNav] = useState(true);
  const [questionnairesNext, setQuestionnairesNext] = useState({});
  const [showModalQuestionnaires, setShowModalQuestionnaires] = useState(false)


  const getQuestionnaires = async () => {
    try {
      const questionnaire = await getNextQuestionnaires()
      if(questionnaire) {
        setQuestionnairesNext(questionnaire)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(()=>{
    if(location.state === "logged"){
      const userCompanyLogo = getUser()?.company?.screen_image_url;
      if(userCompanyLogo){
        history.push('/splash', {
          image_url: userCompanyLogo
        });
      }
    }
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    return () => abortController.abort()
  }, [])

  useEffect(() => {
    if(questionnairesNext?.id) {
      setShowModalQuestionnaires(true)
    }
  }, [questionnairesNext])

  useEffect(() => {
    async function getPaymentsConfirmedToRedirect(){ 
      await getPaymentsConfirmed()
      .then(res => {
          if(!!res[0]){
            history.replace("/confirmedPayment", res[0]);
          }
      })
      .catch(()=> { return })
    }
    getPaymentsConfirmedToRedirect();
  }, [])
  
  const hasPositionEnabled = getUser()?.company?.position_scheduling_enabled;
  const hasTimeClockEnabled = getUser()?.company?.time_clock_enabled; 

  let bodyHome = document.getElementById('bodyHome');

  const capitalize = (info) => {
    return info.charAt(0).toLowerCase() + info.substr(1);
  }

  const cancelLoading = () => {
    if (bodyHome) {
      bodyHome.style.display = 'flex';
    }
    setLoadingSkeleton(false);
  }

  const executeSearchs = async (doGenerateCards, setLoadingSkeleton) => {

    try {
      await getSections(doGenerateCards);
      await getCustomerCompany().then(val => {
        let info = {
          company: val,
          customer: getUser().customer,
          token: getUser().token,
          token_expires_at: getUser().token_expires_at,
        }
        onSignIn(info);
      });

    } catch (error) {
      toast.error(error.message, { autoClose: 3000 });
    } finally{
      setLoadingSkeleton(false);
    }
  }

  const getNextOrderToOpen = async () => {
    try {
      await getNextOpenOrder(setNextOpenOrder);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const getRateOrder = async (setRatingOrder) => {
    try {
      await getRatingOrder(setRatingOrder);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const getNextOnlineOrder = async (setCurrentOrderOnline) => {
    try {
      await getCurrentOnline(setCurrentOrderOnline);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const getNextMessages = async (setMessagesNext) => {
    try {
      await getMessagesNext(setMessagesNext);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const putRateOrder = (order, rated, setRatingOrder) => {
    const doPutRatingOrder = async () => {
      try {
        setLoading(true);

        await putRatingOrder(order.id, rated.rating, rated.comment).then(() => getRateOrder(setRatingOrder));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    };

    return { doPutRatingOrder };
  }

  const getPendingOrders = async (setOrderPending) => {
    try {
      await getPendingPayment(setOrderPending);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const generateCards = (locationType, setAllCards) => {
    const doGenerateCards = async (allSections) => {
      let tempCards = [];
      tempCards[allSections.banners] = <Banners />;
      tempCards[allSections.discount_coupons] = <DiscountCouponsBanner />;
      tempCards[allSections.highlights] = <HighLighted orderType={locationType} />;
      tempCards[allSections.in_company_banner] = <InCompanyBanner />;
      tempCards[allSections.last_services] = <LastServicesOffers orderType={locationType} />;
      tempCards[allSections.offers] = <GroupedServiceOffers orderType={locationType} />;
      tempCards[allSections.partners] = <Partners />;
      tempCards[allSections.today_offers] = <TodayOffers />;
      setAllCards(tempCards);
    }

    return { doGenerateCards };
  }

  const { doPutRatingOrder } = putRateOrder(ratingOrder[0], rated, setRatingOrder);
  const { doGenerateCards } = generateCards(locationType, setAllCards, setLoadingSkeleton);

  useEffect(() => {
    if (location.search !== '') {
      let search = location.search.substring(1);
      let offerJson = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      if (offerJson.screen) {
        let screen = capitalize(offerJson.screen);
        history.push(`/${screen}?${search}`);
      }
    }

    setIsLoadingNav(false);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (!isLoadingNav && isMounted) {
      localStorage.removeItem('products');
      if (document.getElementById('bodyHome')) {
        document.getElementById('bodyHome').style.display = 'none';
      }
      setLoadingSkeleton(true);
      setTimeout(() => debounce(5000, executeSearchs(doGenerateCards, setLoadingSkeleton)), 1500);
    }

    return () => { isMounted = false; };
  }, [locationType, isLoadingNav]);

  useEffect(() => {
    if (rated.rating) {
      doPutRatingOrder();
    }
  }, [rated]);

  useEffect(() => {
    let isMounted = true;

    if (!isLoadingNav && isMounted) {
      setTimeout(() => {
        setInvalidEmail(sessionStorage.getItem('invalid_email'));
        getPendingOrders(setOrderPending);
        getRateOrder(setRatingOrder);
        getNextOrderToOpen();
        getNextMessages(setMessagesNext);
        getNextOnlineOrder(setCurrentOrderOnline);
        getQuestionnaires();
      }, 4000);
    }

    return () => { isMounted = false; };
  }, [isLoadingNav]);

  useEffect(() => {
    if (messagesNext?.message) {
      setModalMessageVisible(true);
    }
  }, [messagesNext]);

  useEffect(() => {
    if (allCards.length === 8) {
      setTimeout(() => cancelLoading(), 1000);
    }
  }, [allCards]);

  return (
    <ContainerMain>
      <Header orderType={locationType} screen="home" />
      <LoaderSpinner loading={loading} />

      {ratingOrder.length > 0 && orderPending.length === 0 &&
        <ModalProfessionalEvaluation
          order={ratingOrder[0]}
          callback={val => setRated(val)}
        />
      }

      {invalidEmail &&
        <ModalInvalidEmail />
      }

      {orderPending.length > 0 && showModalResending &&
        <ModalReSendPayment
          showModalResend={showModalResending}
          order={orderPending[0]}
          setShowModalResend={val => setShowModalResending(val)}
        />
      }
      
      <Container>
        <div id="bodyHome" className="body-home">
          {(allCards && allCards.length > 0) &&
            <>
              {allCards[0]}
              {allCards[1]}
              {allCards[2]}

              {hasTimeClockEnabled && !recognitionRequired &&
                <>
                  <TimeClock />
                  <LineSeparator />
                </>
              }

              {hasPositionEnabled === true &&
               <> 
                {(!hasTimeClockEnabled || (hasTimeClockEnabled && recognitionRequired)) && <Divider />}
                <SchedulePlace />
                <LineSeparator />
               </>
              }
              <LocationSelector location={locationType} setLocation={value => setLocationType(value)} />
              
              {allCards[3]}
              {allCards[4]}
              {allCards[5]}
              {allCards[6]}
              {allCards[7]}
              <LineSeparator />
              <Footer />
            </>
          }
        </div>
        
        {loadingSkeleton &&
          <SkeletonLoaderHome />
        }
      </Container>

      <TabButton screen="home" />

      <NextOpenOrder render={nextOpenOrder?.id ? true : false} order={nextOpenOrder} />

      <ModalNextMessages
        showModalNext={modalMessageVisible}
        message={messagesNext}
        setShowModalNext={val => setModalMessageVisible(val)}
      />

      <ModalQuestionnaires
        setShowModal={setShowModalQuestionnaires}
        showModal={showModalQuestionnaires}
        questionnaire={questionnairesNext}
      />

      {currentOrderOnline?.id && !modalMessageVisible &&
        <ModalNextServiceOnline order={currentOrderOnline} />
      }
    </ContainerMain>
  );
};

export default Home;