import React, { useEffect, useState } from "react";
import { getUser, onSignOut } from "../../../utils/auth";
import { ModalHelp } from "../../../components/ModalHelp";
import { useHistory } from "react-router-dom";
import TabButton from "../../../components/TabButton";
import Header from "../../../components/Header";
import { Container } from "./styles";
import {
  FiUser,
  FiLock,
  FiMap,
  FiBriefcase,
  FiBarChart2,
  FiHelpCircle,
  FiHeart,
  FiMessageCircle,
  FiClipboard
} from "react-icons/fi";

const MyAccount = () => {
  const navigation = useHistory();

  const [showHelpModal, setShowHelpModal] = useState(false);
  const [hasVaccination, setHasVaccination] = useState(false);

  const logout = () => {
    onSignOut();
    navigation.go("/");
  };

  let customerName = getUser()?.customer.name;
  let hasCompany = getUser()?.company?.id;
  let acceptWallet = getUser()?.company?.accept_wallet_payment;

  useEffect(() => {
    setHasVaccination(getUser()?.company?.customer_vaccination_enabled);
  }, []);

  return (
    <Container>
      <Header screen="user" orderType="corporate" />

      <div style={{ background: "#FFFFFF", height: "100vh" }}>
        <div className="container-name">
          <span className="customer-name">Olá, {customerName}</span>
        </div>

        <div className="menu-view">
          <button
            type="button"
            className="menu-item"
            onClick={() => {
              navigation.push("/personalData");
            }}
          >
            <FiUser className="menu-item-icon" />
            <span className="menu-item-text">Dados pessoais</span>
          </button>

          <div className="menu-item-separator" />

          <button
            type="button"
            className="menu-item"
            onClick={() => {
              navigation.push("/changePassword");
            }}
          >
            <FiLock className="menu-item-icon" />
            <span className="menu-item-text">Alteração de senha</span>
          </button>

          <div className="menu-item-separator" />

          <button
            type="button"
            className="menu-item"
            onClick={() => {
              navigation.push("/addresses", { createNew: false });
            }}
          >
            <FiMap className="menu-item-icon" />
            <span className="menu-item-text">Meus endereços</span>
          </button>

          <div className="menu-item-separator" />

          <button
            type="button"
            className="menu-item"
            onClick={() => {
              navigation.push("/linkCompany", { createNew: false });
            }}
          >
            <FiBriefcase className="menu-item-icon" />
            <span className="menu-item-text">Mudei de empresa</span>
          </button>

          <div className="menu-item-separator" />

          {(!hasCompany || acceptWallet) && (
            <div>
              <button
                type="button"
                className="menu-item"
                onClick={() => {
                  navigation.push("/walletStatement", { createNew: false });
                }}
              >
                <FiBarChart2 className="menu-item-icon" />
                <span className="menu-item-text">Extrato Carteira Digital</span>
              </button>

              <div className="menu-item-separator" />
            </div>
          )}

          <button
            type="button"
            className="menu-item"
            onClick={() => setShowHelpModal(true)}
          >
            <FiHelpCircle className="menu-item-icon" />
            <span className="menu-item-text">Precisa de ajuda?</span>
          </button>

          <div className="menu-item-separator" />

          {hasVaccination && hasCompany && (
            <>
              <button
                type="button"
                className="menu-item"
                onClick={() => {
                  navigation.push("/vaccinations");
                }}
              >
                <FiHeart className="menu-item-icon" />
                <span className="menu-item-text">Vacinação</span>
              </button>

              <div className="menu-item-separator" />
            </>
          )}

          <button
            type="button"
            className="menu-item"
            onClick={() => {
              navigation.push("/messages");
            }}
          >
            <FiMessageCircle className="menu-item-icon" />
            <span className="menu-item-text">Mensagens</span>
          </button>
          
          <div className="menu-item-separator" />
          
          <button
            type="button"
            className="menu-item"
            onClick={() => {
              navigation.push("/subscriptions");
            }}
          >
            <FiClipboard className="menu-item-icon" />
            <span className="menu-item-text">Assinaturas</span>
          </button>

          <div className="menu-item-separator" />
          <button type="button" className="logout-item" onClick={() => logout()}>
            <span className="logout">Sair</span>

            <span className="version">versão {"web - 10.0.0"}</span>
          </button>
        </div>

        <ModalHelp
          mode="light"
          showButton={false}
          showModal={showHelpModal}
          setShowModal={setShowHelpModal}
        />
      </div>
      <TabButton screen="account" />
    </Container>
  );
};

export default MyAccount;
