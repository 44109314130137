import React, { useState, useEffect } from 'react';
import { getAddresses } from '../../../api/customer';
import UserAddress from './UserAddresses';
import { toast } from 'react-toastify';
import Header from '../../../components/Header';
import { Container } from './styles';
import ModalDeleteAddress from '../../../components/Modals/ModalDeleteAddress';
import Footer from '../../../components/Footer';
import TabButton from '../../../components/TabButton';
import SkeletonAddresses from './SkeletonAddresses';
import { useHistory } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

const Addresses = () => {
  const navigation = useHistory();
  const [address, setAddress] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteAddress, setDeleteAddress] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);

  const cancelLoading = () => {
    let timeout = setTimeout(() => {
      if (document.getElementById('bodyAddresses')) {
        document.getElementById('bodyAddresses').style.display = 'block';
      }
      setLoadingSkeleton(false);
    }, 500);
    return () => { clearTimeout(timeout); };
  }

  const goCreateNewAddress = () => {
    navigation.push("/newAddress", { data: '' });
  }

  const getAllAdresses = async () => {
    try {
      await getAddresses().then(val => setAddress(val));

      setDeleteAddress(false);
      cancelLoading();
    } catch (erro) {
      toast.error("Erro ao buscar endereços: " + erro.message)
    }
  }

  useEffect(() => {
    let isMounted = true;
    let timeout;

    if (isMounted) {
      if (document.getElementById('bodyAddresses')) {
        document.getElementById('bodyAddresses').style.display = 'none';
      }
      setLoadingSkeleton(true);
      timeout = setTimeout(() => debounce(5000, getAllAdresses()), 1500);
    }

    return () => { isMounted = false; clearTimeout(timeout); };
  }, []);

  return (
    <Container>
      <Header screen="user" orderType="corporate" />

      <ModalDeleteAddress
        showModalDelete={showModal}
        setShowModalDelete={val => setShowModal(val)}
        clicked={option => option && setDeleteAddress(true)}
      />

      <div className="background-body">

        {loadingSkeleton &&
          <SkeletonAddresses />
        }

        <div className="content-addresses" id="bodyAddresses">
          {address.length > 0 &&
            <UserAddress props={address} showModal={setShowModal} deleteAdd={deleteAddress} change={getAllAdresses} />
          }

          <div className="button-new">
            <button type="button" className="button-new-address" onClick={goCreateNewAddress}>
              <span>adicionar novo endereço</span>
            </button>
          </div>
        </div>

        {!loadingSkeleton &&
          <div className="footer-service-list">
            <div className="line-separator"></div>
            <Footer />
          </div>
        }
      </div>

      <TabButton screen="account" />
    </Container>
  )
};

export default Addresses;