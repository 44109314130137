import styled from "styled-components";

export const Container = styled.div`
    .footer-service-list {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @media (max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }

        .container-pack {
            padding-bottom: 80px;
        }
    }
`;

export const PageContainer = styled.div`
    padding-top: 5vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1080px !important;
    min-height: 100vh;
    margin: auto;
    box-sizing: border-box;
    padding-bottom: 10vh;
    position: relative;
`;



export const NotFound = styled.h4`
    color: #14213d;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
    padding: 2rem;
`;
