import { useEffect, useState } from "react";
import moment from "moment-timezone";

import { FiBookmark, FiCalendar, FiClock, FiMapPin } from "react-icons/fi";

import Header from "../../../../../components/Header";
import ModalConfirmation from "../../../../../components/Modals/ModalConfirmation";
import TabButton from "../../../../../components/TabButton";
import { ContainerOpacity } from "../components/ContainerOpacity";
import { ContainerButtonInfo } from "../components/ContainerButtonInfo";
import { ImageInfo } from "../components/ImageInfo";
import { ContainerAbout } from "../components/ContainerAbout";
import { ContainerInfo } from "../components/ContainerInfo";
import { TextAbout } from "../components/TextAbout";
import { ContainerButtonOrange } from "../components/ContainerButtonOrange";
import { ButtonOrange } from "../components/ButtonOrange";
import { NotFound } from "../components/NotFound";
import { PrincipalContainer } from "../components/PrincipalContainer";
import { getScheduled } from "../../../../../api/check";
import { ContainerCheck } from "../components/ContainerCheck";
import { ContainerImage } from "../components/ContainerImage";
import { TextCancel } from "../components/TextCancel";
import Footer from "../../../../../components/Footer";
import LoaderSpinner from "../../../../../components/LoaderSpinner";
import useGeoLocation from "../../../../../hooks/useGeoLocation";
import SchedulePlaceBanner from "../SchedulePlaceBanner";
import { PageInfoWhatToDo } from "../components/PageInfoWhatToDo";
import { ButtonCloseAdblock } from "../../../../../components/AdblockCard/styles";

import { useHistory } from "react-router-dom";
import { getUser } from "../../../../../utils/auth";
import { t } from 'i18next'

export const Checkin = () => {
  const history = useHistory();

  if (!getUser()?.company?.position_scheduling_enabled) {
    history.replace("/home");
  }

  const [showModal, setShowModal] = useState(false);
  const [scheduleChoosed, setScheduleChoosed] = useState({});
  const [isCancel, setIsCancel] = useState(0);
  const [loading, setLoading] = useState(false);
  const hasAutomaticCheckin = getUser()?.company?.position_scheduling_automatic_checkin;

  const [scheduled, setScheduled] = useState([]);

  // const theLocation = useGeoLocation();

  const checkLocation = () => {
    window.location.reload();
  };

  const doGetScheduled = async (isMounted) => {
    setLoading(true);
    await getScheduled().then((places) =>
      isMounted ? setScheduled(places) : null
    );
    setLoading(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      doGetScheduled(isMounted);
    }

    return () => (isMounted = false);
  }, [showModal]);


  return (
    <PrincipalContainer>
      {showModal && (
        <ModalConfirmation
          mode={isCancel ? "cancel" : "check"}
          position={scheduleChoosed.company_place_description}
          setShow={(val) => setShowModal(val)}
          setOption={(op) => op}
          schedule_id={scheduleChoosed.id}
          positionCode={scheduleChoosed.company_place_position_code}
        />
      )}
      <Header screen="home" isNotSchedule={false} />
      <SchedulePlaceBanner
        bannerUrl="https://posher.s3.sa-east-1.amazonaws.com/production/company_places/images/banner_checkin.jpg"
        isUrl={true}
        title={hasAutomaticCheckin ? t("reservationsButtonTitle") : t("checkInButtonTitle")}
        serviceType="presential"
      />

      <LoaderSpinner loading={loading} />

      {/* {!theLocation.error ? ( */}
        <ContainerCheck className="background-body">
          {scheduled.length === 0 ? (
            <NotFound>{t("noCheckinAvailable")}</NotFound>
          ) : (
            <>
              <PageInfoWhatToDo>{t("yourReservations")}</PageInfoWhatToDo>
              {scheduled.map((schedule, index) => {
                var timeFrom = moment(schedule.date_from)
                  .tz(moment.tz.guess())
                  .format("HH:mm");
                var timeTo = moment(schedule.date_to)
                  .tz(moment.tz.guess())
                  .format("HH:mm");
                return (
                  <ContainerButtonInfo key={index}>
                    <ContainerImage>
                      <ImageInfo
                        src={schedule.company_place_position_type_thumb_url}
                      />
                      <TextCancel
                        onClick={() => {
                          setScheduleChoosed(schedule);
                          setIsCancel(true);
                          setShowModal(true);
                        }}
                      >
                        {t("cancelWord")}
                      </TextCancel>
                    </ContainerImage>
                    <ContainerInfo>
                      <ContainerAbout>
                        <FiMapPin width={15} height={15} color={"#14213D"} />
                        <TextAbout>
                          {schedule.company_place_description}
                        </TextAbout>
                      </ContainerAbout>

                      <ContainerAbout>
                        <FiBookmark width={15} height={15} color={"#14213D"} />
                        <TextAbout>
                          {schedule.company_place_position_type_description} -{" "}
                          {schedule.company_place_position_code}
                          {schedule.company_place_position_description &&
                            " - " + schedule.company_place_position_description}
                        </TextAbout>
                      </ContainerAbout>

                      <ContainerAbout>
                        <FiCalendar width={15} height={15} color={"#14213D"} />
                        <TextAbout className="description">
                          {moment(schedule.date_from)
                            .tz(moment.tz.guess())
                            .format("DD/MM/YYYY")}
                        </TextAbout>
                      </ContainerAbout>

                      <ContainerAbout>
                        <FiClock width={15} height={15} color={"#14213D"} />
                        <TextAbout>
                        {t("fromTimePicker").toLowerCase()} {timeFrom} {t("toTimePicker").toLowerCase()} {timeTo}
                        </TextAbout>
                      </ContainerAbout>
                    </ContainerInfo>

                    {!hasAutomaticCheckin &&
                      <ContainerOpacity
                        onClick={() => {
                          setScheduleChoosed(schedule);
                          setIsCancel(false);
                          setShowModal(true);
                        }}
                      >
                        <ContainerButtonOrange>
                          <ButtonOrange>{t("checkInButtonTitle")}</ButtonOrange>
                        </ContainerButtonOrange>
                      </ContainerOpacity>}
                  </ContainerButtonInfo>
                );
              })}
            </>
          )}
          <div className="footer-service-list">
            <div className="line-separator"></div>
            <Footer />
          </div>
          <TabButton screen="home" />
        </ContainerCheck>
      {/* ) : (
        <>
          <ContainerCheck className="background-body">
            <PageInfoWhatToDo>
              Ative sua localização para realizar o checkin!
            </PageInfoWhatToDo>
            <ButtonCloseAdblock onClick={checkLocation}>
              Recarregar
            </ButtonCloseAdblock>
          </ContainerCheck>
        </>
      )} */}
    </PrincipalContainer>
  );
};
