import { getSectionsCatchErrors } from "../getSectionsCatchErrors";
import { fetchWithTimeout, header } from "./request";

export const validatePutResponse = async (putUrl, errorMessage, body) => {
  const { put_bearer } = header(body);
  let isValidation = false;
  try {
    const response = await fetchWithTimeout(`${putUrl}`, put_bearer);

    if (response.status === 400) {
      const responseJson = response.data;

      isValidation = true;

      throw new Error(responseJson.message);
    } else {
      if (response.status !== 200) {
        const data = response.data;
        throw new Error(data.message + " " + response.status);
      }
    }
  } catch (error) {
    if (isValidation) throw error;
    getSectionsCatchErrors(error, errorMessage);
  }
};

export const validatePutResponseBasic = async (putUrl, errorMessage, body) => {
  const { put } = header(body);
  let isValidation = false;
  try {
    const response = await fetchWithTimeout(`${putUrl}`, put);
    if (response.ok) {
      const responseJson = response.data;
      return responseJson;

    } else if (response.status === 400) {
      isValidation = true;
      const data = response.data;
      throw new Error(data.message);

    } else if (response.status === 404) {
      isValidation = true;
      throw new Error("Não encontrado!");

    } else if (response.status !== 200) {
      const data = response.data;
      throw new Error(data.message + " " + response.status);

    }
  } catch (error) {
    if (isValidation) throw error;
    getSectionsCatchErrors(error, errorMessage);
  }
};