import styled from 'styled-components';

export const Container = styled.div`
    .container-name {
        width: 100%;
        background-color: #FFF;
        box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
        padding: 15px 28px;
        border-radius: 0 0 17px 0;
    };
    
    .customer-name {
        color: #14213D;
        font-size: 1.1rem;
        font-weight: 700;
    };

    .menu-view {
        margin-top: 10px;
    };

    .menu-item {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        padding: 15px;
        background: none;
        width: 90%;
    };

    .menu-item-icon {
        margin-right: 2px;
        color: #FA9F2A;
        width: 17px;
        height: 17px;
    };

    .menu-item-text {
        margin-left: 5px;
        font-size: 1rem;
        color: #707070;
    };

    .menu-item-separator {
        border: 1px solid #E1E1E1;
        margin: 5px 20px;
    };

    .logout-item {
        margin: 25px 0 60px 30px;
        background: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    };

    .logout {
        font-size: 1.1rem;
        color: #FA9F2A;
        text-decoration: underline;
    };

    .version {
        position: absolute;
        right: 30px;
        font-size: 1rem;
        color: #707070;
    }
`;