import React, { useState } from 'react';
import { videoClick } from '../../../../../api/service_offer'
import Moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from './styles';
import { FiCamera } from 'react-icons/fi';

const VideoButton = (props) => {
    const category = props.category;
    const service = props.service;
    const offer = props.offer;
    const video = props.video;
    const navigation = useHistory();
    const setLoading = props.setLoading;

    const selectVideo = async () => {
        try {
            setLoading(true);
            
            await videoClick(category.id, service.id, video.id);
            
            setLoading(false);

            navigation.push("/videoView", { category: category, service: service, video: video, offer: offer });
        } catch (error) {
            toast.error(error.message);
        }
    }

    if (video.title) {
        return (
            <Container type="button" key={video.id} onClick={selectVideo}>
                <div className="button-view">
                    <img src={service.thumb_url} className="button-img" />
                </div>

                <div className="container-info-about">
                    <div className="button-title">
                        <span>{video.title}</span>
                    </div>

                    <div className="recorded-at-view">
                        <FiCamera size={15} color="#000#" />
                        <span className="recorded-at-text"> gravado {Moment(video.recorded_at).format('DD/MM')}</span>
                    </div>
                </div>

                <div className="view-button">
                    <span>Ver</span>
                </div>
            </Container>
        )
    } else {
        return <div></div>;
    }
}

export default VideoButton;