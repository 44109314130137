import styled from 'styled-components';

export const Container = styled.div`
    .content-link-company {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 180px;
    }

    .header-message {
        background-color: #FFF;
        padding: 50px 0 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .message {
        color: #707070;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 300;
    }

    .container-options {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .option-balloon {
        width: 800px;
        background-color: #FFF;
        margin-bottom: 25px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 30px 40px;
        border: 1px solid #F4F4F4;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    }

    .text-option {
        color: #14213D;
        font-size: 1.1rem;
        font-weight: bold;
    }

    .text-option-content {
        color: #707070;
        font-size: 1.1rem;
        font-weight: bold;
        margin-left: 10px;
    }

    .obs-option {
        color: #707070;
        font-size: 0.9rem;
        font-weight: 300;
        margin-top: 0.6rem;
        text-align: center;
    }

    .input-container {
        margin: 20px 0;
        position: relative;
        width: 600px;
    }

    .input {
        width: 600px;
        padding: 5px 50px;
        text-align: center;
        background-color: #FFF;
        border: 1px solid #CFCFCF;
        border-radius: 20px;
        height: 50px;
        font-size: 1rem;
        color: #707070;
    }

    .input:focus {
        border: 1px solid #FA9F2A;
    }

    .border-icon-input {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 10px;
        top: 11px;
        border: 1px solid #FA9F2A;
        border-radius: 100%;
        padding: 5px;
        color: #FA9F2A;
    }

    .button-disabled,
    .button-actived {
        background-color: #FA9F2A;
        width: 450px;
        padding: 15px 0;
        font-size: 1rem;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 20px;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    }

    .button-disabled {
        background-color: #707070;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }
    }

    @media(max-width: 840px) {
        .option-balloon {
            width: 600px;
        }

        .message {
            font-size: 1.1rem;
        }

        .input-container,
        .input {
            width: 450px;
            font-size: 0.9rem;
        }

        .button-disabled,
        .button-actived {
            width: 350px;
            font-size: 0.9rem;
        }

        .text-option-content,
        .text-option {
            font-size: 1rem;
        }

        .obs-option {
            font-size: 0.85rem;
        }
    }

    @media(max-width: 620px) {
        .option-balloon {
            width: 500px;
        }

        .input-container,
        .input {
            width: 400px;
        }

        .button-disabled,
        .button-actived {
            width: 300px;
        }
    }

    @media(max-width: 520px) {
        .option-balloon {
            width: 400px;
        }

        .message {
            font-size: 1rem;
        }

        .input-container,
        .input {
            width: 350px;
            font-size: 0.85rem;
        }

        .button-disabled,
        .button-actived {
            width: 250px;
            font-size: 0.85rem;
        }

        .obs-option {
            font-size: 0.8rem;
        }
    }

    @media(max-width: 425px) {
        .option-balloon {
            width: 350px;
        }

        .message {
            font-size: 1rem;
        }

        .input-container,
        .input {
            width: 280px;
            font-size: 0.85rem;
        }

        .button-disabled,
        .button-actived {
            width: 200px;
            font-size: 0.85rem;
        }

        .obs-option {
            font-size: 0.8rem;
        }
    }

    @media(max-width: 375px) {
        .option-balloon {
            width: 310px;
        }

        .message {
            font-size: 1rem;
        }

        .input-container,
        .input {
            width: 270px;
            font-size: 0.85rem;
        }

        .button-disabled,
        .button-actived {
            width: 200px;
            font-size: 0.85rem;
        }

        .obs-option {
            font-size: 0.8rem;
        }

        .text-option-content {
            margin-left: 5px;
        }
    }
`;