import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ButtonsOffer from '../../../../../components/ButtonsOffer';
import ButtonsPackage from '../../../../../components/ButtonsPackage';
import { Container } from './styles';
import { useResponsiveValue } from '@theme-ui/match-media';
import ButtonsSignaturePlan from '../../../../../components/ButtonsSignaturePlan';

const GroupService = (props) => {
    const offers = props.offers;
    const packs = props.packs;
    const subscriptionPlans = props.subscriptionPlans || [];
    const sum = props.sum;
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const numberOfCards = useResponsiveValue([3, 4, 5]);

    return (
        <Container>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCards}
                gutter={20}
                leftChevron={
                    <button className="button-left">
                        <FaChevronLeft size={12} />
                    </button>
                }
                rightChevron={
                    <button className="button-right">
                        <FaChevronRight size={12} />
                    </button>
                }
                outsideChevron={false}
                infiniteLoop={sum >= numberOfCards ? true : false}
                alwaysShowChevrons={sum >= numberOfCards ? true : false}
            >

                {offers.map(offer =>
                    <div key={offer.id} className="view-box">
                        <ButtonsOffer offer={offer} card="grouped" />
                    </div>
                )}

                {packs.map(pack =>
                    <div key={pack.id} className="view-box">
                        <ButtonsPackage pack={pack} />
                    </div>
                )}

                {subscriptionPlans.map(signaturePlan =>
                    <div key={signaturePlan.id} className="view-box">
                        <ButtonsSignaturePlan signaturePlan={signaturePlan} />
                    </div>
                )}

            </ItemsCarousel>
        </Container>
    )
}

export default GroupService;