import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {t} from 'i18next'
import {
  Container,
  ViewBox,
  ButtonImg,
  ButtonView,
  ButtonText,
  Title,
} from './styles';

import { ButtonOpacity } from './components/ButtonOpacity';

import { getChecked } from '../../../../api/check';
import { getUser } from '../../../../utils/auth';

const SchedulePlace = () => {
  const history = useHistory();

  const [checked, setChecked] = useState([]);
  const hasAutomaticCheckin = getUser()?.company?.position_scheduling_automatic_checkin;

  const doGetChecked = async isMounted => {
    await getChecked()
      .then(places => (isMounted ? setChecked(places) : null))
      .catch(error => toast.error(error.message));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      doGetChecked(isMounted);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Title>{t("schedulePlaceTitle")}</Title>
      <Container>
        <ViewBox>
          <ButtonOpacity onClick={() => history.push('/schedulePosition')}>
            <ButtonImg src="https://posher.s3.sa-east-1.amazonaws.com/production/company_places/images/schedule.jpg" />
            <ButtonView>
              <ButtonText numberOfLines={3}>{t("scheduleButtonTitle")}</ButtonText>
            </ButtonView>
          </ButtonOpacity>
        </ViewBox>

        <ViewBox>
          <ButtonOpacity onClick={() => history.push('/checkin')}>
            <ButtonImg src="https://posher.s3.sa-east-1.amazonaws.com/production/company_places/images/checkin.jpg" />
            <ButtonView>
              <ButtonText numberOfLines={3}>{hasAutomaticCheckin ? t("reservationsButtonTitle") : t("checkInButtonTitle")}</ButtonText>
            </ButtonView>
          </ButtonOpacity>
        </ViewBox>

        {checked.length > 0 && (
          <ViewBox>
            <ButtonOpacity onClick={() => history.push('/checkout')}>
              <ButtonImg src="https://posher.s3.sa-east-1.amazonaws.com/production/company_places/images/checkout.jpg" />
              <ButtonView>
                <ButtonText numberOfLines={3}>check-out</ButtonText>
              </ButtonView>
            </ButtonOpacity>
          </ViewBox>
        )}
      </Container>
    </>
  );
};

export default SchedulePlace;
