import styled from 'styled-components';

export const ButtonGoogleContainer = styled.aside`
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
  margin-top: 1.5rem;
  height: 40px;

  transition: ease-in-out 0.3s;

  button {
    border-radius: 20px !important
  }

  div {
    margin-left: 30px;
  }
  
  span {
    margin-right: 30px;

  }
  
  svg {
    position: relative;
    top: 1px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;
