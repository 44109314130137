import React, { useEffect, useState } from "react";
import { ButtonClose, ContentBody, Modal, ReviewContainer } from './styles';


const ModalReview = () => {

  const [showModalReview, setShowModalReview] = useState()

  useEffect(() => {
    if (localStorage.getItem('request_google_review_') == null) {
      setShowModalReview(true)
      localStorage.setItem('request_google_review_', false)
    } 
  }, [])

  return (
    <Modal
      isOpen={showModalReview}
      onRequestClose={() => setShowModalReview(false)}
      overlayClassName="modal-overlay"
    >
      <ButtonClose type="button" onClick={() => setShowModalReview(false)}>
        <span>x</span>
      </ButtonClose>

      <ReviewContainer>

        <div className="header-modal">
          <span className="subtitle-header">enviar</span>
          <span className="title-header">avaliação</span>
        </div>

        <ContentBody>
          <span>Gostaríamos de ouvir sua opinião. Deixe sua avaliação <a target='_blank' href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x94ce578e3e006071:0x23c3b5a36478c121!12e1?source=g.page.m.rc._&laa=merchant-web-dashboard-card">clicando aqui</a>.</span>
        </ContentBody>
      </ReviewContainer>
    </Modal>
  )
}

export default ModalReview