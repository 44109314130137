import React, { useEffect } from "react";
import { FiMail, FiClock, FiCheck } from "react-icons/fi";
import AvailableNow from "../../components/AvailableNow";
import { getDayName } from "../../../../utils/utils";
import Moment from "moment-timezone";
import { Container, InfoView, AndNowBanner } from "./styles";
import Header from "../../../../components/Header";
import TabButton from "../../../../components/TabButton";
import { useHistory } from "react-router-dom";
import { putPaymentsConfirmationUpdate } from "../../../../api/requests/orders/paymentConfirmedApi";
import { toast } from "react-toastify";

const ConfirmedPayment = ({ location }) => {
  const history = useHistory();

  if (!location.state) {
    history.goBack();
  }

  const {
    state: {
      id,
      order_type,
      job_day_id,
      professional,
      scheduled_date,
      service,
      address,
      address_description,
    } = {}
  } = location;

  let dayOfWeek = getDayName(scheduled_date);

  useEffect(() => {
    async function updateConfirmation() {
      await putPaymentsConfirmationUpdate(id).catch((error) => {
        toast.error(error.message);
      });
    }
    updateConfirmation();
  }, []);

  return (
    <Container>
      <Header orderType={order_type} address={address} screen="home" />

      <div className="background-body">
        {job_day_id ? (
          <div>
            <div className="green-check-view">
              <FiCheck color="#fff" size={70} />
            </div>

            <span className="text-ok">Tudo certo!</span>
          </div>
        ) : (
          <>
            <div className="yellow-clock-view">
              <FiClock color="#707070" size={70} />
            </div>

            <span className="text-waiting">Recebemos sua solicitação</span>
          </>
        )}

        <div className="info-tags">
          <InfoView>
            <span className="info-text">
              {job_day_id
                ? `Seu pedido de ${service?.name} com o ${professional?.name
                } foi confirmado para ${dayOfWeek} ${Moment(scheduled_date)
                  .tz("America/Sao_Paulo")
                  .format("DD/MM")}`
                : `Sua solicitação de ${service?.name
                } para ${dayOfWeek} ${Moment(scheduled_date).format(
                  "DD/MM"
                )} está aguardando confirmação.`}
            </span>
          </InfoView>

          <AndNowBanner>
            <div className="and-now-banner">
              <span className="and-now-text">E AGORA?</span>
            </div>
          </AndNowBanner>

          <div className="one-two-banner">
            <span className="one-two-number-text">1</span>
            <div className="info-one-two-text">
              <div className="mail-clock-view">
                <FiMail color="#000" size={20} />
              </div>

              <span className="one-two-text">
                Enviamos{" "}
                {!!job_day_id
                  ? "todas as informações do agendamento para seu email."
                  : "um email com um resumo do seu pedido"}
              </span>
            </div>
          </div>

          <div className="one-two-banner">
            <span className="one-two-number-text">2</span>

            <div className="info-one-two-text">
              <div className="mail-clock-view">
                <FiClock color="#000" size={20} />
              </div>

              <span className="one-two-text">
                {job_day_id
                  ? `No horário combinado se dirija ao local do serviço (${address_description}). O profissional estará te aguardando.`
                  : "Enviaremos em breve um email com a confirmação do horário e profissional para seu serviço."}
              </span>
            </div>
          </div>

          {!job_day_id && (
            <div className="one-two-banner">
              <span className="one-two-number-text">3</span>
              <div className="info-one-two-text">
                <div className="mail-clock-view">
                  <FiClock color="#000" size={20} />
                </div>

                <span className="one-two-text">
                  No horário agendado o profissional fará seu atendimento.
                </span>
              </div>
            </div>
          )}

          <div className="avaliable-now">
            <AvailableNow
              orderType={order_type}
              address={address}
              title="DISPONÍVEL AGORA!"
            />
          </div>
        </div>
      </div>

      <TabButton screen="home" />
    </Container>
  );
};

export default ConfirmedPayment;
