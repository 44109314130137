import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 18px;
    width: 800px;
    padding: 5px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    border: 1px solid #F7F7F7;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

    .order-view {
        display: flex;
        position: relative;
    }

    .container-locale {
        display: flex;
        align-items: center;
    }

    .locale {
        color: #707070;
        font-size: 0.9rem;
        margin-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 350px;
    }

    .service-img {
        width: 100px;
        height: 100px;
        border-radius: 16px;
    }

    .container-image {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .locale-view {
        margin-top: 10px;
        height: 15px;
        align-self: flex-start;
        margin-left: 4px;
    }

    .container-state-name {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }

    .order-state {
        margin-left: 5px;
        color: #14213D;
        font-size: 1rem;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;
    }

    .estimateApprovalButton{
        width: 110px;
        height: 20px;
        background-color: #FA9F2A;
        border-width: 1px;
        border-color: #FA9F2A;
        border-radius: 10px;
        text-align: center;
        font-size: 13px;
        color: #FFFFFF;
        overflow: hidden;
        margin-top: 5px;
    }

    .order-data-view {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 10px;
        margin-top: 5px;
    }

    .service-name-text {
        font-weight: 700;
        font-size: 1.2rem;
        color: #14213D;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 500px;
    }

    .cancel-button-view button {
        background: none;
        color: #FA9F2A;
        text-decoration: underline;
        font-size: 1rem;
    }

    .options-view {
        margin-top: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .date-info-order {
        display: flex;
        margin: 5px 0;
        align-items: center;
    }

    .schedule-date {
        margin-top: 0;
        color: #707070;
        font-size: 0.9rem;
        margin-left: 5px;
    }

    .time-info-order {
        display: flex;
        margin-top: 5px;
        align-items: center;
    }

    .schedule-time {
        color: #707070;
        font-size: 0.9rem;
        margin-left: 5px;
    }

    .price-view {
        color: #707070;
        border: 1px solid #14213D;
        border-radius: 18px 18px 0 18px;
        padding: 0 20px;
        height: 30px;
        position: absolute;
        right: 0;
        top: -5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .price {
        text-align: center;
        font-size: 1rem;
        color: #14213D;
    }

    .professional-view {
        display: flex;
        background-color: #F8F8F8;
        width: 350px;
        border-radius: 18px 0 0 18px;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 5px;
    }

    .professional-image {
        width: 60px;
        height: 60px;
        border-radius: 16px;
    }

    .professional-data {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
    }

    .professional-title {
        font-size: 0.9rem;
        color: #14213D;
        margin-top: 5px;
    }

    .professional-name {
        width: 260px;
        margin-top: 5px;
        font-size: 1rem;
        font-weight: 700;
        color: #14213D;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bottom-view {
        background-color: #F0F0F0;
        border-radius: 0 0 17px 17px;
        justify-content: center;
        margin-left: -5px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }

    .order-number {
        color: #14213D;
        text-align: center;
        font-size: 0.8rem;
        padding: 3px;
    }

    .online-tag-text {
        font-size: 0.7rem;
        color: #FFFFFF;
        margin-left: 2px;
    }

    .access-room-button-view button {
        background-color: #FA9F2A;
        color: #FFFFFF;
        padding: 10px 20px;
        border-radius: 20px;
        font-size: 1rem;
    }

    .copy-link-button-view {
        color: #FA9F2A;
        text-decoration: underline;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .online-tag {
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: #14213D;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        border-radius: 20px;
    }

    @media(max-width: 820px) {
        width: 700px;

        .service-name-text {
            width: 450px;
        }

        .professional-view {
            width: 300px;
        }

        .professional-name {
            width: 200px;
        }
    } 

    @media(max-width: 745px) {
        width: 600px;

        .container-state-name svg {
            width: 14px;
        }

        .container-state-name span{
            font-size: 0.9rem;
        }

        .time-info-order span {
            font-size: 0.8rem;
        }

        .time-info-order svg {
            width: 11px;
        }

        .cancel-button-view button {
            font-size: 0.9rem;
        }

        .container-locale svg {
            width: 14px;
        }

        .container-locale span {
            font-size: 0.9rem;
            width: 250px;
        }

        .service-img {
            width: 90px;
            height: 90px;
        }

        .professional-image {
            width: 50px;
            height: 50px;
        }

        .container-image {
            width: 90px;
        }

        .price {
            font-size: 0.9rem;
        }

        .service-name-text {
            width: 350px;
            font-size: 1.1rem;
        }

        .professional-view {
            width: 280px;
        }

        .professional-name {
            width: 200px;
            font-size: 0.9rem;
        }
    }

    @media(max-width: 620px) {
        width: 500px;

        .container-locale span {
            width: 200px;
        }

        .professional-title {
            font-size: 0.8rem;
        }

        .date-info-order svg {
            width: 11px;
        }

        .date-info-order span {
            font-size: 0.8rem;
        }

        .service-img {
            width: 85px;
            height: 85px;
        }

        .container-image {
            width: 85px;
        }

        .service-name-text {
            width: 260px;
            font-size: 1rem;
        }

        .professional-view {
            width: 230px;
        }

        .professional-name {
            width: 150px;
        }
    }

    @media(max-width: 525px) {
        max-width: 400px;

        .cancel-button-view button {
            font-size: 0.85rem;
        }

        .container-locale span {
            width: 150px;
        }

        .price {
            font-size: 0.8rem;
        }

        .service-img {
            width: 80px;
            height: 80px;
        }

        .container-image {
            width: 80px;
        }

        .service-name-text {
            width: 150px;
        }

        .professional-view {
            width: 200px;
        }

        .professional-name {
            width: 110px;
        }
    }

    @media(max-width: 420px) {
        max-width: 300px;

        .professional-title {
            font-size: 0.7rem;
        }

        .professional-image {
            width: 45px;
            height: 45px;
        }

        .container-locale span {
            width: 100px;
        }

        .service-name-text {
            width: 100px;
        }

        .professional-view {
            width: 150px;
        }

        .professional-name {
            width: 90px;
        }
    }
`;