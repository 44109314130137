import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalCouponDiscount = ({ showModalDiscount, setShowModalDiscount, allDiscountCoupons, setChecked, checked, discountCouponCode, setDiscountCouponCode, clicked }) => {
    const doCloseModal = (option) => {
        clicked(option);
        setShowModalDiscount(false);
    }

    return (
        <div>
            <Modal
                isOpen={showModalDiscount}
                onRequestClose={() => doCloseModal(false)}
                overlayClassName="modal-overlay discount-coupon"
                className="modal-content discount-coupon"
                ariaHideApp={false}
            >
                <button className="cancel-button" type="button" onClick={() => doCloseModal(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">insira seu</span>
                    <span className="title-header">cupom de Desconto</span>
                </div>

                <div className="content-body">
                    <span className="text-body">Você pode selecionar um dos cupons ou digitar no compo abaixo:</span>

                    <div className="separator"></div>

                    {allDiscountCoupons.map((discountCoupon, discountCouponIndex) => {
                        return (
                            <div key={discountCouponIndex} className="container-radio-coupons">
                                <div className="container-radio-buttons">
                                    <input
                                        type="radio"
                                        value={discountCoupon}
                                        checked={discountCoupon === checked}
                                        onChange={() => {
                                            setDiscountCouponCode('')
                                            setChecked(discountCoupon)
                                        }}
                                    />

                                    <label onClick={() => {
                                        setChecked(discountCoupon)
                                        setDiscountCouponCode('')
                                        }}>
                                            {discountCoupon.code}
                                    </label>
                                </div>

                                <span className="coupon-description">{discountCoupon.description}</span>
                            </div>
                        )
                    })}

                    <div className="input-container">
                        <input
                            className="inputs"
                            placeholder="digite seu cupom"
                            value={discountCouponCode}
                            onChange={e => {
                                setDiscountCouponCode(e.target.value)
                                setChecked('')
                            }}
                        />
                    </div>
                </div>

                <button className="accept-button" type="type" onClick={() => doCloseModal(true)}>
                    <span>adicionar cupom</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalCouponDiscount;