import styled from "styled-components";

export const ContainerAbout = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0;
    .title{
        margin-left: 5px;
        color: #14213D;
        font-size: 1.2rem;
        font-weight: 700;
        padding-top: 5px;
    }
    @media(max-width: 745px) {
       .title{
            font-size: 1.1rem;
        }
    }
    @media(max-width: 620px) {
        .title{
            font-size: 1.rem;
        }
    }
`;