import styled from 'styled-components';

export const Container = styled.div`

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        padding-bottom: 50px;

        .footer-service-list .line-separator {
            display: none;
        }
    }

`;

export const Content = styled.div`
    position: relative;
    padding-bottom: 150px;
    display: none;

    .container-buttons-service {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }

    .notFound {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #707070;
        font-size: 1rem;
        margin: 50px 0;
    }

    .button-service {
        margin: 10px 0;
    }

    @media(max-width: 960px) {
        padding-bottom: 40px;
    }
`;