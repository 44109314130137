import React, { useEffect, useState } from 'react';
import { Container, TabButtons } from './styles';
import { FiUser, FiFileText, FiBox, FiTag } from 'react-icons/fi';
import PosherIcon from '../../assets/images/icons/posherIcon.svg';
import PosherIconBlue from '../../assets/images/icons/posherIconBlue.svg';
import { Link } from 'react-router-dom';
import { getPendingAction } from '../../api/service_orders';

const TabButton = ({ screen }) => {
    const [numberPendingOrders, setNumberPendingOrders] = useState();

    useEffect(() => {
        async function getNumberOrder(setNumberPendingOrders) {
            try {
                await getPendingAction(setNumberPendingOrders);
            }
            catch (error) {
                return
            }
        }
        getNumberOrder(setNumberPendingOrders);
    }, []);

    return (
        <Container>
            <TabButtons>
                <Link to="/myAccount" className="button">
                    <FiUser size={22} className={`icon ${screen === 'account' && 'iconActive'}`} />
                    <span className={`button-title ${screen === 'account' && 'active'}`}>Conta</span>
                </Link>

                <Link to="/serviceOrderHistory" className="button">
                    <FiFileText size={22} className={`icon ${screen === 'orders' && 'iconActive'}`} />
                    {numberPendingOrders > 0 && <p>{numberPendingOrders}</p>}
                    <span className={`button-title ${screen === 'orders' && 'active'}`}>Pedidos</span>
                </Link>

                <Link to="/home" className="button">
                    {screen === 'home' ?
                        <img src={PosherIconBlue} className="icon-posher" alt="icone POSHER" />
                        :
                        <img src={PosherIcon} className="icon-posher" alt="icone POSHER" />
                    }
                    <span className={`button-title ${screen === 'home' && 'active'}`}>Início</span>
                </Link>

                <Link to="/myPackages" className="button">
                    <FiBox size={22} className={`icon ${screen === 'packages' && 'iconActive'}`} />
                    <span className={`button-title ${screen === 'packages' && 'active'}`}>Pacotes</span>
                </Link>

                <Link to="/discountCoupons" className="button">
                    <FiTag size={22} className={`icon ${screen === 'coupons' && 'iconActive'}`} />
                    <span className={`button-title ${screen === 'coupons' && 'active'}`}>Cupons</span>
                </Link>
            </TabButtons>
        </Container>
    );
}

export default TabButton;