import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { FiShoppingCart } from 'react-icons/fi';

const HeaderBanner = ({ partner, showShoppingCard, address, quantity }) => {
    const navigation = useHistory();

    return (
        <Container>
            <div className="image-service-list">
                <img src={partner.header_url} className="service-banner" alt="service-banner" />
            </div>

            <div className="container-main">
                <div className="container-partner">
                    <span className="partner-name">{(partner.name).toUpperCase()}</span>
                </div>

                {showShoppingCard &&
                    <button
                        type="button"
                        onClick={() => navigation.push('/partnersCheckout', { partner: partner, address: address })}
                        className="shopping-card-button"
                    >
                        <div className="shopping-card">
                            <FiShoppingCart className="icon" />
                            <div className="quantity-products">
                                <span className="quantity-text">{quantity}</span>
                            </div>
                        </div>
                    </button>
                }
            </div>
        </Container>
    );
}

export default HeaderBanner;