import styled, { keyframes } from "styled-components";

const appear = keyframes`
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${appear} 1.8s linear alternate;
`;

export const LogoImage = styled.img`
  max-width: 95vw;
  max-height: 90vh;
`;