import React, { useState } from 'react';
import PaymentView from '../../ServiceOrder/components/PaymentView';
import { formatCurrencyBRL } from '../../../utils/utils';
import ServiceBanner from '../../ServiceOrder/components/ServiceBanner';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import Header from '../../../components/Header';
import LoaderSpinner from '../../../components/LoaderSpinner';
import ModalMoreInformation from '../../../components/Modals/ModalMoreInformation';
import TabButton from '../../../components/TabButton';
import Footer from '../../../components/Footer';
import { postSignatureContract } from '../../../api/requests/orders/signature/signaturePlanApi';

const SignatureCheckout = ({ location }) => {
    const navigation = useHistory();
    const { signaturePlan } = location.state;
    const [modalVisible, setModalVisible] = useState(false);
    const [methodPayment, setMethodPayment] = useState({});
    const [loading, setLoading] = useState(false);

    const subscriptionPayment = async () => {
        try {
            setLoading(true);

            await postSignatureContract(signaturePlan.id, methodPayment.method, methodPayment.card);

            setLoading(false);

            navigation.push('/signatureCompletedOrder', { signaturePlan: signaturePlan });
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <Container>
            <Header screen="home" orderType="corporate" />
            <LoaderSpinner loading={loading} />

            <ModalMoreInformation
                showModal={modalVisible}
                setShowModal={val => setModalVisible(val)}
                title={signaturePlan?.name}
                body={signaturePlan?.description}
            />

            <div className="background-body">
                <div className="content-body-signaturePlan">
                    <ServiceBanner title="Assinaturas" bannerUrl={signaturePlan.header_url} />

                    <div className="signaturePlan-container">
                        <img src={signaturePlan.thumb_url} className="signaturePlan-image" alt='Nome do pacote' />

                        <div className="content-signaturePlan">
                            <span className="title-signaturePlan">{signaturePlan.name}</span>
                            <span className="description-signaturePlan">{signaturePlan.description}</span>

                            <button
                                type="button"
                                onClick={() => setModalVisible(true)}
                                className="button-more"
                            >
                                <span className="text-button-more">+ informações</span>
                            </button>
                        </div>

                        <div className="price-signaturePlan">
                            <span className="text-price-signaturePlan">R$ <span>{formatCurrencyBRL(signaturePlan.price).substring(2)}</span></span>
                        </div>
                    </div>

                    <div className="divisor-signaturePlan"></div>

                    <span className="total-signaturePlan">PAGAMENTO</span>

                    <PaymentView
                        signaturePlan={signaturePlan}
                        orderType={'corporate'}
                        hasDiscount={false}
                        setCheckedPaymentMethod={setMethodPayment}
                        checkedPaymentMethod={methodPayment}
                        setLoading={setLoading}
                    />

                    <button
                        type="button"
                        disabled={methodPayment.method ? false : true}
                        className={methodPayment.method ? "button-pay" : "button-pay-disabled"}
                        onClick={subscriptionPayment}
                    >
                        <span className="text-pay">concluir compra</span>
                    </button>
                </div>

                <div className="footer-service-list">
                    <div className="line-separator"></div>
                    <Footer />
                </div>
            </div>

            <TabButton screen="home" />
        </Container>
    );
}

export default SignatureCheckout;