import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TabButton from "../../../components/TabButton";
import {
  Container,
  NotFound,
  PageContainer,
} from "./styles";
import LoaderSpinner from "../../../components/LoaderSpinner";
import { getAllSubscriptionsPlans } from "../../../api/requests/orders/signature/signaturePlanApi";
import { toast } from "react-toastify";
import SubscriptionCard from "./components/SubscriptionCard";
import moment from "moment-timezone";
import ModalCancelSubscription from "./components/ModalCancelSubscription";

const Subscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([])
  const [showModalCancel, setShowModalCancel] = useState(false)
  const [selectedCard, setSelectedCard] = useState({})

  async function getAllSubscriptions() {
    await getAllSubscriptionsPlans()
      .then((res) => {
        const newItens = res.map(subscription => {
          return {
            ...subscription,
            paid_at: moment(subscription.paid_at)
              .format("DD/MM/YYYY - HH:mm:ss"),
          }
        })
        setSubscriptions(newItens)
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoading(true);
      getAllSubscriptions();
    }
    return () => (isMounted = false);
  }, [showModalCancel]);

  return (
    <Container>
      <LoaderSpinner loading={loading} />
      <Header screen="user" orderType="corporate" />
      <PageContainer>
        {!subscriptions.length ? (
          <NotFound>
            Nenhuma assinatura encontrada!
          </NotFound>
        ) : (
          subscriptions?.map((subscription) => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              setShowModalCancel={setShowModalCancel}
              setSelectedCard={setSelectedCard}
            />
          ))
        )}

        <ModalCancelSubscription
          showModalCancel={showModalCancel}
          setShowModalCancel={setShowModalCancel}
          subscription_name={selectedCard?.subscription_plan?.name}
          subscription_id={selectedCard?.id}
        />

        <div className="footer-service-list">
          <div className="line-separator"></div>
          <Footer />
        </div>
        <TabButton screen="account" />
      </PageContainer>
    </Container>
  );
};

export default Subscriptions;
