import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import orangeArrowLeftDates from '../../../../../assets/images/icons/orangeArrowLeftDates.svg';
import orangeArrowRightDates from '../../../../../assets/images/icons/orangeArrowRightDates.svg';

import { DateButton } from '../../../../ServiceOrder/components/DateButton';

import { ButtonOpacity } from '../components/ButtonOpacity';
import { TableSchedulePositions } from '../components/TableSchedulePositions';
import Footer from '../../../../../components/Footer';
import LoaderSpinner from '../../../../../components/LoaderSpinner';
import { PageInfoWhatToDo } from '../components/PageInfoWhatToDo';
import SlotRangeSchedule from '../components/SlotRangeSchedule';
import Header from '../../../../../components/Header';
import TabButton from '../../../../../components/TabButton';
import SchedulePlaceBanner from '../SchedulePlaceBanner';
import ModalConfirmation from '../../../../../components/Modals/ModalConfirmation';
import ModalMapa from '../../../../../components/Modals/ModalMapa';
import { FilterInput } from '../components/FilterInput';

import {
  getDates,
  getPlaces,
  getPositions,
  getPositionTypes,
  getTimes,
  getSchedules,
} from '../../../../../api/schedule_place';
import { getUser } from '../../../../../utils/auth';
import { addDaysToCurrentDate } from '../../../../../utils/utils';

import {
  Container,
  Text,
  ContainerNoFlex,
  ContainerDate,
  DateArrowLeft,
  DateArrowRight,
  DatesView,
  ContainerHour,
  ViewBox,
  ButtonImg,
  ButtonView,
  ButtonText,
  TypesView,
  ButtonCheck,
  TextCheck,
  NotFound,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableData,
} from './styles';
import { InputType } from '../components/InputType';
import { TextAreaDescription } from '../components/TextAreaDescription';
import moment from 'moment';
import { t } from "i18next";


export const SchedulePosition = () => {
  const history = useHistory();

  if (!getUser()?.company?.position_scheduling_enabled) {
    history.replace('/home');
  }

  const [positions, setPositions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [allDates, setAllDates] = useState([]);

  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState('');
  const [build, setBuild] = useState('');
  const [type, setType] = useState('');
  const [showMap, setShowMap] = useState();
  const [date, setDate] = useState();
  const [hourFrom, setHourFrom] = useState();
  const [hourTo, setHourTo] = useState();

  const [building, setBuilding] = useState([]);
  const [types, setTypes] = useState([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [times, setTimes] = useState();
  const [schedules, setSchedules] = useState([]);

  const inputFilterRef = useRef(null);
  const inputDescription = useRef(null);
  const textNotes = useRef(null);
  const [filteredPerson, setFilteredPerson] = useState('');
  const [isPositionsFiltered, setIsPositionsFiltered] = useState(false);

  const refDate = useRef(null);
  const refHour = useRef(null);
  const refTypePosition = useRef(null);
  const refPosition = useRef(null);

  const doGetBuildings = async isMounted => {
    setLoading(true);
    await getPlaces()
      .then(places => {
        if (isMounted) {
          setBuilding(places);
          if (places.length === 1) {
            setBuild(places[0]?.id);
          }
        }
      })
      .catch(error => toast.error(error.message));
    setLoading(false);
  };

  const doGetDates = async isMounted => {
    setLoading(true);
    await getDates(build)
      .then(dates => {
        if (isMounted) {
          setAllDates(dates);
          setDate(dates[0]?.date);
        }
      })
      .catch(error => toast.error(error.message));
    setLoading(false);
  };

  const doGetTimes = async isMounted => {
    setLoading(true);
    await getTimes(build, date)
      .then(timesApi => {
        if (isMounted) {
          if (timesApi.times.length === 0) {
            throw new Error("Você já passou do horário para hoje")
          }
          setTimes(timesApi);
          const timeNow = timesApi?.times[0]?.time;
          const timeLast = timesApi?.times[timesApi.times.length - 1]?.time;
          const minHourDefault = "08:00";
          const maxHourDefault = "17:00";
          const hourFromWithInterval = moment(timeLast, 'hh:mm').add(timesApi.interval, 'minutes').format('HH:mm');

          setHourFrom(timeNow > minHourDefault ? timeNow : minHourDefault);
          if (timeLast > maxHourDefault) {
            setHourTo(maxHourDefault)
            return;
          }
          setHourTo(hourFromWithInterval)
        }
      })
      .catch(error => {
        setTimes("");
        toast.error(error.message)
      });
    setLoading(false);
  };

  const doGetTypes = async isMounted => {
    const offset = (moment().utcOffset() / 60);

    const splitedFrom = hourFrom.split(':');
    const splitedTo = hourTo.split(':');
    const hoursFrom = (Number(splitedFrom[0]) - offset) * 60 * 60 * 1000;
    const minutesFrom = Number(splitedFrom[1]) * 60 * 1000;
    const hoursTo = (Number(splitedTo[0]) - offset) * 60 * 60 * 1000;
    const minutesTo = Number(splitedTo[1]) * 60 * 1000;
    const UTCDateFrom = new Date(
      Date.parse(date) + (hoursFrom + minutesFrom),
    ).toISOString();
    const UTCDateTo = new Date(
      Date.parse(date) + (hoursTo + minutesTo),
    ).toISOString();
    setDateFrom(UTCDateFrom);
    setDateTo(UTCDateTo);
    setLoading(true);
    await getPositionTypes(build)
      .then(typesIn => (isMounted ? setTypes(typesIn) : null))
      .catch(error => toast.error(error.message));
    setLoading(false);
  };

  const doGetPositions = async isMounted => {
    setLoading(true);
    await getPositions(build, type.code, dateFrom, dateTo, filteredPerson)
      .then(positionsIn => (isMounted ? setPositions(positionsIn) : null))
      .catch(error => toast.error(error.message));
    setLoading(false);
  };

  const doGetSchedules = async (positionId) => {
    const initialDate = moment.utc(dateFrom).startOf('day').format();
    const nextDayFromDateTo = addDaysToCurrentDate(dateTo, 1);
    const finalDate = moment.utc(nextDayFromDateTo).startOf('day').format();
    setLoading(true);

    await getSchedules(initialDate, finalDate, positionId)
      .then(schedules => (setSchedules(schedules)))
      .catch(error => toast.error(error.message))
      .finally(setLoading(false));
  };

  useEffect(() => {
    refDate?.current?.scrollIntoView();
    if (date) {
      setType('');
      setPosition('');
      setHourTo('');
      setHourFrom('');
      setSchedules([]);
    }
  }, [build]);

  useEffect(() => {
    refHour?.current?.scrollIntoView();
    if (build) {
      setHourFrom(0);
      setHourTo(0);
      setType('');
      setPosition('');
      setSchedules([]);
    }
  }, [date]);

  useEffect(() => {
    refTypePosition?.current?.scrollIntoView();
    if (type) {
      setType('');
      setPosition('');
      setSchedules([]);
    }
  }, [hourTo, hourFrom]);

  useEffect(() => {
    refPosition?.current?.scrollIntoView();
    if (position) {
      setPosition('');
      setSchedules([]);
    }
  }, [type]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      doGetBuildings(isMounted);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && building && build) {
      setDate();
      setTimes("");
      doGetDates(isMounted);
    }
    return () => {
      isMounted = false;
    };
  }, [build, building]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && date) {
      doGetTimes(isMounted);
    }
    return () => {
      isMounted = false;
    };
  }, [date]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && hourTo && hourFrom) {
      doGetTypes(isMounted);
    }
    return () => {
      isMounted = false;
    };
  }, [hourTo, hourFrom]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (type) {
        doGetPositions(isMounted);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [type, filteredPerson]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (type) {
        setFilteredPerson('');
        inputFilterRef.current.value = '';
        setIsPositionsFiltered(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [hourTo, hourFrom, type, date, build]);

  const disableSchedule = () => {
    let shouldDisable = false;
    schedules.forEach(schedule => {
      if (schedule.date_from === dateFrom || schedule.date_to === dateTo)
      shouldDisable = true;
    });
    return shouldDisable;
  };

  return (
    <>
      <Header screen="home" isNotSchedule={false} />
      <LoaderSpinner loading={loading} />
      {showModal && (
        <ModalConfirmation
          setShow={val => setShowModal(val)}
          setOption={op => op}
          buildingId={build}
          positionType={type.code}
          positionId={position.id}
          dateFrom={dateFrom}
          dateTo={dateTo}
          type={type.code}
          date={date}
          hourFrom={hourFrom}
          hourTo={hourTo}
          position={position.description}
          mode="agendamento"
          positionCode={position.code}
          description={inputDescription?.current?.value}
          note={textNotes?.current?.value}
        />
      )}

      {showMap && (
        <ModalMapa
          setShow={val => setShowMap(val)}
          buildingId={build}
          positionType={type.code}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setPosition={(position) => {
            setPosition(position)
            doGetSchedules(position.id)
          }}
        />
      )}

      <SchedulePlaceBanner
        bannerUrl="https://posher.s3.sa-east-1.amazonaws.com/production/company_places/images/banner_checkin.jpg"
        isUrl
        title={t("schedule")}
        serviceType="presential"
      />

      <Container className="background-body">
        {building.length === 0 ? (
          <span className="notFound">Nenhuma data disponível.</span>
        ) : (
          <ContainerNoFlex>
            <PageInfoWhatToDo>{t("scheduleYourPlace")}</PageInfoWhatToDo>
            <Text>{t("selectABuilding")}</Text>
            <TypesView>
              {building.map(buildValue => {
                return (
                  <ViewBox key={buildValue.id}>
                    <ButtonOpacity
                      onClick={() => {
                        setBuild(buildValue.id);
                      }}
                    >
                      <ButtonImg
                        src={buildValue.thumb_url}
                        label={buildValue.id}
                        type={build}
                      />
                      <ButtonView>
                        <ButtonText
                          label={buildValue.id}
                          type={build}
                          numberOfLines={3}
                          style={{ wordBreak: 'break-word' }}
                        >
                          {buildValue.description}
                        </ButtonText>
                      </ButtonView>
                    </ButtonOpacity>
                  </ViewBox>
                );
              })}
            </TypesView>
          </ContainerNoFlex>
        )}

        {build !== '' && (
          <ContainerNoFlex ref={refDate}>
            <Text>{t("selectADate")}</Text>
            <ContainerDate>
              <DateArrowLeft src={orangeArrowLeftDates} />

              <DatesView>
                {allDates.map(dateValue => {
                  return (
                    <div key={dateValue.id} style={{ flexDirection: 'row' }}>
                      <DateButton
                        date={dateValue.date}
                        selected={dateValue.date === date}
                        doSelectDate={() => setDate(dateValue.date)}
                      />
                    </div>
                  );
                })}
              </DatesView>
              <DateArrowRight src={orangeArrowRightDates} />
            </ContainerDate>
          </ContainerNoFlex>
        )}

        {date && times && (
          <ContainerNoFlex ref={refHour}>
            <Text>{t("selectATime")}</Text>
            <ContainerHour>
              <SlotRangeSchedule
                selectedFrom={hourFrom}
                setSelectedFrom={setHourFrom}
                selectedTo={hourTo}
                setSelectedTo={setHourTo}
                selectedDate={date}
                times={times}
              />
            </ContainerHour>
          </ContainerNoFlex>
        )}
        {hourTo && hourFrom && (
          <ContainerNoFlex ref={refTypePosition}>
            {types.length === 0 ?
              (<NotFound className="notFoundDark">Nenhum tipo disponível.</NotFound>)
              : (
                <><Text>{t("selectAType")}</Text>
                  <TypesView>
                    {types.map(typeValue => {
                      return (
                        <ViewBox key={typeValue.id}>
                          <ButtonOpacity
                            onClick={() => {
                              setType(typeValue);
                            }}
                          >
                            <ButtonImg
                              src={typeValue.thumb_url}
                              label={typeValue.code}
                              type={type}
                            />
                            <ButtonView>
                              <ButtonText
                                label={typeValue.code}
                                type={type}
                                numberOfLines={3}
                              >
                                {typeValue.description}
                              </ButtonText>
                            </ButtonView>
                          </ButtonOpacity>
                        </ViewBox>
                      );
                    })}
                  </TypesView>
                </>
              )
            }

          </ContainerNoFlex>
        )}

        {type !== '' && (
          <>
            <ContainerNoFlex ref={refPosition}>
              <ButtonOpacity>
                <ButtonCheck onClick={() => setShowMap(true)}>
                  <TextCheck>{t("showMap")}</TextCheck>
                </ButtonCheck>
              </ButtonOpacity>

              <Text>
                {type.code === 'parking_space'
                  ? t("selectAVacancy")
                  : type.code === 'room'
                    ? t("selectARoom")
                    : t("selectAPosition")}
              </Text>

              <FilterInput
                inputFilterRef={inputFilterRef}
                setFilteredPerson={setFilteredPerson}
                setIsPositionsFiltered={setIsPositionsFiltered}
              />

              {position && <Text>{t("selectedText")} {t("positionWord")} : {position.code}</Text>}

              <TableSchedulePositions
                positions={positions}
                setPosition={(position) => {
                  setPosition(position)
                  doGetSchedules(position.id)
                }}
                position={position}
                isPositionsFiltered={isPositionsFiltered}
              />
            </ContainerNoFlex>
          </>
        )}
        {
          schedules.length > 0 && (
            <>
              <Text>{t("scheduledTimes")}</Text>
              <Table>
                <TableBody>
                { schedules.map(schedule => {
                  return (
                    <TableRow>
                      <TableData>
                        {schedule.customer_name}
                      </TableData>
                      <TableData>
                        {`${t("fromTimePicker")} ${moment(schedule.date_from).local().format('HH:mm')}`}
                      </TableData>
                      <TableData>
                        {`${t("toTimePicker")} ${moment(schedule.date_to).local().format('HH:mm')}`}
                        </TableData>
                    </TableRow>
                  )
                })}
                </TableBody>
              </Table>
            </>
        )}
        {position !== '' && (
          <>
            {type.description_allowed && (
              <InputType inputRef={inputDescription} />
            )}
            {type.note_allowed && <TextAreaDescription textNotes={textNotes} />}
          </>
        )}
        {position !== '' && (
          <ButtonOpacity>
            <ButtonCheck onClick={() => setShowModal(!showModal)} disabled={disableSchedule()}>
              <TextCheck>{t("scheduleButton")}</TextCheck>
            </ButtonCheck>
          </ButtonOpacity>
        )}

        <div className="footer-service-list">
          <div className="line-separator" />
          <Footer />
        </div>
      </Container>

      <TabButton screen="home" />
    </>
  );
};
