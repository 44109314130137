import React, { useState } from 'react';
import './styles.css';
import Modal from 'react-modal';
import { schedulePosition } from '../../../api/schedule_place';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { deleteCheckin, putCheckin, putCheckout } from '../../../api/check';
import LoaderSpinner from '../../LoaderSpinner';
import {t} from 'i18next'

const ModalConfirmation = ({ setShow, setOption, buildingId, positionType, positionId, dateFrom, dateTo, type, date, hourFrom, hourTo, position, mode, schedule_id, positionCode, description, note }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(true);
    const [loading, setLoading] = useState(false);
    
    const closeModal = (option) => {
        setOption(option);
        setShow(false);
        setShowModal(false);
    }
    
    const handleSchedule = async () => {
        setLoading(true);
        if (mode === 'agendamento') {
            await schedulePosition(buildingId, positionType, positionId, dateFrom, dateTo, description, note)
                .then(() => {
                    setLoading(false)
                    const data = {
                        building: buildingId,
                        type: type,
                        date: date,
                        hourFrom: hourFrom,
                        hourTo: hourTo,
                        position: position,
                        positionCode: positionCode
                    };
                    history.push('/resume', { data: data });
                })
                .catch((err) => {
                    toast.error(err.message);
                }).finally(() => {
                    setLoading(false)
                    closeModal(true);
                })
        } else if (mode === 'check') {
            await putCheckin(schedule_id)
                .then(() => {
                    toast.success('check-in feito com sucesso!', { duration: 5000 });
                    history.replace('/home');
                })
                .catch((error) => {
                    toast.error(error.message);
                }).finally(() => {
                    closeModal(true);
                })
        } else if (mode === 'cancel') {
            await deleteCheckin(schedule_id)
                .then(() => {
                    toast.success('cancelamento feito com sucesso!', { duration: 5000 });
                    history.replace('/checkin');
                })
                .catch((err) => {
                    toast.error(err.message);
                }).finally(() => {
                    closeModal(true);
                })
        } else {
            await putCheckout(schedule_id)
                .then(() => {
                    toast.success('check-out feito com sucesso!', { duration: 5000 });
                    history.replace('/home');
                })
                .catch((err) => {
                    toast.error(err.message);
                }).finally(() => {
                    closeModal(true);
                })
        }
        setLoading(false);
    }

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => closeModal(false)}
            overlayClassName="modal-overlay"
            className="modal-content confirmation"
            ariaHideApp={false}
        >

        <LoaderSpinner loading={loading}/>

            <button className="x-to-close" type="button" onClick={() => closeModal(false)}>
                <span>x</span>
            </button>

            <div className="header-modal">
                <span className="subtitle-header">{t("confirmWord")}</span>
                <span className="title-header">{mode === 'check' ? t("checkInButtonTitle") : mode === 'checkout' ? t("checkOutButtonTitle") : mode === 'cancel' ? t("cancellation") : t("schedule")}</span>
            </div>

            <div className="content-body">
                <span>{t("makeScheduleConfirm")} {mode === 'check' ?  t("checkInButtonTitle").toLowerCase() : mode === 'checkout' ? t("checkOutButtonTitle") : mode === 'cancel' ? t("cancellation").toLowerCase() : t("schedule")} {t("atWord")} {positionType === 'parking_space' ? t("vacancyWord") : positionType === 'room' ? t("roomWord") : t("positionWord")} {positionCode}?</span>
            </div>

            <div className="container-buttons">
                <button disabled={loading} className="accept-button" type="type" onClick={handleSchedule}>
                    <span>{t("yesWord")}, {t("confirmWord")}</span>
                </button>
                <button className="cancel-button" type="button" onClick={() => closeModal(false)}>
                    <span>{t("noWord")}</span>
                </button>
            </div>
        </Modal>
    )
}

export default ModalConfirmation;