import React, { useEffect, useState } from 'react';
import { getDiscountCoupons, getDiscountCouponsByCode } from '../../../../../../api/discount_coupons';
import ModalCouponDiscount from '../../../../../../components/Modals/ModalCouponDiscount';
import { toast } from 'react-toastify';
import { FiTag } from 'react-icons/fi';
import { Container } from './styles';

const AddDiscountCouponButton = (props) => {
    const setSelectedDiscountCoupon = props.setSelectedDiscountCoupon;
    const [modalVisible, setModalVisible] = useState(false);
    const [discountCouponCode, setDiscountCouponCode] = useState("");
    const [allDiscountCoupons, setAllDiscountCoupons] = useState([]);
    const [checked, setChecked] = useState({});
    const orderDate = props.orderDate;

    const searchDiscountCoupons = async () => {
        try {
            await getDiscountCoupons(setAllDiscountCoupons, orderDate);
        } catch (error) {
            toast.error(error.message);
            setAllDiscountCoupons([]);
        }
    }

    const addDiscountCoupon = async () => {
        try {
            let couponCode = "";

            if (discountCouponCode === "" && checked.code) {
                couponCode = checked.code;
            } else {
                couponCode = discountCouponCode;
            }

            const coupon = await getDiscountCouponsByCode(couponCode, orderDate);

            setSelectedDiscountCoupon(coupon);

        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            searchDiscountCoupons();
        }

        return () => { isMounted = false; };
    }, []);

    return (
        <Container>
            <button 
                type="button" 
                className="payment-cupon-button" 
                onClick={() => {
                    setDiscountCouponCode('');
                    setChecked({});
                    setModalVisible(true);
                }}
            >
                <FiTag size={15} color="#FFF" />
                <span>adicionar cupom</span>
            </button>

            <ModalCouponDiscount
                setShowModalDiscount={val => setModalVisible(val)}
                allDiscountCoupons={allDiscountCoupons}
                showModalDiscount={modalVisible}
                setChecked={val => setChecked(val)}
                checked={checked} 
                setDiscountCouponCode={setDiscountCouponCode}
                discountCouponCode={discountCouponCode}
                clicked={value => value && addDiscountCoupon()}
            />
        </Container>
    )
}

export default AddDiscountCouponButton;