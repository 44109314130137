import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalCancelOrder = ({ showModalCancel, setShowModalCancel, code, clicked }) => {
    const doCloseModal = (option) => {
        clicked(option);
        setShowModalCancel(false);
    }

    return (
        <div>
            <Modal
                isOpen={showModalCancel}
                onRequestClose={() => doCloseModal(false)}
                overlayClassName="modal-overlay"
                className="modal-content cancel-order"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">confirmar</span>
                    <span className="title-header">cancelamento</span>
                </div>

                <div className="content-body">
                    <span>Confirmar cancelamento do pedido Nº {code}?</span>
                </div>

                <div className="container-buttons">
                    <button className="accept-button" type="type" onClick={() => doCloseModal(true)}>
                        <span>sim, confirmar</span>
                    </button>
                    <button className="cancel-button" type="button" onClick={() => doCloseModal(false)}>
                        <span>não</span>
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalCancelOrder;