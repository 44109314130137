import { header, fetchWithTimeout } from "./helper/request";
import {
  ACCEPT,
  ACTIVE,
  API_BASE,
  API_VERSION,
  CUSTOMERS,
  CUSTOMER_MESSAGES_NEXT,
  MESSAGES,
} from "./helper/api_urls";
import { getSectionsCatchErrors } from "./getSectionsCatchErrors";
import { validatePutResponse } from "./helper/validatePutResponse";

const getMessagesNext = async (callback) => {
  try {
    const { get_bearer } = header();
    const response = await fetchWithTimeout(
      `${API_BASE}${API_VERSION}${CUSTOMER_MESSAGES_NEXT}`,
      get_bearer
    );

    if (response.ok) {
      const responseJson = response.data;

      callback(responseJson);
    } else if (response.status === 404) {
      callback(null);
    } else {
      throw new Error("erro na chamada getMessagesNext: " + response.status);
    }
  } catch (error) {
    getSectionsCatchErrors(
      error,
      "Erro ao realizar consulta de proximas mensagens"
    );
  }
};

const getMessagesViewed = async () => {
  try {
    const { get_bearer } = header();
    const response = await fetchWithTimeout(
      `${API_BASE}${API_VERSION}${CUSTOMERS}${MESSAGES}${ACTIVE}`,
      get_bearer
    );

    if (response.ok) {
      const responseJson = response.data;
      return responseJson;
    } else if (response.status === 404) {
      return null;
    } else {
      throw new Error("erro na chamada getMessagesViewed: " + response.status);
    }
  } catch (error) {
    getSectionsCatchErrors(
      error,
      "Erro ao realizar consulta de mensagens lidas"
    );
  }
};

const putAcceptMessage = async (messageId) => {
  try {
    const putUrl = `${API_BASE}${API_VERSION}${CUSTOMERS}${MESSAGES}/${messageId}${ACCEPT}`;

    return await validatePutResponse(
      putUrl,
      "Erro ao realizar aceite da mensagem"
    );
  } catch (error) {
    throw error;
  }
}

export { getMessagesNext, getMessagesViewed, putAcceptMessage };
