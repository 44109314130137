import styled from "styled-components";

export const ContainerButtonInfo = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  border-radius: 18px;
  width: 800px;
  padding-bottom: 0px;
  margin-bottom: 20px;

  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  transition: ease-in 0.2s;
  height: 4rem;

  position: relative;

  @media (max-width: 820px) {
    width: 90vw;
  }
  @media (max-width: 760px) {
    height: 9rem;
  }
`;
