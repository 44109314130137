import styled from 'styled-components';

export const Container = styled.div`
    .carousel {
        max-width: 600px;
    }

    .button-banner {
        border-radius: 10px;
    }

    .button-banner > img {
        height: 250px;
        border-radius: 10px;
    }

    .carousel .control-dots .dot {
        background: #000;
        box-shadow: none;
    }

    @media(max-width: 780px) {
        .carousel {
            max-width: 550px;
        }

        .button-banner > img {
            height: 250px;
        }
    }

    @media(max-width: 610px) {
        .carousel {
            max-width: 500px;
        }

        .button-banner > img {
            height: 200px;
        }
    }

    @media(max-width: 520px) {
        .carousel {
            max-width: 450px;
        }

        .button-banner > img {
            height: 200px;
        }
    }

    @media(max-width: 470px) {
        .carousel {
            max-width: 400px;
        }

        .button-banner > img {
            height: 180px;
        }

        .carousel .control-dots .dot {
            width: 6px;
            height: 6px;
            margin: 0 6px -5px;
        }
    }

    @media(max-width: 410px) {
        .carousel {
            max-width: 300px;
        }

        .button-banner > img {
            height: 140px;
        }

        .carousel .control-dots .dot {
            width: 4px;
            height: 4px;
            margin: 0 4px -5px;
        }
    }

    @media(max-width: 320px) {
        .carousel {
            max-width: 280px;
        }

        .button-banner > img {
            height: 130px;
        }

        .carousel .control-dots .dot {
            width: 4px;
            height: 4px;
            margin: 0 4px -5px;
        }
    }
`;