import Moment from "moment-timezone";
import React, { useEffect, useState } from 'react';
import { FiCalendar, FiCheck, FiClock, FiMail, FiSend } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { getOneUseTokenOrder, postOneUseTokenOrder } from "../../../../api/one_use_token";
import HeaderSimple from "../../../../components/HeaderSimple";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import { getDayName } from '../../../../utils/utils';
import { AndNowBanner, BackgroundImage, InfoView } from './styles';


function useQuery() {
  let { search } = useLocation();

  search = search.replace('%26', '&');

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OneUseTokenOrder = () => {

  let query = useQuery();
  const token = query.get("token")
  const email = query.get("email")
  const [loading, setLoading] = useState(false)
  const [orderContent, setOrderContent] = useState([])
  const [dayOfWeek, setDayOfWeek] = useState('')
  const [time, setTime] = useState('')
  const [minute, setMinute] = useState('')
  const [waitingList, setWaitingList] = useState('')
  const [attendanceLocale, setAttendanceLocale] = useState('')
  const [isOrderCreated, setIsOrderCreated] = useState(false)
  const navigation = useHistory();

  const doOneUseTokenOrder = async () => {

    try {
      setLoading(true);

      let getResponse = await doGetOrder()
      let postResponse = await doCreateOrder(getResponse)

      if (postResponse.id) {
        setAttendanceLocale(postResponse.attendance_locale)
        setIsOrderCreated(true)
      }

      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
      navigation.push("/home");
    }
  }

  const doGetOrder = async () => {
    const orderData = await getOneUseTokenOrder(token, email);
    setOrderContent(orderData?.content)
    doFormatOrderContent(orderData?.content)
    return orderData
  }

  const doCreateOrder = async (orderData) => {
    const data = {
      hourBegin: orderData?.content?.slots[0].begin_at,
      jobDayId: orderData?.content?.job_day_id,
      paymentMethod: "cash",
      serviceId: orderData?.content?.service_offer?.service_id,
      token: orderData?.token,
      orderType: orderData?.content?.service_offer?.order_type
    }
    const response = await postOneUseTokenOrder(data)

    return response
  }

  const doFormatOrderContent = (orderContent) => {
    setTime(Moment(orderContent.slots[0].begin_at).tz("America/Sao_Paulo").format("HH"));
    setMinute(Moment(orderContent.slots[0].begin_at).tz("America/Sao_Paulo").format("mm"));
    setDayOfWeek(getDayName(orderContent.slots[0].begin_at));
    setWaitingList(time === "00" && minute === "00");
  }

  useEffect(() => {
    doOneUseTokenOrder()
  }, [])


  return (

    <>
      <LoaderSpinner loading={loading} />
      <HeaderSimple></HeaderSimple>
      {isOrderCreated &&
        <BackgroundImage>
          <div className="background-body">
            <div>
              <div className="green-check-view">
                <FiCheck color="#fff" size={70} />
              </div>

              <span className="text-ok">Tudo certo!</span>
            </div>



            {("online" === orderContent?.service_offer?.service_type) && (
                <div className="info-tags">
                  <InfoView>
                    <span className="info-text">
                      Sua solicitação para participar da {orderContent?.service_offer?.title} foi confirmada para {dayOfWeek} {Moment(orderContent?.slots[0].begin_at).tz("America/Sao_Paulo").format(" DD/MM")}

                      {!waitingList &&
                        <span>
                          {' '} às {Moment(orderContent?.slots[0].begin_at).tz("America/Sao_Paulo").format("HH:mm")}.
                        </span>
                      }

                      {waitingList &&
                        <span> para a lista de encaixe.</span>
                      }

                    </span>
                  </InfoView>

                  <AndNowBanner>
                    <div className="and-now-banner">
                      <span className="and-now-text">Olha como é fácil acessar seu serviço:</span>
                    </div>
                  </AndNowBanner>

                  <div className="one-two-banner">
                    <span className="one-two-number-text">1</span>

                    <div className="info-one-two-text">
                      <div className="mail-clock-view">
                        <FiSend color="#000" size={15} />
                      </div>
                      <span className="one-two-text">
                        Geramos um link para sua sessão.
                      </span>
                    </div>

                  </div>

                  <div className="one-two-banner">
                    <span className="one-two-number-text">2</span>

                    <div className="info-one-two-text">
                      <div className="mail-clock-view">
                        <FiCalendar color="#000" size={16} />
                      </div>
                      <span className="one-two-text">
                        Esse link já está disponível no seu email, calendário e no seu app POSHER
                      </span>
                    </div>

                  </div>

                  <div className="one-two-banner">
                    <span className="one-two-number-text">3</span>
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={16} />
                    </div>
                    <span className="one-two-text">
                      No horário da sessão, clique no link em um desses locais e boa sessão!
                    </span>
                  </div>
                </div>
              )}

            {("presential" === orderContent?.service_offer?.service_type || "both" === orderContent?.service_offer?.service_type) && (
              <div className="info-tags">
                <InfoView>
                  <span className="info-text">
                    Seu pedido de <span>{orderContent?.service_offer?.title}</span>foi confirmado para <span>{dayOfWeek} {Moment(orderContent?.slots[0].begin_at).tz("America/Sao_Paulo").format(" DD/MM")}</span>

                    {!waitingList && (
                      <span> às {Moment(orderContent?.slots[0].begin_at).tz("America/Sao_Paulo").format("HH:mm")}.</span>
                    )}

                    {waitingList && (
                      <span> para a lista de encaixe.</span>
                    )}

                  </span>
                </InfoView>



                <AndNowBanner>
                  <div className="and-now-banner">
                    <span className="and-now-text">E AGORA?</span>
                  </div>
                </AndNowBanner>

                <div className="one-two-banner">
                  <span className="one-two-number-text">1</span>
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiMail color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Enviamos todas as informações do agendamento para seu email.
                    </span>
                  </div>

                </div>

                <div className="one-two-banner">
                  <span className="one-two-number-text">2</span>
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      No horário combinado se dirija ao local do serviço {attendanceLocale}. O profissional estará te aguardando
                    </span>
                  </div>
                </div>

              </div>
            )}
          </div>
        </BackgroundImage>
      }
    </>
  )
}

export default OneUseTokenOrder