import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import moment from "moment";
import { registerVaccination } from "../../../../api/vaccinations";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import TableTypes from "../components/TableTypes";
import {
    ModalContainer,
    HeaderContainer,
    HeaderModal,
    HeaderTitle,
    HeaderSubtitle,
    BodyModal,
    ContainerFooterModal,
    CloseModal,
    SendModal,
    Input,
    FileContainer,
    InputFile,
    Warning,
} from "./styles";
import "./style.css";
import { uploadImage } from "../utils/convertBase64";
import { Label } from "../styles";

const ModalNewVaccination = ({
    showVaccinationModal,
    setShowVaccinationModal,
}) => {
    const [loading, setLoading] = useState(false);
    const [vaccinationDate, setVaccinationDate] = useState();
    const [type, setType] = useState({});
    const [types, setTypes] = useState([]);
    const [pictureFileContent, setPictureFileContent] = useState(null);
    const [sendFileOpen, setSendFileOpen] = useState(true);

    const handleCreateVaccination = async () => {
        setLoading(true);
        const date = moment(vaccinationDate).tz("UTC").format("YYYY-MM-DD");
        await registerVaccination(type.id, date, pictureFileContent)
            .then(() => {
                toast.success("Vacina registrada!");
                setShowVaccinationModal(false);
            })
            .catch((err) => toast.error(err.message))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <ModalContainer>
                <Modal
                    isOpen={showVaccinationModal}
                    onRequestClose={() =>
                        setShowVaccinationModal(!showVaccinationModal)
                    }
                    className="vaccination"
                    overlayClassName="modal-overlay"
                    ariaHideApp={false}
                >
                    <HeaderContainer>
                        <HeaderModal>
                            <HeaderTitle>adicionar</HeaderTitle>
                            <HeaderSubtitle>VACINA</HeaderSubtitle>
                        </HeaderModal>
                    </HeaderContainer>

                    <LoaderSpinner loading={loading} />
                    <BodyModal>
                        <Label>Data de vacinação:</Label>
                        <Input
                            min="2021-01-01"
                            type="date"
                            placeholder="data de nascimento"
                            value={vaccinationDate}
                            onChange={(e) => setVaccinationDate(e.target.value)}
                        />
                        <Label>Tipo de vacinação:</Label>
                        <TableTypes
                            types={types}
                            setTypes={setTypes}
                            type={type}
                            setType={setType}
                        />

                        <Label>Foto do comprovante:</Label>
                        {sendFileOpen && !pictureFileContent && (
                            <Warning>foto necessária</Warning>
                        )}
                        <FileContainer>
                            <label for="archive">
                                {pictureFileContent
                                    ? "enviar outra foto"
                                    : "enviar foto"}
                            </label>
                            <InputFile
                                name="archive"
                                type="file"
                                id="archive"
                                onChange={(e) => {
                                    uploadImage(
                                        e,
                                        setPictureFileContent,
                                        setSendFileOpen
                                    );
                                }}
                            />
                        </FileContainer>
                    </BodyModal>

                    <ContainerFooterModal>
                        <SendModal
                            onClick={handleCreateVaccination}
                            disabled={
                                !type ||
                                !vaccinationDate ||
                                !pictureFileContent ||
                                loading
                            }
                        >
                            enviar
                        </SendModal>
                        <CloseModal
                            onClick={() =>
                                setShowVaccinationModal(!showVaccinationModal)
                            }
                        >
                            fechar
                        </CloseModal>
                    </ContainerFooterModal>
                </Modal>
            </ModalContainer>
        </>
    );
};

export default ModalNewVaccination;
