import React from 'react';
import CookieConsent from "react-cookie-consent";

const AcceptCookies = () => {

    return (
        <CookieConsent
            location="bottom"
            overlay = {false}
            buttonText="Aceitar Cookies"
            style={{
                background: "#FA9F2A",
            }}
            buttonStyle={{
                background: "#f7f7f7",
                color: "#FA9F2A",
                fontWeight: "bolder",
            }}
        >
            Este site utiliza cookies para uma melhor experiência.{" "}
            <span> <a style={{ fontSize: "10px", color: "#f7f7f7" }} href="/privacyPolicy" > Política de privacidade</a ></span >
        </CookieConsent >
    );
}

export default AcceptCookies;