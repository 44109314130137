import styled from 'styled-components';

export const Container = styled.div`
    .background-body {
        padding-bottom: 150px;
    }

    .content-wallet {
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .balance {
        width: 800px;
        background-color: #FFFFFF;
        align-self: center;
        display: flex;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid #FA9F2A;
        margin: 50px 0 20px;
    }

    .balance-text {
        color: #FA9F2A;
        font-size: 1.2rem;
    }

    .balance-text:first-child {
        margin-right: 10px;
    }

    .table-container {
        margin-bottom: 20px;
    }

    .card {
        width: 800px;
        align-self: center;
        margin: 10px 0;
    }

    .card-top {
        background-color: #FFFFFF;
        border-radius: 18px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #CFCFCF;
    }

    .text-card {
        color: #707070;
        font-size: 1rem;
        font-weight: 500;
    }

    .text {
        color: #707070;
        font-size: 1rem;
        text-align: center;
    }

    .button-more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 10px;
    }

    .next-page-button {
        background-color: #FA9F2A;
        font-size: 1.2rem;
        width: 200px;
        padding: 5px 0;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }

        .background-body {
            padding-bottom: 70px;
        }
    }

    @media(max-width: 820px) {
        .balance,
        .card {
            width: 600px;
        }
    }

    @media(max-width: 640px) {
        .balance,
        .card {
            width: 500px;
        }

        .text-card {
            font-size: 0.9rem;
        }

        .balance-text {
            font-size: 1.1rem;
        }

        .text-card:nth-child(2) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 20px;
        }
    }

    @media(max-width: 520px) {
        .balance,
        .card {
            width: 400px;
        }

        .text-card {
            font-size: 0.85rem;
        }

        .balance-text {
            font-size: 1rem;
        }
    }

    @media(max-width: 425px) {
        .balance,
        .card {
            width: 300px;
        }

        .text-card {
            font-size: 0.85rem;
        }

        .balance-text {
            font-size: 1rem;
        }
    }
`;