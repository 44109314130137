import React, { useEffect, useState } from 'react';
import { getUser } from '../../../../utils/auth';
import SwitchSelector from "react-switch-selector";
import { Container } from "./styles";
import { t } from 'i18next';

const LocationSelector = ({ setLocation }) => {
  const [locations, setLocations] = useState([]);
  const [company, setCompany] = useState();
  const [ready, setReady] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setCompany(getUser()?.company);
    setReady(true);
  }, []);

  useEffect(() => {
    if (disable) {
      setTimeout(() => setDisable(false), 2000);
    }
  }, [disable]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && ready) {
      let tempLocations = [];

      if (company) {
        tempLocations.push({
          label: t("company"),
          value: "corporate",
        });
      }

      if (!company || company.accept_residential) {
        tempLocations.push({ 
          label: t("home"), 
          value: "residential",
        });
      }

      setLocations(tempLocations);
    }
  }, [ready, company]);

  return (
    <Container>
      {ready && locations && locations.length > 0 &&
        <div className="container-choose">
          <span className="choose-location-text">{t("viewServicesFor")}</span>
          <div className="choose-location-view">
            <SwitchSelector
              initialSelectedIndex={0}
              onChange={value => {
                setDisable(true); setLocation(value);
              }}
              disabled={disable}
              fontColor="#FA9F2A"
              selectedBackgroundColor="#FA9F2A"
              selectedFontColor="#FFF"
              backgroundColor="#FFF"
              buttonColor="#FA9F2A"
              borderColor="#FA9F2A"
              options={locations}
            />
          </div>
        </div>
      }
    </Container>
  );
};

export default LocationSelector;
