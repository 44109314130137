import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 20px;
    background: none;
    margin: 0 20px;

    .img-button img {
        width: 120px;
        height: 120px;
        border-radius: 15px;
        border: 1px solid #f2f2f2;
    }

    .info-button {
        margin: 0 15px;
        color: #14213D;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .name-button {
        text-transform: uppercase;
        font-weight: 700;
        color: #14213D;
        font-size: 0.813rem;
        margin: 10px 0 5px;
        text-align: center;
        height: 43px;
        font-size: 14px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 190px;
    }

    .more-info {
        display: flex;
        align-items: center;
        margin: 5px 0;
    }

    .more-info > span {
        margin-left: 2px;
    }

    .icon-button {
        position: absolute;
        right: 0;
    }

    .icon-button .button-orange{
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FA9F2A;
        width: 40px;
        height: 119px;
        border-radius: 0 18px 18px 0;
    }


    @media(max-width: 910px) {
        .img-button img {
            width: 100px;
            height: 100px;
        }

        .name-button {
            font-size: 0.75rem;
        }
    }

    @media(max-width: 640px) {
        .img-button img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }
    }

    @media(max-width: 425px) {
        .img-button img {
            width: 80px;
            height: 80px;
        }

    }

    @media(max-width: 320px) {
        .img-button img {
            width: 70px;
            height: 70px;
        }
    }
`;