import React, { useEffect, useState } from "react";
import { FiCheck, FiMail, FiClock, FiSend, FiCalendar, FiChevronLeft, FiAlertTriangle } from "react-icons/fi";
import AvailableNow from "../../components/AvailableNow";
import { getDayName } from "../../../../utils/utils";
import Moment from "moment-timezone";
import { InfoView, AndNowBanner, BackgroundImage } from './styles';
import Header from "../../../../components/Header";
import TabButton from "../../../../components/TabButton";
import { toast } from "react-toastify";
import { createOrder } from "../../../../api/service_orders";
import { useHistory } from "react-router-dom";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import { PixPage } from "../components/PixPage";
import ModalReview from "../../../../components/Modals/ModalReview";

const CompleteOrder = ({ location }) => {
  const history = useHistory();

  if (!location.state) {
    history.goBack();
  }

  const [loading, setLoading] = useState(false);
  const {
    state: {
      orderType,
      attendanceLocale,
      slot,
      hourBegin,
      offer,
      paymentMethod,
      paymentSignature,
      pixCode,
      paymentExpirationMinutes,
      nextSuggestion,
      serviceId,
      cardId,
      addressId,
      walletSelected,
      note,
      order
    } = {}
  } = location;

  let time = Moment(hourBegin).tz("America/Sao_Paulo").format("HH");
  let minute = Moment(hourBegin).tz("America/Sao_Paulo").format("mm");
  let dayOfWeek = getDayName(hourBegin);

  const waitingList = time === "00" && minute === "00";

  const createNewOrder = async () => {
    try {
      setLoading(true);

      const response = await createOrder(nextSuggestion?.hour_begin, nextSuggestion?.job_day_id, paymentMethod, paymentSignature, serviceId, cardId, null, addressId, orderType, walletSelected, note);

      setLoading(false);

      history.push("/completeOrder", {
        orderType: orderType,
        attendanceLocale: response.attendance_locale,
        slot: slot,
        hourBegin: nextSuggestion?.hour_begin,
        offer: offer,
        paymentMethod: paymentMethod,
        paymentSignature: paymentSignature,
        pixCode: response.pix_qr_code,
        paymentExpirationMinutes: response.payment_expiration_minutes,
        nextSuggestion: response?.next_date_suggestion,
        serviceId: serviceId,
        cardId: cardId,
        addressId: addressId,
        walletSelected: false,
        note: note
      });

    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  }

  useEffect(() => {
    if (nextSuggestion?.job_day_id) {
      sessionStorage.setItem('oneClick', JSON.stringify(location.state));
    } else {
      sessionStorage.removeItem('oneClick');
    }
  }, [nextSuggestion]);

  return (
    <div>
      <Header screen="home" orderType={orderType} />
      <LoaderSpinner loading={loading} />
      <BackgroundImage>
        <ModalReview/>
       
        {paymentMethod === "pix" ?
          <PixPage
            paymentExpirationMinutes={paymentExpirationMinutes}
            pixCode={pixCode}
            waitingList={waitingList}
            slot={slot}
            dayOfWeek={dayOfWeek}
            hourBegin={hourBegin}
            offer={offer}
            order={order}
          />
          :
          <div className="background-body">
            <div>
              <div className="green-check-view">
                <FiCheck color="#fff" size={70} />
              </div>

              <span className="text-ok">Tudo certo!</span>
            </div>



            {("online_group" === slot?.service_type ||
              "online_personal" === slot?.service_type) && (
                <div className="info-tags">
                  <InfoView>
                    <span className="info-text">
                      Sua solicitação para participar da {offer.title} foi confirmada para {dayOfWeek} {Moment(hourBegin).tz("America/Sao_Paulo").format(" DD/MM")}

                      {!waitingList &&
                        <span>
                          {' '} às {Moment(hourBegin).tz("America/Sao_Paulo").format("HH:mm")}.
                        </span>
                      }

                      {waitingList &&
                        <span> para a lista de encaixe.</span>
                      }

                    </span>
                  </InfoView>

                  <AndNowBanner>
                    <div className="and-now-banner">
                      <span className="and-now-text">Olha como é fácil acessar seu serviço:</span>
                    </div>
                  </AndNowBanner>

                  <div className="one-two-banner">
                    <span className="one-two-number-text">1</span>

                    <div className="info-one-two-text">
                      <div className="mail-clock-view">
                        <FiSend color="#000" size={15} />
                      </div>
                      <span className="one-two-text">
                        Geramos um link para sua sessão.
                      </span>
                    </div>

                  </div>

                  <div className="one-two-banner">
                    <span className="one-two-number-text">2</span>

                    <div className="info-one-two-text">
                      <div className="mail-clock-view">
                        <FiCalendar color="#000" size={16} />
                      </div>
                      <span className="one-two-text">
                        Esse link já está disponível no seu email, calendário e no seu app POSHER
                      </span>
                    </div>

                  </div>

                  <div className="one-two-banner">
                    <span className="one-two-number-text">3</span>
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={16} />
                    </div>
                    <span className="one-two-text">
                      No horário da sessão, clique no link em um desses locais e boa sessão!
                    </span>
                  </div>

                  {nextSuggestion?.job_day_id &&
                    <button className="one-click-banner" onClick={createNewOrder}>
                      <div className="icon-one-click">
                        <FiChevronLeft color='#FFF' size={30} />
                      </div>
                      <div className="mail-clock-view">
                        <FiCalendar color='#000' size={20} />
                      </div>
                      <span className="one-two-text">Clique aqui e agende o mesmo serviço para {getDayName(nextSuggestion.hour_begin)}, {Moment(nextSuggestion.hour_begin).tz("America/Sao_Paulo").format('DD/MM')}, às {Moment(nextSuggestion.hour_begin).tz("America/Sao_Paulo").format('HH:mm')}.</span>
                    </button>
                  }

                  <div className="avaliable-now">
                    <AvailableNow
                      orderType={orderType}
                      title="DISPONÍVEL AGORA!"
                    />
                  </div>
                </div>
              )}

            {"presential" === slot?.service_type && (
              <div className="info-tags">
                <InfoView>
                  <span className="info-text">
                    Seu pedido de <span>{offer.title}</span> com o <span>{slot.professional.name}</span> foi confirmado para <span>{dayOfWeek} {Moment(hourBegin).tz("America/Sao_Paulo").format(" DD/MM")}</span>

                    {!waitingList && (
                      <span> às {Moment(hourBegin).tz("America/Sao_Paulo").format("HH:mm")}.</span>
                    )}

                    {waitingList && (
                      <span> para a lista de encaixe.</span>
                    )}

                  </span>
                </InfoView>

                <AndNowBanner>
                  <div className="and-now-banner">
                    <span className="and-now-text">E AGORA?</span>
                  </div>
                </AndNowBanner>

                <div className="one-two-banner">
                  <span className="one-two-number-text">1</span>
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiMail color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      Enviamos todas as informações do agendamento para seu email.
                    </span>
                  </div>

                </div>

                <div className="one-two-banner">
                  <span className="one-two-number-text">2</span>
                  <div className="info-one-two-text">
                    <div className="mail-clock-view">
                      <FiClock color="#000" size={20} />
                    </div>

                    <span className="one-two-text">
                      No horário combinado se dirija ao local do serviço {attendanceLocale}. O profissional estará te aguardando
                    </span>
                  </div>
                </div>

                {paymentMethod === 'credit' &&
                  <div className="one-two-banner">
                    <span className="one-two-number-text card-charge-text">!</span>
                    <div className="mail-clock-view">
                      <FiAlertTriangle color="#000" width="20" />
                      </div>
                    <span className="one-two-text">Para validar o cartão, podemos fazer um lançamento agora no valor do pedido, que será estornado imediatamente. O serviço só será cobrado após a sua realização.</span>
                  </div>
                }

                {nextSuggestion?.job_day_id &&
                  <button className="one-click-banner" onClick={createNewOrder}>
                    <div className="icon-one-click">
                      <FiChevronLeft color='#FFF' size={30} />
                    </div>
                    <div className="mail-clock-view">
                      <FiCalendar color='#000' size={20} />
                    </div>
                    <span className="one-two-text">Clique aqui e agende o mesmo serviço para {getDayName(nextSuggestion.hour_begin)}, {Moment(nextSuggestion.hour_begin).tz("America/Sao_Paulo").format('DD/MM')}, às {Moment(nextSuggestion.hour_begin).tz("America/Sao_Paulo").format('HH:mm')}.</span>
                  </button>
                }

                <div className="avaliable-now">
                  <AvailableNow
                    orderType={orderType}
                    title="DISPONÍVEL AGORA!"
                  />
                </div>
              </div>
            )}
          </div>
        }<TabButton screen="home" />
      </BackgroundImage>
    </div>
  );
};

export default CompleteOrder;
