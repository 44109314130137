import React from 'react';
import { ModalHelp } from '../../../../components/ModalHelp';
import { ContainerFooterLogin } from './styles';

export function LoginFooter() {
  return (
    <>
      <ContainerFooterLogin>
        <a href="/terms">Termos e condições</a>
        <ModalHelp mode="light" />
      </ContainerFooterLogin>
    </>
  );
}
