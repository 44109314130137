import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    PAYMENTS_ORDERS,
    PAYMENTS_ON_DEMAND_ORDERS,
    PAYMENTS_PARTNERS,
    CUSTOMER_CREDIT_CARDS,
    CUSTOMER_DEBIT_CARDS,
    PAYMENTS_DIGITAL_WALLET,
    PAYMENTS_WALLET_STATEMENT,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

const getOrderPaymentMethods = async (callback, orderType, serviceId) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PAYMENTS_ORDERS}/${orderType}/methods?service_id=${serviceId}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getOrderPaymentMethods: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de métodos de pagamento');
    }
}

const getWalletBalance = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PAYMENTS_DIGITAL_WALLET}`, get_bearer);
        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson);

        } else {
            throw new Error(`erro na chamada getWalletBalance: ${response.status}`);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta do saldo da carteira');
    }
}

const getWalletStatement = async (callback, limit) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PAYMENTS_WALLET_STATEMENT}?page=0&limit=${limit}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error(`erro na chamada getWalletStatement: ${response.status}`);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de extrato');
    }
}

const getPartnersPaymentMethods = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PAYMENTS_PARTNERS}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getPartnersPaymentMethods: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de métodos de pagamento');
    }
}

const getOnDemandOrderPaymentMethods = async (callback, orderType, serviceId) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${PAYMENTS_ON_DEMAND_ORDERS}/${orderType}/methods?service_id=${serviceId}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getOnDemandOrderPaymentMethods: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de métodos de pagamento');
    }
}

const getCustomerCreditCards = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_CREDIT_CARDS}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getOnDemandOrderPaymentMethods: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de cartões de crédito');
    }
}

const getCustomerDebitCardsMultiBenefits = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_DEBIT_CARDS}/multi-benefits`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getCustomerDebitCardsMultiBenefits: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de cartões de débito');
    }
}

const deleteCustomerCreditCard = async (creditCardId) => {
    let validationError = false;
    try {

        const { delete_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_CREDIT_CARDS}/${creditCardId}`, delete_bearer);

        if (response.status === 400) {
            validationError = true;
            const data = response.data;
            throw new Error(data.message)
        } else if (!response.ok) {
            const data = response.data;
            throw new Error(data.message)
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao deletar cartão de crédito');
        }
    }
}

const deleteCustomerDebitCardsMultiBenefits = async (debitCardMultiId) => {
    let validationError = false;
    try {
        const { delete_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_DEBIT_CARDS}/${debitCardMultiId}`, delete_bearer);

        if (response.status === 400) {
            validationError = true;
            const data = response.data;
            throw new Error(data.message)
        } else if (!response.ok) {
            const data = response.data;
            throw new Error(data.message)
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {

            getSectionsCatchErrors(error, 'Erro ao deletar cartão de multiplo benefícios');
        }
    }
}

const makeCreditCard = async (holder_name, number, expiration_month, expiration_year, security_code) => {
    let validationError = false;

    try {
        let data = {
            holder_name: holder_name,
            number: number,
            expiration_month: expiration_month,
            expiration_year: expiration_year,
            security_code: security_code
        };

        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_CREDIT_CARDS}`, post_bearer);

        if (response.status === 400) {
            const data = response.data;

            validationError = true;

            throw new Error(data.message);
        } if (!response.ok) {
            throw new Error('erro na chamada makeCreditCard: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar criação do cartão de crédito');
        }
    }
}

const makeDebitCard = async (holder_name, number, expiration_date, security_code, card_type, brand) => {
    let data = {
        holder_name: holder_name,
        number: number,
        expiration_date: expiration_date,
        security_code: security_code,
        card_type: card_type,
        brand: brand
    };

    try {
        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_DEBIT_CARDS}`, post_bearer);

        if (!response.ok) {
            const data = response.data;

            throw new Error(data.message)
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar criação de cartão de débito');
    }
}

export {
    getOrderPaymentMethods,
    getWalletBalance,
    getWalletStatement,
    getOnDemandOrderPaymentMethods,
    getCustomerCreditCards,
    getCustomerDebitCardsMultiBenefits,
    getPartnersPaymentMethods,
    deleteCustomerCreditCard,
    deleteCustomerDebitCardsMultiBenefits,
    makeCreditCard,
    makeDebitCard
}