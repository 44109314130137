import Moment from 'moment-timezone';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { formatCurrencyBRL } from '../../../utils/utils';
import { ModalApproveApprovalOrder } from '../ModalApproveApprovalOrder';
import { ModalCancelApprovalOrder } from '../ModalCancelApprovalOrder';
import './styles.css'

export function ModalApprovalOrder({ approvalModalOpen, setApprovalModalOpen, order, type, searchOrders, setLoad, loading, setLoading, setAllOrders }) {
    const [approveApprovalModalOpen, setApproveApprovalModalOpen] = useState(false);
    const [cancelApprovalModalOpen, setCancelApprovalModalOpen] = useState(false);

    return (
        <>
            {approvalModalOpen &&
                <Modal
                    isOpen={approvalModalOpen}
                    onRequestClose={() => setApprovalModalOpen(!approvalModalOpen)}
                    overlayClassName="modal-overlay"
                    className="modal-content approval-order"
                    ariaHideApp={false}
                >
                    
                    <button className="x-to-close" type="button" onClick={() => setApprovalModalOpen(false)}>
                        <span>x</span>
                    </button>
                    
                    <div className="header-modal">
                            <span className="subtitle-header">Orçamento</span>
                            <span className="title-header">APROVAR</span>
                    </div>

                    <div className="body-all-content">

                        <div className="content-body">
                            <span><b>Pedido:</b> {order.id}</span>
                        </div>

                        <div className="content-body">
                            <span><b>Serviço:</b> {order.service.name}</span>
                        </div>
                        <div className="content-body">
                            <span><b>Data de entrega:</b> {Moment(order.estimate.delivery_date).format('DD/MM/YYYY')}</span>
                        </div>
                        <div className="content-body">
                            <span><b>Total:</b> {formatCurrencyBRL(order.total_value)}</span>
                        </div>
                        <div className="content-body">
                            <span><b>Itens:</b></span>
                        </div>
                        <div className="content-body">
                            {order.estimate.items.map((item, index) => {
                                return (
                                    <li key={index}>{item.description} - &nbsp; {formatCurrencyBRL(item.price)}</li>
                                )
                            })}
                        </div>
                    </div>

                    <div className="container-buttons">
                        <button className="accept-button" type="type" onClick={() => setApproveApprovalModalOpen(!approveApprovalModalOpen)}>
                            <span>aprovar</span>
                        </button>
                        <button className="cancel-button" type="button" onClick={() => setCancelApprovalModalOpen(!cancelApprovalModalOpen)}>
                            <span>reprovar</span>
                        </button>
                    </div>

                    {cancelApprovalModalOpen &&
                        <ModalCancelApprovalOrder
                            setLoad={setLoad}
                            loading={loading}
                            setLoading={setLoading}
                            searchOrders={searchOrders}
                            type={type}
                            setAllOrders={setAllOrders}
                            cancelApprovalModalOpen={cancelApprovalModalOpen}
                            setCancelApprovalModalOpen={setCancelApprovalModalOpen}
                            approvalModalOpen={approvalModalOpen}
                            setApprovalModalOpen={setApprovalModalOpen}
                            order={order} />
                    }

                    {approveApprovalModalOpen &&
                        <ModalApproveApprovalOrder
                            setLoad={setLoad}
                            loading={loading}
                            setLoading={setLoading}
                            searchOrders={searchOrders}
                            type={type}
                            setAllOrders={setAllOrders}
                            approveApprovalModalOpen={approveApprovalModalOpen}
                            setApproveApprovalModalOpen={setApproveApprovalModalOpen}
                            approvalModalOpen={approvalModalOpen}
                            setApprovalModalOpen={setApprovalModalOpen}
                            order={order} />
                    }
                </Modal>
            }

        </>
    )
}