import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, ButtonSend } from './styles';
import { makeUsernameLogin } from '../../../api/login';
import LoaderSpinner from '../../../components/LoaderSpinner';
import LogoImg from '../../../assets/posherlogo.png';
import { LoginFooter } from '../components/LoginFooter';
import { MicrosoftButtonLogin } from '../components/MicrosoftButtonLogin';
import GoogleButtonLogin from '../components/GoogleButtonLogin';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';


const LoginEmail = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const navigation = useHistory();
    const [username, setUser] = useState('');
    const alreadyDidDeep = sessionStorage.getItem('alreadyDidDeep');

    const {t} = useTranslation();
    
    if (
        location &&
        location.state &&
        location?.state?.from?.search &&
        alreadyDidDeep !== 'true'
    ) {
        const deepJson = {
            pathname: location.state.from.pathname,
            search: location.state.from.search,
        };

        sessionStorage.setItem('alreadyDidDeep', true);
        sessionStorage.setItem('deepLink', JSON.stringify(deepJson));
    }

    const usernameLogin = async e => {
        e.preventDefault();
        if (username === '') {
            toast.warn('Favor informar o email ou CPF', { autoClose: 5000 });
        } else {
            try {
                setLoading(true);

                const userLogin = await makeUsernameLogin(username);
                const data = await userLogin;
                data.customer.username = username;

                setLoading(false);
                if (data.registered) {
                    navigation.push('/signin-password', data.customer);
                } else {
                    navigation.push('/signup', data.customer);
                }
            } catch (erro) {
                setLoading(false);
                toast.error(`Erro ao realizar login: ${erro.message}`);
            }
        }
    };

    useEffect(() => {
        const redirectError = localStorage.getItem('AUTH_ERROR');
        if (redirectError) {
            toast.error(redirectError);
            localStorage.removeItem('AUTH_ERROR');
        }
    }, []);

    useEffect(() => {
        localStorage.removeItem('provider');
        localStorage.removeItem('external-token');
        sessionStorage.clear();
    }, []);

    useEffect(() => {
        const abortController = new AbortController()

        return () =>  abortController.abort() 
    }, [])

    return (
        <Container>
            <LoaderSpinner loading={loading} />
            <img src={LogoImg} alt="POSHER" className="logo-img-login" />

            <div className="forms">
                <span className="login-text">{t("letsConnect")}</span>

                <form onSubmit={usernameLogin}>
                    <input
                        type="text"
                        placeholder={t("usernamePlaceholder")}
                        id="email"
                        name="email"
                        onChange={e => setUser(e.target.value)}
                    />
                </form>

                <span className="after-login-text">
                    {t("userEmailDescription")}
                </span>

                <ButtonSend type="submit" onClick={usernameLogin}>
                    <span>{t("enterButton")}</span>
                </ButtonSend>
                <MicrosoftButtonLogin setLoading={setLoading} />
                <GoogleButtonLogin setLoading={setLoading} />
            </div>
            <LoginFooter />
        </Container>
    );
};

export default LoginEmail;