import React, { useState } from 'react';
import Moment from 'moment-timezone';
import { Container } from './styles';
import Header from '../../../components/Header';
import { useHistory } from 'react-router-dom';
import Footer from '../../../components/Footer';
import TabButton from '../../../components/TabButton';

const VideoView = ({ location }) => {
    const navigation = useHistory();
    const { video, category, service } = location.state;

    return (
        <Container>
            <Header screen="home" orderType="online" />

            <div className="background-body">

                <div className="content-body-video-view">
                    <div className="video-title-view">
                        <div className="video-title-view-left">
                            <span className="video-title-category">{category.name} </span>
                            <span className="video-title-service">{service.name} </span>
                        </div>
                        <div className="video-title-view-right">
                            <span className="video-title-recorded-at">Gravado dia</span>
                            <span>{Moment(video.recorded_at).format('DD/MM/yyyy')}</span>
                        </div>
                    </div>

                    <video className="video-container" controls>
                        <source src={video.link} type="video/mp4" />
                        <source src={video.link} type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>

                    <button type="button" className="button-go-back" onClick={() => navigation.goBack()}>
                        <span>voltar</span>
                    </button>
                </div>

                <div className="footer-service-list">
                    <div className="line-separator"></div>
                    <Footer />
                </div>
            </div>

            <TabButton screen="home" />
        </Container>
    )
}

export default VideoView;