
import styled from "styled-components";

export const ButtonOrange = styled.button`
    background-color: #FA9F2A;
    justify-content: center;
    width: 7rem;
    align-self: flex-end;
    height: 100%;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;
    font-size: 1.1rem;
    @media(max-width: 720px) {
        width: 6rem;
    }
`;