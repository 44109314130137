import styled from 'styled-components';

export const Container = styled.div`
    background-color: #FFF;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 160px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #FA9F2A;
    border-radius: 18px;

    .container-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 10px;
    };

    .image {
        border-radius: 18px;
        width: 120px;
        height: 120px;
        margin-left: 10px;
        margin-top: 10px;
    };

    .content {
        display: flex;
        flex-direction: column;
        margin-left: 13px;
    };

    .title {
        color: #14213D;
        font-size: 1.2rem;
        font-weight: bold;
        width: 550px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        margin-bottom: 5px;
    };

    .description {
        color: #B7B7B7;
        font-size: 1rem;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 640px;
    };

    .button-more {
        background: none;
        display: flex;
        align-items: flex-start;
        margin-top: 5px;
    }

    .text-button-more {
        color: #FA9F2A;
        text-decoration: underline;
        font-size: 1rem;
    };

    .back-quantity {
        background-color: #FFF;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 300px;
        align-self: flex-end;
        border: 1px solid #CFCFCF;
        border-radius: 20px;
        padding: 5px;
        margin-top: -35px;
        margin-right: 10px;
    };

    .button {
        background-color: #FA9F2A;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 10px;
        border-radius: 100%;
    };

    .quantity {
        color: #707070;
        font-size: 0.9rem;
    };

    .text-button {
        color: #FFF;
    };

    .price {
        border: 1px solid #14213D;
        border-radius: 17px 17px 0 17px;
        padding: 7px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
    };

    .text-price {
        color: #14213D;
        font-size: 0.9rem;
    };

    .price-value {
        font-size: 1rem;
    };

    @media(max-width: 840px) {
        width: 600px;
        height: 140px;

        .image {
            width: 110px;
            height: 110px;
        }

        .title {
            width: 350px;
            font-size: 1rem;
        }

        .description {
            width: 450px;
            font-size: 0.9rem;
        }

        .text-button-more {
            font-size: 0.9rem;
        }

        .back-quantity {
            margin-top: -40px;
            width: 200px;
        }

        .button {
            padding: 7px 8px;
        }

        .quantity {
            font-size: 0.9rem;
        };

        .text-price {
            font-size: 0.85rem;
        }

        .price-value {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 630px) {
        width: 500px;
        height: 130px;

        .image {
            width: 100px;
            height: 100px;
        }

        .title {
            width: 290px;
            font-size: 1rem;
        }

        .description {
            width: 380px;
            font-size: 0.9rem;
        }

        .text-button-more {
            font-size: 0.9rem;
        }

        .back-quantity {
            margin-top: -40px;
            width: 150px;
        }

        .button {
            padding: 7px 8px;
        }

        .quantity {
            font-size: 0.9rem;
        };

        .text-price {
            font-size: 0.85rem;
        }

        .price-value {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 530px) {
        width: 400px;
        height: 120px;

        .image {
            width: 90px;
            height: 90px;
        }

        .title {
            width: 200px;
            font-size: 1rem;
        }

        .description {
            width: 260px;
            font-size: 0.9rem;
        }

        .text-button-more {
            font-size: 0.9rem;
        }

        .back-quantity {
            margin-top: -35px;
            width: 150px;
        }

        .button {
            padding: 7px 8px;
        }

        .quantity {
            font-size: 0.9rem;
        };

        .text-price {
            font-size: 0.85rem;
        }

        .price-value {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 425px) {
        width: 350px;
        height: 145px;

        .image {
            width: 80px;
            height: 80px;
        }

        .title {
            width: 150px;
            font-size: 1rem;
        }

        .description {
            width: 220px;
            font-size: 0.9rem;
        }

        .text-button-more {
            font-size: 0.9rem;
        }

        .back-quantity {
            margin-top: -2px;
            align-self: center;
            margin-left: 10px;
            width: 250px;
        }

        .button {
            padding: 7px 8px;
        }

        .quantity {
            font-size: 0.9rem;
        };

        .price {
            padding: 5px 6px;
        }

        .text-price {
            font-size: 0.85rem;
        }

        .price-value {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 375px) {
        width: 300px;
        height: 145px;

        .image {
            width: 80px;
            height: 80px;
        }

        .title {
            width: 120px;
            font-size: 0.9rem;
        }

        .description {
            width: 190px;
            font-size: 0.85rem;
        }

        .text-button-more {
            font-size: 0.85rem;
        }

        .back-quantity {
            margin-top: -2px;
            align-self: center;
            margin-left: 10px;
            width: 200px;
        }

        .button {
            padding: 7px 8px;
        }

        .quantity {
            font-size: 0.9rem;
        };

        .price {
            padding: 5px 6px;
        }

        .text-price {
            font-size: 0.80rem;
        }

        .price-value {
            font-size: 0.85rem;
        }
    }
`;