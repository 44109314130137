import styled from "styled-components";

export const ContainerCheck = styled.div`
    padding-top: 5rem;
    gap: 2vh;
    width: 100%;
    padding-bottom: 10rem;

  .background-body {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1080px !important;
    margin: auto;
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
  }


  .footer-service-list {
    position: absolute;
    bottom: 30px;
    left: 0;
  }

  

`;