import styled from 'styled-components';

export const ButtonChat = styled.button`
    background-color: #FA9F2A;
    border-radius: 1rem;
    padding: 5px 10px;
    width: 100;
    align-self: center;
    margin: 0 10px;
    margin-right: 10px;
    position: relative;
    transition: .2s ease-in;
    &:hover{
        opacity: 0.8;
    }
`;

export const ContainerChat = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

export const TextChat = styled.h3`
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    margin-left: 5px;
`;

export const ContainerQuantityChat = styled.div`
    background-color: #d9534f;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    position: absolute;
    top: -7px;
    right: -3px;
`;



