import {
  API_BASE,
  API_VERSION,
  COMPANIES,
  GROUP,
} from "../../helper/api_urls";
import { validateGetResponse } from "../../helper/validateGetResponse";

const getCompaniesGroup = async () => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${COMPANIES}${GROUP}`;

    return await validateGetResponse(
      getUrl,
      "Erro ao verificar os grupos disponíveis"
      );
  } catch (error) {
    throw error;
  }
};

const getCompaniesGroupByCode = async (code) => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${COMPANIES}?code=${code}`;

    return await validateGetResponse(
      getUrl,
      "Erro ao achar o grupo pelo código"
    );
  } catch (error) {
    throw error;
  }
};

export { getCompaniesGroup, getCompaniesGroupByCode };
