import styled from 'styled-components';

export const SkeletonLoader = styled.div`
    width: 100%;
    max-width: 1080px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-skt {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .banner-skeleton {
        width: 1080px;
        height: 200px;
    }

    @media(max-width: 1070px) {
        .banner-skeleton {
            width: 1070px;
        }
    }

    @media(max-width: 1050px) {
        .title-skeleton {
            width: 1040px;
        }
    }

    @media(max-width: 1030px) {
        .banner-skeleton {
            width: 1030px;
        }
    }

    @media(max-width: 1000px) {
        .banner-skeleton {
            width: 980px;
        }
    }

    @media(max-width: 970px) {
        .banner-skeleton {
            width: 950px;
        }
    }

    @media(max-width: 940px) {
        .banner-skeleton {
            width: 920px;
        }
    }

    @media(max-width: 890px) {
        .banner-skeleton {
            width: 890px;
        }
    }

    @media(max-width: 850px) {
        .banner-skeleton {
            width: 850px;
        }
    }

    @media(max-width: 820px) {
        .banner-skeleton {
            width: 810px;
        }
    }

    @media(max-width: 780px) {
        .banner-skeleton {
            width: 770px;
        }
    }

    @media(max-width: 720px) {
        .banner-skeleton {
            width: 710px;
        }
    }

    @media(max-width: 660px) {
        .banner-skeleton {
            width: 650px;
        }
    }

    @media(max-width: 600px) {
        .banner-skeleton {
            width: 590px;
        }
    }

    @media(max-width: 550px) {
        .banner-skeleton {
            width: 540px;
        }
    }

    @media(max-width: 490px) {
        .banner-skeleton {
            width: 480px;
        }
    }

    @media(max-width: 425px) {
        .banner-skeleton {
            width: 420px;
        }
    }

    @media(max-width: 375px) {
        .banner-skeleton {
            width: 370px;
        }
    }

    @media(max-width: 320px) {
        .banner-skeleton {
            width: 310px;
        }
    }
`;

export const GroupSkeletons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .today-skeleton {
        width: 600px;
        height: 160px;
        border-radius: 20px;
        margin: 20px 0;
    }

    @media(max-width: 920px) {
        .today-skeleton {
            width: 500px;
            height: 140px;
        }
    }

    @media(max-width: 700px) {
        .today-skeleton {
            width: 400px;
            height: 120px;
        }
    }

    @media(max-width: 400px) {
        .today-skeleton {
            width: 350px;
            height: 100px;
        }
    }

    @media(max-width: 375px) {
        .today-skeleton {
            width: 300px;
            height: 100px;
        }
    }
`;