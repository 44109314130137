import React from "react";
import { FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { t } from 'i18next'
import { ClearButton, FilterButton, Input, FilterInputContainer } from "./styles";

export function FilterInput({
  inputFilterRef,
  setFilteredPerson,
  setIsPositionsFiltered,
}) {
  function handleSubmitFilterPerson() {
    setFilteredPerson(inputFilterRef.current.value);
    setIsPositionsFiltered(true);
  }

  function handleClearFilter() {
    setIsPositionsFiltered(false);
    inputFilterRef.current.value = "";
    setFilteredPerson(inputFilterRef.current.value);
  }

  return (
    <FilterInputContainer>
      <Input
        placeholder={t("filterByName")}
        onSubmit={() => {
          handleSubmitFilterPerson();
        }}
        ref={inputFilterRef}
      />
      <ClearButton
        onClick={() => {
          handleClearFilter();
        }}
      >
        <IoClose size={18} color={"#fa9f2a"} />
      </ClearButton>
      <FilterButton
        onClick={() => {
          handleSubmitFilterPerson();
        }}
      >
        <FaSearch size={18} color={"#FFF"} />
      </FilterButton>
    </FilterInputContainer>
  );
}
