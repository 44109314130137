import React from 'react';
import Logo from '../../assets/Logo.svg';
import { Container, LeftInfos, RightInfos, About, GridTerms, GridSuport } from './styles';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <Container>
            <LeftInfos>
                <Link to="/home" className="logo-posher">
                    <img src={Logo} alt="POSHER" title="Voltar para home" />
                </Link>

                <About>
                    <span className="copyright">Copyright © 2021 - POSHER DO BRASIL TECNOLOGIA EM SERVIÇOS LTDA. Todos os direitos reservados.</span>
                    <span className="address">Av Pedroso de Morais, 377 - Pinheiros - São Paulo, SP - CEP: 05.419-000</span>
                </About>
            </LeftInfos>

            <RightInfos>

                <GridSuport>
                    <Link
                        to="#"
                        onClick={(e) => {
                            window.location = 'mailto:contato@posherapp.com';
                            e.preventDefault();
                        }}
                        className="title-suport"
                    >
                        Atendimento ao cliente
                    </Link>

                    <Link
                        to="#"
                        onClick={(e) => {
                            window.location = 'mailto:vendas@posherapp.com';
                            e.preventDefault();
                        }}
                        className="title-suport"
                    >
                        Vendas para empresas
                    </Link>

                    <Link
                        to="#"
                        onClick={(e) => {
                            window.location = 'mailto:parceiros@posherapp.com';
                            e.preventDefault();
                        }}
                        className="title-suport"
                    >
                        Suporte aos profissionais
                    </Link>
                </GridSuport>
            </RightInfos>
        </Container>
    );
}

export default Footer;