import styled from 'styled-components';

export const ButtonServiceContainer = styled.div`
    position: relative;

    @media(max-width: 740px) {
        .more-information {
            left: 140px;
        }
    }

    @media(max-width: 639px) {
        .more-information {
            left: 115px;
            font-size: 0.813rem;
        }
    }

    @media(max-width: 520px) {
        .more-information {
            left: 108px;
            font-size: 0.75rem;
        }
    }

    @media(max-width: 390px) {
        .more-information {
            left: 95px;
            font-size: 0.7rem;
        }
    }
`;

export const Container = styled.button`
    display: flex;
    position: relative;
    width: 700px;
    height: 160px;
    border-radius: 16px;
    border: 1px solid #FA9F2A;
    background: #FFF;
    margin: 0 10px;
    transition: .2s background;

    :hover {
        background: #F8F8F8;
    }

    .img-button {
        align-self: center;
        margin-left: 10px;
    }

    .img-button img {
        width: 140px;
        height: 140px;
        border-radius: 16px;
        border: 1px solid #f2f2f2;
        background-color: #f2f2f2;
    }

    .info-button {
        margin: 0 15px;
        color: #14213D;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .info-button h3 {
        text-transform: uppercase;
        font-weight: 700;
        color: #14213D;
        font-size: 1.2rem;
        margin: 10px 0 5px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 38ch;
    }

    .info-button .subTitle {
        text-transform: uppercase;
        color: #C4C4C4;
        font-size: 0.9rem;
        margin: 10px 0 5px;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 420px;
        height: 32px;
    }

    .more-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        margin: 3px 0;
    }

    .more-info > span {
        margin-left: 2px;
    }

    .icon-button {
        position: absolute;
        right: 0;
    }

    .icon-button .button-orange{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FA9F2A;
        width: 90px;
        height: 159px;
        border-radius: 0 1rem 1rem 0;
        font-size: 1rem;
    }

    .icon-button .button-free {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0px;
        font-size: 1rem;
        width: 110px;
        height: 45px;
        background: #E6860B;
        border-radius: 16px 0 16px 0;
    }

    @media(max-width: 920px) {
        width: 600px;
        height: 150px;

        .img-button img {
            width: 130px;
            height: 130px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 149px;
            width: 80px;
        }

        .icon-button .button-free {
            height: 40px;
            width: 100px;
        }

        .info-button h3 {
            width: 350px;
            font-size: 1rem;
            margin-bottom: 0;
        }

        .info-button .subTitle {
            width: 360px;
            font-size: 0.8rem;
        }
    }

    @media(max-width: 740px) {
        width: 500px;
        height: 140px;

        .img-button img {
            width: 120px;
            height: 120px;
        }

        .icon-button .button-orange{
            height: 139px;
            width: 70px;
            font-size: 0.813rem;
        }

        .icon-button .button-free {
            height: 35px;
            width: 90px;
            font-size: 0.813rem;
        }

        .info-button h3 {
            width: 260px;
            font-size: 0.9rem;
        }

        .info-button .subTitle {
            width: 270px;
            font-size: 0.75rem;
        }
    }

    @media(max-width: 639px) {
        width: 435px;
        height: 120px;

        .img-button img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 119px;
            width: 60px;
        }

        .icon-button .button-free {
            height: 30px;
            width: 80px;
        }

        .info-button h3 {
            width: 230px;
            font-size: 0.8rem;
        }

        .info-button .subTitle {
            width: 230px;
            font-size: 0.7rem;
        }
    }

    @media(max-width: 520px) {
        width: 380px;
        height: 110px;

        .img-button img {
            width: 90px;
            height: 90px;
            border-radius: 10px;
        }

        .info-button h3 {
            width: 200px;
        }

        .info-button .subTitle {
            width: 200px;
            margin: 0;
            font-size: 0.65rem;
        }

        .icon-button .button-orange{
            height: 109px;
            width: 50px;
        }

        .icon-button .button-free {
            height: 30px;
            width: 70px;
        }

        .icon-button .button-orange svg {
            width: 15px;
        }

        .icon-button .button-orange span {
            font-size: 0.7rem;
        }
    }

    @media(max-width: 440px) {
        width: 360px;

        .info-button h3 {
            width: 180px;
            font-size: 0.753rem;
        }

        .info-button .subTitle {
            width: 180px;
            height: 20px;
            font-size: 0.6rem;
        }
    }

    @media(max-width: 390px) {
        width: 330px;
        height: 100px;

        .img-button img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }

        .icon-button .button-orange{
            height: 99px;
            width: 50px;
        }

        .icon-button .button-free {
            height: 25px;
            width: 70px;
            font-size: 0.7rem;
        }

        .info-button h3 {
            width: 160px;
        }

        .info-button .subTitle {
            width: 150px;
            height: 20px;
        }
    }

    @media(max-width: 350px) {
        width: 300px;
        height: 100px;

        .img-button img {
            width: 80px;
            height: 80px;
            border-radius: 10px;
        }

        .info-button h3 {
            width: 130px;
            font-size: 0.713rem;
        }

        .info-button .subTitle {
            width: 130px;
            height: 20px;
        }
    }
`;