import React, { useEffect, useState } from 'react';
import { getWalletStatement } from '../../../api/payments';
import { formatCurrencyBRL } from '../../../utils/utils';
import Moment from 'moment-timezone';
import { toast } from 'react-toastify';
import Header from '../../../components/Header';
import { Container } from './styles';
import TabButton from '../../../components/TabButton';
import Footer from '../../../components/Footer';
import SkeletonWallet from './SkeletonWallet';
import { debounce } from 'throttle-debounce';

const WalletStatement = () => {
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);
    const [allStatements, setAllStatements] = useState({
        balance: 0,
        entries: [],
        has_next_entries_page: false
    });
    const [page, setPage] = useState(1);

    const cancelLoading = () => {
        setTimeout(() => {
            if (document.getElementById('bodyWallet')) {
                document.getElementById('bodyWallet').style.display = 'flex';
            }
            setLoadingSkeleton(false);
        }, 1000);
    }

    const searchStatements = async () => {
        try {
            await getWalletStatement(setAllStatements, page * 10).then(() => cancelLoading());

        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            if (document.getElementById('bodyWallet')) {
                document.getElementById('bodyWallet').style.display = 'none';
            }
            setLoadingSkeleton(true);
            setTimeout(() => debounce(5000, searchStatements()), 1500);
        }

        return () => { isMounted = false };
    }, [page]);

    return (
        <Container>
            <Header screen="wallet" orderType="corporate" />

            <div className="background-body">
                {loadingSkeleton &&
                    <SkeletonWallet />
                }

                <div className="content-wallet" id="bodyWallet">
                    <div className="balance">
                        <span className="balance-text">Saldo: </span>
                        <span className="balance-text">{formatCurrencyBRL(allStatements.balance)}</span>
                    </div>

                    <div className="table-container">
                        {allStatements.entries.length > 0 ?
                            allStatements.entries.map((statement, index) => {
                                return (
                                    <div key={index} className="card">
                                        <div className="card-top">
                                            <span className="text-card">{Moment(statement.created_at).format('DD/MM/YYYY')}</span>
                                            <span className="text-card">{statement.description}</span>
                                            <span className="text-card">{formatCurrencyBRL(statement.value)}</span>
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <span className="text">Não há lançamentos a serem exibidos.</span>
                        }

                        {allStatements.has_next_entries_page &&
                            <div className="button-more">
                                <button type="button" className="next-page-button" onClick={() => setPage(page + 1)}>
                                    <span>mais</span>
                                </button>
                            </div>
                        }
                    </div>
                </div>

                {!loadingSkeleton &&
                    <div className="footer-service-list">
                        <div className="line-separator"></div>
                        <Footer />
                    </div>
                }
            </div>

            <TabButton screen="account" />
        </Container>
    );
}

export default WalletStatement;