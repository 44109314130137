import React, { useEffect, useState } from "react";
import Moment from "moment-timezone";
import { FiCalendar, FiClock, FiMap, FiLink, FiGlobe, FiInfo, FiMessageCircle } from "react-icons/fi";
import { formatCurrencyBRL } from "../../../../utils/utils";
import { cancelOrder, cancelOnDemandOrder } from "../../../../api/service_orders";
import { toast } from "react-toastify";
import { Container } from './styles';
import CopyToClipboard from "react-copy-to-clipboard";
import ModalCancelOrder from "../../../../components/Modals/ModalCancelOrder";
import DailyIframe from '@daily-co/daily-js';
import { ModalApprovalOrder } from "../../../../components/Modals/ModalApprovalOrder";
import { useHistory } from "react-router-dom";
import { ButtonChat, ContainerChat, ContainerQuantityChat, TextChat } from "../../components/Chat";
import LoaderSpinner from "../../../../components/LoaderSpinner";

const OrderCard = (props) => {
  const order = props.order;
  const type = props.type;
  const setAllOrders = props.setAllOrders;
  const setLoad = props.setLoading;
  const searchOrders = props.searchOrders;
  const allChats = props.allChats;
  const [loading, setLoading] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelButtonView, setCancelButtonView] = useState();
  const [locale, setLocale] = useState();
  const [accessRoomButtonView, setAccessRoomButtonView] = useState();
  const [copyLinkButtonView, setCopyLinkButtonView] = useState();
  const [onlineTag, setOnlineTag] = useState();
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  let chatInfo = allChats.find(x => Number(x.subject_id) === order.id);

  const history = useHistory();

  const cancelOrderCall = async () => {
    try {
      setLoading(true);

      if ("scheduled_with_professional" === order.state) {
        await cancelOrder(order.id);
      } else if ("waiting_confirmation" === order.state) {
        await cancelOnDemandOrder(order.id);
      }

      toast.success("Pedido cancelado com sucesso", { autoClose: 3000 });

      setLoading(false);

      searchOrders(type, setAllOrders, true, setLoad);
      setCancelModalVisible(false);
    } catch (erro) {
      setLoading(false);
      setCancelModalVisible(false);
      toast.error(erro.message);
    }
  };

  const openVideo = async (urlVideo) => {
    const source = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
    const userAudioDevice = source.getAudioTracks()[0];
    const userVideoDevice = source.getVideoTracks()[0];

    let callFrame = DailyIframe.createFrame({
      showFullscreenButton: true,
      showLeaveButton: true,
      audioSource: userAudioDevice.id, 
      videoSource: userVideoDevice.id,
      iframeStyle: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
      }
    });

    callFrame.join({ url: urlVideo, showLeaveButton: true, showFullscreenButton: true, audioSource: userAudioDevice.id, videoSource: userVideoDevice.id }).catch(() => {
      callFrame.destroy(); toast.warn("A Sessão foi encerrada!")
    });
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if ("corporate" === order.order_type && order.company) {
        setLocale(
          <div className="container-locale">
            <FiMap size={12} color="#14213D" />
            <span className="locale">{order.company.name}</span>
          </div>
        );
      } else if ("residential" === order.order_type) {
        setLocale(
          <div className="container-locale">
            <FiMap size={12} color="#14213D" />
            <span className="locale">{order.address_description}</span>
          </div>
        );
      } else {
        setLocale(<></>);
      }

      if ("scheduled_with_professional" === order.state || "waiting_confirmation" === order.state) {
        setCancelButtonView(
          <div className="cancel-button-view">
            <button type="button" onClick={() => setCancelModalVisible(true)}>
              <span className="cancel-button">cancelar</span>
            </button>
          </div>
        );
      } else {
        setCancelButtonView(null);
      }

      if (order.online_room_link) {
        setAccessRoomButtonView(
          <div className="access-room-button-view">
            <button type="button" onClick={() => openVideo(order.online_room_link)}>
              <span className="access-room-button">acessar serviço</span>
            </button>
          </div>
        );

        setCopyLinkButtonView(
          <div className="copy-link-button-view">
            <FiLink
              width={12}
              style={{ color: "#FA9F2A", marginTop: -2, marginRight: 5 }}
            />
            <CopyToClipboard onCopy={() => toast.success("Link copiado com sucesso!")} text={order.online_room_link}>
              <span className="copy-link-button">copiar link</span>
            </CopyToClipboard>
          </div>
        );
      } else {
        setAccessRoomButtonView(null);
        setCopyLinkButtonView(null);
      }

      if (
        "online_group" === order.service_type ||
        "online_personal" === order.service_type
      ) {
        setOnlineTag(
          <div className="online-tag">
            <FiGlobe size={10} color="#FFFFFF" />
            <span className="online-tag-text">online</span>
          </div>
        );
      } else {
        setOnlineTag(null);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [order.id]);

  return (
    <Container>

      <LoaderSpinner loading={loading}/>
      <div className="order-view">

        <div className="container-image">
          <img src={order.service.thumb_url} className="service-img" alt="Imagem do serviço" />

          {onlineTag}

          <div className="container-state-name">
            <FiInfo size={15} color="#14213D" />
            <span className="order-state">{order.state_name}</span>
          </div>

          {order.can_approve_estimate && order.estimate &&
            <button onClick={() => setApprovalModalOpen(!approvalModalOpen)} className="estimateApprovalButton">Visualizar</button>
          }
          <ModalApprovalOrder approvalModalOpen={approvalModalOpen} setApprovalModalOpen={setApprovalModalOpen} order={order} setLoad={setLoad} setLoading={setLoading} loading={loading} searchOrders={searchOrders} type={type} setAllOrders={setAllOrders} />

          <div className="locale-view">{locale}</div>
        </div>

        <div className="order-data-view">
          <span className="service-name-text">{order.service.name}</span>

          <div className="date-info-order">
            <FiCalendar size={14} color="#14213D" />
            <span className="schedule-date">{Moment(order.scheduled_date).format("DD/MM/YYYY")}</span>
          </div>

          <div className="time-info-order">
            <FiClock size={14} color="#14213D" />
            {"waiting_confirmation" === order.state ?
              <span className="schedule-time">
                entre {Moment(order.scheduled_date).tz("America/Sao_Paulo").format("HH:mm")} e {Moment(order.scheduled_until).tz("America/Sao_Paulo").format("HH:mm")}
              </span>
              :
              <span className="schedule-time">{Moment(order.scheduled_date).tz("America/Sao_Paulo").format("HH:mm")}</span>
            }
          </div>
        </div>

        <div className="price-view">
          <span className="price">{formatCurrencyBRL(order.total_value)}</span>
        </div>

        {order.professional.name && (
          <div className="professional-view">
            <img src={order.professional.picture_url} className="professional-image" alt="Imagem do profissional" />

            <div className="professional-data">
              <span className="professional-title">Profissional</span>
              <span className="professional-name">{order.professional.name}</span>
            </div>
          </div>
        )}
      </div>

      {(cancelButtonView || accessRoomButtonView || copyLinkButtonView) && (
        <div className="options-view">
          {cancelButtonView}
          {accessRoomButtonView}
          {copyLinkButtonView}
        </div>
      )}

      {order.chat_enabled === true &&
        <ButtonChat
          onClick={() => history.push('/chat', {
            orderId: order.id,
            namePro: order.professional?.name,
            idPro: order.professional?.id
          })}>
          {(chatInfo && chatInfo.last_message_delivered === false) &&
            <ContainerQuantityChat />
          }
          <ContainerChat>
            <FiMessageCircle width={20} height={20} color={'#FFF'} />
            <TextChat>Chat</TextChat>
          </ContainerChat>
        </ButtonChat>
      }

      <div className="bottom-view">
        <span className="order-number">pedido nº {order.id}</span>
      </div>

      {cancelModalVisible &&
        <ModalCancelOrder
          showModalCancel={cancelModalVisible}
          setShowModalCancel={val => setCancelModalVisible(val)}
          code={order.id}
          clicked={value => value && cancelOrderCall()}
        />
      }
    </Container>
  );
};

export default OrderCard;
