import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { postQuestionnaireAnswers } from '../../../api/questionnaires';
import LoaderSpinner from '../../../components/LoaderSpinner/index';
import './styles.css';

const ModalQuestionnaires = ({ showModal, setShowModal, questionnaire }) => {

  const [loading, setLoading] = useState(false);
  const [questionnaireStarted, setQuestionnaireStarted] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [answers, setAnswers] = useState([])

  useEffect(() => {
    if (questionnaire?.questions) {
      setCurrentQuestion(questionnaire?.questions[questionIndex])
    }
  }, [questionIndex, questionnaire.questions])

  const goToNextQuestion = async () => {
    saveCurrentAnswer()
    if (questionIndex < questionnaire.questions.length - 1) {
      setQuestionIndex(questionIndex + 1)
    } else {
      finishQuestionnaire();
    }
  }

  const finishQuestionnaire = async () => {
    try {
      setLoading(true)
      await doSendQuestionnaireAnswers(questionnaire.id, answers)
      toast.success('Respostas enviadas com sucesso')
    } catch (error) {
      toast.error(error.message)
    }
    setLoading(false)
    setShowModal(false)
  }

  const doSendQuestionnaireAnswers = async (questionnaireId, answers) => {
    const data = {
      questionnaire_id: questionnaireId,
      answers: answers
    }

    await postQuestionnaireAnswers(data)
  }

  const skipQuestionnaireAnswers = async () => {
    questionnaire.questions.forEach(question => {
      let answer = {
        question_id: question.id,
        answer: 'SKIPPED ANSWER'
      }
      answers.push(answer)
    })

    finishQuestionnaire();
  }

  const saveCurrentAnswer = () => {
    let answer = {
      question_id: currentQuestion.id,
      answer: currentAnswer
    }
    answers.push(answer)
    setAnswers(answers)
    setCurrentAnswer('')
  }

  const questionnaireStartView = () => {
    return (
      <>
        <div className='questionnaire-body'>
          <span className='title'>{questionnaire.title}</span>
          <span className='description'>{questionnaire.description}</span>
        </div>

        <button className='question-button' onClick={() => setQuestionnaireStarted(true)}>
          <span>iniciar</span>
        </button>
        <button className='skip-button' onClick={() => skipQuestionnaireAnswers()}>
          <span>pular</span>
        </button>
      </>
    )
  }

  const questionnaireChoicesView = () => {
    return Object.entries(currentQuestion.options).map(([key, value]) => {
      return (
        <div style={{flexDirection: 'row', display: 'flex'}}>
          <div className='choice-input'>
            <input
              type="radio"
              value={currentAnswer}
              checked={currentAnswer === key}
              onChange={() => { setCurrentAnswer(key) }}
            />
          </div>
          <div>
            <label className='choice-label' onClick={() => setCurrentAnswer(key)}>{value}</label>
          </div>
        </div>
      )
    })
  }

  const questionnaireTextView = () => {
    return (
      <div>
        <textarea
          placeholder='escreva aqui sua resposta...'
          className='input-text'
          value={currentAnswer}
          onChange={text => { setCurrentAnswer(text.target.value) }}
        />
      </div>
    )
  }

  return (
    <div>
      <Modal
        isOpen={showModal}
        overlayClassName="modal-overlay"
        className="modal-content questionnaire-modal"
        ariaHideApp={false}
      >
        <LoaderSpinner loading={loading} />
        <div className="content-body">
          {!questionnaireStarted && questionnaireStartView()}

          {questionnaireStarted &&
            <div>
              <div className='questionnaire-body'>
                <span className='title'>{questionIndex + 1} - {currentQuestion.title}</span>
                {(currentQuestion.type === 'choice' || currentQuestion.type === 'boolean') &&
                  <div className='questionnaire-choice'>
                    {questionnaireChoicesView()}
                  </div>
                }
                {(currentQuestion.type === 'text' || questionnaire.type === '') && questionnaireTextView()}
              </div>

              <button className={currentAnswer ? 'question-button' : 'question-button-disabled'} onClick={() => { currentAnswer !== '' && goToNextQuestion() }}>
                <span>enviar</span>
              </button>
            </div>
          }
        </div>

      </Modal>
    </div>
  );
}

export default ModalQuestionnaires;