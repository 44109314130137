import styled from "styled-components";

export const TextCancel = styled.h3`
    color: #E6860B;
    font-size: 0.8rem;
    transition: ease-in 0.2s;
    cursor: pointer;
    &:hover{
        opacity: 1 !important;
        color: #E6760B;
        transform: scale(1.1);
    }
`;