import styled from "styled-components";
import { COLORS } from "../../../utils/colors";

export const Container = styled.div`
  .content-body {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 200px;
  }

  .title-personal-data {
    margin: 50px 0 30px;
    align-self: center;
    color: #14213D;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .input-active,
  .input {
    align-self: center;
    text-align: center;
    width: 500px;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }

  .input-active {
    color: #707070;
  }

  .input-name-text {
    text-align: center;
    color: #8c8c8c;
    font-size: 0.9rem;
    margin: 5px 0;
  }

  .icon-personal-input {
    position: absolute;
    right: 15px;
    top: 16px;
  }

  .buttom-view {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    margin-top: 20px;
    background-color: #FA9F2A;
    border-radius: 20px;
    width: 400px;
    font-size: 1.2rem;
  }

  .button-remove-account {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    margin-top: 20px;
    background-color: ${COLORS.danger};
    border-radius: 20px;
    width: 400px;
    font-size: 1.2rem;
  }

  .footer-service-list {
    position: absolute;
    bottom: 30px;
    left: 0;
  }

  @media(max-width: 960px) {
    .footer-service-list .line-separator {
      display: none;
    }
  }

  @media(max-width: 650px) {
    .input-active,
    .input {
      width: 400px;
    }
  }

  @media(max-width: 530px) {
    .input-active,
    .input {
      width: 300px;
    }

    .buttom-view {
      width: 300px;
    }
  }

  @media(max-width: 425px) {
    .input-active,
    .input {
      width: 200px;
      font-size: 1rem;
    }

    .title-personal-data {
      font-size: 1.1rem;
    }

    .icon-personal-input.select {
      top: 10px;
    }

    .buttom-view {
      width: 200px;
      padding: 10px 0;
    }
  }
`;

export const InputContainer = styled.div`
  border-radius: 30px;
  border: 1px solid #cfcfcf;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  width: 600px;
  background: ${({ disabled }) => (disabled ? "#C7C7C7" : "#fff")};

  .styled {
    overflow: hidden;
    width: 92%;
    align-self: center;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    width: 100%;
    font-size: 1.2rem;
    cursor: pointer;
    color: #707070;
    background: none;
    text-align: center;
    text-align-last: center;
    text-align: center;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    align-self: center;
    z-index: 2;
    border: none;
    padding-bottom: 5px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: background-color 0.3s ease;
    outline: none;
  }

  select option {
    display: flex;
    color: #707070;
    font-size: 1rem;
    text-align: center;
    text-align-last: center;
  }

  select option:disabled {
    background: #F2F2F2;
  }

  select::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  select::-webkit-scrollbar {
    width: 7px;
    background: #f7f7f7;
    border-radius: 0 0 20px 20px;
  }

  select::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  select::-webkit-scrollbar-corner {
    border-radius: 20px;
  }

  input[type="date"] {
    cursor: text;
  }

  input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  @media(max-width: 650px) {
    width: 500px;
  }

  @media(max-width: 530px) {
    width: 400px;
  }

  @media(max-width: 425px) {
    width: 300px;
    
    select {
      font-size: 1rem;
    }
  }

`;

export const InputContainerCPF = styled.div`
  border-radius: 30px;
  border: 1px solid #cfcfcf;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 600px;
  background: ${({ disabled }) => (disabled ? "#C7C7C7" : "#fff")};

  @media(max-width: 650px) {
    width: 500px;
  }

  @media(max-width: 530px) {
    width: 400px;
  }

  @media(max-width: 425px) {
    width: 300px;
  }
`;
