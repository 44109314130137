import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0 40px;

    .video-container {
        margin: 20px 0;
    }

    @media(max-width: 840px) {
        margin: 20px 0 30px;

        .video-container {
            margin: 10px 0;
        } 
    }
`;