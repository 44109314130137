import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getHomeSliders } from "../../../../api/marketing";
import { Container } from './styles';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ModalBannerHome from "../../../../components/Modals/ModalBannerHome";

const Banners = () => {
    const [allBannersSlider, setAllBannersSlider] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [bannerImg, setBannerImg] = useState('');

    const executeSearchs = async (setAllBannersSlider) => {
        try {
            await getHomeSliders(setAllBannersSlider);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const showModalBanner = (banner) => {
        if(banner.description && banner.description.length > 0) {
            setBannerImg(banner);
            setShowModal(true);
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            executeSearchs(setAllBannersSlider);
        }

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <Container>
            <ModalBannerHome 
                body={bannerImg} 
                showModalBanner={showModal} 
                setShowModalBanner={val => setShowModal(val)} 
            />

            {allBannersSlider.length > 0 &&
                <Carousel
                    className="carousel"
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    emulateTouch={true}
                    showArrows={false}
                >
                    {allBannersSlider.map((banner, bannerIndex) => {
                        return (
                            <button key={bannerIndex} onClick={() => showModalBanner(banner)} className="button-banner">
                                <img src={banner.fileUrl} alt="banner" />
                            </button>
                        )
                    })}
                </Carousel>
            }
        </Container >
    );
}

export default Banners;