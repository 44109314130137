import {
  API_BASE,
  API_VERSION,
  CASHBACKS_AVAILABLE,
} from "./helper/api_urls";
import { validateGetResponse } from "./helper/validateGetResponse";

const getAvailableCashbacks = async () => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${CASHBACKS_AVAILABLE}`
    return await validateGetResponse(
      getUrl,
      "Erro ao realizar consulta de cashbacks"
    )
  } catch (error) {
    throw error;
  }
}

export { getAvailableCashbacks }