import React, { useEffect, useState } from 'react';
import { getCategory, getService, getDatesByService, getSlotsByServiceAndDate, selectTimeSlot } from '../../../../api/service_offer';
import DateButton from '../../components/DateButton';
import SlotTimeButton from '../../components/SlotTimeButton';
import PaymentView from '../../components/PaymentView';
import { formatCurrencyBRL } from '../../../../utils/utils';
import ServiceBanner from '../../components/ServiceBanner';
import FinishOrderButton from '../components/FinishOrderButton';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import Moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, ButtonVideoSection, DatesViewContainer, SlotTimeView, SlotView, TextAreaEnabled } from './styles';
import Header from '../../../../components/Header';
import Rating from 'react-rating';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import Footer from '../../../../components/Footer';
import SkeletonSlotList from './SkeletonSlotList';
import TabButton from '../../../../components/TabButton';
import { debounce } from 'throttle-debounce';
import VideosGrid from '../../VideoList/components/VideosGrid';
import ModalProfessionalPerfil from '../../../../components/Modals/ModalProfessionalPerfil';

const SlotList = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const navigation = useHistory();
  let orderType = location.state?.orderType;
  let offer = location.state?.offer;
  const [category, setCategory] = useState();
  const [service, setService] = useState();
  const [allDates, setAllDates] = useState([]);
  const [allSlots, setAllSlots] = useState([]);
  const [selectedHourBegin, setSelectedHourBegin] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [checkedPaymentMethod, setCheckedPaymentMethod] = useState({});
  const [selectedDiscountCoupon, setSelectedDiscountCoupon] = useState();
  const [offerDate, setOfferDate] = useState();
  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [walletSelected, setWalletSelected] = useState(false);
  const [paymentSignature, setPaymentSignature] = useState();
  const [showModalPerfil, setShowModalPerfil] = useState(false);
  const [perfil, setPerfil] = useState('');
  const [description, setDescription] = useState('');

  if (!offer && location.search !== '') {
    let search = location.search.substring(1);
    offer = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

    sessionStorage.removeItem('deepLink');
    sessionStorage.setItem('alreadyDidDeep', true);

    orderType = offer.order_type;
  }

  let free = offer?.free;

  const goToVideoList = async () => {
    navigation.push("/videoList", { offer: offer, category: category, service: service });
  }

  const cancelLoading = () => {
    setTimeout(() => {
      if (document.getElementById('bodySlot')) {
        document.getElementById('bodySlot').style.display = 'block';
      }
      setLoadingSkeleton(false);
    }, 1000);
  }

  const executeSearchs = async () => {
    try {
      await getCategory(setCategory, offer.category_id);
      await getService(setService, offer.category_id, offer.service_id);
      await getDatesByService(setAllDates, offer.order_type, offer.category_id, offer.service_id);

      cancelLoading();
    } catch (error) {
      toast.error(error.message);
      setAllDates([]);
    }
  }

  const selectDate = async (date) => {
    try {
      setSelectedDate(date);
      setSelectedSlot(null);
      setSelectedHourBegin(null);

      await getSlotsByServiceAndDate(setAllSlots, offer.order_type, offer.category_id, offer.service_id, date);

    } catch (error) {
      toast.error(error.message)
      setAllSlots([]);
    }
  }

  const selectSlot = async (slot, categoryId, serviceId, slotTime, addressId) => {
    try {
      await selectTimeSlot(orderType, categoryId, serviceId, slot.professional.id, slotTime, addressId);

    } catch (error) {
      toast.error(error.message)

    }

    setSelectedHourBegin(slotTime);
    setSelectedSlot(slot);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (document.getElementById('bodySlot')) {
        document.getElementById('bodySlot').style.display = 'none';
      }
      setLoadingSkeleton(true);
      setTimeout(() => debounce(5000, executeSearchs()), 1500);
    }

    return () => { isMounted = false; };
  }, []);

  useEffect(() => {
    if (offer?.begin_at || allDates.length === 1) {
      let offerDate = null !== offer?.begin_at ? Moment(offer?.begin_at).tz('America/Sao_Paulo').format('YYYY-MM-DD') : allDates[0].date;

      setSelectedDate(offerDate);
      setOfferDate(offerDate);

      selectDate(offerDate);
    }
  }, [allDates]);

  useEffect(() => {
    if (null !== allSlots && allSlots.length === 1 && allSlots[0].slots.length === 1) {
      let slot = allSlots[0];
      selectSlot(slot, offer.category_id, offer.service_id, slot.slots[0].begin_at, null);
    }
  }, [allSlots]);

  let slotComponents = allSlots.map((slot, slotIndex) => {
    if (null === selectedSlot && (null !== offer.job_day_id && offer.job_day_id === slot.job_day_id) && null !== offerDate && offerDate === selectedDate) {
      setSelectedSlot(slot);

      if ('online_group' === slot.service_type) {
        selectSlot(slot, offer.category_id, offer.service_id, offer.begin_at, null);
      }
    }

    if (!selectedSlot || selectedSlot.job_day_id === slot.job_day_id) {
      return (
        <SlotView key={slotIndex}>
          <button
            type="button"
            className="button-perfil"
            onClick={() => { slot.professional.profile !== '' && setShowModalPerfil(true); setPerfil(slot.professional.profile) }}
          >
            <img src={slot.professional.picture_url} className="slot-professional-img" />

            {slot.professional.profile !== '' &&
              <div className='container-perfil'>
                <span className='text-perfil'>perfil</span>
              </div>
            }
          </button>

          <div className="slot-price-view">
            {offer.estimate_required ? (
              <span>orçar</span>
            )
              : (
                <span>{formatCurrencyBRL(offer.price)}</span>
              )}
          </div>

          <div className="slot-professional-view">
            <div className="slot-info-professional">
              <span className="slot-professional-name">{slot.professional.name}</span>

              <div className="professional-rating-view">
                <Rating
                  emptySymbol={<AiOutlineStar size={16} color="#14213D" />}
                  fullSymbol={<AiFillStar size={16} color="#14213D" />}
                  start={0}
                  stop={5}
                  fractions={2}
                  placeholderRating={slot.professional.rating}
                  placeholderSymbol={<AiFillStar size={16} color="#14213D" />}
                  readonly
                />
              </div>
            </div>

            <span className="slot-info-hour">Horários disponíveis {slot.attendance_locale ? (' - ' + slot.attendance_locale) : ''}</span>

            <SlotTimeView>
              {slot.slots.map((slotTime, slotTimeIndex) => {
                return (
                  <div key={slotTimeIndex}>
                    <SlotTimeButton
                      slotTime={slotTime}
                      doSelectTimeSlot={() => selectSlot(slot, offer.category_id, offer.service_id, slotTime.begin_at, null)}
                      selected={slotTime.begin_at === selectedHourBegin}
                    />
                  </div>
                );
              })}
            </SlotTimeView>

          </div>
        </SlotView>
      )
    }
  });

  return (
    <Container>
      <Header screen="home" orderType={orderType} />
      <LoaderSpinner loading={loading} />

      {showModalPerfil &&
        <ModalProfessionalPerfil
          message={perfil}
          setShowModalProfessional={val => setShowModalPerfil(val)}
          showModalProfessional={showModalPerfil}
        />
      }

      <div className="background-body">

        {loadingSkeleton &&
          <SkeletonSlotList />
        }

        <div className="slot-body-content" id="bodySlot">
          <ServiceBanner
            bannerUrl={(category ? category.header_url : null)}
            title={offer?.title}
            serviceType={offer?.service_type}
          />

          {allDates.length > 0 &&
            <div className="choose-date" key={"datesFragment"}>
              <span className="choose-one-date">Escolha uma data</span>

              <div className="container-dates-view">
                <DatesViewContainer>
                  {allDates.map((date, dateIndex) => {
                    return (
                      <div key={dateIndex}>
                        <DateButton
                          date={date.date}
                          selected={selectedDate === date.date}
                          doSelectDate={() => selectDate(date.date)}
                        />
                      </div>
                    )
                  })}
                </DatesViewContainer>
              </div>
            </div>
          }

          {allDates.length === 0 && (!service || !service.videos || service.videos.length === 0) &&
            <div className="not-found-text">
              <span className="notFound">Nenhum profissional disponível no momento</span>
            </div>
          }

          {selectedDate &&
            <div className="container-slot-component">
              {slotComponents}
            </div>
          }

          {selectedHourBegin &&
            <div className="payment-container" key={"paymentFragment"}>
              {!free &&
                <div className="payment-view-container">
                  <PaymentView
                    offer={offer}
                    orderType={orderType}
                    setCheckedPaymentMethod={setCheckedPaymentMethod}
                    checkedPaymentMethod={checkedPaymentMethod}
                    setPaymentSignature={setPaymentSignature}
                    setSelectedDiscountCoupon={setSelectedDiscountCoupon}
                    selectedDiscountCoupon={selectedDiscountCoupon}
                    setWalletSelected={setWalletSelected}
                    setLoading={setLoading}
                    selectedDate={selectedDate}
                  />
                </div>
              }

              {offer?.note_enabled === true &&
                <TextAreaEnabled
                  placeholder="Descreva os itens a serem orçados..."
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  numberOfLines={5}
                />
              }

              <FinishOrderButton
                offer={offer}
                description={description}
                orderType={orderType}
                hourBegin={selectedHourBegin}
                slot={selectedSlot}
                paymentMethod={checkedPaymentMethod.method}
                paymentSignature={paymentSignature}
                serviceId={offer.service_id}
                cardId={checkedPaymentMethod.card}
                walletSelected={walletSelected}
                discountCouponId={selectedDiscountCoupon ? selectedDiscountCoupon.id : null}
                addressId={null}
                free={free}
                setLoading={setLoading}
              />
            </div>
          }

          {service && service.videos && service.videos.length > 0 &&
            <div>
              {allDates.length > 0 &&
                <div>
                  <div className="not-found-view">
                    <span className="not-found-video">NÃO ENCONTROU O QUE PROCURAVA?</span>
                  </div>

                  <div className="button-container">
                    <ButtonVideoSection type="button" onClick={goToVideoList}>
                      <span>veja nossas sessões gravadas</span>
                    </ButtonVideoSection>
                  </div>
                </div>
              }

              {allDates.length === 0 &&
                <div>
                  <div className="sessions-container">
                    <span className="text-title-session">veja nossas sessões gravadas</span>
                  </div>
                  <VideosGrid setLoading={setLoading} service={service} category={category} offer={offer} />
                </div>
              }
            </div>
          }

          {!loadingSkeleton &&
            <div className="footer-service-list">
              <div className="line-separator"></div>
              <Footer />
            </div>
          }
        </div>
      </div>
      <TabButton screen="home" />
    </Container>
  );
};

export default SlotList;
