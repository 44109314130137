import styled from 'styled-components';

export const Container = styled.div`
  .no-service {
    font-size: 1.2rem;
    color: #C4C4C4;
    margin: 20px 0;
  };

  .container-pack {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 15px;
    padding-bottom: 150px;
  };

  .container-pack-unit {
    display: flex;
    margin: 20px 0;
    width: 20rem;
  };

  .footer-service-list {
    position: absolute;
    bottom: 30px;
    left: 0;
  };

  @media(max-width: 960px) {
    .footer-service-list .line-separator {
      display: none;
    }

    .container-pack {
      padding-bottom: 80px;
    }
  }
`;