import styled from "styled-components";

export const FilterInputContainer = styled.div`
  background-color: #f1f1f1;
  display: flex;
  width: 18rem;
  border-radius: 1rem;
  align-items: center;
`;

export const Input = styled.input`
  color: #14213d;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  border-radius: 1rem;
`;

export const FilterButton = styled.button`
  color: #14213d;
  background-color: #fa9f2a;
  height: 100%;
  padding: 0.8rem 0.8rem;

  border-radius: 1rem;

  transition: ease-in-out 0.2s;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`;

export const ClearButton = styled.button`
  color: #14213d;
  background-color: #f1f1f1;
  height: 100%;
  padding: 0.8rem 0.8rem;

  
  transition: ease-in-out 0.2s;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`;
