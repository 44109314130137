import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    COMPANIES,
    CUSTOMER_COMPANY,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

/*get*/

const getCompanyById = async (codeCompany) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${COMPANIES}?code=${codeCompany}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;

        } else if (response.status === 404) {
            validationError = true;

            throw new Error("Empresa não encontrada");

        } else if (response.status === 400) {
            validationError = true;

            throw new Error("Dados inválidos");

        } else {
            throw new Error("erro na chamada getCompanyById: " + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao buscar empresa por código');
        }
    }
}

const getCompanyByEmail = async (emailCompany) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${COMPANIES}?employee_email=${emailCompany}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;

        } else if (response.status === 404) {
            validationError = true;

            throw new Error("Empresa não encontrada");

        } else if (response.status === 400) {
            validationError = true;

            throw new Error("Dados inválidos");

        } else {
            throw new Error("erro na chamada getCompanyByEmail: " + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao buscar empresa por email');
        }
    }
}

/*put*/

const putEmployeeInCompany = async (idCompany) => {
    let validationError = false;
    try {
        const { put_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_COMPANY}/${idCompany}/join`, put_bearer);

        if (response.ok) {
            return response.status;

        } else if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);

        } else {
            throw new Error("erro na chamada putEmployeeInCompany: " + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao adicionar empregado na empresa');
        }
    }
}

export {
    getCompanyById,
    getCompanyByEmail,
    putEmployeeInCompany
}