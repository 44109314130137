import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

const ButtonsSignaturePlan = ({ signaturePlan }) => {
    const navigation = useHistory();

    const goToSignatureCheckout = (signaturePlan) => {
        navigation.push("/signatureCheckout", { signaturePlan: signaturePlan });
    }

    return (
        <Container type="button" onClick={() => goToSignatureCheckout(signaturePlan, navigation)}>
            <div className="img-button">
                <img src={signaturePlan.thumb_url} alt={signaturePlan.name} />
            </div>
            
            <h3 className="name-button">{signaturePlan.name}</h3>
        </Container>
    );
}

export default ButtonsSignaturePlan;