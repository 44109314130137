import styled from 'styled-components';

export const Container = styled.div`
    .button-new-card {
        background: none;
        color: #FA9F2A;
        text-decoration: underline;
        font-size: 1.1rem;
        transition: .3s color;
    }

    .button-new-card:hover {
        color: #E6860B;
    }
`;