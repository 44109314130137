import React from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { cancelSignaturePlan } from '../../../../../api/requests/orders/signature/signaturePlanApi';
import './styles.css';

const ModalCancelSubscription = ({ showModalCancel, setShowModalCancel, subscription_name, subscription_id }) => {
    
    const handleCloseModal = () => {
        setShowModalCancel(prevState => !prevState);
    }

    const handleCancelSubscription = async () => {
        try {
            await cancelSignaturePlan(subscription_id)
            toast.success('Cancelamento realizado com sucesso')
        } catch(error) {
            toast.error(error.message)
        } finally {
            handleCloseModal()
        }
    }

    return (
        <div>
            <Modal
                isOpen={showModalCancel}
                onRequestClose={() => handleCloseModal()}
                overlayClassName="modal-overlay"
                className="modal-content cancel-order"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">confirmar</span>
                    <span className="title-header">cancelamento</span>
                </div>

                <div className="content-body">
                    <span>Confirmar cancelamento do {subscription_name}?</span>
                    <span>A assinatura poderá ser utilizada até a data de validade e não será renovada.</span>
                </div>

                <div className="container-buttons">
                    <button className="accept-button" type="type" onClick={() => handleCancelSubscription()}>
                        <span>sim, confirmar</span>
                    </button>
                    <button className="cancel-button" type="button" onClick={() => handleCloseModal(false)}>
                        <span>não</span>
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalCancelSubscription;