import React from 'react';
import { FiCheck } from 'react-icons/fi';
import AvailableNow from '../../../ServiceOrder/components/AvailableNow';
import { Container } from './styles';
import Header from '../../../../components/Header';
import TabButton from '../../../../components/TabButton';

const LinkedCompany = ({ location }) => {
    const company = location?.state?.company;

    return (
        <Container>
            <Header screen="user" orderType="corporate" />

            <div className="background-body">
                <div className="checked">
                    <FiCheck size={50} color="#FFF" />
                </div>

                <span className="all-right">Tudo certo :)</span>

                <span className="text">Agora você pode aproveitar todos os serviços oferecidos para a {company.name}</span>

                <div className="block-services">
                    <AvailableNow orderType={"corporate"} title="COMECE POR AQUI" />
                </div>
            </div>

            <TabButton screen="account" />
        </Container>
    );
}

export default LinkedCompany;