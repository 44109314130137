import React from 'react';
import { createOrder } from '../../../../../api/service_orders';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from './styles';

const FinishOrderButton = (props) => {
    const offer = props.offer;
    const orderType = props.orderType;
    const hourBegin = props.hourBegin;
    const slot = props.slot;
    const paymentMethod = props.paymentMethod;
    const paymentSignature = props.paymentSignature;
    const serviceId = props.serviceId;
    const cardId = props.cardId;
    const discountCouponId = props.discountCouponId;
    const addressId = props.addressId;
    const setLoading = props.setLoading;
    const walletSelected = props.walletSelected;
    const free = props.free;
    const note = props.description;
    const navigation = useHistory();

    const create = async () => {
        try {
            if (!paymentMethod && !free) {
                toast.warn("Favor selecionar a forma de pagamento");
                return;
            }

            if (paymentMethod === 'payroll' && !paymentSignature) {
                toast.warn("Favor adicione uma assinatura");
                return;
            }

            setLoading(true);

            const order = await createOrder(hourBegin, slot.job_day_id, (free ? 'cash' : paymentMethod), paymentSignature, serviceId, cardId, discountCouponId, addressId, offer.order_type, walletSelected, note);

            setLoading(false);

            navigation.push("/completeOrder", {
                orderType: orderType,
                attendanceLocale: order.attendance_locale,
                slot: slot,
                hourBegin: hourBegin,
                offer: offer,
                paymentMethod: paymentMethod,
                paymentSignature: paymentSignature,
                pixCode: order.pix_qr_code,
                paymentExpirationMinutes: order.payment_expiration_minutes,
                nextSuggestion: order?.next_date_suggestion,
                serviceId: serviceId,
                cardId: cardId,
                addressId: addressId,
                walletSelected: false,
                note: note,
                order
            });

        } catch (error) {
            setLoading(false);
            toast.error(error.message)
        }
    }

    return (
        <Container>
            <button className="finish-order-button" onClick={create}>
                <span>concluir pedido</span>
            </button>
        </Container>
    )
}

export default FinishOrderButton;