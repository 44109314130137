import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalProfessionalPerfil = ({ showModalProfessional, setShowModalProfessional, message }) => {
    const messageAdjusted = message.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    
    return (
        <div>
            <Modal
                isOpen={showModalProfessional}
                onRequestClose={() => setShowModalProfessional(false)}
                overlayClassName="modal-overlay"
                className="modal-content professional-perfil"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">perfil do</span>
                    <span className="title-header">PROFISSIONAL</span>
                </div>

                <div className="content-body">
                    <div dangerouslySetInnerHTML={{__html: messageAdjusted }}></div>
                </div>

                <button className="close-button" type="type" onClick={() => setShowModalProfessional(false)}>
                    <span>fechar</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalProfessionalPerfil;