import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";

import {
  doTimeClockCheckin,
  doTimeClockCheckout,
  getTimeClockCheckinCurrent,
} from "../../../../../api/time_clock";

import LoaderSpinner from "../../../../../components/LoaderSpinner";

import {
  ModalContainer,
  HeaderContainer,
  HeaderModal,
  HeaderTitle,
  HeaderSubtitle,
  BodyModal,
  ContainerFooterModal,
  CloseModal,
  SendModal,
  ModalContent,
} from "./styles";
import "./style.css";
import { t } from "i18next";

const ModalTimeClockConfirm = ({
  showModal,
  setShowModal,
  modalType,
  handleCancelTimeClock,
}) => {
  const [loading, setLoading] = useState(false);

  async function handleConfirmCheckin() {
    try {
      setLoading(true);
      await doTimeClockCheckin();
      toast.success("Entrada realizada com sucesso", { duration: 5000 });
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirmCheckout() {
    try {
      setLoading(true);
      const totalTimeCheckout = await getTimeClockCheckinCurrent();
      await doTimeClockCheckout();
      toast.success(
        `Saída realizada com sucesso com o tempo total de ${totalTimeCheckout.total_time}`,
        {
          duration: 5000,
        }
      );
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleConfirmAction() {
    setLoading(true);

    modalType === t("entryWord")
      ? await handleConfirmCheckin()
      : modalType === t("exitWord")
      ? await handleConfirmCheckout()
      : await handleCancelTimeClock();

    setLoading(false);
    setShowModal(!showModal);
  }

  return (
    <>
      <ModalContainer>
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(!showModal)}
          className="vaccination"
          overlayClassName="modal-overlay"
          ariaHideApp={false}
        >
          <LoaderSpinner loading={loading} />
          <HeaderContainer>
            <HeaderModal>
              <HeaderTitle>{t("confirmWord")}</HeaderTitle>
              <HeaderSubtitle>{modalType}</HeaderSubtitle>
            </HeaderModal>
          </HeaderContainer>

          <BodyModal>
            <ModalContent>{t("makeScheduleConfirm")} {modalType}?</ModalContent>
          </BodyModal>

          <ContainerFooterModal>
            <SendModal
              onClick={() => {
                handleConfirmAction();
              }}
            >
              {t("yesWord")}, {modalType === t("cancellation") ? t("cancelWord") : t("confirmWord")}
            </SendModal>
            <CloseModal onClick={() => setShowModal(!showModal)}>
              {t("noWord")}
            </CloseModal>
          </ContainerFooterModal>
        </Modal>
      </ModalContainer>
    </>
  );
};

export default ModalTimeClockConfirm;
