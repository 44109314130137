import styled from "styled-components";

export const Container = styled.div`
    .footer-service-list {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @media (max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }

        .container-pack {
            padding-bottom: 80px;
        }
    }
`;

export const PageContainer = styled.div`
    align-items: center;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: auto;
    max-width: 1080px !important;
    min-height: 100vh;
    padding-bottom: 10vh;
    padding-top: 5vh;
    position: relative;
    width: 100%;
    
`;

export const NotFound = styled.h4`
    color: #14213d;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
    padding: 2rem;
`;


export const SubscriptionCardContainer = styled.section`
    align-items: center;
    border: 1px solid #F7F7F7;
    border-radius: 1rem;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 0.1rem;
    transition: ease-in 0.3s;

    aside {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        button {
            background: none;
            color: #fa9f2a;
            text-decoration: underline;
        }

        span {
            text-align: center;
            color: #e63946;
        }
    }
    
    img {
        border-radius: 1rem;
        height: 6.25rem;
        width: 6.25rem;
        margin-bottom: 1.5rem;
    }
    
    article {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        max-width: 60vw;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        span {
            color: #707070;
            font-size: 0.9rem;

            b {
                color: #14213D;
                font-weight: 700;
                font-size: 1.2rem;
            }
        }

        @media(min-width: 1000px){
            max-width: 40vw;
        }
    }

    &:hover {
        transform: scale(1.05);
    }

    @media(min-width: 600px){
        flex-direction: row;
        align-items: flex-start;

        img {
            margin-bottom: 0rem;
        }
    }

`;