import styled from 'styled-components';

export const Container = styled.div`
    .content-new-address {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 200px;
        flex-direction: column;
    }

    .subtitle {
        color: #707070;
        font-size: 1.3rem;
        margin-bottom: 40px;   
    }

    .input-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .container-cep {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .input-inactive {
        width: 600px;
        padding: 5px 70px;
        text-align: center;
        background-color: #C7C7C7;
        border: 1px solid #CFCFCF;
        border-radius: 20px;
        height: 60px;
        font-size: 1rem;
        color: #707070;
        margin: 10px 0;
    }

    .input-one {
        width: 600px;
        padding: 5px 70px;
        text-align: center;
        background-color: #FFF;
        border: 1px solid #CFCFCF;
        border-radius: 20px;
        height: 60px;
        font-size: 1rem;
        color: #707070;
        margin: 10px 0;
    }

    .input-one:focus {
        border: 1px solid #FA9F2A;
    }

    .buttom-disabled {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 15px;
        top: 23px;
        border: 1px solid #CFCFCF;
        border-radius: 100%;
        padding: 5px;
        background: none;
    }

    .border-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 15px;
        top: 23px;
        border: 1px solid #FA9F2A;
        border-radius: 100%;
        padding: 5px;
        background: none;
    }

    .forgot-cep {
        color: #FA9F2A;
        text-decoration: underline;
        font-weight: 300;
        font-size: 0.9rem;
        text-align: center;
    }

    .input {
        position: relative;
        display: flex;
        margin: 8px 0;
        width: 600px;
        align-items: center;
        justify-content: center;
    }

    .input-second {
        width: 600px;
        padding: 5px 0;
        text-align: center;
        background-color: #C7C7C7;
        border: 1px solid #CFCFCF;
        height: 60px;
        border-radius: 20px;
        color: #707070;
        margin: 10px 0;
        font-size: 1rem;
    }

    .border-icon-input {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 15px;
        top: 26px;
        border: 1px solid #FA9F2A;
        border-radius: 100%;
        padding: 5px;
    }

    input::placeholder {
        color: #C7C7C7;
        opacity: 1;
    }

    .input-second::placeholder {
        color: #707070;
        opacity: 1;
    }

    input:-ms-input-placeholder {
        color: #C7C7C7;
    }

    .input-second:-ms-input-placeholder {
        color: #707070;
    }

    input::-ms-input-placeholder {
        color: #C7C7C7;
    }

    .input-second::-ms-input-placeholder {
        color: #707070;
    }

    .button-new-address {
        align-self: center;
        border-radius: 19px;
        padding: 15px 0;
        font-size: 1.1rem;
        margin: 20px 0 50px;
        width: 500px;
        background-color: #FA9F2A;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }
    }

    @media(max-width: 640px) {
        .input-second,
        .input,
        .input-inactive,
        .input-one {
            width: 500px;
        }

        .button-new-address {
            width: 400px;
        }
    }

    @media(max-width: 540px) {
        .input-second,
        .input,
        .input-inactive,
        .input-one {
            width: 400px;
        }

        .subtitle {
            font-size: 1.1rem;
            text-align: center;
        }

        .border-icon svg,
        .buttom-disabled svg {
            width: 15px;
        }

        .border-icon,
        .buttom-disabled {
            padding: 3px 6px;
            top: 25px;
        }

        .input-second, 
        .input-one {
            font-size: 0.95rem;
        }

        .button-new-address {
            width: 300px;
        }
    }

    @media(max-width: 425px) {
        .input-second,
        .input,
        .input-inactive,
        .input-one {
            width: 300px;
        }

        .input-second, 
        .input-one {
            font-size: 0.9rem;
        }

        .subtitle {
            font-size: 1rem;
        }

        .input-one {
            padding: 5px 50px;
        }

        .button-new-address {
            width: 250px;
        }
    }
`;