import styled from "styled-components";

export const ButtonOrange = styled.button`
  background-color: #fa9f2a;
  justify-content: center;
  width: 7rem;
  align-self: flex-end;
  height: 100%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
  font-size: 1.1rem;
  @media (max-width: 720px) {
    width: 6rem;
  }
`;

export const SelectDaysContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  @media(max-width: 700px){
    gap: 0.6rem;
  }
`;

export const ButtonSelectDays = styled.button`
  background-color: ${({ selected }) => (selected ? "#FA9F2A" : "#FFFFFF")};
  color: ${({ selected }) => (selected ? "#FFFFFF" : "#FA9F2A")};
  border: 1px solid #fa9f2a;

  justify-content: center;
  width: 7rem;
  align-self: flex-end;
  height: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  font-size: 1.1rem;

  transition: filter ease-in 0.22s;

  @media (max-width: 720px) {
    width: 6rem;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
