import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, ButtonSendAgain } from './styles';
import { makeRecoverCodePassword, makeValidateVerifyCode } from '../../../../api/customer';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { ModalHelp } from '../../../../components/ModalHelp';
import ReactCodeInput from 'react-code-input';
import { FiRotateCcw } from 'react-icons/fi';
import LogoImg from '../../../../assets/posherlogo.png';
import { LoginFooter } from '../../components/LoginFooter';

const CodeRecover = ({ location }) => {
    const navigation = useHistory();
    let username = null;
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');

    if (location && location.state) {
        username = location.state.username;
    }

    const doSendEmailRecover = async () => {
        try {
            setLoading(true);

            const response = await makeRecoverCodePassword(username);

            if (response.ok) {
                toast.success('codigo enviado em seu email e SMS com sucesso!')
            }

            setLoading(false);

        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const doValidateVerifyCode = async () => {
        try {
            setLoading(true);

            const response = await makeValidateVerifyCode(code, username);

            if (response.valid) {
                navigation.push("/recover-password", { username: username, recoverCode: code });
            }

            setLoading(false);

        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (code.length === 6) {
            let isMounted = true;

            if (isMounted) {
                doValidateVerifyCode();
            }

            return () => { isMounted = false; };
        }
    }, [code]);

    useEffect(() => {
        if (!location || !location.state) {
            navigation.push('/');
        }
    }, []);
    
    return (
        <Container>
            <LoaderSpinner loading={loading} />
            <img src={LogoImg} alt="POSHER" className="logo-img-login" />

            <div className="code-forms">
                <span className="login-text">Digite o código enviado para o seu email ou SMS em até 10 minutos para confirmar a troca de senha.</span>

                <ReactCodeInput className="input-code" type='number' fields={6} onChange={cod => setCode(cod)} />

                <ButtonSendAgain onClick={doSendEmailRecover}>
                    <FiRotateCcw size={20} color="#FFF" />
                    <span className="text-button-code">enviar novamente</span>
                </ButtonSendAgain>
            </div>

            <LoginFooter />
        </Container>
    );
}

export default CodeRecover;