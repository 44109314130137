export function getTimeDiff(setActualTime, currentTime) {
  const dateFinal = new Date(currentTime).getTime();
  const now = new Date().getTime();
  let gap = now - dateFinal;
  let ss = 1000;
  let mm = ss * 60;
  let hh = mm * 60;
  let h = Math.floor(gap / hh);
  let m = Math.floor((gap % hh) / mm);
  let s = Math.floor((gap % mm) / ss);
  if (s < 10) {
    s = `0${s}`;
  }
  if (m < 10) {
    m = `0${m}`;
  }
  if (h < 10) {
    h = `0${h}`;
  }
  setActualTime(`${h}:${m}:${s}`);
}
