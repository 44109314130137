import styled from 'styled-components';

export const ContainerFooterLogin = styled.div`
  width: 95vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  transition: ease-in 0.4s;
  a {
    color: #fff;
  }
  a:hover {
    opacity: 0.8;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 1rem;
    a {
      font-size: 1.2rem;
    }
  }
`;
