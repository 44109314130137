import styled from "styled-components";

export const Title = styled.h3`
  color: #14213d;
  font-size: 1.4rem;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 4rem;
`;

export const Container = styled.div`
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  text-align: center;

  @media (max-width: 500px) {
    width: 90vw;
  }
`;

export const ViewBox = styled.div`
  margin: 0 2rem;
  align-items: center;
`;

export const ButtonImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 1.01rem;
  background-color: #fff;
`;

export const ButtonView = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonText = styled.h3`
  color: #14213d;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 5px 0;
  flex: 1;
  flex-wrap: wrap;
  width: 0.8;
  text-align: center;
`;

export const LineSeparator = styled.div`
  width: 100%;
  max-width: 900px !important;
  border: 0.1px solid #f2f2f2;
  margin: 50px 0;
`;

export const ContainerPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
