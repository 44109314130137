import styled from 'styled-components';

export const Container = styled.div`
    z-index: 5;
    width: 100vw;
    border: 1px solid #d9d9d9;
    

    .title-tag {
        display: none;
        position: absolute;
        top: 40px;
        left: -12px;
        background: #f9f9f9;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        padding: 0 12px;
        height: 30px;
        align-items: center;
        justify-content: center;
        z-index: 5;
    }
    
    .header {
        background-color: #f7f7f7;
        box-shadow: inset 0 -1px 0 #dcdcdc;
        z-index: 5;
    }

`;

export const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    max-width: 1080px !important;
    margin: auto;
    
    .logo > img {
        width: 30px;
    }
    
    .showSchedule{
        margin-right: 10%;
        white-space: nowrap;
        font-size: 0.8rem;
        color: #a6a5a5;
    }

    @media(max-width: 970px) {
        justify-content: flex-start;

        .logo {
            margin: 0 20px;
        }
    }

    @media(max-width: 768px) {
        justify-content: flex-end;

        .logo {
            display: none;
        }
    }
`;

export const NavBar = styled.nav`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.875rem;
    margin: 0 10%;

    list-style: none;

    .nav {
        text-decoration: none;
        margin-right: 30px;
        padding-bottom: 10px;
        margin-bottom: -10px;
    }

    .nav:hover {
        border: 0px;
        border-bottom: 1px;
        border-style: solid;
        border-color: #FA9F2A;
    }

    .nav > li {
        color: #14213D;
        transition: 0.3s color;
    }

    .nav > p {
        position: absolute;
        margin-top: -12px;
        margin-left: 3.5rem;
        color: #FA9F2A;
        border: 1px solid #FA9F2A;
        width: 17px;
        height: 17px;
        text-align: center;
        border-radius: 50%;
    }

    .nav:hover > li {
        color: #FA9F2A;
    }

    .active > li {
        color: #FA9F2A;
    }

    .active {
        border: 0px;
        border-bottom: 1px;
        border-style: solid;
        border-color: #FA9F2A;
    }

    @media(max-width: 1020px) {
        margin: 0 10%;
    }

    @media(max-width: 970px) {
        display: none;
    }
`;

export const ShowServicesTo = styled.div`
    margin: 0 9% 0 0;
    
    .showService {
        font-size: 0.8rem;
        color: #a6a5a5;
        text-transform: uppercase;
    }

    .location-container {
        margin-top: 5px;
        display: flex;
        align-items: center;
    }

    .location-container > svg {
        stroke: #14213D;
    }

    .location-container > span {
        font-size: 0.8rem;
        font-weight: 500;
        color: #14213D;
        margin-left: 5px;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .button-go-to-company {
        text-decoration: none;
        position: relative;
    }

    .button-go-to-company > .title-tag {
        top: 45px;
        color: #14213D;
        font-size: 0.8rem;
        width: 150px;
        left: 5px;
    }

    .button-go-to-company:hover > .title-tag {
        display: flex;
    }

    @media(max-width: 970px) {
        margin: 0 5%;
    }

    @media(max-width: 768px) {
        margin: 0 1%;
    }
`;

export const MoreInfos = styled.div`
    display: flex;
    align-items: center;

    .user {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        margin-right: 30px;
        color: #14213D;
        cursor: pointer;
    }

    .user > svg {
        stroke: #FA9F2A;
    }

    .user > span {
        font-size: 11px;
    }

    .wallet {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        margin-right: 30px;
        color: #14213D;
    }

    .wallet > svg {
        stroke: #FA9F2A;
        fill: #FA9F2A;
    }

    .wallet > span {
        font-size: 11px;
    }

    .exit {
        position: relative;
        background: none;
    }

    .exit > svg {
        stroke: #FA9F2A;
    }

    .user > .title-tag {
        top: 45px;
        right: -18px;
    }

    .user > .title-tag,
    .wallet > .title-tag {
        top: 45px;
        left: -18px;
    }

    .title-tag > span {
        font-size: 12px;
        color: #14213D;
    }

    .user:hover > .title-tag,
    .wallet:hover > .title-tag,
    .exit:hover > .title-tag {
        display: flex;
    }

    .iconSelected {
        background-color: rgba(250, 159, 42, 0.1);
        border-radius: 100px;
    }

    @media(max-width: 970px) {
        .exit,
        .user {
            display: none;
        }

        .wallet {
            position: absolute;
            right: 5px;
        }
    }

    @media(max-width: 768px) {
        .wallet {
            display: none;
        }
    }
`;

export const HeaderNoCompany = styled.div`

    @media(max-width: 768px) {
        margin-right: 17px;
    }

    .questionText {
        margin-top: -4px;
        font-size: 0.8rem;
        color: #000000;
}
    
    .yesNoText {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .yesButtomView {
        margin-left: 5px;
        background-color: #FA9F2A;
        border-radius: 30px;
        width: 100%;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .noButtomView {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 100%;
        background-color: #fff;
        border-radius: 30px;
        margin-left: 10px;
    }

    .yesButtomText {
        color: #fff;
        font-size: 0.7rem;
        text-align: center;
    }

    .noButtomText {
        color: #000;
        font-size: 0.7rem;
    }
`;