import React, { useEffect, useState } from 'react';
import { getHighLightedOffers } from '../../../../api/service_offer';
import ButtonsOffer from '../../../../components/ButtonsOffer';
import ItemsCarousel from 'react-items-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Container, ButtonsContainer } from './styles';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useHistory } from 'react-router-dom';
import { createOrder } from '../../../../api/service_orders';
import { toast } from 'react-toastify';
import ModalWarningOneClick from '../../../../components/Modals/ModalWarningOneClick';
import LoaderSpinner from '../../../../components/LoaderSpinner';

const HighLighted = (props) => {
  const navigation = useHistory();
  const [allHighLighted, setAllHighLighted] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [oneClick, setOneClick] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const numberOfCards = useResponsiveValue([3, 4, 5]);
  const [length, setLength] = useState(0);

  const doCreateOrder = async () => {
    try {
      setLoading(true);

      const response = await createOrder(oneClick.nextSuggestion.hour_begin, oneClick.nextSuggestion.job_day_id, oneClick.paymentMethod, oneClick.paymentSignature, oneClick.serviceId, oneClick.cardId, null, oneClick.addressId, oneClick.orderType, oneClick.walletSelected);

      setLoading(false);

      navigation.push("/completeOrder", {
        orderType: oneClick.orderType,
        attendanceLocale: response.attendance_locale,
        slot: oneClick.slot,
        hourBegin: oneClick.nextSuggestion.hour_begin,
        offer: oneClick.offer,
        paymentMethod: oneClick.paymentMethod,
        paymentSignature: oneClick.paymentSignature,
        pixCode: response.pix_qr_code,
        paymentExpirationMinutes: response.payment_expiration_minutes,
        nextSuggestion: response?.next_date_suggestion,
        serviceId: oneClick.serviceId,
        cardId: oneClick.cardId,
        addressId: oneClick.addressId,
        walletSelected: oneClick.walletSelected
      });

    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  }

  const searchOffers = async (setAllHighLighted, orderType) => {
    try {
      await getHighLightedOffers(setAllHighLighted, orderType);
    } catch (error) {
      setAllHighLighted([]);
    }
  }

  useEffect(() => {
    setOneClick(JSON.parse(sessionStorage.getItem('oneClick')));
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && props.orderType) {
      searchOffers(setAllHighLighted, props.orderType);
    }

    return () => { isMounted = false; };
  }, [props.orderType]);

  useEffect(() => {
    if (oneClick) {
      setLength(allHighLighted.length + 1);
    } else {
      setLength(allHighLighted.length);
    }
  }, [allHighLighted]);

  return (
    <Container>
      {oneClick &&
        <ModalWarningOneClick
          showModalOneClick={showModal}
          setShowModalOneClick={val => setShowModal(val)}
          oneClick={oneClick}
          clicked={option => option && doCreateOrder()}
        />
      }
      <LoaderSpinner loading={loading} />


      {(allHighLighted.length > 0 || oneClick) &&
        <div>
          <p className="title">Destaques</p>

          <div className="carousel">
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={numberOfCards}
              gutter={20}
              leftChevron={
                <button className="button-left">
                  <FaChevronLeft size={12} />
                </button>
              }
              rightChevron={
                <button className="button-right">
                  <FaChevronRight size={12} />
                </button>
              }
              outsideChevron={false}
              infiniteLoop={length >= numberOfCards ? true : false}
              alwaysShowChevrons={length >= numberOfCards ? true : false}
            >
              {oneClick &&
                <ButtonsContainer>
                  <ButtonsOffer offer={oneClick.offer} oneClick={oneClick} setShowModal={val => setShowModal(val)} />
                </ButtonsContainer>
              }

              {allHighLighted.length > 0 &&
                allHighLighted.map(offer => {
                  return (
                    <>
                      <ButtonsContainer key={offer.category_id}>
                        <ButtonsOffer offer={offer} />
                      </ButtonsContainer>
                    </>
                  )
                })}
            </ItemsCarousel>
          </div>
        </div>
      }
    </Container >
  );
}

export default HighLighted;