import styled from "styled-components";

export const TextAbout = styled.h3`
    color: #707070;
    font-size: 14px;

    display: flex;
    align-items: center;
    gap: 1rem;
    padding-right:  1rem;
`;
