import Moment from 'moment-timezone';
import React from 'react';
import Modal from 'react-modal';
import { PaymentType } from '../../../utils/utils';
import './styles.css';

const ModalWarningOneClick = ({ showModalOneClick, setShowModalOneClick, oneClick, clicked }) => {
    const doCloseModal = (option) => {
        setShowModalOneClick(false);
        clicked(option);
    }

    return (
        <div>
            <Modal
                isOpen={showModalOneClick}
                onRequestClose={() => doCloseModal(false)}
                overlayClassName="modal-overlay oneClick"
                className="modal-content oneClick"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">próximo agendamento</span>
                    <span className="title-header">serviço {oneClick.offer.title}</span>
                </div>

                <div className="content-body">
                    <span>
                        Confirmar agendamento do serviço {oneClick.offer.title} para o dia {Moment(oneClick.nextSuggestion.hour_begin).tz("America/Sao_Paulo").format('DD/MM')} às {Moment(oneClick.nextSuggestion.hour_begin).tz("America/Sao_Paulo").format('HH:mm')}, com a forma de pagamento: {PaymentType(oneClick.paymentMethod)}?
                    </span>
                </div>

                <div className="container-buttons">
                    <button className="accept-button" type="type" onClick={() => doCloseModal(true)}>
                        <span>confirmar</span>
                    </button>
                    <button className="cancel-button" type="button" onClick={() => doCloseModal(false)}>
                        <span>cancelar</span>
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalWarningOneClick;