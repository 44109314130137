import styled from 'styled-components';

export const Container = styled.div`
    z-index: 5;
    width: 100vw;
    border: 1px solid #d9d9d9;
    
    .header {
        background-color: #f7f7f7;
        box-shadow: inset 0 -1px 0 #dcdcdc;
        z-index: 5;
    }

    .title-tag {
        display: none;
        position: absolute;
        top: 40px;
        left: -12px;
        background: #f9f9f9;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        padding: 0 12px;
        height: 30px;
        align-items: center;
        justify-content: center;
        z-index: 5;
    }

`;

export const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    width: 100%;
    max-width: 1080px !important;
    margin: auto;
    
    .logo > img {
        width: 30px;
    }

    .enter {
        position: relative;
        background: none;
    }

    .enter > svg {
        stroke: #FA9F2A;
    }

    .enter:hover > .title-tag {
        display: flex;
    }

    .title-tag > span {
        font-size: 12px;
        color: #14213D;
    }

    @media(max-width: 970px) {
        .logo {
            margin: 0 20px;
        }
            
        .enter {
          position: relative;
          background: none;
          margin-right: 40px;
        }
    }
`;