import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { makePasswordLogin } from '../../../api/login';
import { onSignIn } from '../../../utils/auth';
import {
    makeRecoverCodePassword,
    getTermsConditions,
} from '../../../api/customer';
import { Container, ButtonSubmit, ButtonForgot } from './styles';
import LoaderSpinner from '../../../components/LoaderSpinner';
import ModalWarningRecover from '../../../components/Modals/ModalWarningRecover';
import LogoImg from '../../../assets/posherlogo.png';
import ModalTermsAndConditions from '../../../components/Modals/ModalTermsAndConditions';
import { LoginFooter } from '../components/LoginFooter';

import { t } from 'i18next';

const LoginPassword = ({ location }) => {
    let name = null;
    let username = null;
    let mobile_phone = null;
    let email = null;
    const navigation = useHistory();
    const [loading, setLoading] = useState(false);
    const [terms, setTerms] = useState();
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalRecover, setShowModalRecover] = useState(false);
    const userFromStorage = JSON.parse(
        localStorage.getItem('posher_logged_user'),
    );
    const [token, setToken] = useState('');
    const deep = JSON.parse(sessionStorage.getItem('deepLink'));

    if (location && location.state) {
        name = location.state.name;
        username = location.state.username;
        mobile_phone = location.state.mobile_phone;
        email = location.state.email;
    }

    const passwordLogin = async e => {
        e.preventDefault();

        if (password === '') {
            toast.warn('Favor informar a senha', { autoClose: 5000 });
        } else if (!token) {
            toast.warn('Favor preencher o reCAPTCHA');
        } else {
            try {
                setLoading(true);

                const data = await makePasswordLogin(username, password);

                setLoading(false);

                onSignIn(data);

                await getTermsConditions(setTerms);
            } catch (erro) {
                setLoading(false);
                toast.error(erro.message);
            }
        }
    };

    const doSendEmailRecover = async () => {
        try {
            setLoading(true);

            const response = await makeRecoverCodePassword(username);

            if (response.ok) {
                setLoading(false);

                navigation.push('/code-recover', { username });
            }

            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!location.state && !userFromStorage) {
            navigation.push('/');
        }
    }, []);

    useEffect(() => {
        const abortController = new AbortController()

        return () =>  abortController.abort() 
    }, [])

    useEffect(() => {
        if (terms?.privacy) {
            setShowModal(true);
        } else if (terms && !terms?.privacy) {
            if (deep) {
                navigation.push(`${deep.pathname}${deep.search}`, 'logged');
            } else {
                navigation.push('/home', 'logged');
            }
        }
    }, [terms]);

    return (
        <Container>
            <LoaderSpinner loading={loading} />
            <img src={LogoImg} alt="POSHER" className="logo-img-login" />

            <ModalTermsAndConditions
                showModalTerms={showModal}
                setShowModalTerms={val => setShowModal(val)}
                setLoading={val => setLoading(val)}
                privacy={terms?.privacy}
                service={terms?.service}
                version={terms?.version}
                setTerm={val => setTerms(val)}
            />

            <ModalWarningRecover
                email={email}
                phone={mobile_phone}
                showModalRecover={showModalRecover}
                setShowModalRecover={val => setShowModalRecover(val)}
                clicked={option => option && doSendEmailRecover()}
            />

            <div className="password-forms">
                <span className="login-text">
                    {location.state && <h1>{t("welcomeBack")}, {name}.</h1>}
                </span>

                <form onSubmit={passwordLogin}>
                    <input
                        type="password"
                        placeholder={t("passwordPlaceholder")}
                        id="password"
                        name="password"
                        onChange={e => setPassword(e.target.value)}
                    />
                </form>

                <ButtonSubmit type="submit" onClick={passwordLogin}>
                    <span>{t("enterButton")}</span>
                </ButtonSubmit>

                <ButtonForgot type="button" onClick={() => setShowModalRecover(true)}>
                    <span>{t("forgottenPassword")}</span>
                </ButtonForgot>

                <div className="recaptcha">
                    <ReCAPTCHA
                        sitekey="6Lfy_KoaAAAAAIOD9_hfRhgDYH5VgrIb_PcPmEuo"
                        onChange={token => setToken(token)}
                    />
                </div>
            </div>

            <LoginFooter />
        </Container>
    );
};

export default LoginPassword;
