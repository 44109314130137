import styled from 'styled-components';

export const Container = styled.div`
    width: 800px;
    padding: 5px 0;
    border-radius: 18px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 12px 0;
    align-self: center;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);

    .image {
        width: 100px;
        height: 100px;
        border-radius: 18px;
        margin: 5px 0;
        margin-left: 12px;
    };

    .info {
        display: flex;
        flex-direction: column;
        margin-left: 13px;
    };

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
    };

    .title-text {
        color: #14213D;
        font-size: 1.2rem;
        font-weight: bold;
        max-width: 590px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    };

    .status {
        color: #14213D;
        font-size: 0.9rem;
        font-weight: bold;
        margin: 2px 0;  
    };

    .status-disabled {
        color: #707070;
        font-size: 0.9rem;
        font-weight: bold;
        margin: 2px 0;  
    };

    .quantity {
        color: #707070;
        font-size: 0.9rem;
        margin: 2px 0;   
    };

    .quantity-bold {
        color: #14213D;
        font-weight: bold;
    };

    .expire {
        color: #707070;
        font-size: 0.9rem;
    };

    .button-more {
        background: none;
        width: 100%;
    };

    .more-info {
        color: #FA9F2A;
        font-size: 0.9rem;
        display: flex;
        align-self: flex-start;
        text-align: left;
        margin: 2px 0;  
    };

    .price {
        border-radius: 17px 17px 0 17px;
        position: absolute;
        top: 0;
        right: 0;
        width: 71px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #14213D;
    };

    .price-text {
        color: #14213D;
        font-size: 0.8rem;
    };

    .price-value {
        font-size: 0.85rem;
    };

    @media(max-width: 840px) {
        width: 600px;

        .image {
            width: 90px;
            height: 90px;
            margin-left: 10px;
        };

        .title-text {
            width: 400px;
        };
    }

    @media(max-width: 630px) {
        width: 500px;

        .image {
            width: 85px;
            height: 85px;
            margin-left: 8px;
        };

        .title-text {
            width: 300px;
        };
    }

    @media(max-width: 530px) {
        width: 400px;

        .image {
            width: 80px;
            height: 80px;
            margin-left: 8px;
        };

        .title-text {
            width: 200px;
        };
    }

    @media(max-width: 425px) {
        width: 350px;

        .image {
            width: 80px;
            height: 80px;
            margin-left: 8px;
        };

        .title-text {
            width: 170px;
        };
    }

    @media(max-width: 375px) {
        width: 300px;

        .image {
            width: 75px;
            height: 75px;
            margin-left: 8px;
        };

        .title-text {
            width: 120px;
            font-size: 1rem;
        };
    }
`;