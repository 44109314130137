import React from 'react';
import { Container, DescriptionCard, ButtonCloseAdblock } from './styles';
import logo from '../../assets/Logo.svg';
import { useDetectAdBlock } from "adblock-detect-react";

const AdblockCard = ({ setHasAdBlock }) => {
    const adBlockDetected = useDetectAdBlock();

    function checkAdBlock() {
        setHasAdBlock(adBlockDetected ? true : false);
        window.location.reload();
    }

    return (
        <Container>
            <img src={logo} alt="Logo adblock card" />
            <DescriptionCard>Olá! Muito legal que você entrou no aplicativo web da POSHER! Identificamos que você possui um AdBlocker instalado. Para poder usar nosso aplicativo web você precisará desabilitar o AdBlocker para nosso site. Por favor siga com o desbloqueio na área de configuração do seu computador e em seguida clique no botão abaixo para aproveitar tudo que nosso aplicativo oferece!</DescriptionCard>
            <ButtonCloseAdblock onClick={checkAdBlock}>Seguir para POSHER</ButtonCloseAdblock>
        </Container>
    );
}

export default AdblockCard;