import styled from 'styled-components';

export const ButtonDate = styled.button`
    background: none;
    margin-right: 12px;

    .button-view {
        width: 80px;
        height: 80px;
        border-radius: 16px;
        background-color: #FFFFFF;
    }

    .button-view.button-selected-view {
        border: 1px solid #FA9F2A;
    }

    .container-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .day-of-week-view {
        background-color: #FA9F2A;
        border-radius: 16px 16px 0 0;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-size: 0.9rem;
        text-align: center;
        text-transform: lowercase;
    }

    .day-view {
        background: none;
        margin-top: 3px;
        color: #FA9F2A;
        font-size: 1.8rem;
        text-align: center;
    }

    .day-text.day-selected-text {
        color: #14213D;
    }

    .month-view {
        border-radius: 0 0 16px 16px;
        background: none;
        color: #FA9F2A;
        margin-top: -3px;
        font-size: 1rem;
        text-align: center;
        text-transform: lowercase;
    }

    .month-text.month-selected-text {
        color: #14213D;
    }

    @media(max-width: 870px) {
        .button-view {
            width: 70px;
            height: 70px;
        }

        .day-view {
            font-size: 1.5rem;
        }

        .month-view {
            font-size: 0.9rem;
        }

        .day-of-week-view {
            font-size: 0.85rem;
        }
    }

    @media(max-width: 550px) {
        .button-view {
            width: 65px;
            height: 65px;
        }

        .day-view {
            font-size: 1.4rem;
        }

        .month-view {
            font-size: 0.85rem;
        }

        .day-of-week-view {
            font-size: 0.8rem;
        }
    }

    @media(max-width: 450px) {
        .button-view {
            width: 60px;
            height: 60px;
        }

        .day-view {
            font-size: 1.3rem;
        }

        .month-view {
            font-size: 0.8rem;
        }

        .day-of-week-view {
            font-size: 0.75rem;
        }
    }

    @media(max-width: 395px) {
        .button-view {
            width: 55px;
            height: 55px;
        }

        .day-view {
            font-size: 1rem;
        }

        .month-view {
            font-size: 0.75rem;
        }

        .day-of-week-view {
            font-size: 0.7rem;
        }
    }
`;