import styled from 'styled-components';
import imgSrc from '../../../../assets/images/bg-last-services.png';

export const Container = styled.div`
    .container {
        width: 1080px;
        background-color: #14213D;
        margin: 50px 0 0;
        background-image: url(${imgSrc});
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .carousel {
        width: 900px !important;
    }

    .group-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .title {
        color: #FFF;
        font-size: 1.4rem;
        margin-bottom: 5px;
        font-weight: 700;
    }

    .subTitle {
        color: #FFF;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    .button-left {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        left: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    .button-right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        right: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    .button-right svg {
        color: #3e3e3e;
        fill: #3e3e3e;
    }

    @media(max-width: 960px) {
        margin-left: 5px;
        .container,
        .carousel {
            max-width: 860px !important;
        }
    }

    @media(max-width: 910px) {
        .container,
        .carousel {
            margin: 0 10px;
            max-width: 700px !important;
        }

        .button-left,
        .button-right {
            top: 35px;
        }

        .container {
            padding: 40px 20px 20px;
        }
    }

    @media(max-width: 780px) {
        .title {
            font-size: 1.2rem;
        }

        .subTitle {
            font-size: 1rem;
        }
    }

    @media(max-width: 740px) {
        .container,
        .carousel {
            max-width: 600px !important;
        }
    }

    @media(max-width: 640px) {
        .container,
        .carousel {
            max-width: 400px !important;
        }

        .button-left,
        .button-right {
            top: 20px;
        }
    }

    @media(max-width: 425px) {
        .container {
            width: 100%;
            margin: 0;
        }

        .carousel {
            margin: 0 5px;
            max-width: 350px !important;
        }
    }

    @media(max-width: 390px) {
        .button-left,
        .button-right {
            display: none;
        }   
    }

    @media(max-width: 375px) {
        .container,
        .carousel {
            margin-left: 0px;
            max-width: 300px !important;
        }
    }

    @media(max-width: 320px) {
        .container,
        .carousel {
            max-width: 270px !important;
        }
    }
`;

export const ButtonsContainer = styled.div`

`;