import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { approveEstimateCall } from '../../../api/service_orders';
import LoaderSpinner from '../../LoaderSpinner';
import './styles.css'

export function ModalApproveApprovalOrder({ approveApprovalModalOpen, setApproveApprovalModalOpen, order, setApprovalModalOpen, type, searchOrders, setLoad, loading, setLoading, setAllOrders }) {

    const [closeAllModals, setCloseAllModals] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (closeAllModals) {
            setApproveApprovalModalOpen(false)
            setApprovalModalOpen(false);
        }
        setLoading(false);
    }, [approveApprovalModalOpen, closeAllModals, setApprovalModalOpen, setApproveApprovalModalOpen, setLoading, loading])

    return (
        <>
            <Modal
                isOpen={approveApprovalModalOpen}
                onRequestClose={() => setApproveApprovalModalOpen(!approveApprovalModalOpen)}
                overlayClassName="modal-overlay"
                className="modal-content reprove-order"
                ariaHideApp={false}
            >
                <LoaderSpinner loading={loading} />


                <button className="x-to-close" type="button" onClick={() => setApproveApprovalModalOpen(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">Confirmar</span>
                    <span className="title-header">APROVAÇÃO</span>
                </div>

                <div className="body-all-content">
                    <div className="content-reprove-body">
                        <span>Confirmar aprovação do orçamento do pedido Nº {order.id}?</span>
                    </div>
                </div>

                <div className="container-buttons">
                    <button
                        className="accept-button"
                        type="button"
                        onClick={() => approveEstimateCall(order, setCloseAllModals, setAllOrders, setLoad, searchOrders, type, setLoading, history)}>
                        <span>sim, aprovar</span>
                    </button>

                    <button
                        className="cancel-button"
                        type="button"
                        onClick={() => setApproveApprovalModalOpen(!approveApprovalModalOpen)}>
                        <span>não</span>
                    </button>
                </div>

            </Modal>

        </>
    )
}