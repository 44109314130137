import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalRemoveCard = ({ showModalRemove, setShowModalRemove, clicked }) => {
    const doCloseModal = (option) => {
        clicked(option);
        setShowModalRemove(false);
    }

    return (
            <div>
                <Modal
                    isOpen={showModalRemove}
                    onRequestClose={() => doCloseModal(false)}
                    overlayClassName="modal-overlay remove"
                    className="modal-content remove"
                    ariaHideApp={false}
                >
                    <div className="header-modal">
                        <span className="subtitle-header">confirmar</span>
                        <span className="title-header">DELETAR</span>
                    </div>

                    <div className="content-body">
                        <span>Confirmar exclusão do cartão?</span>
                    </div>

                    <div className="container-buttons">
                        <button className="accept-button" type="type" onClick={() => doCloseModal(true)}>
                            <span>sim, deletar</span>
                        </button>
                        <button className="cancel-button" type="button" onClick={() => doCloseModal(false)}>
                            <span>não</span>
                        </button>
                    </div>
                </Modal>
            </div>
    );
}

export default ModalRemoveCard;