import styled from 'styled-components';

export const Container = styled.aside`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;

    img{
        width: 8rem;
    }

`;

export const DescriptionCard = styled.h2`
    color: #717171;
    margin-top: 5rem;
    width: 55%;

    @media(max-width: 800px) {
        width: 100%;
    }
`;