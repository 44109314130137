import React, { useState, useEffect } from 'react';
import { deleteAddressInactivation } from '../../../../api/customer';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Container } from './styles';
import LoaderSpinner from '../../../../components/LoaderSpinner';

const UserAddress = ({ props, showModal, deleteAdd, change }) => {
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(false);

    const deleteAddress = async () => {
        try {
            setLoading(true);
            await deleteAddressInactivation(id);

            toast.success("Endereço removido com sucesso")
            setLoading(false);

            change();

        } catch (erro) {
            setLoading(false);
            toast.error("Erro ao remover endereço: " + erro.message)
        }
    }

    useEffect(() => {
        if (deleteAdd) {
            deleteAddress();
        } else {
            setId('');
        }
    }, [deleteAdd]);

    return (
        <Container>
            <LoaderSpinner loading={loading} />

            {props.map((address, index) => {
                return (
                    <div key={index} className="container-address">
                        <div className="about-address">
                            <span className="description-address">{address.description}</span>
                            <span className="name-address">{address.address}, {address.number}, {address.city_name}</span>
                        </div>

                        <button type="button" onClick={() => { showModal(true); setId(address.id) }} className="function-address">
                            <FiTrash2 size={15} color="#FA9F2A" />
                            <span>remover</span>
                        </button>
                    </div>
                )
            })}
        </Container>
    );
};

export default UserAddress;