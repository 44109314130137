import styled from "styled-components";

export const PixTimerContainer = styled.div`
  margin-top: 1.5rem;

  h2 {
    background: #ffffff;
    color: #707070;
    font-size: 2rem;
    border-radius: 1rem;
    height: 3.5rem;
    width: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h3 {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: normal;
  }
`;
