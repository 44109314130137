import React, { useEffect, useState } from 'react';
import MicrosoftLogin from 'react-microsoft-login';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makePasswordLogin, makeUsernameLogin } from '../../../../api/login';
import { ButtonMicrosoftLogin } from './styles';

import MicrosoftLogo from './MicrosoftLogo.svg';
import { onSignIn } from '../../../../utils/auth';
import { getTermsConditions } from '../../../../api/customer';
import ModalTermsAndConditions from '../../../../components/Modals/ModalTermsAndConditions';
import { t } from 'i18next';

export function MicrosoftButtonLogin({ setLoading }) {
  const [terms, setTerms] = useState();
  const deep = JSON.parse(sessionStorage.getItem('deepLink'));
  const [showModal, setShowModal] = useState(false);
  const [msalInstance, onMsalInstanceChange] = useState();
  const history = useHistory();

  const logoutHandler = () => {
    msalInstance.logout();
  };

  const authHandler = async (err, data, msal) => {
    if (!err && data) {
      onMsalInstanceChange(msal);
      try {
        setLoading(true);
        localStorage.setItem('provider', 'MSAL');
        localStorage.setItem('external-token', data.idToken.rawIdToken);
        const microsoftData = await makeUsernameLogin('MSAL');
        microsoftData.customer.external = true;
        if (microsoftData.registered) {
          const loginData = await makePasswordLogin(
            data.account.userName,
            'MSAL',
          );

          if (microsoftData.invalid_email) {
            localStorage.setItem('invalid_email', true);
          }
          onSignIn(loginData);
          await getTermsConditions(setTerms);
        } else {
          microsoftData.customer.username = microsoftData.customer.email;
          history.push('/signup', microsoftData.customer);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController()

    return () => abortController.abort()
  }, [])

  useEffect(() => {
    if (terms?.privacy) {
      setShowModal(true);
    } else if (terms && !terms?.privacy) {
      if (deep) {
        history.push(`${deep.pathname}${deep.search}`, 'logged');
      } else {
        history.push('/home', 'logged');
      }
    }
  }, [terms]);

  return msalInstance ? (
    <ButtonMicrosoftLogin onClick={logoutHandler}>
      <img src={MicrosoftLogo} alt="Microsoft logo" />
      Sair da conta
    </ButtonMicrosoftLogin>
  ) : (
    <>
      <ModalTermsAndConditions
        showModalTerms={showModal}
        setShowModalTerms={val => setShowModal(val)}
        setLoading={val => setLoading(val)}
        privacy={terms?.privacy}
        service={terms?.service}
        version={terms?.version}
        setTerm={val => setTerms(val)}
      />
      <MicrosoftLogin
        clientId={process.env.REACT_APP_CUSTOMER_MICROSOFT_CLIENT_ID}
        authCallback={authHandler}
        prompt="select_account"
      >
        <ButtonMicrosoftLogin>
          <img src={MicrosoftLogo} alt="Microsoft logo" />
            <span>{t("SignInMicrosoft")}</span>
        </ButtonMicrosoftLogin>
      </MicrosoftLogin>
    </>
  );
}
