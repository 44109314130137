import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import "@brainhubeu/react-carousel/lib/style.css";

export const GlobalStyle = createGlobalStyle`
  :root {
    --background: #fff;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  // font-size: 16px (Desktop)
  html {
    /* padding: 0 20%; */
    align-items: center;
    justify-content: center;
    align-self: center;
    @media (max-width: 1080px) {
      font-size: 93.75%; // 15px
    }

    @media (max-width: 720px) {
      font-size: 87.5%; // 14px
    }
  }

  body {
    background: #f7f7f7;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .hideScroll{
    overflow: hidden;
  }

  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background: #f7f7f7;
    border-radius: 0 0 20px 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 20px;
    -webkit-border-radius: 20px;
  }

  ::-webkit-scrollbar-corner {
    border-radius: 20px;
  }

  body, input, textarea, button {
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #fff;
  }

  h1, h2, h3, h4, h5, h6, strong {
    color: #fff;
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    background: #C7C7C7;
    color: #FFF;
    cursor: not-allowed;
  }

  .background-body {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1080px !important;
    margin: auto;
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
  }

  .line-separator {
    width: 100%;
    max-width: 960px !important;
    border: 0.1px solid #F2F2F2;
    margin: 20px 5%;

    @media(max-width: 1080px) {
        max-width: 880px !important;
    }

    @media(max-width: 930px) {
        max-width: 780px !important;
    }

    @media(max-width: 910px) {
        max-width: 650px !important;
    }

    @media(max-width: 740px) {
        max-width: 560px !important;
    }

    @media(max-width: 720px) {
        max-width: 550px !important;
        margin: 10px 5%;
    }

    @media(max-width: 640px) {
        max-width: 400px !important;
    }

    @media(max-width: 540px) {
        max-width: 350px !important;
    }

    @media(max-width: 425px) {
        max-width: 300px !important;
    }

    @media(max-width: 375px) {
        max-width: 270px !important;
    }

    @media(max-width: 320px) {
        max-width: 240px !important;
    }
  }

  .more-information {
    background: none;
    color: #FA9F2A;
    text-decoration: underline;
    width: 180px;
    text-align: left;
    padding: 10px 20px;
    position: absolute;
    bottom: 10px;
    left: 155px;
    font-size: 0.9rem;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

`;