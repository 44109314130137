import styled from "styled-components";

export const TableView = styled.tbody`
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    text-align: center;
    width: 45vw;
    display: flex;
    flex-direction: column;
    height: 20rem;
    overflow-y: scroll;
    color: #000;
    border: 1px solid #C4C4C4;

    @media(max-width: 700px){
        width: 70vw;
    }
    
    @media(max-width: 500px){
        width: 90vw;
    }
`;

export const TableRow = styled.tr`
    border-bottom: 0.1px solid #C4C4D4;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
    color: ${props => props.clicked && !props.disabled ? "#FA9F2A" : "#000"};
    transition: ease-in .2s;
    &:hover{
        color: #fff;
        background: #FA9F2A;
    }
    opacity: ${props => props.disabled && '0.4'};
    cursor: ${props => props.disabled && 'auto'};
`;


export const TableCode = styled.th`
    margin-right: auto;
`;

export const TableLocal = styled.th` 
    margin-right: auto;
    margin-left: auto;
    text-align: center;
`;

export const TableInfoScheduled = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    gap: 0.1rem;
    p{
        font-size: 0.9rem;
    }
`;