import React, { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getTermsConditions } from '../../../../api/customer';
import { makePasswordLogin, makeUsernameLogin } from '../../../../api/login';
import ModalTermsAndConditions from '../../../../components/Modals/ModalTermsAndConditions';
import { onSignIn } from '../../../../utils/auth';
import { ButtonGoogleContainer } from './styles';
import {t} from 'i18next'


export default function GoogleButtonLogin({ setLoading }) {
  const [terms, setTerms] = useState();
  const deep = JSON.parse(sessionStorage.getItem('deepLink'));
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const responseGoogle = async response => {
    if (response) {
      if(response.error){
        toast.warning('Não foi possível realizar login com Google');
      } else {
        try {
          setLoading(true);
          localStorage.setItem('provider', 'GCP');
          localStorage.setItem('external-request-code', response.code);
          const data = await makeUsernameLogin('GCP');

          localStorage.setItem('external-request-code', null);
          localStorage.setItem('external-token', data.external_authorization_token);

          data.customer.external = true;
          if (data.registered) {
            const loginData = await makePasswordLogin(
              data.customer.email,
              'GCP',
            );

            if (data.invalid_email) {
              localStorage.setItem('invalid_email', true);
            }
            onSignIn(loginData);
            await getTermsConditions(setTerms);
          } else {
            data.customer.username = data.customer.email;
            history.push('/signup', data.customer);
          }
        } catch (error) {
          toast.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController()

    return () => abortController.abort()
  }, [])

  useEffect(() => {
    if (terms?.privacy) {
      setShowModal(true);
    } else if (terms && !terms?.privacy) {
      if (deep) {
        history.push(`${deep.pathname}${deep.search}`, 'logged');
      } else {
        history.push('/home', 'logged');
      }
    }
  }, [terms]);

  return (
    <>
      <ModalTermsAndConditions
        showModalTerms={showModal}
        setShowModalTerms={val => setShowModal(val)}
        setLoading={val => setLoading(val)}
        privacy={terms?.privacy}
        service={terms?.service}
        version={terms?.version}
        setTerm={val => setTerms(val)}
      />
      <ButtonGoogleContainer>
        <GoogleLogin
          clientId={process.env.REACT_APP_CUSTOMER_GOOGLE_CLIENT_ID}
          buttonText={t("SignInGoogle")}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy="single_host_origin"
          prompt="select_account"
	        scope='openid profile https://www.googleapis.com/auth/calendar.events'
          responseType='code'
        >
        </GoogleLogin>
      </ButtonGoogleContainer>
    </>
  );
}
