import React from "react";

import { Input, InputContainer } from "./styles";

export function InputType({
  inputRef,
}) {

  return (
    <InputContainer>
      <Input
        placeholder="Descrição"
        ref={inputRef}
      />
    </InputContainer>
  );
}
