import styled from "styled-components";

export const ContainerOpacity = styled.div`
  cursor: pointer;
  transition: ease-in 0.2s;
  display: flex;
  justify-content: flex-end;
  height: 100%;

  &:hover {
    opacity: 0.6;
  }
`;
