import styled from 'styled-components';

export const Container = styled.div`
    .content-addresses {
        margin-top: 50px;
        padding-bottom: 200px;
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    .button-new {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-new-address {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 500px;
        align-self: center;
        padding: 15px 0;
        font-size: 1.2rem;
        background-color: #FA9F2A;
        border-radius: 19px;
        transition: .2s background;
    }

    .button-new-address:hover {
        background-color: #E6860B;
    }

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }
    }

    @media(max-width: 650px) {
        .button-new-address {
            width: 400px;
            font-size: 1rem;
        }
    }

    @media(max-width: 540px) {
        .button-new-address {
            width: 300px;
            font-size: 1rem;
        }
    }

    @media(max-width: 425px) {
        .button-new-address {
            width: 250px;
            font-size: 0.9rem;
        }
    }
`;