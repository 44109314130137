import styled from 'styled-components';

export const Container = styled.div`
    align-items: center;
    border: 1px solid #fff;
    background-color: #f7f7f7;
    z-index: 6;
    position: absolute;
    top: 40px;
    left: -100px;
    width: 250px;
    border-radius: 10px;

    .logo img {
        width: 150px;
    }
`;

export const NavBar = styled.nav`
    align-items: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    margin: 2% 2%;

    list-style: none;

    .dropNav {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 10px 20px;
    }

    .dropNav:hover {
        background: #F2F2F2;
        border-radius: 8px;
    }

    .dropNav > svg {
        stroke: #FA9F2A;
        transition: 0.2s stroke;
    }

    .dropNav > .goRight {
        position: absolute;
        right: 10px;
    }

    .dropNav:hover > li {
        color: #FA9F2A;
    }

    li {
        color: #14213D;
        transition: 0.2s color;
        margin-left: 5px;
    }

    .active li {
        color: #FA9F2A;
    }

    .divider {
        border: .1px solid #F2F2F2;
        margin: 2px 2px;
    }
`;