import {
  API_BASE,
  API_VERSION,
  CUSTOMERS,
  TYPES,
  VACCINATIONS,
} from "./helper/api_urls";
import { fetchWithTimeout, header } from "./helper/request";
import { getSectionsCatchErrors } from "./getSectionsCatchErrors";

const getVaccinationsTypes = async () => {
  let validationError = false;
  try {
    const { get_bearer } = header();
    const response = await fetchWithTimeout(
      `${API_BASE}${API_VERSION}${CUSTOMERS}${VACCINATIONS}${TYPES}`,
      get_bearer
    );

    if (response.ok) {
      const responseJson = response.data;
      return responseJson;
    } else {
      throw new Error(
        "erro na chamada getVaccinationsTypes: " + response.status
      );
    }
  } catch (error) {
    if (validationError) {
      throw error;
    } else {
      getSectionsCatchErrors(error, "Erro ao buscar os tipos das vacinações");
    }
  }
};

const getVaccinations = async () => {
  let validationError = false;
  try {
    const { get_bearer } = header();
    const response = await fetchWithTimeout(
      `${API_BASE}${API_VERSION}${CUSTOMERS}${VACCINATIONS}`,
      get_bearer
    );
    if (response.ok) {
      const responseJson = response.data;
      return responseJson;
    } else if (response.status === 404) {
      validationError = true;
      throw new Error("Nenhum registro encontrado");
    } else {
      throw new Error("erro na chamada getVaccinations: " + response.status);
    }
  } catch (error) {
    if (validationError) {
      throw error;
    } else {
      getSectionsCatchErrors(error, "Erro ao buscar as vacinações");
    }
  }
};

const registerVaccination = async (type_id, date, picture_file_content) => {
  let validationError = false;
  try {
    let data = {
      type_id: type_id,
      date: date,
      picture_file_content: picture_file_content,
    };
    const { post_bearer } = header(data);
    const response = await fetchWithTimeout(
      `${API_BASE}${API_VERSION}${CUSTOMERS}${VACCINATIONS}`,
      post_bearer
    );

    if (response.ok) {
      return;
    }

    if (response.status === 400) {
      validationError = true;
      const errorJson = response.data;
      throw new Error(errorJson.message);
    } else {
      throw new Error("erro na chamada getVaccinations: " + response.status);
    }
  } catch (error) {
    if (validationError) {
      throw error;
    } else {
      getSectionsCatchErrors(error, "Erro ao enviar vacinação.");
    }
  }
};

export { getVaccinationsTypes, getVaccinations, registerVaccination };
