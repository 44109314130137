import styled from 'styled-components';

export const Container = styled.div`

    .container-select-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .slots-range-data-view {
        margin-top: 20px;
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
    }

    .slots-range-data-text {
        color: #14213D;
        font-size: 1.1rem;
        text-align: center;
        margin-bottom: 10px;
        margin-left: 120px;
        margin-right: 120px;
    }

    .select-container {
        display: flex;
        align-items: center;
    }

    .select-time {
        width: 300px;
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 10px;
    }

    .select-time select::-ms-expand {
        display: none;
    }

    .select-time select {
        width: 100%;
        height: 50px;
        font-size: 1.2rem;
        cursor: pointer;
        color: #14213D;
        background-color: #F5F5F5;
        border: none;
        border-radius: 10px;
        border: 2px solid #F1F1F1;
        padding: 10px;
        padding-right: 40px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        transition: background-color 0.3s ease;
    }

    .select-time select option {
        font-size: 1rem;
        color: #14213D;
    }

    .select-time select::-webkit-scrollbar-track {
        background-color: #f7f7f7;
        border-radius: 20px;
        -webkit-border-radius: 20px;
    }

    .select-time select::-webkit-scrollbar {
        width: 7px;
        background: #f7f7f7;
        border-radius: 0 0 20px 20px;
    }

    .select-time select::-webkit-scrollbar-thumb {
        background: #dad7d7;
        border-radius: 20px;
        -webkit-border-radius: 20px;
    }

    .select-time select::-webkit-scrollbar-corner {
        border-radius: 20px;
    }

    .select-time select:hover {
        background-color: white;
    }

    .select-time .select-icon {
        position: absolute;
        top: 15px;
        right: 10px;
        pointer-events: none;
        padding-left: 5px;
    }

    @media(max-width: 870px) {
        .select-time {
            width: 200px;
        }

        .slots-range-data-text {
            margin-left: 80px;
            margin-right: 80px;
        }
    }

    @media(max-width: 450px) {
        .select-time {
            width: 160px;
        }

        .slots-range-data-text {
            margin-left: 60px;
            margin-right: 60px;
        }
    }

    @media(max-width: 410px) {
        .select-time {
            width: 130px;
        }

        .slots-range-data-text {
            margin-left: 45px;
            margin-right: 45px;
        }
    }


    @media (max-width: 660px){
        .select-container{
            gap: 1.5rem;
            flex-direction: column;
        }
    }

`;