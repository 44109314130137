import React, { useState } from 'react';
import { FiCalendar, FiClock, FiMap, FiStar } from 'react-icons/fi';
import Modal from 'react-modal';
import { formatCurrencyBRL } from '../../../utils/utils';
import Moment from 'moment-timezone';
import './styles.css';
import Rating from 'react-rating';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

const ModalProfessionalEvaluation = ({ order, callback }) => {
    const [stars, setStars] = useState(0);
    const [text, setText] = useState('');
    const [showModalEvaluating, setShowModalEvaluating] = useState(true);

    const ratingOrder = {
        rating: stars,
        comment: text,
    }

    return (
        <div>
            <Modal
                isOpen={showModalEvaluating}
                overlayClassName="modal-overlay evaluating"
                className="modal-content evaluating"
                ariaHideApp={false}
            >
                <div className="header">
                    <FiStar size={20} color="#14213D" />
                    <span className="title">avaliação de serviço</span>
                </div>

                <div className="content">
                    <div className="text-content">
                        <span className="title-content">SERVIÇO CONCLUÍDO!</span>
                        <span className="subtitle-content">Agora gostaríamos de ouvir sua opinião</span>
                    </div>

                    <div className="order">
                        <img className="img" src={order.service.thumb_url} />

                        <div className="price">
                            <span className="sign-price">R$ </span>
                            <span className="text-price">{formatCurrencyBRL(order.total_value).substring(2)}</span>
                        </div>

                        <div className="about-order">
                            <div className="about-order-container">
                                <span className="order-type">{order.service.name}</span>

                                <div className="order-info">
                                    <FiCalendar size={14} color="#000000" />
                                    <span className="text-order-info">{Moment(order.scheduled_date).format('DD/MM/YYYY')}</span>
                                </div>

                                <div className="order-info">
                                    <FiClock size={14} color="#000000" />
                                    <span className="text-order-info">{Moment(order.scheduled_date).tz("America/Sao_Paulo").format('HH:mm')}</span>
                                </div>

                                <div className="order-info">
                                    <FiMap size={14} color="#000000" />
                                    <span className="text-order-info">{order.address_description}</span>
                                </div>
                            </div>
                            <div className="container-professional">
                                <div className="professional">
                                    <img className="professional-img" src={order.professional.picture_url} />

                                    <div className="professional-about">
                                        <span className="professional-text">Profissional:</span>
                                        <span className="professional-name">{order.professional.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rating-stars">
                        <span className="text-rating">AVALIE O SERVIÇO</span>
                        <div className="stars-container">
                            <Rating
                                emptySymbol={<AiOutlineStar size={40} color="#FA9F2A" />}
                                fullSymbol={<AiFillStar size={40} color="#FA9F2A" />}
                                start={0}
                                stop={5}
                                fractions={2}
                                onChange={val => setStars(val)}
                                placeholderRating={stars}
                                placeholderSymbol={<AiFillStar size={40} color="#FA9F2A" />}
                            />
                        </div>
                    </div>

                    <div className="container-bottom-rating">
                        <textarea
                            className="text-area"
                            onChange={e => setText(e.target.value)}
                            value={text}
                            placeholder="Deixe algum comentário sobre como foi seu atendimento (opcional)"
                        />

                        <button
                            type="button"
                            onClick={() => { callback(ratingOrder); setShowModalEvaluating(false) }}
                            disabled={!stars}
                            className={stars ? "button-active" : "button-disabled"}
                        >
                            <span>enviar avaliação</span>
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ModalProfessionalEvaluation;