import i18next from 'i18next';
import 'intl';
import 'intl/locale-data/jsonp/pt-BR';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import { languageToMomentLocale } from '../i18n/languageUtils';

/* Converte para R$ */

export const formatCurrencyBRL = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);


export const getDayName = (date) => {
  const dayOfWeek = moment(date, "YYYY-MM-DD").local().format('dddd');
  return dayOfWeek;
}

export const getMonthName = (date) => {
  const monthName = moment(date).local().format('MMM');
  return monthName;
}

/* Diferença de minutos */

export const getDatesMinuteDifference = (dateFrom, dateTo) => {
  return Math.ceil(Math.abs(new Date(dateFrom) - dateTo) / (1000 * 60));
}

export const addDaysToCurrentDate = (dateFrom, numberOfDays) => {
  if (isNaN(numberOfDays)) {
    throw new Error('Argument must be a number, string given');
  }
  const newDate = moment(dateFrom, "YYYY-MM-DD").add('days', numberOfDays).toISOString();
  return newDate;
}

/* Métodos de pagamento */

const Methods = [
  {
    method: 'credit',
    value: 'Crédito'
  },
  {
    method: 'debit',
    value: 'Débito'
  },
  {
    method: 'cash',
    value: 'Dinheiro'
  },
  {
    method: 'billed',
    value: 'Faturado'
  },
  {
    method: 'package',
    value: 'Pacote'
  },
  {
    method: 'payroll',
    value: 'Débito em Folha'
  },
  {
    method: 'mercadopago',
    value: 'MercadoPago'
  },
  {
    method: 'alelo_multi',
    value: 'Alelo Multibenefícios'
  },
  {
    method: 'subscription',
    value: 'Assinatura'
  },
  {
    method: 'wallet',
    value: 'Carteira Digital'
  },
  {
    method: 'pix',
    value: 'Pix'
  },
];

export const PaymentType = (type) => {
  return Methods.find(method => method.method === type)?.value;
};

/* Formatar a hora */

export const formatHour = (dateSelected, time) => {
  let date = new Date(Date.parse(dateSelected) + (time * 60 * 60 * 1000));
  return date.toISOString();
}