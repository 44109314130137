export function getTimer(setActualTime, currentTime, iterator, setTimeOver) {
  window.onbeforeunload = () => {
    localStorage.setItem("pix_timer", iterator);
  };

  let ss = 1000;
  let mm = ss * 60;
  let hh = mm * 60;
  let dd = hh * 24;
  let gap = currentTime * mm - iterator * 1000;

  let h = Math.floor((gap % dd) / hh);
  let m = Math.floor((gap % hh) / mm);
  let s = Math.floor((gap % mm) / ss);

  window.onbeforeunload = () => {
    localStorage.setItem("pix_timer", iterator);
  };

  if (h <= 0 && m <= 0 && s <= 1) {
    setActualTime("00:00:00");
    setTimeOver(true);
    return;
  }

  if (s < 10) {
    s = `0${s}`;
  }
  if (m < 10) {
    m = `0${m}`;
  }
  if (h < 10) {
    h = `0${h}`;
  }

  setActualTime(`${h}:${m}:${s}`);
}
