import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 30px;

    .finish-order-button {
        background-color: #FA9F2A;
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 15px 0;
        color: #fff;
        font-size: 1.1rem;
    }

    @media(max-width: 450px) {
        .finish-order-button {
            width: 300px;
        }
    }
`;