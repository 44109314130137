import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { getCompaniesGroup } from '../../../api/requests/companies/changeCompany';
import './styles.css';

const ModalCompaniesGroup = ({ choosed }) => {
    const [showModalCompany, setShowModalCompany] = useState(false);
    const [companies, setCompanies] = useState([]);

    const array = [];

    const sendChoose = (company) => {
        array.push(company);
        choosed(array);
        setShowModalCompany(false);
    }

    async function getAllGroups() {
        await getCompaniesGroup().then((res) => {
            if (res.length > 0) {
                setCompanies(res);
                setShowModalCompany(true);
            }
        }).catch(() => { return })
    }

    useEffect(() => {
        getAllGroups();
    }, [])

    return (
        <div>
            <Modal
                isOpen={showModalCompany}
                onRequestClose={() => setShowModalCompany(false)}
                overlayClassName="modal-overlay company"
                className="modal-content company"
                ariaHideApp={false}
            >
                <button className="cancel-button" type="button" onClick={() => setShowModalCompany(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">Selecione seu</span>
                    <span className="title-header">ESCRITÓRIO</span>
                </div>

                <div className="content-body">
                    <span className="text-body">Oba! Vimos aqui que você tem algumas empresas no grupo nas quais pode entrar. Selecione abaixo em qual escritório você está:</span>
                    
                    <div className="separator"></div>

                    <div className="selects-companies">
                        {companies.map((company, companyIndex) => {
                            return (
                                <div key={companyIndex} className="container-radio-coupons">
                                    <div className="container-radio-buttons">
                                        <input
                                            type="radio"
                                            value={company}
                                            onChange={() => sendChoose(company)}
                                        />
                                        <label onClick={() => sendChoose(company)}>{company.name}</label>
                                    </div>

                                    <span className="company-description">{company.address.address}, {company.address.number}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <button className="close-button" type="type" onClick={() => setShowModalCompany(false)}>
                    <span>não, não é nenhuma dessas.</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalCompaniesGroup;