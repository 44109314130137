import styled from 'styled-components';
import imgSrc from '../../../assets/images/bg-complete-order-light.jpg';

export const Container = styled.div`
    .background-body {
        background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${imgSrc});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .container-all-right {
        margin-bottom: 40px;
    }

    .all-right {
        display: flex;
        background-color: #50B041;
        width: 90px;
        height: 90px;
        border-radius: 60px;
        align-items: center;
        justify-content: center;
        margin: 78px auto 20px;
    }

    .text-all-right {
        text-align: center;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        text-align: center;
    }

    .message {
        width: 100%;
        font-size: 1.3rem;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
    }

    .message-now {
        background: #14213d;
        border-radius: 20px;
        padding: 20px 0;
        width: 500px;
        text-align: center;
        font-size: 1.1rem;
    }

    .body-message {
        display: flex;
        align-items: center;
        background-color: #fff;
        color: #14213D;
        margin: 15px 0;
        border-radius: 20px;
        width: 800px;
        height: 100px;
    }

    .id-message {
        background-color: #14213D;
        color: #fff;
        height: 100px;
        padding: 0 20px;
        border-radius: 19px 0 0 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        font-weight: 700;
        font-size: 1.2rem;
    }

    .message-text {
        display: flex;
        align-items: center;
    }

    .text {
        font-size: 1rem;
        margin-left: 10px;
    }

    .container-messages {
        margin: 20px 0 100px;
    }

    @media(max-width: 840px) {
        .message-now {
            width: 400px;
        }
        
        .body-message {
            width: 600px;
        }
    }

    @media(max-width: 640px) {
        .all-right {
            width: 80px;
            height: 80px;
            margin-top: 50px;
        }

        .all-right svg {
            width: 40px;
        }

        .message {
            font-size: 1.1rem;
        }
        
        .message-now {
            width: 300px;
            padding: 15px 0;
        }
        
        .body-message {
            width: 500px;
            height: 90px;
        }

        .id-message {
            height: 90px;
        }

        .text {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 520px) {
        .all-right {
            width: 70px;
            height: 70px;
            margin-top: 50px;
        }

        .all-right svg {
            width: 40px;
        }

        .message {
            font-size: 1.1rem;
        }
        
        .message-now {
            width: 250px;
            padding: 10px 0;
            font-size: 1rem;
        }
        
        .body-message {
            width: 400px;
            height: 90px;
        }

        .id-message {
            height: 90px;
        }

        .text {
            font-size: 0.8rem;
        }

        .container-messages {
            margin: 10px 0 80px; 
        }
    }

    @media(max-width: 405px) {
        .message {
            font-size: 1.1rem;
        }
        
        .message-now {
            width: 250px;
            padding: 10px 0;
            font-size: 1rem;
        }
        
        .body-message {
            width: 350px;
            height: 90px;
        }

        .id-message {
            height: 90px;
            margin-right: 5px;
        }

        .text {
            font-size: 0.8rem;
            margin-left: 5px;
        }

        .body-message svg {
            width: 30px;
        }
    }

    @media(max-width: 360px) {
        .message {
            font-size: 1.1rem;
        }
        
        .message-now {
            width: 250px;
            padding: 10px 0;
            font-size: 1rem;
        }
        
        .body-message {
            width: 300px;
            height: 90px;
            padding-right: 10px;
        }

        .id-message {
            height: 90px;
            padding: 0 12px;
            margin-right: 5px;
        }

        .text {
            font-size: 0.8rem;
            margin-left: 5px;
        }

        .body-message svg {
            width: 30px;
        }
    }
`;