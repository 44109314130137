import { getSectionsCatchErrors } from './getSectionsCatchErrors';
import {
    API_BASE,
    API_VERSION,
    DISCOUNT_COUPONS_AVAILABLE,
    DISCOUNT_COUPONS_BY_CODE
} from './helper/api_urls';
import { fetchWithTimeout, header } from './helper/request';
import { validateGetResponse } from './helper/validateGetResponse';

const getDiscountCoupons = async (callback, orderDate) => {
    try {
        const { get_bearer } = header();
        let getUrl
        if (orderDate) {
            getUrl = `${API_BASE}${API_VERSION}${DISCOUNT_COUPONS_AVAILABLE}?orderDate=${orderDate}`;
        } else {
            getUrl = `${API_BASE}${API_VERSION}${DISCOUNT_COUPONS_AVAILABLE}`;
        }

        const response = await fetchWithTimeout(getUrl, get_bearer);
        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error("erro na chamada getDiscountCoupons: " + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de cupons de desconto');
    }
}

const getDiscountCouponsByCode = async (discount_coupon_code, orderDate) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${DISCOUNT_COUPONS_BY_CODE}?code=${discount_coupon_code}&orderDate=${orderDate}`, get_bearer);

        if (response.ok) {
            const data = response.data;
            return data

        } else if (response.status === 404) {
            validationError = true;
            throw new Error('Cupom inválido');

        } else {
            throw new Error('erro na chamada getDiscountCouponsByCode: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de cupom de desconto');
        }
    }
}

const validateDiscountCoupon = async (discount_coupon_id, service_id, payment_method, order_type) => {
    try {
        const getUrl = `${API_BASE}${API_VERSION}${DISCOUNT_COUPONS_BY_CODE}/${discount_coupon_id}/valid?service_id=${service_id}&payment_method=${payment_method}&order_type=${order_type}`
        return await validateGetResponse(
            getUrl,
            "Erro ao validar cupom de desconto.",
        )
    } catch (error) {
        throw error
    }
}

export {
    getDiscountCoupons,
    getDiscountCouponsByCode,
    validateDiscountCoupon
};
