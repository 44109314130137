import styled from "styled-components";

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 18px;
  margin-bottom: 30px;
  width: 60%;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
  transition: ease-in 0.2s;
  word-break: break-all;
  cursor: pointer;
  @media (max-width: 750px) {
    width: 95%;
  }
  &:hover {
    transform: scale(1.05);
    box-shadow: 5px 5px 6px 5px rgba(0, 0, 0, 0.3);
  }
`;

export const MessageTitle = styled.h3`
  text-align: center;
  font-size: 1.8rem;
  color: #fa9f2a;
  padding: 30px 20px;
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const MessageHeader = styled.span`
  background-color: #fa9f2a;
  border-radius: 18px 18px 0 0;
  width: 100%;
  padding: 0.3rem 0;
  text-align: center;
`;
