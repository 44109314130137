import React from "react"
import { SubscriptionCardContainer } from "../styles"

const SubscriptionCard = ({ subscription, setShowModalCancel, setSelectedCard }) => {
  const { subscription_plan } = subscription

  function handleCancelSubscription(item) {
    setShowModalCancel(prevState => !prevState)
    setSelectedCard(item)
  }

  return (
    <SubscriptionCardContainer>
      <aside>
        <img src={subscription_plan.thumb_url} alt={subscription_plan.name} />
        {subscription.renewable ? <button type="button" onClick={() => handleCancelSubscription(subscription)}>cancelar</button> : <span>Renovação cancelada</span>}
      </aside>
      <article>
        <span><b>{subscription_plan.name}</b></span>
        <span>Situação: {subscription.state_name}</span>
        <span>Valor: {subscription.amount_paid}</span>
        <span>Pago em: {subscription.paid_at}</span>
      </article>
    </SubscriptionCardContainer>
  )
}

export default SubscriptionCard
