import styled from 'styled-components';

export const Container = styled.div`
    .content-change-password {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .title-change-password {
        color: #14213D;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .container-input {
        position: relative;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    .inputs {
        width: 600px;
        text-align: center;
        margin: 15px 0;
        font-size: 1.1rem;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #CFCFCF;
        background-color: #fff;
        color: #14213D;
    }

    .inputs:focus {
        border: 1px solid #FA9F2A;
    }

    .border-icon-input {
        position: absolute;
        right: 15px;
        top: 30px;
        background-color: #FA9F2A;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .buttom-view {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;
        margin-top: 15px;
        background-color: #FA9F2A;
        border-radius: 20px;
        width: 400px;
        font-size: 1.1rem;
    }

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }
    }

    @media(max-width: 650px) {
        .inputs {
            width: 500px;
            height: 55px;
        }

        .border-icon-input {
            top: 27px;
        }

        .buttom-view {
            width: 350px;
        }
    }

    @media(max-width: 540px) {
        .inputs {
            width: 400px;
            height: 50px;
            font-size: 1rem;
        }

        .border-icon-input {
            top: 25px;
        }

        .buttom-view {
            width: 300px;
            padding: 12px 0;
        }
    }

    @media(max-width: 410px) {
        .title-change-password {
            font-size: 1.1rem;
        }

        .inputs {
            width: 300px;
            font-size: 0.9rem;
        }

        .border-icon-input {
            top: 25px;
        }

        .buttom-view {
            width: 250px;
            font-size: 0.9rem;
            padding: 12px 0;
        }
    }
`;