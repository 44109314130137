import styled from 'styled-components';

export const Container = styled.div`
    .content-body-signaturePlan {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 200px;
    }

    .signaturePlan-container {
        display: flex;
        margin: 10px 0;
        border: 1px solid #F2F2F2;
        box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.2);
        width: 800px;
        padding: 10px;
        border-radius: 20px;
        position: relative;
    }

    .signaturePlan-image {
        width: 100px;
        height: 100px;
        border-radius: 20px;
    }

    .content-signaturePlan {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #14213D;
    }

    .title-signaturePlan {
        font-weight: 700;
        font-size: 1.2rem;
        width: 550px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .description-signaturePlan {
        font-size: 1rem;
        width: 550px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #B7B7B7;
        margin: 5px 0;
    }

    .button-more {
        background: none;
        color: #FA9F2A;
        text-decoration: underline;
        font-size: 1.1rem;
        margin-top: 5px;
        transition: .2s color;
    }

    .price-signaturePlan {
        border: 1px solid #14213D;
        color: #14213D;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 20px 20px 0 20px;
        padding: 5px 15px;
        font-size: 1rem;
    }

    .divisor-signaturePlan {
        border: 1px solid #F2F2F2;
        width: 70%;
        margin: 20px 0;
    }

    .total-signaturePlan {
        color: #14213D;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .button-pay-disabled {
        font-size: 1.2rem;
        border-radius: 20px;
        margin-top: 10px;
        padding: 20px 230px;
        background-color: #CFCFCF;
    }

    .button-pay {
        font-size: 1.2rem;
        border-radius: 20px;
        margin-top: 10px;
        padding: 20px 230px;
        background-color: #FA9F2A;
        transition: .2s background;
    }

    .button-more:hover {
        color: #E6860B;
    }

    .button-pay:hover {
        background-color: #E6860B; 
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    }

    @media(max-width: 960px) {
        .content-body-signaturePlan {
            padding-bottom: 100px;
        }

        .footer-service-list .line-separator {
            display: none;
        }
    }

    @media(max-width: 890px) {
        .signaturePlan-container {
            width: 600px;
        }

        .description-signaturePlan,
        .title-signaturePlan {
            width: 350px;
        }

        .button-pay,
        .button-pay-disabled {
            padding: 15px 150px;
        }
    }

    @media(max-width: 630px) {
        .signaturePlan-container {
            width: 500px;
        }

        .description-signaturePlan,
        .title-signaturePlan {
            width: 270px;
        }

        .button-pay,
        .button-pay-disabled {
            padding: 15px 130px;
            margin-top: 5px;
        }
    }

    @media(max-width: 520px) {
        .signaturePlan-container {
            width: 400px;
        }

        .signaturePlan-image {
            width: 80px;
            height: 80px;
        }

        .title-signaturePlan {
            font-size: 1rem;
        }

        .description-signaturePlan {
            font-size: 0.9rem;
        }

        .description-signaturePlan,
        .title-signaturePlan {
            width: 200px;
        }

        .button-more {
            font-size: 0.9rem;
        }

        .price-signaturePlan {
            font-size: 0.9rem;
        }

        .button-pay,
        .button-pay-disabled {
            padding: 15px 130px;
            margin-top: 5px;
        }
    }

    @media(max-width: 450px) {
        .button-pay,
        .button-pay-disabled {
            padding: 15px 100px;
            margin-top: 5px;
            font-size: 1rem;
        }
    }

    @media(max-width: 425px) {
        .signaturePlan-container {
            width: 350px;
        }

        .title-signaturePlan {
            font-size: 0.9rem;
        }

        .description-signaturePlan {
            font-size: 0.75rem;
        }

        .description-signaturePlan,
        .title-signaturePlan {
            width: 150px;
        }

        .button-more {
            font-size: 0.85rem;
            margin-top: 10px;
        }

        .price-signaturePlan {
            font-size: 0.9rem;
            padding: 3px 8px;
        }
    }

    @media(max-width: 375px) {
        .signaturePlan-container {
            width: 300px;
            padding: 5px;
        }

        .description-signaturePlan,
        .title-signaturePlan {
            width: 110px;
        }
    }
`;