import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { getTermsConditions, makeAcceptTerms } from '../../../api/customer';
import { onSignOut } from '../../../utils/auth';
import './styles.css';

const ModalTermsAndConditions = ({ showModalTerms, setShowModalTerms, setLoading, privacy, service, version, onCancel, setTerm }) => {
    const [isSelected, setSelection] = useState(false);
    const [newTerms, setNewTerms] = useState();

    const servicePrivacy = service + privacy;

    const cancelAccept = () => {
        onSignOut();

        if (onCancel) {
            onCancel();
        }

        setShowModalTerms(false);
    }

    useEffect(() => {
        if (newTerms) {
            setTerm(newTerms);
        }
    }, [newTerms]);

    const submitTerms = async () => {
        try {
            setShowModalTerms(false);
            setLoading(true);

            await makeAcceptTerms(version);
            await getTermsConditions(setNewTerms);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <div>
            <Modal
                isOpen={showModalTerms}
                onRequestClose={() => cancelAccept()}
                overlayClassName="modal-overlay terms"
                className="modal-content terms"
                ariaHideApp={false}
            >
                <button className="cancel-button" type="button" onClick={() => cancelAccept()}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">Confira nossos</span>
                    <span className="title-header">TERMOS E CONDIÇÕES E POLÍTICA DE PRIVACIDADE</span>
                </div>

                <div className="content-body">
                    <div dangerouslySetInnerHTML={{ __html: servicePrivacy }}></div>
                </div>

                <div className="check-terms">
                    <div className="container-check">
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => setSelection(!isSelected)}
                        />
                        <span className="checkmark" onClick={() => setSelection(!isSelected)}></span>
                    </div>
                    <label className="ext-check" onClick={() => setSelection(!isSelected)}>Li e aceito</label>
                </div>

                <button
                    type="button"
                    disabled={!isSelected ? true : false}
                    className={isSelected ? "accept-button" : "disabled-button"}
                    onClick={submitTerms}
                >
                    <span>continuar</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalTermsAndConditions;