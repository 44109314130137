import React from "react";
import { FiGlobe } from "react-icons/fi";
import { Container, Title } from "./styles";

const ServiceBanner = ({ bannerUrl, title, serviceType }) => {
  return (
    <Container>
      <div className="image-service-list">
        <img src={bannerUrl} className="service-banner" alt="service-banner" />
      </div>

      <div className="title">
        <Title>{title}</Title>
      </div>

      {"online" === serviceType &&
        <div className="online-view">
          <FiGlobe width={12} />
          <span className="online-text">online</span>
        </div>
      }
    </Container>
  );
};

export default ServiceBanner;
