import Bowser from 'bowser'

const validBrowserVersions = {
  firefox: '>25.0',
  edge: '>15.0',
  chrome: '>45.0',
  safari: '>7.1',
  opera: '>32.0'
}

const validMobileBrowserVersions = {
  firefox: '>95.0',
  edge: '>15.0',
  chrome: '>96.0',
  safari: '>15.1',
  opera: '>64.0'
}

function browserDetect(setIsValidBrowser) {
  const browser = Bowser.getParser(window.navigator.userAgent)

  if (browser.getPlatform().type === 'desktop') {
    setIsValidBrowser(browser.satisfies(validBrowserVersions))

  } else if (browser.getPlatform().type === 'mobile' || 'tablet') {
    setIsValidBrowser(browser.satisfies(validMobileBrowserVersions))

  } else {
    setIsValidBrowser(false)
  }
}

export default browserDetect;