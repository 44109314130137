import { fetchWithTimeout, header } from './helper/request';
import {
    API_SCHEDULE,
    DATES,
    MAP,
    PLACES,
    POSITIONS,
    POSITION_TYPES,
    SCHEDULES,
    TIMES,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';
import { getUser } from '../utils/auth';

const getPlaces = async () => {
    let validationError = false;

    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}`,
            get_bearer_common,
        );
        if (response.ok) {
            const data = response.data;
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            const data = response.data;

            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de edifícios');
        }
    }
};

const getDates = async build => {
    let validationError = false;
    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}/${build}${DATES}`,
            get_bearer_common,
        );
        if (response.ok) {
            const data = response.data;
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data;
            validationError = true;

            throw new Error(responseJson.message);
        } else {
            const data = response.data;

            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de datas');
        }
    }
};

const getTimes = async (build, date) => {
    let validationError = false;
    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}/${build}${DATES}/${date}${TIMES}`,
            get_bearer_common,
        );
        if (response.ok) {
            const data = response.data;
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data;
            validationError = true;

            throw new Error(responseJson.message);
        } else {
            const data = response.data;

            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(
                error,
                'Erro ao realizar consulta de horários disponíveis',
            );
        }
    }
};

const getPositionTypes = async buildingId => {
    let validationError = false;

    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}/${buildingId}${POSITION_TYPES}`,
            get_bearer_common,
        );

        if (response.ok) {
            const data = response.data;
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            const data = response.data;

            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(
                error,
                'Erro ao realizar consulta de tipos de posições',
            );
        }
    }
};

const getPositionsMapUrl = (buildingId, positionType, dateFrom, dateTo) => {
    return `${API_SCHEDULE}${PLACES}/${buildingId}${POSITIONS}/${positionType}${MAP}?date_from=${dateFrom}&date_to=${dateTo}&token=${getUser().token
        }`;
};

const getPositionMapWithCode = async (buildingId, positionType, code) => {
    let validationError = false;
    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}/${buildingId}${POSITIONS}/${positionType}/${code}`, get_bearer_common,
        );

        if (response.ok) {
            const data = response.data;
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            const data = response.data;

            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de posições por código');
        }
    }
};

const getPositions = async (
    buildingId,
    positionType,
    dateFrom,
    dateTo,
    filteredPerson,
) => {
    let validationError = false;
    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}/${buildingId}${POSITIONS}/${positionType}?date_from=${dateFrom}&date_to=${dateTo}&scheduled_user_name=${filteredPerson}`,
            get_bearer_common,
        );

        if (response.ok) {
            const data = response.data;
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            const data = response.data;

            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de posições');
        }
    }
};

const getSchedules = async (
    dateFrom,
    dateTo,
    placePositionId,
) => {
    let validationError = false;
    try {
        const { get_bearer_common } = header();
        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}${POSITIONS}${SCHEDULES}?place_position_id=${placePositionId}&date_from=${dateFrom}&date_to=${dateTo}`,
            get_bearer_common,
        );
        if (response.ok) {
            const data = response.data;
            console.log('Data', data)
            return data;
        }
        if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            const data = response.data;

            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de agendamentos');
        }
    }
}

const schedulePosition = async (
    buildingId,
    positionType,
    positionId,
    dateFrom,
    dateTo,
    description,
    note,
) => {
    let validationError = false;

    try {
        const { post_bearer_common } = header({
            date_from: dateFrom,
            date_to: dateTo,
            description,
            note,
        });

        const response = await fetchWithTimeout(
            `${API_SCHEDULE}${PLACES}/${buildingId}${POSITIONS}/${positionType}/${positionId}${SCHEDULES}`,
            post_bearer_common,
        );

        if (response.status === 400) {
            const responseJson = response.data;
            validationError = true;

            throw new Error(responseJson.message);
        } else if (response.status !== 200) {
            const data = response.data;
            throw new Error(data.message);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao agendar');
        }
    }
};

export {
    getPlaces,
    getDates,
    getTimes,
    getPositionTypes,
    getPositionsMapUrl,
    getPositionMapWithCode,
    getPositions,
    getSchedules,
    schedulePosition,
};