import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getVaccinations } from "../../../api/vaccinations";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import TabButton from "../../../components/TabButton";
import {
    ButtonAddVaccination,
    Container,
    PageContainer,
    VaccinationContainer,
    VaccinationDate,
    VaccinationType,
    NotFound,
} from "./styles";
import ModalNewVaccination from "./ModalNewVaccination";
import LoaderSpinner from "../../../components/LoaderSpinner";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getUser } from "../../../utils/auth";

const Vaccination = () => {
    const history = useHistory();

    if (!getUser()?.company?.customer_vaccination_enabled) {
      history.replace("/home");
    }

    const [loading, setLoading] = useState(false);
    const [showVaccinationModal, setShowVaccinationModal] = useState(false);
    const [vaccinations, setVaccinations] = useState([]);



    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setLoading(true);
            async function getAllVaccinations() {
                await getVaccinations()
                    .then((res) => setVaccinations(res))
                    .catch((err) => toast.error(err.message))
                    .finally(() => setLoading(false));
            }
            getAllVaccinations();
        }
        return () => (isMounted = false);
    }, [showVaccinationModal]);

    return (
        <Container>
            {showVaccinationModal && (
                <ModalNewVaccination
                    showVaccinationModal={showVaccinationModal}
                    setShowVaccinationModal={setShowVaccinationModal}
                />
            )}

            <LoaderSpinner loading={loading} />
            <Header screen="user" orderType="corporate" />
            <PageContainer>
                {!vaccinations ? (
                    <NotFound>
                        Nenhuma vacina encontrada! Registre uma nova vacina
                        clicando no botão <b>adicionar vacinação</b>.
                    </NotFound>
                ) : (
                    vaccinations?.map((vaccination) => (
                        <VaccinationContainer key={vaccination.id}>
                            <VaccinationDate>
                                {moment(vaccination.date).format("DD/MM/YYYY")}
                            </VaccinationDate>
                            <VaccinationType>
                                {vaccination.typeDescription}
                            </VaccinationType>
                        </VaccinationContainer>
                    ))
                )}
                <ButtonAddVaccination
                    onClick={() =>
                        setShowVaccinationModal(!showVaccinationModal)
                    }
                >
                    adicionar vacinação
                </ButtonAddVaccination>

                <div className="footer-service-list">
                    <div className="line-separator"></div>
                    <Footer />
                </div>
                <TabButton screen="home" />
            </PageContainer>
        </Container>
    );
};

export default Vaccination;
