import React, { useEffect, useState } from 'react';
import { getLastServiceOffers } from '../../../../api/service_offer';
import ButtonsOffer from '../../../../components/ButtonsOffer';
import ItemsCarousel from 'react-items-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Container, ButtonsContainer } from './styles';
import { toast } from 'react-toastify';
import { useResponsiveValue } from '@theme-ui/match-media';

const LastServicesOffers = (props) => {
  const [allLastServiceOffers, setLastServiceOffers] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const numberOfCards = useResponsiveValue([3, 4, 5]);

  const searchOffers = async (setLastServiceOffers, orderType) => {
    try {
      await getLastServiceOffers(setLastServiceOffers, orderType);

    } catch (error) {
      toast.error(error.message);
      setLastServiceOffers([]);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && props.orderType) {
      searchOffers(setLastServiceOffers, props.orderType);
    }

    return () => { isMounted = false; };
  }, [props.orderType])

  return (
    <Container>
      {allLastServiceOffers.length > 0 &&
        <div className="container">
          <div className="group-text">
            <span className="title">ÚLTIMOS SERVIÇOS AGENDADOS?</span>
            <span className="subTitle">bora repetir?</span>
          </div>
          
          <div className="carousel">
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={numberOfCards}
              gutter={20}
              leftChevron={
                <button className="button-left">
                  <FaChevronLeft size={12} />
                </button>
              }
              rightChevron={
                <button className="button-right">
                  <FaChevronRight size={12} />
                </button>
              }
              outsideChevron={false}
              infiniteLoop={allLastServiceOffers.length > numberOfCards ? true : false}
              alwaysShowChevrons={allLastServiceOffers.lenght > numberOfCards ? true : false}
            >

              {allLastServiceOffers.map((offer, offerIndex) => {
                return (
                  <ButtonsContainer key={offerIndex}>
                    <ButtonsOffer offer={offer} card="lastUsed" />
                  </ButtonsContainer>
                )
              })}

            </ItemsCarousel>
          </div>
        </div>
      }
    </Container>
  );
}

export default LastServicesOffers;