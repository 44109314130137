import React, { useEffect, useState } from 'react';
import { getGroupedServiceOffers } from '../../../../api/service_offer';
import { toast } from 'react-toastify';
import { Container, LineSeparator } from './styles';
import GroupService from './GroupService';

const GroupedServiceOffers = (props) => {
  const [allGroupedServiceOffers, setAllGroupedServiceOffers] = useState([]);

  const searchOffers = async (setAllGroupedServiceOffers, orderType) => {
    try {
      await getGroupedServiceOffers(setAllGroupedServiceOffers, orderType);

    } catch (error) {
      toast.error(error.message)
      setAllGroupedServiceOffers([]);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && props.orderType) {
      searchOffers(setAllGroupedServiceOffers, props.orderType);
    }

    return () => { isMounted = false; };
  }, [props.orderType])
  
  return (
    <Container>
      {allGroupedServiceOffers.length > 0 &&
        allGroupedServiceOffers.map((grouped, grouped_index) => {
          const sum = grouped.offers.length + grouped?.servicePackages.length;

          return (
            <div className="content" key={grouped_index}>
              <LineSeparator />

              <h3 className="title">{grouped.group_name}</h3>

              <GroupService offers={grouped.offers} packs={grouped.servicePackages} sum={sum} subscriptionPlans={grouped.subscriptionPlans}/>
            </div>
          )
        })
      }
    </Container>

  );
}

export default GroupedServiceOffers;