import styled from 'styled-components';

export const ButtonHour = styled.button`
    background: none;
    display: flex;
    align-items: center;

    .time-scroll-button {
        width: 60px;
        border-right: 1px solid #EEEEEE;
        padding: 5px 0;
    }

    .wait-list {
        width: 75px; 
        border-right: 0; 
        margin-right: 10px;
    }

    .container-hour {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;
    }

    .wait-list-text {
        font-size: 1rem; 
        color: #FA9F2A; 
        font-weight: 500;
        text-align: center;
    }

    .time-text {
        font-size: 1.2rem;
        color: #FA9F2A;
        font-weight: 500;
    }

    .wait-list-text.wait-list-text-selected,
    .minute-text.minute-text-selected,
    .time-text.time-text-selected {
        font-weight: 700;
        color: #14213D;
    }

    .minute-text {
        font-size: 1rem;
        color: #FA9F2A;
        margin-top: -5px;
        font-weight: 500;
    }

    .container-hour-minute {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @media(max-width: 870px) {
        .time-scroll-button {
            width: 52px;
        }

        .wait-list-text {
            font-size: 0.9rem; 
        }

        .time-text {
            font-size: 1.1rem;
        }

        .minute-text {
            font-size: 0.9rem;
        }
    }

    @media(max-width: 375px) {
        .wait-list-text {
            font-size: 0.8rem; 
        }

        .time-text {
            font-size: 1rem;
        }

        .minute-text {
            font-size: 0.8rem;
        }
    }
`;