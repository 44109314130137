import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .carousel {
        width: 900px !important;
    }

    .orderNotFound {
        color: #C4C4C4;
        margin-left: 20px;
        text-align: center;
    }

    .button-left {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        left: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    .button-right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        right: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    .button-right svg {
        color: #3e3e3e;
        fill: #3e3e3e;
    }

    .title {
        color: #14213D;
        font-size: 1.4rem;
        margin-bottom: 10px;
        margin-top: 30px;
        font-weight: 700;
        margin-bottom: 20px;
        margin-left: 20px;
        text-align: center;
    }

    @media(max-width: 1000px) {
        .carousel {
            width: 850px !important;
        }

        .button-left,
        .button-right {
            top: 47px;
        }
    }

    @media(max-width: 920px) {
        .carousel {
            width: 800px !important;
        }

        .button-left,
        .button-right {
            top: 48px;
        }
    }

    @media(max-width: 910px) {
        .carousel {
            width: 700px !important;
        }

        .button-left,
        .button-right {
            top: 45px;
        }
    }

    @media(max-width: 780px) {
        .carousel {
            width: 650px !important;
        }

        .button-left,
        .button-right {
            top: 45px;
            width: 35px;
            height: 35px;
        }

        .title {
            font-size: 1.2rem;
        }
    }

    @media(max-width: 700px) {
        .carousel {
            width: 600px !important;
        }

        .button-left,
        .button-right {
            top: 35px;
        }
    }

    @media(max-width: 639px) {
        .carousel {
            width: 450px !important;
        }

        .button-left,
        .button-right {
            top: 48px;
            width: 30px;
            height: 30px;
        }
    }

    @media(max-width: 520px) {
        .carousel {
            width: 400px !important;
        }

        .button-left,
        .button-right {
            top: 42px;
        }
    }

    @media(max-width: 440px) {
        .carousel {
            width: 350px !important;
        }
    }

    @media(max-width: 390px) {
        .carousel {
            width: 300px !important;
        }

        .button-left,
        .button-right {
            top: 37px;
        }
    }

    @media(max-width: 320px) {
        .carousel {
            width: 280px !important;
        }

        .button-left,
        .button-right {
            top: 35px;
        }
    }
`;

export const ButtonsContainer = styled.div`

`;