import React, { useState, useEffect } from 'react';
import { Container } from './styles';
import { getService } from '../../../api/service_offer';
import { formatCurrencyBRL } from '../../../utils/utils';
import Moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { FiDollarSign, FiTag } from 'react-icons/fi';

const DiscountCouponCard = (props) => {
    const coupon = props.coupon;
    const [service, setService] = useState();
    let description = '';

    if (coupon.discount_value) {
        description += formatCurrencyBRL(coupon.discount_value);
    } else {
        description += coupon.discount_percentage + '%'
    }

    const findService = async () => {
        try {
            if(coupon.service_id) {
                await getService(setService, 1, coupon.service_id);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            findService();
        }

        return () => { isMounted = false; };
    }, []);

    if (coupon.service_id) {
        if (coupon.order_type && coupon.order_type === 'residential') {
            description += ` OFF no serviço ${service && service.name} residencial`;

        } else if (coupon.order_type && coupon.order_type === 'corporate') {
            description += ` OFF no serviço ${service && service.name} corporativo`;

        } else {
            description += ` OFF no serviço ${service && service.name}`;

        }
    } else if (coupon.order_type) {
        if (coupon.order_type === 'residential') {
            description += ` OFF em todos os serviços residenciais`;

        } else if (coupon.order_type === 'corporate') {
            description += ` OFF em todos os serviços corporativos`;
        }
    } else {
        description += ' OFF em todos os serviços';
    }

    return (
        <Container>
            <span className="code-text">{coupon.code}</span>
            <FiTag width={15} className="card-icon"></FiTag>
            <span className="description-text">{description}</span>
            <span className="validate">válido até: {Moment(coupon.expire_at).subtract({ hour: 1 }).format('DD/MM/YYYY')}</span>
        </Container>
    );
}

export default DiscountCouponCard;