import { API_BASE, API_VERSION, PAYMENTS, SUBSCRIPTIONS, SUBSCRIPTION_PLANS, SUBSCRIPTION_PLANS_METHODS, SUBSCRIPTION_PLANS_PURCHASE } from "../../../helper/api_urls";
import { validateDeleteResponse } from "../../../helper/validateDeleteResponse";
import { validateGetResponse } from "../../../helper/validateGetResponse";
import { validatePostResponse } from "../../../helper/validateResponse";

const getSignaturesPaymentsMethods = async (callback, signature_id) => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${PAYMENTS}${SUBSCRIPTION_PLANS_METHODS}?plan_id=${signature_id}`;

    return callback(await validateGetResponse(
      getUrl,
      "Erro ao verificar métodos de pagamento por assinatura"
    ))
  } catch (error) {
    throw error;
  }
}

const getSignaturesByCategory = async (callback, category_id) => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${SUBSCRIPTION_PLANS_PURCHASE}?category_id=${category_id}`;

    return callback(await validateGetResponse(
      getUrl,
      "Erro ao verificar planos de assinatura por categoria"
    ))
  } catch (error) {
    throw error;
  }
}

const getAllSubscriptionsPlans = async () => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${SUBSCRIPTION_PLANS}${SUBSCRIPTIONS}`;
    return await validateGetResponse(
      getUrl,
      "Erro ao requisitar todos os planos de assinatura contratados.",
    )
  } catch (error) {
    throw error;
  }
}

const postSignatureContract = async (signature_id, method, card_id) => {
  try {
    const postUrl = `${API_BASE}${API_VERSION}${SUBSCRIPTION_PLANS}/${signature_id}${SUBSCRIPTIONS}`;

    const body = {
      payment_method: method,
      card_id: card_id
    }

    return await validatePostResponse(
      postUrl,
      "Erro ao confirmar contrato de plano de assinatura",
      body
    )
  } catch (error) {
    throw error;
  }
}

const cancelSignaturePlan = async (signature_id) => {
  try {
    const deleteUrl = `${API_BASE}${API_VERSION}${SUBSCRIPTION_PLANS}${SUBSCRIPTIONS}/${signature_id}`;

    return await validateDeleteResponse(
      deleteUrl,
      "Erro ao cancelar plano de assinatura",
      null
    )
  } catch (error) {
    throw error;
  }
}

export {
  getSignaturesPaymentsMethods,
  getAllSubscriptionsPlans,
  getSignaturesByCategory,
  postSignatureContract,
  cancelSignaturePlan,
}

