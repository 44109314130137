import React from 'react';
import { FiCheck, FiClock, FiDollarSign, FiMail } from 'react-icons/fi';
import { Container } from './styles';
import Header from '../../../components/Header';
import TabButton from '../../../components/TabButton';

const PackageCompletedOrder = ({ location }) => {
    const { pack } = location.state;

    return (
        <Container>
            <Header screen="home" orderType="corporate" />

            <div className="background-body">
                <div className="container-all-right">
                    <div className="all-right">
                        <FiCheck size={50} color="#FFFFFF" />
                    </div>

                    <span className="text-all-right">Tudo certo :)</span>
                </div>

                <span className="message">Sua compra foi concluída</span>

                <div className="message-now">
                    <span className="text-now">E AGORA?</span>
                </div>

                <div className="container-messages">
                    <div className="body-message">
                        <div className="id-message">
                            <span className="id">1</span>
                        </div>
                        <div className="message-text">
                            <FiMail size={20} color="#14213D" />
                            <span className="text">Acabamos de enviar um recibo da compra para seu email.</span>
                        </div>
                    </div>

                    <div className="body-message">
                        <div className="id-message">
                            <span className="id">2</span>
                        </div>
                        <div className="message-text">
                            <FiDollarSign size={20} color="#14213D" />
                            <span className="text">Se você usou cartão de crédito, seu pacote já está disponível. Caso seja boleto aguarde até 2 dias para confirmação.</span>
                        </div>
                    </div>

                    <div className="body-message">
                        <div className="id-message">
                            <span className="id">3</span>
                        </div>
                        <div className="message-text">
                            <FiClock size={20} color="#14213D" />
                            <span className="text">Seu pacote estará disponível para usar por {pack.validity_days} dias após a confirmação de pagamento.</span>
                        </div>
                    </div>
                </div>
            </div>

            <TabButton screen="home"/>
        </Container>
    );
}

export default PackageCompletedOrder;