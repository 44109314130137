//Transform device locale to language code
const localeToLanguage = {
  "pt": "ptBR",
  "en": "en",
  "es": "es"
}

//Transform country code to language code
const countryToLanguage = {
  "BR": "ptBR",
  "AR": "es",
  "CL": "es",
  "CO": "es",
  "MX": "es",
  "PE": "es",
}

const languageToMomentLocale = {
  "ptBR": "pt-br",
  "en": "en",
  "es": "es"
}

const getDeviceLocaleLanguage = () => {
  const locale = navigator.language

  //Extract only language in locale identifier
  const localeSubstring = locale.substring(0, 2);
  return localeToLanguage[localeSubstring]
}

export {
  localeToLanguage,
  countryToLanguage,
  getDeviceLocaleLanguage,
  languageToMomentLocale
}