import styled from "styled-components";

export const TextAboutEmphasis = styled.h3`
  color: #14213d;
  font-size: 14px;
  margin-left: 5px;
  text-transform: uppercase;
  font-weight: 700;

  display: flex;
  gap: 2rem;
  align-items: center;
  width: 25rem;

  @media (max-width: 720px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    width: 70vw;
    font-size: 12px;
  }
`;
