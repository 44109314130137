import { t } from 'i18next';
import React, { useEffect, useState, Fragment } from 'react';
import { FaQuestion } from 'react-icons/fa';
import { Linking, View, TouchableOpacity, Text, Modal } from 'react-native';
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { style, ModalStyle } from './style';

const ModalHelp = ({ mode, showButton = true, showModal, setShowModal }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const doToggleCloseModal = () => {
        setModalVisible(!modalVisible);

        if (setShowModal) {
            setShowModal(!modalVisible);
        }
    }

    useEffect(() => {
        if (showModal) {
            setModalVisible(showModal);
        }
    }, [showModal]);

    return (
        <Fragment>
            {showButton &&
                <View style={style.needHelp}>
                    <TouchableOpacity style={style.buttonNeedHelp} onPress={doToggleCloseModal}>
                        <Text style={[style.helpText, (mode === 'dark') && style.dark]}>
                            {t("needHelp?")}
                    </Text>
                        <View style={[style.borderIconHelp, (mode === 'dark') && style.dark]}>
                            <FaQuestion
                                size={15}
                                color="#fff"
                                // style={[style.iconHelp, (mode === 'dark') && style.dark]}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
            }

            <Modal animationType="fade" transparent={true} visible={modalVisible}>
                <View style={ModalStyle.containerHelper}>
                    <View style={ModalStyle.containerBody}>
                        <View style={ModalStyle.headerHelper}>
                            <Text style={ModalStyle.subtitleHeader}>{t("needWord")}</Text>
                            <Text style={ModalStyle.titleHeader}>{t("helpWord")}</Text>
                        </View>

                        <View style={ModalStyle.containerContent}>
                            <Text style={ModalStyle.content}>{t("customerSupport")}</Text>
                            <Text style={ModalStyle.cellphone}>(11) 4673-3092 ou</Text>
                            <TouchableOpacity onPress={() => Linking.openURL('mailto:contato@posherapp.com')}>
                                <Text style={ModalStyle.content}>contato@posherapp.com</Text>
                            </TouchableOpacity>
                        </View >

                        <TouchableOpacity onPress={doToggleCloseModal} style={ModalStyle.buttonClose}>
                            <Text style={ModalStyle.textButtonModal}>{t("closeButton")}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal >
        </Fragment>
    );
}

export default ModalHelp;