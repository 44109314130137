import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../../utils/auth';
import './styles.css';

const ModalInvalidEmail = () => {
    const email = getUser().customer.email;
    const navigation = useHistory();
    const [showModal, setShowModal] = useState(true)

    const doCloseModal = () => {
        setShowModal(false);
        navigation.push('/personalData');
    }

    return (
        <div>
            <Modal
                isOpen={showModal}
                overlayClassName="modal-overlay InvalidEmail"
                className="modal-content InvalidEmail"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">email inválido:</span>
                    <span className="title-header">Altere seu email</span>
                </div>

                <div className="content-body">
                    <span className="text-body">Seu email <span>{email}</span> parece ser inválido. Corrija seu cadastro.</span>
                </div>

                <button className="accept-button" type="type" onClick={() => doCloseModal()}>
                    <span>ir para configurações</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalInvalidEmail;