import {
  API_BASE,
  API_VERSION,
  CUSTOMER_ORDERS,
  FEEDBACK,
} from "../../helper/api_urls";
import { validateGetResponse } from "../../helper/validateGetResponse";
import { validatePutResponse } from "../../helper/validatePutResponse";

const putSendClientFeedback = async (order_id, positive) => {
  try {
    const putUrl = `${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${order_id}${FEEDBACK}`;

    const body = {
      positive,
    };
    return await validatePutResponse(putUrl, "Erro ao dar o feedback", body);
  } catch (error) {
    throw error;
  }
};

const getOrderToGiveFeedback = async (order_id) => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${CUSTOMER_ORDERS}/${order_id}`;

    return await validateGetResponse(
      getUrl,
      "Erro ao pegar informações do pedido para dar feedback"
    );
  } catch (error) {
    throw error;
  }
};

export { putSendClientFeedback, getOrderToGiveFeedback };
