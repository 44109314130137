import { getSectionsCatchErrors } from "./getSectionsCatchErrors";
import { API_BASE, API_VERSION, CUSTOMERS_TERMS } from "./helper/api_urls";
import { fetchWithTimeout, header } from "./helper/request";

const getTermsConditionsToSee = async (callback) => {
    try {
        const { get } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_TERMS}`, get);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getTermsConditions: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de termos');
    }
}


export {
    getTermsConditionsToSee
}