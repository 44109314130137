import styled from "styled-components";
import { COLORS } from "../../utils/colors";

document.body.style.backgroundColor = "#F4F0F4"; 

export const Container = styled.aside`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: ${COLORS.background};
  color: #14213D;
  font-family: 'Roboto';
  font-weight: 'normal';
  font-size: 13;
  flex-direction: column;
  gap: 1rem;

  .text-title {
    color: #14213D;
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    bottom: 10px;
    text-align: center;
    margin-bottom: 30px;
  }

  .text-info {
    color: #14213D;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    width: 700px;
    text-align: center;
    align-self: center;
    margin-bottom: 30px;
  }

  @media (max-width: 700px) {
    .text-info {
      left: 203px;
      width: 300px;
    }

    .text-title {
      left: 3px
    }
  }
`

export const Input = styled.div`
    margin: 20px 0;
    position: relative;
    width: 600px;
    align-items: center;

    input {
      width: 600px;
      padding: 5px 50px;
      text-align: center;
      background-color: #FFF;
      border: 1px solid #CFCFCF;
      border-radius: 20px;
      height: 50px;
      font-size: 1rem;
      color: #707070;
    }

    @media (max-width: 700px) {
      input {
        position: relative;
        width: 300px;
        left: 150px;
      }
    }
`

export const Button = styled.div`
    .button-disabled,
    .button-actived {
        background-color: #FA9F2A;
        width: 450px;
        padding: 15px 0;
        font-size: 1rem;
        margin-bottom: 10px;
        align-items: center;
        border-radius: 20px;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    }

    .button-disabled {
        background-color: #707070;
    }

    @media (max-width: 700px) {
      .button-disabled,
      .button-actived {
        width: 300px;
      }
    }
`

export const ScratchCardContainer = styled.div`
  display: flex;
  flex-direction: column;

  .image-container {
    cursor: pointer;
    align-self: center;

    canvas {
      border: solid;
      border-width: 3px;
      border-radius: 20px;
      border-color: #FA9F2A;
    }
    

    @media (max-width: 700px) {
      left: 155px;
    }

    @media (max-width: 400px) {
      left: 195px;
    }
  }

  .text-container {
    position: relative;
    width: 430px;
    height: 180px;
    top: 40px;
    left: 147px;
    font-size: 2rem;
    vertical-align: middle;
    align-items: center;
    text-align: center;
    display: table-cell;
    overflow: hidden;

    span {
      width: 100%;
      overflow: hidden;
    }
    
    @media (max-width: 700px) {
      left: 5px;
      top: 15px;
    }
    
    @media (max-width: 400px) {
    left: 5px;
    top: -5px;
    }
  }
`