import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalDeleteAddress = ({ showModalDelete, setShowModalDelete, clicked }) => {
    const doCloseModal = (option) => {
        clicked(option);
        setShowModalDelete(false);
    }

    return (
        <div>
            <Modal
                isOpen={showModalDelete}
                onRequestClose={() => doCloseModal(false)}
                overlayClassName="modal-overlay"
                className="modal-content delete-address"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">confirmar</span>
                    <span className="title-header">cancelamento</span>
                </div>

                <div className="content-body">
                    <span>Confirmar exclusão permanente do endereço?</span>
                </div>

                <div className="container-buttons">
                    <button className="accept-button" type="type" onClick={() => doCloseModal(true)}>
                        <span>sim, excluir</span>
                    </button>
                    <button className="cancel-button" type="button" onClick={() => doCloseModal(false)}>
                        <span>não</span>
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalDeleteAddress;