import React, { useEffect, useState } from 'react';
import { getDiscountCoupons } from '../../api/discount_coupons'
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import TabButton from '../../components/TabButton';
import DiscountCouponCard from './DiscountCard';
import { Container } from './styles';
import SkeletonDiscountCoupons from './SkeletonDiscountCoupons';
import Footer from '../../components/Footer';
import { debounce } from 'throttle-debounce';
import { getAvailableCashbacks } from '../../api/cashback'
import CashbackCard from './CashbackCard';

const DiscountCoupons = () => {
    const [allCoupons, setAllCoupons] = useState([]);
    const [allCashbacks, setAllCashbacks] = useState([]);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);

    const cancelLoading = () => {
        setTimeout(() => {
            if (document.getElementById('couponBody')) {
                document.getElementById('couponBody').style.display = 'flex';
            }
            setLoadingSkeleton(false);
        }, 1000);
    }

    const searchDiscountCoupons = async () => {
        try {
            await getDiscountCoupons(setAllCoupons);

        } catch (error) {
            toast.error(error.message);
            setAllCoupons([]);
        } finally {
            cancelLoading();
        }
    }

    const searchAvailableCashbacks = async () => {
        try {
            await getAvailableCashbacks().then((cashback) => setAllCashbacks(cashback))
        } catch (error) {
            toast.error(error.message);
            setAllCoupons([]);
        }
    }

    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            if (document.getElementById('couponBody')) {
                document.getElementById('couponBody').style.display = 'none';
            }
            setLoadingSkeleton(true);
            setTimeout(() => debounce(5000, searchDiscountCoupons()), 1500);
            setTimeout(() => debounce(5000, searchAvailableCashbacks()), 1500);
        }

        return () => { isMounted = false; };
    }, []);

    return (
        <Container>
            <Header screen="coupons" orderType="corporate" />
            <div className="background-body">

                {loadingSkeleton &&
                    <SkeletonDiscountCoupons />
                }

                <div className="content-coupon" id="couponBody">
                    {(allCoupons.length === 0 && allCashbacks.length === 0) &&
                        <span className="not-found-text">Nenhum cupom encontrado.</span>
                    }

                    {allCoupons.length > 0 &&
                        <div className="container-coupons-discount">
                            <div className="container-titles">
                                <span className="title">Estes são seus cupons disponíveis.</span>
                                <span className="title">Você pode utilizá-los no checkout</span>
                            </div>

                            <div className="coupon-list">
                                {allCoupons.map((coupon, couponIndex) => {
                                    return (
                                        <div key={couponIndex}>
                                            <DiscountCouponCard coupon={coupon} />
                                        </div>
                                    )
                                })}
                            </div>


                        </div>
                    }

                    {allCashbacks.length > 0 &&
                        <div className="container-cashback-discount">
                            <div className="container-titles">
                                <span className="title">Estes são seus cashbacks disponíveis.</span>
                            </div>
                            <div className="coupon-list">
                                {allCashbacks.map((cashback, cashbackIndex) => {
                                    return (
                                        <div key={cashbackIndex}>
                                            <CashbackCard cashback={cashback} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>

                {!loadingSkeleton &&
                    <div className="footer-service-list">
                        <div className="line-separator"></div>
                        <Footer />
                    </div>
                }
            </div>

            <TabButton screen="coupons" />
        </Container>
    );
};

export default DiscountCoupons;
