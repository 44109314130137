import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    CUSTOMERS,
    CUSTOMER_COUNTRIES,
    CUSTOMERS_TERMS,
    CUSTOMERS_PASSWORD_RECOVER,
    CUSTOMERS_RECOVER_CODE,
    CUSTOMER_ADDRESSES,
    ADDRESS_ZIP,
    CUSTOMERS_PASSWORD,
    CUSTOMER_FRIENDS_RECOMMENDATION,
    CUSTOMER_COMPANY,
    LANGUAGE,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';
import { validateDeleteResponse } from './helper/validateDeleteResponse'
import { validatePutResponse } from './helper/validatePutResponse';

/*Post*/

const makeRegisterCustomer = async (email, cpf, identificationDocument, name, mobilePhone, country, password) => {
    let validationError = false;
    try {
        let data = {
            cpf: cpf,
            identification_document: identificationDocument,
            email: email,
            name: name,
            country: country,
            mobile_phone: mobilePhone,
            password: password
        };

        const { post } = header(data);

        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS}`, post);

        if (response.status === 400) {
            const data = response.data;

            validationError = true;

            throw new Error(data.message);
        } else if (!response.ok) {
            throw new Error('erro na chamada makeRegisterCustomer: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar criação do cadastro');
        }
    }
}

const makeAcceptTerms = async (version) => {
    try {
        const { post_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_TERMS}/${version}`, post_bearer);

        if (!response.ok) {
            throw new Error('erro na chamada makeAcceptTerms: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar aceitação dos termos');
    }
}

const makeRecoverPassword = async (username) => {
    try {
        const { post } = header({ "username": username });
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_PASSWORD_RECOVER}`, post);

        if (!response.ok) {
            throw new Error('erro na chamada makeRecoverPassword: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao enviar o email');
    }
}

const makeRecoverCodePassword = async (username) => {
    try {
        const { post } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_RECOVER_CODE}?username=${username}`, post);

        if (response.ok) {
            return response;

        } else if (!response.ok) {
            throw new Error('erro na chamada makeRecoverCodePassword: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao enviar codigo de verificacao');
    }
}

const makeValidateVerifyCode = async (code, username) => {
    try {
        const { get } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_RECOVER_CODE}/${code}/valid?username=${username}`, get);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;

        } else if (!response.ok) {
            throw new Error('erro na chamada makeValidateVerifyCode: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao validar codigo de verificacao');
    }
}

const makeNewAddress = async (description, zipCode, state, address, complement, number, district, cityName, cityCode) => {
    let validationError = false;

    try {
        let data = {
            'description': description,
            'zipCode': zipCode,
            'state_abbreviation': state,
            'address': address,
            'complement': complement,
            'number': number,
            'district': district,
            'city_name': cityName,
            'city_code': cityCode,
        };

        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ADDRESSES}`, post_bearer);

        if (response.status === 400) {
            const data = response.data;

            validationError = true;

            throw new Error(data.message);
        } else if (!response.ok) {
            throw new Error('erro na chamada makeNewAddress: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar criação do endereço');
        }
    }
}

const makeFriendsRecommendation = async (arrayContacts) => {
    let validationError = false;

    try {
        let data = {
            friends: arrayContacts,
        };

        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_FRIENDS_RECOMMENDATION}`, post_bearer);

        if (response.ok) {
            return response.status;
        } else {
            validationError = true;

            throw new Error('erro na chamada makeFriendsRecommendation: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao enviar a recomendação para amigos');
        }
    }
}

/*GET*/

const getCustomerCountries = async (callback) => {
    try {
        const { get } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_COUNTRIES}`, get);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getCustomerCountries: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de países');
    }
}

const getCustomer = async () => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else {
            throw new Error('erro na chamada getCustomer: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao buscar informações do usuário');
    }
}

const getCustomerCompany = async () => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_COMPANY}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else if (response.status === 404) {
            return null;
        } else {
            throw new Error('erro na chamada getCustomerCompany: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao buscar informações de empresa');
        }
    }
}

const getTermsConditions = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_TERMS}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error('erro na chamada getTermsConditions: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de termos');
    }
}

const getCoupomRecommendation = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_FRIENDS_RECOMMENDATION}/reward`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else {
            throw new Error('erro na chamada getCoupomRecommendation: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de cupom para compartilhamento.');
    }
}

const getAddresses = async () => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ADDRESSES}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else {
            throw new Error('erro na chamada getAddresses: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de endereços');
    }
}

const residentialAvailable = async (zipCode) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${ADDRESS_ZIP}/${zipCode}/residential`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else if (response.status === 404) {
            validationError = true;

            throw new Error("CEP não encontrado");
        } else if (response.status === 400) {
            validationError = true;

            throw new Error("CEP Inválido");
        } else {
            throw new Error('erro na chamada residentialAvailable: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de disponibilidade do residencial');
        }
    }
}

const findZipCode = async (zipCode) => {
    let validationError = false;
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${ADDRESS_ZIP}/${zipCode}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            return responseJson;
        } else if (response.status === 404) {
            validationError = true;

            throw new Error("CEP não encontrado");
        } else if (response.status === 400) {
            validationError = true;

            throw new Error("CEP Inválido");
        } else {
            throw new Error('erro na chamada findZipCode: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar consulta de CEP');
        }
    }
}

/*Put*/

const makeUpdateCustomer = async (email, fullName, mobile_phone, gender, birthdayDate) => {
    let validationError = false;

    try {
        let data = {
            email: email,
            name: fullName,
            mobile_phone: mobile_phone,
            gender: gender,
            birthday_date: birthdayDate
        };

        const { put_bearer } = header(data);

        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS}`, put_bearer);

        if (response.status === 400) {
            validationError = true;

            const data = response.data;

            throw new Error(data.message);
        } else if (!response.ok) {
            throw new Error('erro na chamada makeUpdateCustomer: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;
        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar atualização do cadastro');
        }
    }
}

const makeChangePassword = async (username, oldPassword, newPassword) => {
    let validationError = false;

    try {
        let data = {
            username: username,
            oldPassword: oldPassword,
            newPassword: newPassword
        };

        const { put_bearer } = header(data);

        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_PASSWORD}`, put_bearer);

        if (response.status === 403) {
            validationError = true;

            throw new Error('Senha atual inválida');

        } else if (response.status === 400) {
            const data = response.data;

            validationError = true;

            throw new Error(data.message);
        } else if (!response.ok) {
            throw new Error('erro na chamada makeChangePassword: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao realizar alteração de senha');
        }
    }
}

const makeUserChangePassword = async (username, recoverCode, newPassword) => {
    let validationError = false;
    try {
        let data = {
            username: username,
            recoverCode: recoverCode,
            newPassword: newPassword
        };

        const { put } = header(data);

        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMERS_PASSWORD}`, put);

        if (response.ok) {
            return response;

        } else if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error('Tempo Expirado: ' + responseJson.message);

        } else {
            throw new Error('erro na chamada makeUserChangePassword: ' + response.status);
        }

    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao alterar a senha');
        }
    }
}

const updateLanguage = async (language) => {
    try {
        const putUrl = `${API_BASE}${API_VERSION}${CUSTOMERS}${LANGUAGE}?language=${language.toUpperCase()}`;
        return await validatePutResponse(
            putUrl,
            "Erro ao salvar linguagem"
        );
    } catch (error) {
        throw error;
    }
}

/*delete*/

const deleteAddressInactivation = async (id) => {
    try {
        const { delete_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_ADDRESSES}/${id}`, delete_bearer);

        if (response.ok) {
            return response.status;
        } else {
            throw new Error('erro na chamada deleteAddressInactivation: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao inativar o endereço');
    }
}

const deleteCustomerAccount = async (password) => {
    try {
        const deletUrl = `${API_BASE}${API_VERSION}${CUSTOMERS}`;

        let body = {
            'password_confirmation': password
        }

        return await validateDeleteResponse(
            deletUrl,
            "Erro ao remover conta",
            body
        );
    } catch (error) {
        throw error;
    }
}

export {
    makeRegisterCustomer,
    makeAcceptTerms,
    makeRecoverPassword,
    makeRecoverCodePassword,
    makeValidateVerifyCode,
    makeNewAddress,
    makeUpdateCustomer,
    makeChangePassword,
    makeFriendsRecommendation,
    makeUserChangePassword,
    getCustomerCountries,
    getCustomer,
    getCustomerCompany,
    getTermsConditions,
    getAddresses,
    getCoupomRecommendation,
    residentialAvailable,
    findZipCode,
    deleteAddressInactivation,
    deleteCustomerAccount,
    updateLanguage
}