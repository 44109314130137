import axios from 'axios';
import { getUserToken } from '../../utils/auth';

const header = data => {
  const userToken = getUserToken();
  const sessionId = localStorage.getItem('uuid');
  const provider = localStorage.getItem('provider') ?? '';
  const external_token = localStorage.getItem('external-token') ?? '';
  const external_request_code = localStorage.getItem('external-request-code') ?? '';

  const basicAuthorization =
    process.env.REACT_APP_CUSTOMER_WEB_BASIC_AUTHORIZATION;

  const post = {
    method: 'POST',
    body: data != null ? JSON.stringify(data) : '',
    headers: {
      Authorization: basicAuthorization,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
      'External-Authorization-Provider': provider,
      'External-Authorization-Token': external_token,
      'External-Authorization-Request-Code': external_request_code,
    },
  };

  const get = {
    method: 'GET',
    headers: {
      Authorization: basicAuthorization,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
      'External-Authorization-Provider': provider,
      'External-Authorization-Token': external_token,
      'External-Authorization-Request-Code': external_request_code,
    },
  };

  const put = {
    method: 'PUT',
    body: data != null ? JSON.stringify(data) : '',
    headers: {
      Authorization: basicAuthorization,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
      'External-Authorization-Provider': provider,
      'External-Authorization-Token': external_token,
      'External-Authorization-Request-Code': external_request_code,
    },
  };

  const post_bearer = {
    method: 'POST',
    body: data != null ? JSON.stringify(data) : '',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
      'External-Authorization-Provider': provider,
      'External-Authorization-Token': external_token,
      'External-Authorization-Request-Code': external_request_code,
    },
  };

  const get_bearer = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
      'External-Authorization-Provider': provider,
      'External-Authorization-Token': external_token,
      'External-Authorization-Request-Code': external_request_code,
    },
  };

  const put_bearer = {
    method: 'PUT',
    body: data != null ? JSON.stringify(data) : '',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
      'External-Authorization-Provider': provider,
      'External-Authorization-Token': external_token,
      'External-Authorization-Request-Code': external_request_code,
    },
  };

  const delete_bearer = {
    method: 'DELETE',
    body: data != null ? JSON.stringify(data) : '',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
      'External-Authorization-Provider': provider,
      'External-Authorization-Token': external_token,
      'External-Authorization-Request-Code': external_request_code,
    },
  };

  const get_bearer_chat = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      Consumer: 'customer',
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
    },
  };

  const post_bearer_common = {
    method: 'POST',
    body: data != null ? JSON.stringify(data) : '',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
    },
  };

  const get_bearer_common = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
    },
  };

  const put_bearer_common = {
    method: 'PUT',
    body: data != null ? JSON.stringify(data) : '',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
    },
  };

  const delete_bearer_common = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-type': 'application/json',
      'session-Id': sessionId,
      'Device-Platform': 'web',
      'App-Version': '10.0.0',
    },
  };

  return {
    post,
    get,
    put,
    post_bearer,
    get_bearer,
    put_bearer,
    delete_bearer,
    get_bearer_chat,
    post_bearer_common,
    get_bearer_common,
    put_bearer_common,
    delete_bearer_common,
  };
};

const fetchWithTimeout = async (url, options, timeout = 20000) => {

  const controller = new AbortController();

  const response = await axios(url, { method: options.method, headers: options.headers, data: options.body, signal: controller.signal }).then((response) => {

    if (response.status === 200) {
      response.ok = true;
    }
    return response;
  },
    (error) => {

      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    });

  controller.abort()

  return response
};

export { fetchWithTimeout, header };