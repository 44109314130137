import React from 'react';
import VideoButton from '../VideoButton';
import { Container } from './styles';

const VideosGrid = (props) => {

    const category = props.category;
    const service = props.service;
    const offer = props.offer;
    const setLoading = props.setLoading;

    let videosCount = service.videos.length;

    const columns = 3;
    const rows = Math.floor(videosCount / columns);
    const lastRowElements = videosCount - rows * columns;

    let tempVideos = [];
    tempVideos = tempVideos.concat(service.videos);

    for (let i = 0; i < (columns - lastRowElements); i++) {
        tempVideos.push({ id: 'empty-' + i, });
    }

    return (
        <Container>
            {tempVideos.map((video, videoIndex) =>
                <div key={videoIndex} className="video-container">
                    <VideoButton setLoading={setLoading} video={video} service={service} category={category} offer={offer} />
                </div>
            )}
        </Container >
    );
};

export default VideosGrid;
