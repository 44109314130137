import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const Context = createContext({});

export const ContextProvider = ({ children }) => {
  const userRef = useRef(false);
  const [user, setUser] = useState(false);
  const userLocal = localStorage.getItem('posher_logged_user');

  useEffect(() => {
    if (userLocal) {
      userRef.current = true;
      setUser(true);
      return;
    }

    setUser(false);
    userRef.current = false;
  }, []);

  return (
    <Context.Provider value={{ user, userRef, userLocal }}>
      {children}
    </Context.Provider>
  )
}

export function useAuthContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
}


