import React from "react";

import { TextArea, FilterInputContainer } from "./styles";

export function TextAreaDescription({
  textNotes,
}) {
  return (
    <FilterInputContainer>
      <TextArea
        placeholder="Observações"
        ref={textNotes}
      />
    </FilterInputContainer>
  );
}
