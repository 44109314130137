import React from 'react';
import Modal from 'react-modal';
import './styles.css';

const ModalMoreInformation = ({ showModal, setShowModal, title, body }) => {
    return (
        <div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                overlayClassName="modal-overlay"
                className="modal-content more-info"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="title-header">{title}</span>
                </div>

                <div className="content-body">
                    <span>
                        {body}
                    </span>
                </div>

                <button className="close-button" type="type" onClick={() => setShowModal(false)}>
                    <span>fechar</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalMoreInformation;