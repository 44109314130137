import styled from 'styled-components';

export const Container = styled.div`
    border: 1px solid #F4F4F4;
    background-color: #FFFFFF;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 30px;
    margin-bottom: 20px;
    width: 600px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);

    .code-text {
        font-size: 1rem;
        font-weight: 700;
        color: #14213D;
    }

    .description-text {
        font-size: 0.95rem;
        color: #707070;
        margin-top: 5px;
    }

    .validate {
        font-size: 0.9rem;
        color: #707070;
        font-weight: bold;
        margin-top: 5px;
    }

    .card-icon {
        left: 33.5rem;
        bottom: 1rem;
        color: black;
        position: relative;
    }

    @media(max-width: 620px) {
        width: 500px;
    }

    @media(max-width: 520px) {
        width: 400px;
    }

    @media(max-width: 420px) {
        width: 300px;

        .code-text {
            font-size: 0.9rem;
        }

        .description-text {
            font-size: 0.85rem;
        }

        .validate {
            font-size: 0.8rem;
        }
    }
`;