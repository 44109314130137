import { API_BASE, API_VERSION, QUESTIONNAIRES_ANSWERS, QUESTIONNAIRES_NEXT } from "./helper/api_urls";
import { validateGetResponse } from './helper/validateGetResponse';
import { validatePostResponse } from "./helper/validateResponse";

const getNextQuestionnaires = async () => {
  try {
    const getUrl = `${API_BASE}${API_VERSION}${QUESTIONNAIRES_NEXT}`;
    return await validateGetResponse(
      getUrl,
      "Erro ao consultar questionários",
    );
  } catch (error) {
    if(error.name !== 'NotFoundError') {
      throw error;
    }
  }
}

const postQuestionnaireAnswers = async (body) => {
  try {
    const postUrl = `${API_BASE}${API_VERSION}${QUESTIONNAIRES_ANSWERS}`;
    return await validatePostResponse(
      postUrl,
      "Erro ao enviar respostas do questionários",
      body
    );
  } catch (error) {
    throw error;
  }
}

export {
  getNextQuestionnaires,
  postQuestionnaireAnswers
}