import styled from 'styled-components';
import imgSrc from '../../../../assets/bg-login.png';

export const Container = styled.div`
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${imgSrc});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
    position: relative;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-img-login {
        position: absolute;
        top: 100px;
        width: 500px;
    }

    .code-forms {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .code-forms .input-code > input {
        margin: 10px;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        font-size: 2rem;
        border: 1px solid lightgrey; 
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px; 
        text-align: center; 
        box-sizing: border-box; 
        color: #FA9F2A; 
        background-color: white;
    }

    .code-forms .input-code > input::-webkit-outer-spin-button,
    .code-forms .input-code > input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    .code-forms .input-code > input[type=number] {
        -moz-appearance: textfield;
    }

    .login-text {
        text-align: center;
        width: 500px;
        margin-bottom: 10px;
    }

    @media(max-width: 740px) {
        .code-forms .input-code > input {
            margin: 5px;
            width: 50px;
            height: 50px;
            border-radius: 5px;
            font-size: 1.5rem;
        }

        .logo-img-login {
            width: 400px;
        }
    }

    @media(max-width: 520px) {
        .login-text {
            width: 250px;
        }

        .code-forms .input-code > input {
            width: 40px;
            height: 40px;
            font-size: 1.3rem;
        }

        .logo-img-login {
            width: 350px;
        }
    }

    @media(max-width: 375px) {
        .code-forms .input-code > input {
            width: 30px;
            height: 30px;
            font-size: 1rem;
        }

        .logo-img-login {
            width: 300px;
        }
    }
`;

export const ButtonSendAgain = styled.button`
    background: none;
    display: flex;
    align-items: center;
    text-decoration: underline;
    font-size: 1rem;
    margin-top: 20px;

    > .text-button-code {
        margin-left: 5px;
    }
`;