import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;

  .payment-card-view {
    width: 100%;
    margin: 20px 0;
  }

  .payment-method-view {
    margin: 10px 0;
    position: relative;
    display: flex;
    align-items: center;
  }

  .payment-method-view input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 20px;
    height: 20px;

    border: 1px solid #FA9F2A;
    transition: .2s all linear;
    margin-right: 5px;

    position: relative;
    cursor: pointer;
  }

  .payment-method-view input:checked {
    border: 6px solid #FA9F2A;
  }

  .payment-method-view label {
    color: #14213D;
    font-size: 1.1rem;
    cursor: pointer;
    margin-right: 20px;
  }

  .check-wallet {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .check-wallet label {
    cursor: pointer;
    color: #14213D;
    font-size: 1.1rem;
    margin-left: 10px;
  }

  .check-wallet input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 20px;
    height: 20px;
    border: 1px solid #FA9F2A;
    border-radius: 2px;

    transition: .2s all linear;
    cursor: pointer;
  }

  .check-wallet input:checked {
    background-color: #FA9F2A;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .check-wallet input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    cursor: pointer;
  }

  .text-total {
    text-align: center;
    color: #14213D;
    font-size: 1rem;
  }

  .text-total span {
    font-weight: 700;
  }

  .new-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px; 
    margin-bottom: 5px;
  }

  .container-title-payment-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .title-payment-view {
    text-align: center; 
    color: #14213D;
    font-size: 1.2rem; 
    font-weight: 700;
  }

  .payment-cupon-view {
    display: flex;
    margin: 10px 0;
    align-self: center;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .payment-cupon-text {
    font-size: 1.1rem;
    color: #14213D;
  }

  .container-info-payment-view {
    margin: 15px 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .discount-coupon-view {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .text-discount {
    text-align: center; 
    color: #14213D; 
    font-size: 0.75rem; 
  }

  .text-discount span {
    font-weight: 700;
  }

  .discount-coupon-code {
    font-size: 0.75rem;
    background-color: #F0F0F0;
    color: #E6860B;
    padding: 5px 30px 5px 10px;
    border-radius: 11px;
    margin-left: 5px;
    font-weight: 700;
  }

  .remove-discount-coupon-button {
    width: 20px;
    height: 20px;
    border: 1px solid #B7B7B7;
    border-radius: 18px;
    margin-left: -20px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .remove-discount-coupon-button-text {
    text-align: center;
    color: #B7B7B7;
  }

  .text-warning-package {
    color: #14213D;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 20px;
  }

  .delete-card svg {
    width: 17px;
  }

  .payment-signature-input {
    margin: 10px 0 20px 10px; 
    width: 400px;
    display: flex;
    text-align: center;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #CFCFCF;
    background-color: #fff;
    font-size: 1.1rem;
    color: #14213D;
  }

  @media(max-width: 870px) {
    max-width: 400px;

    .check-wallet label {
      font-size: 1rem;
    }

    .payment-method-view label {
      font-size: 1rem;
    }

    .payment-cupon-text {
      font-size: 1rem;
    }

    .title-payment-view {
      font-size: 1.1rem; 
    }

    .text-total {
      font-size: 1rem;
    }
  }

  @media(max-width: 450px) {
    max-width: 300px;

    .check-wallet input {
      width: 16px;
      height: 16px;
    }

    .checkmark:after {
      left: 4px;
      top: 0;
    }

    .payment-method-view input {
      width: 16px;
      height: 16px;
    }

    .check-wallet label {
      font-size: 0.9rem;
    }

    .payment-cupon-text {
      font-size: 0.9rem;
    }

    .title-payment-view {
      font-size: 1rem; 
    }

    .text-total {
      font-size: 0.9rem;
    }
  }
`;