import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    CUSTOMER_COMPANY,
    CUSTOMER_COMPANY_OFFER_HEADER_STATUS,
    CUSTOMER_COMPANY_OFFER_HEADER_INTERACT,
    CUSTOMER_HOME_SECTIONS
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

const getUserCompany = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_COMPANY}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson);
        }

    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao obter informações da empresa');
    }
}

const getUserCompanyOfferHeaderStatus = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_COMPANY_OFFER_HEADER_STATUS}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson);
        }

    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao obter status de empresa');
    }
}

const putUserCompanyOfferHeaderInteract = async () => {
    try {
        const { put_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_COMPANY_OFFER_HEADER_INTERACT}`, put_bearer);

        if (!response.ok) {
            const data = response.data;
            throw new Error(data.message)
        }

    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao alterar a empresa');
    }
}

const getSections = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${CUSTOMER_HOME_SECTIONS}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;
            callback(responseJson);

        } else if (response.status === 404) {
            callback(null);
        }

        else {
            throw new Error('erro na chamada getSections: ' + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao buscar as seções da home');
    }
}

export {
    getUserCompany
    , getSections
    , getUserCompanyOfferHeaderStatus
    , putUserCompanyOfferHeaderInteract
}