import styled from 'styled-components';

export const Container = styled.div`
    .button {
        background: none;
    };

    .container-products {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
        padding-bottom: 150px;
    }

    #partnerBody {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: center;
    }

    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    };

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }

        .container-products {
            padding-bottom: 100px;
        }
    }
`;