import styled from 'styled-components';
import BackgroundChat from '../../../assets/images/backgroundChat.png';

export const ContainerMain = styled.div`
    height: 100vh;
    position: relative;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    height: 50px;
    background-color: #FFFFFF;
    font-size: 3rem;
`;

export const ButtonOpacity = styled.button`
    width: 80px;
    height: 50px;
    background: #fff;
    transition: ease-in .2s;
    &:hover{
        opacity: 0.8;
    }
`;

export const ScrollDiv = styled.h3`
    overflow: auto;
`;

export const Name = styled.h3`
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 300;
    color: #14213D;
    max-width: 50vw;
`;

export const ContainerMessages = styled.div`
    background-color: rgba(250px, 15px, 42px, 0.1);
    overflow: auto;
    padding: 3rem 10px;
    height: 80vh;
    background-image: url(${BackgroundChat});
    display: flex;
    flex-direction: column;
    word-break: break-all;
    

    .BalloomGray{
        border-bottom-left-radius: 0;
        background-color: #FFFFFF;
        max-width: 45vw;
        align-self: flex-start;
        border-radius: 15px;
        margin: 10px 0;
        padding: 10px 10px;
        padding-right: 15px;
        margin-left: 15px;
        border-radius: 15px 15px  15px  0;
        h4{
            margin-left: auto;
        }
    }

    .BalloomOrange{
        background-color: #FA9F2A;
        max-width: 45vw;
        align-self: flex-end;
        border-radius: 15px 15px 0 15px;
        padding: 10px 10px 10px 15px;
        margin: 10px 0;
        margin-right: 15px;
        overflow: hidden;
        margin-left: auto;
        h4{
            margin-left: auto;
        }
    }


    @media(max-width:500px){
        padding: 2rem 0;
        .BalloomGray{
            margin-left: 5px;
        }
        .BalloomOrange{
            margin-right: 5px;
        }
    }
        
`;

export const TodayContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
`;

export const TextToday = styled.h3`
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    border-radius: 25px;
    padding: 8px 2rem;
    margin: 20px;
    color: #FFFFFF;
`;

export const Message = styled.div`
    color: #14213D;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 300;
`;

export const ContainerTimeCheck = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    margin-top: 5px;
`;

export const Hour = styled.h4`
    color: #14213D;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
`;

export const ButtonContainer = styled.button`
    background-color: #FA9F2A;
    height: 50px;
    width: 50px;
    margin: 10px 0;
    border-radius: 50%;
    
    transition: ease-in .2s;
    &:hover{
        opacity: 0.8;
    }
`;

export const InputText = styled.textarea`
    border-radius: 19px;
    border: 0.5px solid #C4C4C4;
    width: 75vw;
    height: 100%;
    color: #14213D;
    font-family: Roboto;
    font-size: 1rem;
    padding: 20px 20px;
    &:focus::placeholder{
        color: #ffffff;
    }
`;

export const ContainerFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #FFFFFF;
    flex: 1;
    padding: 10px 0;
    height: 10vh;
`;

