import styled from "styled-components";

export const OrderFeedbackContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  gap: 6vh;
  padding: 2rem;

  img {
    width: 15rem;
  }

  h1, h2 {
    color: #14213d;
  }
`;
