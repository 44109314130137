import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, NavBar } from "./styles";
import {
  FiUser,
  FiLock,
  FiMap,
  FiBriefcase,
  FiChevronRight,
  FiMessageCircle, 
  FiHeart, 
  FiClipboard
} from "react-icons/fi";
import { getUser } from "../../../utils/auth";
import { t } from 'i18next'


const Header = () => {
  const [hasVaccination, setHasVaccination] = useState(false);
  const hasCompany = getUser()?.company;

  useEffect(() => {
    setHasVaccination(getUser()?.company?.customer_vaccination_enabled);
  }, []);

  return (
    <Container>
      <NavBar>
        <Link to="/personalData" className="dropNav">
          <FiUser size={13} />
          <li>{t("personalData")}</li>
          <FiChevronRight size={15} className="goRight" />
        </Link>

        <hr className="divider" />

        <Link to="/changePassword" className="dropNav">
          <FiLock size={13} />
          <li>{t("changePasswordTitle")}</li>
          <FiChevronRight size={15} className="goRight" />
        </Link>

        <hr className="divider" />

        <Link to="/addresses" className="dropNav">
          <FiMap size={13} />
          <li>{t("myAddresses")}</li>
          <FiChevronRight size={15} className="goRight" />
        </Link>

        <hr className="divider" />

        <Link to="/linkCompany" className="dropNav">
          <FiBriefcase size={13} />
          <li>{t("changedCompany")}</li>
          <FiChevronRight size={15} className="goRight" />
        </Link>
        {hasVaccination && hasCompany && (
          <>
            <hr className="divider" />

            <Link to="/vaccinations" className="dropNav">
              <FiHeart size={13} />
              <li>{t("vaccination")}</li>
              <FiChevronRight size={15} className="goRight" />
            </Link>
          </>
        )}
        <hr className="divider" />

        <Link to="/messages" className="dropNav">
          <FiMessageCircle size={13} />
          <li>{t("messages")}</li>
          <FiChevronRight size={15} className="goRight" />
        </Link>

        <hr className="divider" />

        <Link to="/subscriptions" className="dropNav">
          <FiClipboard size={13} />
          <li>{t("subscriptions")}</li>
          <FiChevronRight size={15} className="goRight" />
        </Link>
      </NavBar>
    </Container>
  );
};

export default Header;
