import React from 'react';
import { SkeletonLoader, GroupSkeletons, LineSeparator } from './styles';
import Skeleton from 'react-loading-skeleton';

const SkeletonLoaderHome = () => {
    return (
        <SkeletonLoader>
            <div className="banner-skt">
                <Skeleton className="banner-skeleton" />
            </div>
            <Skeleton className="title-skeleton" />
            <GroupSkeletons>
                <Skeleton className="today-skeleton" />
                <Skeleton className="today-skeleton second" />
            </GroupSkeletons>

            <Skeleton className="switch-skeleton" />

            <Skeleton className="title-skeleton" />
            <GroupSkeletons>
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
            </GroupSkeletons>

            <LineSeparator />

            <Skeleton className="title-skeleton" />
            <GroupSkeletons>
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
            </GroupSkeletons>

            <LineSeparator />

            <Skeleton className="title-skeleton" />
            <GroupSkeletons>
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
            </GroupSkeletons>

            <LineSeparator />

            <Skeleton className="title-skeleton" />
            <GroupSkeletons>
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
            </GroupSkeletons>

            <LineSeparator />

            <Skeleton className="title-skeleton" />
            <GroupSkeletons>
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
                <Skeleton className="group-skeleton" />
            </GroupSkeletons>
        </SkeletonLoader>
    );
}

export default SkeletonLoaderHome;