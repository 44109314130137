
import styled from "styled-components";

export const NotFound = styled.h4`
    color: #14213D;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 4rem;
`;