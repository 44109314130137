import styled from "styled-components";
import imgSrc from '../../../../../assets/images/bg-complete-order-light.jpg';

export const BackgroundView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 400px;
`;

export const InfoText = styled.h3`
    font-size: 1.1rem;
    margin: 1rem 0 2rem 0;
    color: #fff;
`;

export const InfoView = styled.div`
    margin-bottom: 30px;
    width: 37.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    @media(max-width: 520px) {
        width: 20rem;
    }
`;

export const BackgroundImageColor = styled.div`
    width: 100vw;
    border-radius: 19px;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const GreenCheckView = styled.div`
    background-color: #50B041;
    width: 90px;
    height: 90px;
    border-radius: 60px;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 78px auto 30px;

    @media(max-width: 900px) {
      width: 70px;
      height: 70px;
    }
`;

export const GreenCheck = styled.div`
    color: #fff;
    top: 10px;
    text-align: center;
    position: relative;
    font-size: 70px;
    @media(max-width: 900px) {
        top: 20px;
      font-size: 40px;
    }
`;

export const AllOk = styled.h3`
    margin: 15px; 
    text-align: center; 
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
`;

export const ImageBackground = styled.div`
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${imgSrc});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1080px !important;
    margin: auto;
    box-sizing: border-box;
    min-height: 100vh;
    position: relative;
`;
