import styled from "styled-components";

export const ContainerOpacity = styled.div`
    cursor: pointer;
    transition: ease-in 0.2s;
    display: flex;
    justify-content: flex-end;
    &:hover{
         opacity: 0.6;
     }
     height: 100%;
`;