import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/context";
import SignIn from "../views_2/Login/LoginEmail";
import SignInPassword from "../views_2/Login/LoginPassword";
import CodeRecover from "../views_2/Login/RecoverPassword/CodeRecover";
import RecoverPassword from "../views_2/Login/RecoverPassword";
import MyAccount from "../views_2/Customer/MyAccount";
import CompleteOrder from "../views_2/ServiceOrder/Order/CompleteOrder";
import CompleteExpressOrder from "../views_2/ServiceOrder/Order/CompleteExpressOrder";
import CompleteOnDemandOrder from "../views_2/ServiceOrder/OnDemandOrder/CompleteOnDemandOrder";
import LinkCompany from "../views_2/Customer/LinkCompany";
import WalletStatement from "../views_2/Customer/WalletStatement";
import MyPackages from "../views_2/MyPackages";
import RegisterCustomer from "../views_2/Customer/RegisterCustomer";
import Home from "../views_2/Home_2/Home";
import OnDemandOrderData from "../views_2/ServiceOrder/OnDemandOrder/OnDemandOrderData";
import ServiceList from "../views_2/ServiceOrder/ServiceList";
import SlotList from "../views_2/ServiceOrder/Order/SlotList";
import NewAddress from "../views_2/Customer/Addresses/components/NewAddress";
import Addresses from "../views_2/Customer/Addresses";
import ServiceOrderHistory from "../views_2/ServiceOrder/ServiceOrderHistory";
import DiscountCoupons from "../views_2/DiscountCoupons";
import TermsConditions from "../views_2/TermsConditions";
import PersonalData from "../views_2/Customer/PersonalData";
import ChangePassword from "../views_2/Customer/ChangePassword";
import LinkedCompany from "../views_2/Customer/LinkCompany/LinkedCompany";
import VideoView from "../views_2/ServiceOrder/VideoView";
import VideoList from "../views_2/ServiceOrder/VideoList";
import PackageCheckout from "../views_2/MyPackages/PackageCheckout";
import PackageCompletedOrder from "../views_2/MyPackages/PackageCompletedOrder";
import PartnerProductOrder from "../views_2/PartnerProductOrder";
import PartnersCheckout from "../views_2/PartnerProductOrder/PartnersCheckout";
import PartnerCompletedOrder from "../views_2/PartnerProductOrder/PartnerCompletedOrder";
import * as Sentry from "@sentry/react";

import { isAuthenticated } from "./auth";

import { useDetectAdBlock } from "adblock-detect-react";
import AdblockCard from "../components/AdblockCard";
import CompleteOrderApproval from "../views_2/ServiceOrder/ServiceOrderHistory/CompleteOrderApproval";
import { Checkin } from "../views_2/Home_2/components/SchedulePlace/Checkin";
import { Checkout } from "../views_2/Home_2/components/SchedulePlace/Checkout";
import { SchedulePosition } from "../views_2/Home_2/components/SchedulePlace/SchedulePosition";
import Resume from "../views_2/Home_2/components/SchedulePlace/Resume";
import Chat from "../views_2/ServiceOrder/Chat";
import Vaccination from "../views_2/Customer/Vaccination";
import Messages from "../views_2/Customer/Messages";
import { NotFound } from "../views_2/NotFound";

import ReactGA from "react-ga";

import { SplashLogo } from "../views_2/Home_2/SplashLogo";

import { TimeClockHistory } from "../views_2/Home_2/components/TimeClock/TimeClockHistory";
import ConfirmedPayment from "../views_2/ServiceOrder/Order/ConfirmedPayment";
import { OrderFeedback } from "../views_2/ServiceOrder/Order/OrderFeedback";
import SignatureCheckout from "../views_2/MySignatures/SignatureCheckout";
import SignatureCompletedOrder from "../views_2/MyPackages/SignatureCompletedOrder";
import Subscriptions from "../views_2/Customer/Subscriptions";
import browserDetect from "../components/BrowserDetect";
import BrowserDetectCard from "../components/BrowserDetect/BrowserDetectCard";
import OneUseTokenOrder from "../views_2/ServiceOrder/Order/OneUseTokenOrder";
import ScratchCard from "../views_2/ScratchCard";

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_CUSTOMER_WEB_ENVIRONMENT === "live") {
      ReactGA.initialize("UA-71078877-2");
      ReactGA.pageview(window.location.pathname);
    }
  }, [location]);
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...rest} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export function Routes({ authenticated, ...rest }) {
  const { user, userRef } = useAuthContext();
  const logged = localStorage.getItem("posher_logged_user");

  const adBlockDetected = useDetectAdBlock();
  const [hasAdblock, setHasAdBlock] = useState(false);
  const [isValidBrowser, setIsValidBrowser] = useState(false);
  
  usePageViews();

  useEffect(() => {
    if (logged) {
      const customer = JSON.parse(logged)?.customer
      Sentry.setUser({ 
        id: customer?.id
      });
    }
  }, [logged])

  useEffect(() => {
    browserDetect(setIsValidBrowser);
    // setHasAdBlock(adBlockDetected);

    const loggedUser = localStorage.getItem("posher_logged_user");

    if (loggedUser) {
      userRef.current = true;
      // setRotas(true);
      return;
    }
    userRef.current = false;
  }, [hasAdblock, adBlockDetected, userRef]);

  // if (user) {
  //   return (
  //     <Switch>
  //       <Route {...rest} path="/home" authenticated={authenticated} component={Home} isPrivate />
  //     </Switch>
  //   )
  // }

  return (
    <Switch>
      {!isValidBrowser && <BrowserDetectCard/>}
      {hasAdblock && <AdblockCard setHasAdBlock={setHasAdBlock} />}

      <Route
        {...rest}
        path="/"
        authenticated={authenticated}
        exact
        component={SignIn}
      >
        {user || userRef.current === true || logged ? (
          <Redirect to="/home" />
        ) : undefined}
      </Route>

      <Route {...rest} path="/signin-password" component={SignInPassword}>
        {user || userRef.current === true || logged ? (
          <Redirect to="/home" />
        ) : undefined}
      </Route>


      <Route {...rest} path="/signup" component={RegisterCustomer}>
        {user || userRef.current === true || logged ? (
          <Redirect to="/home" />
        ) : undefined}
      </Route>

      <Route {...rest} path="/code-recover" component={CodeRecover}>
        {user || userRef.current === true || logged ? (
          <Redirect to="/home" />
        ) : undefined}
      </Route>

      <Route {...rest} path="/recover-password" component={RecoverPassword}>
        {user || userRef.current === true || logged ? (
          <Redirect to="/home" />
        ) : undefined}
      </Route>

      {/* <Route {...rest} path="/terms" component={TermsConditions}>
        {user || userRef.current === true || logged ? (
          <Redirect to="/home" />
        ) : undefined}
      </Route> */}

      <Route path="/terms" component={TermsConditions} />

      <Route path="/one_use_token" component={OneUseTokenOrder} />

      <Route path="/prizes" component={ScratchCard} />  

      <PrivateRoute
        {...rest}
        path="/splash"
        authenticated={authenticated}
        component={SplashLogo}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/home"
        authenticated={authenticated}
        component={Home}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/onDemandOrderData"
        authenticated={authenticated}
        component={OnDemandOrderData}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      {/* Schedule */}

      <PrivateRoute
        {...rest}
        path="/checkin"
        authenticated={authenticated}
        component={Checkin}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/checkout"
        authenticated={authenticated}
        component={Checkout}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/schedulePosition"
        authenticated={authenticated}
        component={SchedulePosition}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/resume"
        authenticated={authenticated}
        component={Resume}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      {/* 🕒🕒🕒🕒🕒🕒🕒🕒🕒🕒🕒🕒 Time Clock 🕒🕒🕒🕒🕒🕒🕒🕒🕒🕒🕒🕒 */}

      <PrivateRoute
        {...rest}
        path="/timeClockHistory"
        authenticated={authenticated}
        component={TimeClockHistory}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      {/*  */}

      <PrivateRoute
        {...rest}
        path="/serviceList"
        authenticated={authenticated}
        component={ServiceList}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/slotlist"
        authenticated={authenticated}
        component={SlotList}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/newAddress"
        authenticated={authenticated}
        component={NewAddress}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/myPackages"
        authenticated={authenticated}
        component={MyPackages}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/serviceOrderHistory"
        authenticated={authenticated}
        component={ServiceOrderHistory}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/completeOrderApproval"
        authenticated={authenticated}
        component={CompleteOrderApproval}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/discountCoupons"
        authenticated={authenticated}
        component={DiscountCoupons}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/addresses"
        authenticated={authenticated}
        component={Addresses}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/myAccount"
        authenticated={authenticated}
        component={MyAccount}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/vaccinations"
        authenticated={authenticated}
        component={Vaccination}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/messages"
        authenticated={authenticated}
        component={Messages}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>
      
      <PrivateRoute
        {...rest}
        path="/subscriptions"
        authenticated={authenticated}
        component={Subscriptions}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/linkCompany"
        authenticated={authenticated}
        component={LinkCompany}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/linkedCompany"
        authenticated={authenticated}
        component={LinkedCompany}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/walletStatement"
        authenticated={authenticated}
        component={WalletStatement}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/completeOrder"
        authenticated={authenticated}
        component={CompleteOrder}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/completeExpressOrder"
        authenticated={authenticated}
        component={CompleteExpressOrder}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/completeOnDemandOrder"
        authenticated={authenticated}
        component={CompleteOnDemandOrder}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/confirmedPayment"
        authenticated={authenticated}
        component={ConfirmedPayment}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/orderFeedback"
        authenticated={authenticated}
        component={OrderFeedback}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        path="/packageCheckout"
        authenticated={authenticated}
        component={PackageCheckout}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        path="/signatureCheckout"
        authenticated={authenticated}
        component={SignatureCheckout}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/packageCompletedOrder"
        authenticated={authenticated}
        component={PackageCompletedOrder}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/signatureCompletedOrder"
        authenticated={authenticated}
        component={SignatureCompletedOrder}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/personalData"
        authenticated={authenticated}
        component={PersonalData}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/changePassword"
        authenticated={authenticated}
        component={ChangePassword}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/videoView"
        authenticated={authenticated}
        component={VideoView}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/videoList"
        authenticated={authenticated}
        component={VideoList}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/partner"
        authenticated={authenticated}
        component={PartnerProductOrder}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/partnersCheckout"
        authenticated={authenticated}
        component={PartnersCheckout}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/partnerCompletedOrder"
        authenticated={authenticated}
        component={PartnerCompletedOrder}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <PrivateRoute
        {...rest}
        path="/chat"
        authenticated={authenticated}
        component={Chat}
        isPrivate
      >
        {user || userRef.current === true || logged ? undefined : undefined}
      </PrivateRoute>

      <Route component={NotFound} />
    </Switch>
  );
}
