import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {t} from 'i18next'

import {
  ContainerPage,
  Container,
  ViewBox,
  ButtonImg,
  ButtonView,
  ButtonText,
  Title,
} from "./styles";

import { ButtonOpacity } from "./components/ButtonOpacity";
import ModalTimeClockConfirm from "./ModalTimeClockConfirm";

import { getTimeClockCheckinCurrent } from "../../../../api/time_clock";
import { getTimeDiff } from "./utils/getTimeDiff";

const TimeClock = () => {
  const history = useHistory();

  const [hasCurrentCheckin, setHasCurrentCheckin] = useState(null);
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [actualTime, setActualTime] = useState("00:00:00");

  function handleOpenCheckModal(type) {
    setModalType(type);
    setShowModal(!showModal);
  }

  const doGetChecked = async (isMounted) => {
    await getTimeClockCheckinCurrent()
      .then((currentCheckin) => {
        if (isMounted) {
          setHasCurrentCheckin(currentCheckin);
        }
      })
      .catch(() => setHasCurrentCheckin(""));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      doGetChecked(isMounted);
    }

    return () => (isMounted = false);
  }, [showModal]);

  useEffect(() => {
    let interval;
    if (hasCurrentCheckin) {
      interval = setInterval(() => {
        getTimeDiff(setActualTime, hasCurrentCheckin.started_at);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [hasCurrentCheckin]);

  return (
    <ContainerPage>
      <Title>{t("eletronicPoint")}</Title>
      <Container>
        <ViewBox>
          <ButtonOpacity onClick={() => history.push("/timeClockHistory")}>
            <ButtonImg
              src={
                "https://posher.s3.sa-east-1.amazonaws.com/production/time_clock/images/history.jpg"
              }
            />
            <ButtonView>
              <ButtonText numberOfLines={3}>{t("historyWord")}</ButtonText>
            </ButtonView>
          </ButtonOpacity>
        </ViewBox>

        <ViewBox>
          <ButtonOpacity
            disabled={!!hasCurrentCheckin}
            onClick={() => {
              handleOpenCheckModal(t("entryWord"));
            }}
          >
            <ButtonImg
              src={
                "https://posher.s3.sa-east-1.amazonaws.com/production/time_clock/images/checkin.jpg"
              }
            />
            <ButtonView>
              <ButtonText numberOfLines={3}>{t("entryWord")}</ButtonText>
            </ButtonView>
          </ButtonOpacity>
        </ViewBox>

        <ViewBox>
          <ButtonOpacity
            disabled={!hasCurrentCheckin}
            onClick={() => {
              handleOpenCheckModal(t("exitWord"));
            }}
          >
            <ButtonImg
              src={
                "https://posher.s3.sa-east-1.amazonaws.com/production/time_clock/images/checkout.jpg"
              }
            />
            <ButtonView>
              <ButtonText numberOfLines={3}>{t("exitWord")}</ButtonText>
              <ButtonText numberOfLines={3}>
                {hasCurrentCheckin && actualTime}
              </ButtonText>
            </ButtonView>
          </ButtonOpacity>
        </ViewBox>
      </Container>

      {modalType && (
        <ModalTimeClockConfirm
          modalType={modalType}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </ContainerPage>
  );
};

export default TimeClock;
