import { header, fetchWithTimeout } from './helper/request';
import {
    API_BASE,
    API_VERSION,
    MARKETING_BANNERS_HEADER,
    MARKETING_BANNERS_COMPANY_OFFER,
    MARKETING_HR_RECOMMENDATION
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';

/*get*/

const getHomeSliders = async (callback) => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${MARKETING_BANNERS_HEADER}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;

            callback(responseJson);
        } else {
            throw new Error("erro na chamada getHomeSliders: " + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta de banners');
    }
}

const getBannerCompanyOffer = async () => {
    try {
        const { get_bearer } = header();
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${MARKETING_BANNERS_COMPANY_OFFER}`, get_bearer);

        if (response.ok) {
            const responseJson = response.data;
            return responseJson;

        } else {
            throw new Error("erro na chamada getBannerCompanyOffer: " + response.status);
        }
    } catch (error) {
        getSectionsCatchErrors(error, 'Erro ao realizar consulta do banner da empresa');
    }
}

/*Post*/
const makeHrRecommendation = async (name, email, companyName) => {
    let validationError = false;
    let data = {
        responsible_name: name,
        responsible_email: email,
        company_name: companyName,
    }

    try {
        const { post_bearer } = header(data);
        const response = await fetchWithTimeout(`${API_BASE}${API_VERSION}${MARKETING_HR_RECOMMENDATION}`, post_bearer);

        if (response.ok) {
            return response.status;

        } else if (response.status === 400) {
            const responseJson = response.data;

            validationError = true;

            throw new Error(responseJson.message);
        } else {
            throw new Error('erro na chamada makeHrRecommendation: ' + response.status);
        }
    } catch (error) {
        if (validationError) {
            throw error;

        } else {
            getSectionsCatchErrors(error, 'Erro ao enviar a recomendação');
        }
    }
}

export {
    getHomeSliders,
    getBannerCompanyOffer,
    makeHrRecommendation
}