import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getTodayOffers } from "../../../../api/service_offer";
import { Container, ButtonsContainer } from './styles';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ItemsCarousel from 'react-items-carousel';
import ButtonsOfferLarge from "../../../../components/ButtonsOfferLarge";
import { useResponsiveValue } from '@theme-ui/match-media';
import {t} from 'i18next'

const TodayOffers = () => {
  const [allTodayOffers, setAllTodayOffers] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const numberOfCards = useResponsiveValue([1, 2, 2]);

  const searchOffers = async (setAllTodayOffers) => {
    try {
      await getTodayOffers(setAllTodayOffers);
    } catch (error) {
      toast.error(error.message);
      setAllTodayOffers([]);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      searchOffers(setAllTodayOffers);
    }

    return () => { isMounted = false };
  }, []);

  return (
    <Container>
      <p className="title">{t("whatsForToday")}</p>

      {allTodayOffers.length > 0 ?
        <div className="carousel">
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={numberOfCards}
            gutter={20}
            leftChevron={
              <button className="button-left">
                <FaChevronLeft size={12} />
              </button>
            }
            rightChevron={
              <button className="button-right">
                <FaChevronRight size={12} />
              </button>
            }
            outsideChevron={false}
            infiniteLoop={allTodayOffers.length > numberOfCards ? true : false}
            alwaysShowChevrons={allTodayOffers.lenght > numberOfCards ? true : false}
          >
            {allTodayOffers.map((offer, todayOffersIndex) => {
              return (
                <ButtonsContainer key={todayOffersIndex}>
                  <ButtonsOfferLarge offer={offer} card="today" />
                </ButtonsContainer>
              );
            })}

          </ItemsCarousel>
        </div>
        :

        <p className="orderNotFound"> {t("noOfferFound")} </p>
      }
    </Container>
  );
};

export default TodayOffers;
