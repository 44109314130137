import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './styles.css';
import ReCAPTCHA from "react-google-recaptcha";
import {t} from 'i18next'

const ModalWarningRecover = ({ showModalRecover, setShowModalRecover, email, phone, clicked }) => {
    const [token, setToken] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if(!showModalRecover) {
            setToken('');
            setError(false);
        }
    }, [showModalRecover]);

    useEffect(() => {
        if(token) {
            setError(false);
        }
    }, [token]);

    const doCloseModal = (option) => {
        if (!token) {
            setError(true);
        } else {
            setError(false);
            clicked(option);
            setShowModalRecover(false);
        }
    }

    return (
        <div>
            <Modal
                isOpen={showModalRecover}
                onRequestClose={() => setShowModalRecover(false)}
                overlayClassName="modal-overlay recover"
                className="modal-content recover"
                ariaHideApp={false}
            >
                <div className="header-modal">
                    <span className="subtitle-header">{t("changePasswordTitle")}</span>
                    <span className="title-header">{t("sendVerificationCode")}</span>
                </div>

                <div className="content-body">
                    {error &&
                        <span className="error">Favor, verifique o reCAPTCHA</span>
                    }
                    <span>{t("sendVerificationCodeToEmail")}</span>
                    <span>{email}</span>
                    <span>{t("sendVerificationCodeToPhone")}</span>
                    <span>{phone}.</span>
                </div>

                <div className="container-buttons">
                    <button className="accept-button" type="type" onClick={() => doCloseModal(true)}>
                        <span>{t("confirmWord")}</span>
                    </button>
                    <button className="cancel-button" type="button" onClick={() => setShowModalRecover(false)}>
                        <span>{t("cancelWord")}</span>
                    </button>
                </div>

                <div className="recaptcha">
                    <ReCAPTCHA
                        sitekey="6Lfy_KoaAAAAAIOD9_hfRhgDYH5VgrIb_PcPmEuo"
                        onChange={token => setToken(token)}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default ModalWarningRecover;