import React, { useState } from "react";
import { FiCheck, FiMail, FiClock, FiCalendar } from "react-icons/fi";
import AvailableNow from "../../components/AvailableNow";
import { getDayName } from "../../../../utils/utils";
import Moment from "moment-timezone";
import { InfoView, AndNowBanner, BackgroundImage } from './styles';
import Header from "../../../../components/Header";
import TabButton from "../../../../components/TabButton";
import { toast } from "react-toastify";
import { createExpressOrder } from "../../../../api/service_orders";
import { useHistory } from "react-router-dom";
import ModalReview from "../../../../components/Modals/ModalReview";
import SkeletonExpressOrder from './SkeletonExpressOrder';
import LoaderSpinner from '../../../../components/LoaderSpinner';
import { useEffect } from "react";
import { debounce } from 'throttle-debounce';

const CompleteExpressOrder = ({ location }) => {
  const history = useHistory();

  if (!location.state) {
    history.goBack();
  }

  const [loadingSkeleton, setLoadingSkeleton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [dayOfWeek, setDayOfWeek] = useState();
  const {
    state: {
      offer
    } = {}
  } = location;

  const createOrder = async () => {
    try {
      setOrder(await createExpressOrder(offer.slot_suggestion?.hour_begin, offer.slot_suggestion?.job_day_id, offer.service_id, offer.order_type));
      setLoadingSkeleton(false);
  
    } catch (error) {
      toast.error(error.message);
      
      history.goBack();
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setLoadingSkeleton(true);
      setTimeout(() => debounce(5000, createOrder()), 1500);
     
      if(order){
        setDayOfWeek(getDayName(order.scheduled_date));
      }
    }

    return () => { isMounted = false; };
  }, []);

  return (
    <div>
      <Header screen="home" orderType={offer.order_type} />
      <LoaderSpinner loading={loading} />

      {loadingSkeleton &&
        <div className="background-body">
          <SkeletonExpressOrder />
        </div>
      }
      
      {order && 
        <>         
          <BackgroundImage>
            <ModalReview/>
          
              <div className="background-body">
                <div>
                  <div className="green-check-view">
                    <FiCheck color="#fff" size={70} />
                  </div>

                  <span className="text-ok">Tudo certo!</span>
                </div>

                <div className="info-tags">
                  <InfoView>
                    <span className="info-text">
                      Seu checkin de <span>{offer.title}</span> foi confirmado para <span>{dayOfWeek} {Moment(order.scheduled_date).tz("America/Sao_Paulo").format(" DD/MM")}</span>

                      <span> às {Moment(order.scheduled_date).tz("America/Sao_Paulo").format("HH:mm")}.</span>

                    </span>
                  </InfoView>

                  <AndNowBanner>
                    <div className="and-now-banner">
                      <span className="and-now-text">E AGORA?</span>
                    </div>
                  </AndNowBanner>

                  <div className="one-two-banner">
                    <span className="one-two-number-text">1</span>
                    <div className="info-one-two-text">
                      <div className="mail-clock-view">
                        <FiMail color="#000" size={20} />
                      </div>

                      <span className="one-two-text">
                        Enviamos todas as informações do agendamento para seu email.
                      </span>
                    </div>

                  </div>

                  {"presential" === offer.service_type &&
                    <div className="one-two-banner">
                      <span className="one-two-number-text">2</span>
                      <div className="info-one-two-text">
                        <div className="mail-clock-view">
                          <FiClock color="#000" size={20} />
                        </div>

                        <span className="one-two-text">
                          No horário combinado se dirija ao local do serviço {order.attendance_locale}. O profissional estará te aguardando
                        </span>
                      </div>
                    </div>
                  }

                  {"online" === offer.service_type &&
                    <>
                      <div className="one-two-banner">
                        <span className="one-two-number-text">2</span>

                        <div className="info-one-two-text">
                          <div className="mail-clock-view">
                            <FiCalendar color="#000" size={16} />
                          </div>
                          <span className="one-two-text">
                            Esse link já está disponível no seu email, calendário e no seu app POSHER
                          </span>
                        </div>

                      </div>

                      <div className="one-two-banner">
                        <span className="one-two-number-text">3</span>
                        <div className="mail-clock-view">
                          <FiClock color="#000" size={16} />
                        </div>
                        <span className="one-two-text">
                          No horário da sessão, clique no link em um desses locais e boa sessão!
                        </span>
                      </div>
                    </>
                  }

                  <div className="avaliable-now">
                    <AvailableNow
                      orderType={order.order_type}
                      title="DISPONÍVEL AGORA!"
                    />
                  </div>
                </div>
              </div>
            <TabButton screen="home" />
          </BackgroundImage>
        </>
      }
    </div>
  );
};

export default CompleteExpressOrder;
