import React, { useState, useEffect, useRef } from 'react';
import { ButtonContainer, ButtonOpacity, ContainerFooter, ContainerMain, ContainerMessages, ContainerTimeCheck, Header, Hour, InputText, Message, Name, ScrollDiv, TextToday, TodayContainer } from './style';
import { getUser, getUserToken } from '../../../utils/auth';
import moment from 'moment-timezone';
import SockJS from 'sockjs-client';
import { API_CHAT } from '../../../api/helper/api_urls';
import { getChatMessages, getChats } from '../../../api/chats'
import { toast } from 'react-toastify';
import { FiArrowLeft, FiCheck, FiSend } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import LoaderSpinner from '../../../components/LoaderSpinner';

var stompClient = null;
var onerror = false;

const Chat = (props) => {
    const orderId = props.location.state.orderId;
    const namePro = props.location.state.namePro;
    const idPro = props.location.state.idPro;
    const [chat, setChat] = useState({});
    const history = useHistory();
    const userId = getUser().customer.id;
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState({});
    const [loading, setLoading] = useState(false);
    const [roomId, setRoomId] = useState(0);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(false);
    const [reconect, setReconect] = useState(false);

    let hasMessage = false;

    const messagesEndRef = useRef(null);
    const messageReplyRef = useRef(null);

    const connect = () => {
        const Stomp = require("stompjs");
        let sockJS = new SockJS(`${API_CHAT}/ws`);
        stompClient = Stomp.over(sockJS);
        stompClient.connect({}, onConnected, onError);
    };

    const onConnected = () => {
        setError(false);
        setIsConnected(true);
        stompClient.subscribe(
            `/user/${roomId}_${chat.recipient_id ? chat.recipient_id : ''}_${chat.recipient_type}_${userId}_customer/queue/messages`,
            onMessageReceived
        );
    };

    const onError = (error) => {
        if (onerror === true) {
            onerror = false;
            setReconect(true);
        } else {
            setError(true);
            onerror = true;
        }
    };

    const onMessageReceived = async (msg) => {

        const notification = JSON.parse(msg.body);

        if (notification.content === null && msg) {
            stompClient.disconnect();
            setRoomId(notification.room_id);

            setTimeout(() => {
                getMessages(notification.room_id);
            }, 1000);
        } else {
            const confirm = {
                token: getUserToken(),
                consumer: "customer",
                message_id: notification.id,
            }

            await stompClient.send("/app/chat/delivered", {}, JSON.stringify(confirm));

            setMessages(oldArray => [...oldArray, notification]);
        }
    };

    const sendMessage = (msg) => {
        if (msg.trim() !== "") {
            var message = {
                recipient_id: chat.recipient_id,
                recipient_type: chat.recipient_type,
                subject_id: chat.recipient_type === 'posher' ? null : orderId,
                subject_type: chat.recipient_type === 'posher' ? null : 'order',
                content: msg,
                token: getUserToken(),
                consumer: "customer",
                room_id: roomId,
            };
            stompClient.send("/app/chat", {}, JSON.stringify(message));

            message.direction = "send";

            setMessages(oldArray => [...oldArray, message]);
        }
    };

    const getMessages = async (id) => {
        setLoading(true);

        try {
            await getChatMessages(id).then(newMessages => {
                setMessages(newMessages);
                newMessages.forEach((message) => {
                    if (!message.delivered && message.direction === 'reply' && !hasMessage) {
                        setNewMessage(message);
                        messageReplyRef.current?.scrollIntoView();
                        hasMessage = true;
                    }
                })
                if (!hasMessage) {
                    messagesEndRef.current.scrollIntoView();
                }
            });
        } catch (error) {
            toast.error(error.message, 5000);

        } finally {
            setLoading(false);
        }
    }

    const getChat = async () => {
        try {
            setLoading(true);

            await getChats(idPro, orderId)
                .then(val => {
                    setChat(val);
                    setRoomId(val.id);
                    if ((val.id === 0 && !val.recipient_id) || (val.id === 0 && val.recipient_id)) {
                        connect();
                        getMessages(val.id);
                    }
                });

        } catch (error) {
            toast.error(error.message, 5000);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        messagesEndRef.current.scrollIntoView();
    }, [messages])

    useEffect(() => {
        let isMounted = true;

        if (error === true && isMounted) {
            connect();
            setError(false);
        }

        return () => (isMounted = false);
    }, [error]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted) {
            getChat();
        }

        return () => {
            isMounted = false;

            if (isConnected) {
                stompClient.disconnect();
            }
        }
    }, []);

    useEffect(() => {
        if (roomId > 0) {
            connect();
            getMessages(roomId);
        }
    }, [roomId]);

    return (
        <>
            <ContainerMain>
                <LoaderSpinner loading={loading} />
                <Header>
                    <ButtonOpacity onClick={() => {
                        try {
                            stompClient.disconnect();
                        } catch (error) {
                            setIsConnected(false)
                        } 
                        finally {
                            history.goBack();
                        }
                    }}>
                        <FiArrowLeft size={30} color={'#FA9F2A'} />
                    </ButtonOpacity>
                    <Name numberOfLines={1}>{namePro}</Name>

                    <div style={{ width: 30 }}></div>
                </Header>

                <ScrollDiv
                >
                    <ContainerMessages>
                        {messages.length > 0 &&
                            <>
                                {messages.map((message, index) => {
                                    let todayDay = new Date(Date.parse(message.created_at));
                                    let lastDay = new Date(Date.parse(messages[index > 0 ? index - 1 : index].created_at));
                                    let today = new Date();

                                    return (
                                        <div key={index}>
                                            {((todayDay.getDate() > lastDay.getDate() && todayDay.getDate() !== today.getDate()) || messages[0] === message) &&
                                                <TodayContainer>
                                                    <TextToday>{moment(message.created_at).tz('America/Sao_Paulo').format('DD/MM/YYYY')}</TextToday>
                                                </TodayContainer>
                                            }
                                            {(todayDay.getDate() > lastDay.getDate() && todayDay.getDate() === today.getDate()) &&
                                                <TodayContainer>
                                                    <TextToday>Hoje</TextToday>
                                                </TodayContainer>
                                            }
                                            <div ref={newMessage === message ? messageReplyRef : null} className={message.direction === 'reply' ? 'BalloomGray' : 'BalloomOrange'} >

                                                <Message>{message.content}</Message>
                                                <ContainerTimeCheck>
                                                    <Hour>{moment(message.created_at).tz('America/Sao_Paulo').format('HH:mm')}</Hour>

                                                    {message.direction !== 'reply' &&
                                                        <>
                                                            <FiCheck size={15} color={'#FFF'} style={{ 'margin-left': 5 }} />
                                                            {message.delivered && <FiCheck size={15} color={'#FFF'} style={{ 'margin-left': -9 }} />}
                                                        </>
                                                    }
                                                </ContainerTimeCheck>
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                        <div ref={messagesEndRef} />
                    </ContainerMessages>

                </ScrollDiv>

                <ContainerFooter>
                    <InputText
                        multiline
                        placeholder="escreva uma mensagem"
                        placeholderTextColor={'#CFCFCF'}
                        value={message}
                        onChange={text => setMessage(text.target.value)}
                    />

                    <ButtonContainer
                        disabled={message === '' ? true : false}
                        onClick={() => { message !== '' && sendMessage(message); setMessage('') }}
                    >
                        <FiSend size={20} color={'#FFFFFF'} />
                    </ButtonContainer>
                </ContainerFooter>
            </ContainerMain >
        </>
    )
}

export default Chat;