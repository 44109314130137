import { getSectionsCatchErrors } from '../getSectionsCatchErrors'
import { fetchWithTimeout, header } from './request'

export const validateDeleteResponse = async (deleteUrl, errorMessage, body) => {
  const { delete_bearer } = header(body)
  let isValidation = false
  try {
    const response = await fetchWithTimeout(`${deleteUrl}`, delete_bearer)

    if (response.ok) {
      return response
    } else if (response.status === 400) {
      isValidation = true
      const data = response.data
      throw new Error(data.message)
    } else {
      const data = response.data
      throw new Error(data.message)
    }
  } catch (error) {
    if (isValidation) throw error
    getSectionsCatchErrors(error, errorMessage)
  }
}