import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .carousel {
        width: 900px !important;
    }

    .title {
        color: #14213D;
        font-size: 1.4rem;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    .button-left {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        left: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    .button-right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        right: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    .button-right svg {
        color: #3e3e3e;
        fill: #3e3e3e;
    }

    @media(max-width: 960px) {
        margin-left: 5px;
        .carousel {
            max-width: 860px !important;
        }
    }

    @media(max-width: 910px) {
        .carousel {
            margin: 0 10px;
            max-width: 700px !important;
        }

        .button-left,
        .button-right {
            top: 35px;
        }
    }

    @media(max-width: 780px) {
        .title {
            font-size: 1.2rem;
        }
    }

    @media(max-width: 740px) {
        .carousel {
            max-width: 600px !important;
        }
    }

    @media(max-width: 640px) {
        .carousel {
            max-width: 400px !important;
        }

        .button-left,
        .button-right {
            top: 20px;
        }
    }

    @media(max-width: 425px) {
        .carousel {
            margin: 0 5px;
            max-width: 350px !important;
        }
    }

    @media(max-width: 390px) {
        .button-left,
        .button-right {
            display: none;
        }

        .carousel {
            margin-left: 0px;
            width: 300px !important;
        }
    }
`;

export const ButtonsContainer = styled.div`

`;