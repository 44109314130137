import styled from 'styled-components';

export const SkeletonLoader = styled.div`
    width: 100%;
    max-width: 1280px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-skt {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .banner-skeleton {
        width: 600px;
        height: 220px;
        border-radius: 9px;
    }

    .switch-skeleton {
        width: 1080px;
        height: 280px;
        margin: 20px 0 0;
    }

    .title-skeleton {
        width: 320px;
        height: 30px;
        margin: 30px 0 10px;
        border-radius: 20px;
    }

    @media(max-width: 700px) {
        .banner-skeleton {
            width: 400px;
            height: 200px;
        }

        .switch-skeleton {
            height: 200px;
        }
    }

    @media(max-width: 640px) {
        .title-skeleton {
            width: 280px;
        }
    }

    @media(max-width: 490px) {
        .banner-skeleton {
            width: 300px;
            height: 150px;
        }

        .title-skeleton {
            width: 200px;
        }

        .switch-skeleton {
            height: 150px;
        }
    }

    @media(max-width: 360px) {
        .banner-skeleton {
            width: 300px;
            height: 150px;
        }

        .title-skeleton {
            width: 200px;
        }

        .switch-skeleton {
            width: 300px;
        }
    }
`;

export const GroupSkeletons = styled.div`
    .today-skeleton {
        width: 420px;
        height: 130px;
        border-radius: 20px;
        margin: 0 20px;
    }

    .group-skeleton {
        width: 120px;
        height: 120px;
        border-radius: 20px;
        margin: 0 20px;
    }

    @media(max-width: 920px) {
        .today-skeleton {
            width: 300px;
            height: 120px;
        }
    }

    @media(max-width: 700px) {
        .today-skeleton {
            width: 280px;
            height: 120px;
            margin: 0 10px;
        }
    }

    @media(max-width: 640px) {
        .today-skeleton {
            width: 400px;
        }

        .today-skeleton.second {
            display: none;
        }
    }

    @media(max-width: 490px) {
        .today-skeleton {
            width: 300px;
        }

        .group-skeleton {
            width: 80px;
            height: 80px;
        }
    }

    @media(max-width: 375px) {
        .group-skeleton {
            width: 60px;
            height: 60px;
        }
    }

    @media(max-width: 325px) {
        .group-skeleton {
            margin: 0 5px;
        }
    }
`;

export const LineSeparator = styled.div`
    width: 100%;
    max-width: 1150px !important;
    border: 0.1px solid #F2F2F2;
    margin: 20px 5% 40px;

    @media(max-width: 1200px) {
        max-width: 1000px !important;
    }

    @media(max-width: 1060px) {
        max-width: 900px !important;
    }

    @media(max-width: 960px) {
        display: none;
    }
`;