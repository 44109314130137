import { onSignOut } from "../utils/auth";
import sentry from "./helper/sentry";

export const getSectionsCatchErrors = (error, elseError) => {
  sentry(error);

  if (error.name === 'AbortError') {
    throw new Error('Erro de conexão com o servidor');
  }

  // temporary overcharge in server
  if (error.toString().includes('503')) {
    throw new Error("Serviço indisponível - " + elseError);
  }

  // Auth error, redirect to login
  else if (
    error.toString().includes("401") ||
    error.toString().includes("403")
  ) {
    const logout = async () => {
      onSignOut();
      localStorage.setItem(
        "AUTH_ERROR",
        "Erro de autenticação, faça o login novamente, por favor!"
      );
      window.location.reload();
    };
    logout();
    throw new Error(elseError);
  } else {
    throw new Error(elseError);
  }
};
