import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 1080px !important;
    display: flex;
    padding: 20px 0;
    margin: 40px 0 1rem 0;
    background-color: #14213D;
    align-items: center;
    justify-content: center;

    .info-coupon {
        font-size: 1.4rem;
    }

    @media(max-width: 780px) {
        .info-coupon {
            font-size: 1.2rem;
        }
    }

    @media(max-width: 460px) {
        .info-coupon {
            font-size: 1rem;
        }
    }

    @media(max-width: 375px) {
        .info-coupon {
            font-size: 0.9rem;
        }
    }
`;

export const ButtonCoupon = styled.button`
    display: flex;    
    flex-direction: row;
    background-color: #FA9F2A;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: 20px;
    width: 200px;
    height: 42px;
    font-size: 1.2rem;
    transition: .2s background;

    :hover {
        background-color: #E6860B;
    }

    .text-coupon {
        color: #FFF;
        margin-left: 8px;
        margin-bottom: 3px;
    }

    @media(max-width: 780px) {
        width: 160px;
        height: 38px;
        font-size: 1rem;
    }

    @media(max-width: 460px) {
        width: 100px;
        height: 35px;
        font-size: 0.9rem;

        > svg {
            width: 14px;
        }

        .text-coupon {
            color: #FFF;
            margin-left: 5px;
        }
    }

    @media(max-width: 375px) {
        width: 80px;
        height: 28px;
        font-size: 0.8rem;

        > svg {
            width: 12px;
        }
    }
`;