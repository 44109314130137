import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import Moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

const NextOpenOrder = (props) => {
  const order = props.order;
  const render = props.render;
  const navigation = useHistory();
  const [modalVisible, setModalVisible] = useState();
  const [locale, setLocale] = useState();
  const [day, setDay] = useState();

  const serviceOrderHistory = async () => {
    localStorage.setItem("nextOpenOrderId" + order.id, order.id);
    navigation.push("serviceOrderHistory", { selectedOrder: order });
  }

  const closeModal = async () => {
    localStorage.setItem("nextOpenOrderId" + order.id, order.id);
    setModalVisible(false);
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted && order?.id) {
      setModalVisible(render);

      if (localStorage.getItem("nextOpenOrderId" + order.id)) {
        setModalVisible(false);
      }

      if ('global' === order.order_type) {
        setLocale('on-line');
      } else if ('residential' === order.order_type) {
        setLocale('em casa');
      } else {
        setLocale('na empresa');
      }

      let today = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      let scheduleDate = new Date(order.scheduled_date);

      if (scheduleDate.getDate() === today.getDate() &&
        scheduleDate.getMonth() === today.getMonth() &&
        scheduleDate.getFullYear() === today.getFullYear()) {
        setDay(' Hoje');
      } else if (scheduleDate.getDate() === tomorrow.getDate() &&
        scheduleDate.getMonth() === tomorrow.getMonth() &&
        scheduleDate.getFullYear() === tomorrow.getFullYear()) {
        setDay(' Amanhã');
      } else {
        setDay((' dia ' + Moment(order.scheduled_date).format('DD/MM/yyyy')));
      }
    }

    return () => { isMounted = false; };
  }, [order]);

  if (modalVisible) {
    return (
      <Container>
        <div className="text-view">
          <button
            type="button"
            className="close-button"
            onClick={closeModal}
          >
            <span>x</span>
          </button>

          <span className="text">Você tem um agendamento <span>{locale}</span> de {order.service.name} <span>{day}</span> às <span>{Moment(order.scheduled_date).tz("America/Sao_Paulo").format('HH:mm')}</span></span>
        </div>

        <button className="button-go-to-service" onClick={serviceOrderHistory}>
          <FaArrowRight size={20} color="#fff" />
        </button>
      </Container>
    );
  } else {
    return (
      <></>
    );
  }
}

export default NextOpenOrder;