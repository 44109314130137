import React, { useState } from 'react';
import { deleteCustomerCreditCard, deleteCustomerDebitCardsMultiBenefits } from '../../../../../../api/payments';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import ModalRemoveCard from '../../../../../../components/Modals/ModalRemoveCard';
import { Container } from './styles';

const DeleteCardButton = (props) => {
    const card = props.card;
    const type = props.type;
    const setAllCards = props.setAllCards;
    let cardList = props.cardList;
    const setLoading = props.setLoading;
    const setCheckedPaymentMethod = props.setCheckedPaymentMethod;
    const [cancelModalVisible, setCancelModalVisible] = useState(false);

    const deleteCreditCards = async () => {
        try {
            setCheckedPaymentMethod({})
            setLoading(true);
            await deleteCustomerCreditCard(card.id);
            cardList = cardList.filter(item => item.id !== card.id);
            setAllCards(cardList);

            toast.success("Cartão deletado com sucesso");

            setLoading(false);

        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    const deleteDebitCards = async () => {
        try {
            setLoading(true);

            await deleteCustomerDebitCardsMultiBenefits(card.id);
            cardList = cardList.filter(item => item.id !== card.id);
            setAllCards(cardList);

            toast.success("Cartão deletado com sucesso");

            setLoading(false);

        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    return (
        <Container>
            <button type="button" className="button-delete-card" onClick={() => setCancelModalVisible(true)}>
                <FiTrash2 color="#FD5B5B" size={19} />
            </button>

            <ModalRemoveCard
                setShowModalRemove={val => setCancelModalVisible(val)}
                showModalRemove={cancelModalVisible}
                clicked={(option) => {
                    if (option && type === 'creditCard') {
                        deleteCreditCards();
                    } else if (option && type === 'debitCard') {
                        deleteDebitCards();
                    }
                }}
            />
        </Container>
    )
}

export default DeleteCardButton;