import styled from 'styled-components';

export const Container = styled.div`
    width: 70%;
    
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    };

    .title {
        text-align: center;
        color: #14213D;
        font-size: 1rem;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-weight: 700;
    };

    .in-company-banner-button {
        width: 70%;
        justify-content: center;
        align-items: center;
        background-color: #FA9F2A;
        margin: 10px 0;
        height: 40px;
        border-radius: 23px;
    };

    .in-company-banner-button-text {
        color: #fff;
        font-size: 1rem;
    };

    .description {
        text-align: center;
        color: #14213D;
        font-size: 0.9rem;
        margin-bottom: 15px;
    };

    .banner-image {
        width: 500px;
    };

    @media(max-width: 740px) {
        .banner-image {
            width: 450px;
        };
    };

    @media(max-width: 640px) {
        .banner-image {
            width: 300px;
        };
    };

    @media(max-width: 430px) {
        .in-company-banner-button-text {
            color: #fff;
            font-size: 0.9rem;
        };
    };

    @media(max-width: 390px) {
        .banner-image {
            width: 100%;
        };
    };

    @media(max-width: 360px) {
        .in-company-banner-button {
            width: 100%;
        };
    }
`;