import styled from "styled-components";

export const ButtonOpacity = styled.button`
  transition: ease-in 0.2s;
  &:hover {
    opacity: 0.6;
  }
  
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  background: none !important;
  `;
