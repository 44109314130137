export const isAuthenticated = () => {
  const userIsLogged = localStorage.getItem("posher_logged_user");

  if (userIsLogged && !!userIsLogged) {
    return true;
  }

  return false;
};

export const hasParams = (location) => {
  if (location && location.state) {
    return true;
  }

  return false;
};
