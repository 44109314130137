import styled from "styled-components";

export const Container = styled.aside`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding: 2rem;

  img {
    width: 15rem;
  }

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media (max-width: 860px) {
    width: 80%;
  }
`;

export const DescriptionCard = styled.h2`
  color: #717171;
  margin-top: 2rem;
`;

export const ButtonRedirect = styled.button`
  margin-top: 2rem;
  color: #FFFFFF;
  background: #fa9f2a;
  border-radius: 1rem;
  padding: 1rem 2rem;
  transition: ease-in 0.2s;
  font-weight: bold;
  font-size: 1.2rem;

  &:hover {
    opacity: 0.8;
  }
`;
