import React from 'react';
import { Container, DescriptionCard } from './styles';
import logo from '../../../assets/Logo.svg';

const BrowserDetectCard = () => {

  return (
    <Container>
      <img src={logo} alt="Logo browser card" />
      <DescriptionCard>Olá! Por favor, atualize o seu navegador para a versão mais recente para ter uma melhor experiência em nosso site.</DescriptionCard>
    </Container>
  );
}

export default BrowserDetectCard;