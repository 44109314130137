import React from 'react';
import { formatCurrencyBRL } from '../../../../utils/utils';
import { Container, ButtonContainer } from './styles';
import { FiShoppingCart } from 'react-icons/fi';

const Product = ({ product, descriptionSelected, addShoppingCard }) => {
    return (
        <div>
            <ButtonContainer>
                <Container onClick={() => addShoppingCard(product)}>
                    <img src={product.image_url} className="image" />

                    <div className="content">
                        <span className="title">{product.name}</span>
                        <span className="description">{product.description}</span>
                    </div>

                    <div className="buy-container">
                        <div className="buy">
                            <div className="icon">
                                <FiShoppingCart className="icon-buy" />
                            </div>
                            <span className="text-buy">comprar</span>
                        </div>
                        <div className="price">
                            <span className="text-price">R$ <span className="price-value">{formatCurrencyBRL(product.price).substring(2)}</span></span>
                        </div>
                    </div>
                </Container>

                <button
                    type="button"
                    className="button-more"
                    onClick={() => descriptionSelected(product)}
                >
                    <span>+ informações</span>
                </button>
            </ButtonContainer>
        </div>
    );
}

export default Product;