import React from 'react';
import Moment from 'moment-timezone';
import { ButtonHour } from './styles';

const SlotTimeButton = (props) => {
    const doSelectTimeSlot = props.doSelectTimeSlot;
    const slotTime = props.slotTime;
    const selected = props.selected;

    let time = Moment(slotTime.begin_at).tz("America/Sao_Paulo").format('HH');
    let minute = Moment(slotTime.begin_at).tz("America/Sao_Paulo").format('mm');

    const waitingList = time === '00' && minute === '00';

    return (
        <ButtonHour type="button" onClick={doSelectTimeSlot}>
            <div className={`time-scroll-button ${waitingList && 'wait-list'}`}>
                <div className="container-hour" >
                    {waitingList ?
                        <span className={`wait-list-text ${selected && "wait-list-text-selected"}`}>lista de encaixe</span>
                        :
                        <div className="container-hour-minute">
                            <span className={`time-text ${selected && 'time-text-selected'}`}>{time}</span>
                            <span className={`minute-text ${selected && 'minute-text-selected'}`}>{minute}</span>
                        </div>
                    }
                </div>
            </div>
        </ButtonHour>
    )
}

export default SlotTimeButton;