import styled from 'styled-components';

export const Container = styled.div`
    .container-view {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        width: 550px;
        border-radius: 19px;
        padding: 11px;
        margin-bottom: 10;
    }

    .title {
        text-align: center;
        color: #000;
        font-size: 1rem;
        margin-bottom: 10px;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 700;
        color: #14213D;
    }

    .subtitle {
        text-align: center;
        font-size: 0.9rem;
        margin-bottom: 30px;
        color: #14213D;
    }

    .carousel {
        width: 500px !important;
    }

    .button-left {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        left: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    .button-right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50px;
        right: -10px;
        width: 39px;
        height: 39px;
        border-radius: 100%;
        color: #14213D;
        border: 1px solid #c4c4c4;
        background: white;
    }

    @media(max-width: 900px) {
        .container-view {
            width: 450px;
        }

        .carousel {
            max-width: 400px !important;
        }

        .button-left,
        .button-right {
            top: 30px;
        }
    }

    @media(max-width: 640px) {
        .container-view {
            width: 450px;
        }

        .carousel {
            max-width: 400px !important;
        }

        .button-left,
        .button-right {
            top: 20px;
        }
    }

    @media(max-width: 490px) {
        .carousel {
            margin: 0;
            max-width: 320px !important;
        }

        .button-left,
        .button-right {
            display: none;
        } 

        .container-view {
            width: 350px;
            padding: 11px 0;
        }
    }

    @media(max-width: 375px) {  
        .carousel {
            max-width: 290px !important;
        }

        .container-view {
            width: 300px;
            padding: 11px 0;
        }
    }
`;

export const ButtonsContainer = styled.div`

`;