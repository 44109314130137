import styled from 'styled-components';
import ImgBackground from '../../../../assets/images/bg-complete-order-light.jpg';

export const Container = styled.div`
    .background-body {
        background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${ImgBackground});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-bottom: 150px;
    }

    .checked {
        border-radius: 100%;
        background-color: #50B041;
        align-items: center;
        justify-content: center;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0 20px;
    }

    .all-right {
        font-size: 1.3rem;
        font-weight: 700;
        color: #FFF;
        margin-bottom: 30px;
    }

    .text {
        width: 500px;
        font-size: 1.2rem;
        color: #FFF;
        text-align: center;
        margin-bottom: 80px;
    }

    .block-services {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }

    @media(max-width: 890px) {
        .text {
            width: 450px;
        }
    }

    @media(max-width: 480px) {
        .text {
            width: 350px;
            font-size: 1rem;
        }

        .all-right {
            font-size: 1.1rem;
        }

        .checked {
            padding: 8px;
        }
    }

    @media(max-width: 375px) {
        .text {
            width: 250px;
        }
    }

    @media(max-width: 320px) {
        .text {
            width: 220px;
        }
    }
`;