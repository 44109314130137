import styled from "styled-components";

export const InputContainer = styled.div`
  background-color: #f1f1f1;
  display: flex;
  
  border-radius: 1rem;
  align-items: center;
  margin: 0 0 2vh 0;
`;

export const Input = styled.input`
  color: #14213d;
  background-color: #f1f1f1;
  width: 30rem;

  height: 100%;
  padding: 0.8rem;
  border-radius: 1rem;

  @media(max-width: 600px){
    width: 90vw;
  }
`;
