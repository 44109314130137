import styled from "styled-components";

export const Container = styled.div`
    .footer-service-list {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @media (max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }

        .container-pack {
            padding-bottom: 80px;
        }
    }
`;

export const PageContainer = styled.div`
    padding-top: 5vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1080px !important;
    min-height: 100vh;
    margin: auto;
    box-sizing: border-box;
    padding-bottom: 10vh;
    position: relative;
`;

export const ButtonAddVaccination = styled.button`
    margin: 2rem 0 5rem 0;
    background: #fa9f2a;
    padding: 1rem 3rem 1rem 3rem;
    border-radius: 18px;
    transition: ease-in-out 0.2s;
    &:hover {
        opacity: 0.8;
    }
`;

export const VaccinationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 18px;
    padding: 20px 30px;
    margin-bottom: 30px;
    width: 60%;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
    border-left: 2px solid #fa9f2a;
    transition: ease-in 0.2s;
    @media (max-width: 600px) {
        text-align: center;
        width: 90%;
        align-items: center;
        flex-direction: column;
    }
    &:hover {
        transform: scale(1.05);
    }
`;

export const VaccinationDate = styled.h3`
    color: #fa9f2a;
`;

export const VaccinationType = styled.h3`
    color: #707070;
`;

export const NotFound = styled.h4`
    color: #14213d;
    font-family: "Roboto", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
    padding: 2rem;
`;

export const Label = styled.label`
    color: #000;
    margin: 2rem 0 1rem 0;
`;
