import React, { useState } from "react";
import { Container, ButtonServiceContainer } from "./styles";
import { useHistory } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import { FaCalendar, FaSignInAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { getService } from "../../api/service_offer";
import { residentialAvailable } from "../../api/customer";
import { formatCurrencyBRL } from "../../utils/utils";
import ModalMoreInformation from "../Modals/ModalMoreInformation";
import ModalAddress from "../Modals/ModalAddress";
import ModalSite from "../Modals/ModalSite";

const ButtonLarge = (props) => {
    const history = useHistory();
    const offer = props.offer;
    const card = props.card;
    const setLoading = props.setLoading;
    const [selectedAddress, setSelectedAddress] = useState(props.address);
    const [selectAddress, setSelectAddress] = useState(false);
    const [selectAddressMessage, setSelectAddressMessage] = useState();
    const [service, setService] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalSiteUrl, setModalSiteUrl] = useState(false);

    const navigateOffer = (offer, address) => {
        if (offer.service_id && card !== "packages") {
            if (offer.on_demand_order) {
                history.push("/onDemandOrderData", {
                    offer: offer,
                    orderType: offer.order_type,
                    address: address,
                });
            } else if (offer.express_order) {
                if (offer.slot_suggestion) {
                    history.push("/completeExpressOrder", {
                        offer: offer
                    });
                } else {
                    toast.warn("Checkin indisponível");
                }
            } else {
                if (offer.site_url && offer.site_url.length > 0) {
                    setModalSiteUrl(true);
                } else {
                    history.push("/slotList", {
                        offer: offer,
                        orderType: offer.order_type,
                        address: address,
                    });
                }
            }
        } else if (card === 'packages') {
            history.push("/packageCheckout", {
                pack: offer,
            });
        } else {
            history.push("/signatureCheckout", {
                signaturePlan: offer,
            });
        }
    };

    const selectOffer = (offer, address) => {
        setSelectAddressMessage("");

        if (
            "residential" === offer.order_type &&
            !address &&
            card !== "packages"
        ) {
            setSelectAddress(true);
            return;
        }

        navigateOffer(offer, address);
    };

    const afterSelectAddress = async (address) => {
        try {
            let available = await residentialAvailable(address.zipCode);

            if (available.available) {
                setSelectAddress(false);

                navigateOffer(offer, address);
                setSelectAddressMessage("");
            } else {
                setSelectAddressMessage(
                    "Serviço indisponível no endereço selecionado"
                );
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    const openServiceModal = async () => {
        try {
            setLoading(true);
            if (card !== "packages") {
                await getService(
                    setService,
                    offer.category_id,
                    offer.service_id
                );
            } else {
                setService(offer);
            }

            setLoading(false);

            setModalVisible(true);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    return (
        <div>
            <ModalAddress
                showModalAddress={selectAddress}
                setShowModalAddress={setSelectAddress}
                afterSelect={(address) => afterSelectAddress(address)}
                warningMessage={selectAddressMessage}
                checkResidentialAvailability={true}
            />

            <ModalMoreInformation
                showModal={modalVisible}
                setShowModal={(val) => setModalVisible(val)}
                title={service?.name}
                body={service?.description}
            />

            <ModalSite
                showModal={modalSiteUrl}
                setShowModal={(val) => setModalSiteUrl(val)}
                site={offer.site_url}
            />

            <ButtonServiceContainer>
                <Container
                    onClick={() =>
                        selectOffer(
                            offer,
                            selectedAddress,
                            setSelectAddress,
                            setSelectAddressMessage
                        )
                    }
                >
                    <div className="img-button">
                        <img src={offer.thumb_url} alt={offer.title} />
                    </div>

                    <div className="info-button">
                        {card !== "packages" && card !== 'subscriptions' ? (
                            <h3>{offer.title}</h3>
                        ) : (
                            <h3>{offer.name}</h3>
                        )}
                        <div className="more-info">
                            <span className="subTitle">
                                {offer.description}
                            </span>
                        </div>
                    </div>

                    <div className="icon-button">
                        {card === "packages" || 'subscriptions' ? (
                            <div className="button-orange">
                                <FiShoppingCart size={25} color={"#FFF"} />
                                <span>comprar</span>
                            </div>
                        ) : offer.schedulable ? (
                            <div className="button-orange">
                                <FaCalendar size={25} color={"#FFF"} />
                                <span>agendar</span>
                            </div>
                        ) : (
                            <div className="button-orange">
                                <FaSignInAlt size={25} color={"#FFF"} />
                                <span>acessar</span>
                            </div>
                        )}

                        {(offer.schedulable || card === "packages" || "subscriptions") && (
                            <div className="button-free">
                                <span>
                                    {offer.estimate_required
                                        ? "orçar"
                                        : offer.free
                                            ? "grátis"
                                            : offer.express_order
                                                ? "checkin": formatCurrencyBRL(offer.price)}
                                </span>
                            </div>
                        )}
                    </div>
                </Container>

                <button
                    type="button"
                    onClick={openServiceModal}
                    className="more-information"
                >
                    + informações
                </button>
            </ButtonServiceContainer>
        </div>
    );
};

export default ButtonLarge;
