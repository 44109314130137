import styled from 'styled-components';

export const Container = styled.div`
    .container-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 150px;
    };
    
    .footer-service-list {
        position: absolute;
        bottom: 30px;
        left: 0;
    };

    .button-continue {
        align-self: center;
        margin-top: 20px;
        background: none;
    };

    .text-continue {
        color: #FA9F2A;
        text-decoration: underline;
        align-self: center;
        font-size: 1rem;
    };

    .message-text {
        font-size: 1.2rem;
        color: #B7B7B7;
        text-align: center;
        margin: 20px 0;
    };

    .button-go-back {
        background-color: #FA9F2A;
        border-radius: 20px;
        padding: 15px 0;
        width: 400px;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: 20px;
    };

    .text-go-back {
        font-size: 1rem;
        color: #FFF;
    };

    .container-payments {
        background-color: #FFF;
        width: 650px;
        display: flex;
        flex-direction: column;
        align-self: center;
        padding: 20px 0;
        border-radius: 18px;
        margin: 20px 5px;
    };

    .title-payment {
        text-align: center;
        color: #14213D;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 15px;
    };

    .address-text {
        margin-bottom: 10px;
        margin-left: 30px;
        color: #14213D;
        font-size: 1rem;
        font-weight: 300;
    };

    .text-description {
        font-weight: bold;
    };

    .total {
        text-align: center;
        color: #14213D;
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 5px;
    };

    .total-value {
        font-weight: bold;
    };

    .button-finish {
        width: 100%;
        background-color: #FA9F2A;
        border-radius: 20px;
        padding: 15px 0;
        align-self: center;
    };

    .button-disabled {
        width: 100%;
        background-color: #707070;
        border-radius: 20px;
        padding: 15px 0;
        align-self: center;
    };

    .text-finish {
        text-align: center;
        color: #FFFFFF;
        font-size: 1.1rem;
    };

    @media(max-width: 960px) {
        .footer-service-list .line-separator {
            display: none;
        }

        .container-body {
            padding-bottom: 60px;
        }
    }

    @media(max-width: 840px) {
        .container-payments {
            width: 450px;
        }
    }

    @media(max-width: 630px) {
        .button-finish,
        .button-disabled {
            width: 90%;
        }

        .button-go-back {
            width: 300px;
        };

        .text-go-back {
            font-size: 1rem;
        };
    }

    @media(max-width: 460px) {
        .container-payments {
            width: 350px;
        }
    }

    @media(max-width: 375px) {
        .container-payments {
            width: 310px;
        }

        .button-go-back {
            width: 200px;
        };

        .text-go-back {
            font-size: 0.9rem;
        };
    }
`;