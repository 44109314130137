import React, { useState, useEffect } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { RiArrowDownSFill } from 'react-icons/ri';
import {
  getCustomerCountries,
  makeRegisterCustomer,
  getTermsConditions,
} from '../../../api/customer';
import { makePasswordLogin } from '../../../api/login';
import { useAuthContext } from '../../../hooks/context';
import { onSignIn, getUser } from '../../../utils/auth';
import {
  putEmployeeInCompany,
  getCompanyByEmail,
} from '../../../api/companies';
import ModalCompanies from '../../../components/Modals/ModalCompanies';
import { Container } from './styles';
import LoaderSpinner from '../../../components/LoaderSpinner';
import ModalTermsAndConditions from '../../../components/Modals/ModalTermsAndConditions';
import ReCAPTCHA from 'react-google-recaptcha';
import { t } from 'i18next'
import { countryToLanguage } from '../../../i18n/languageUtils';
import i18n from '../../../i18n';

const RegisterCustomer = ({ location, authenticated }) => {
  const navigation = useHistory();
  const username = location.state ? location.state.username : null;
  const external = location.state?.external || null;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(location?.state && location?.state?.email);
  const [cpf, setCpf] = useState('');
  const [fullName, setFullName] = useState(
    location?.state && location.state.name,
  );
  const [disableCpf, setDisableCpf] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [disableEmail, setDisableEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState();
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [mobilePhone, setMobilePhone] = useState();
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [showModalCompanies, setShowModalCompanies] = useState(false);
  const [identificationDocument, setIdentificationDocument] = useState('');
  const [country, setCountry] = useState('Brasil');
  const [token, setToken] = useState('');
  const [countries, setCountries] = useState([]);
  const { user, userRef } = useAuthContext();

  const onCancelTerms = () => {
    navigation.push('/');
  };

  const passwordHideAndShow = () => {
    setHidePassword(!hidePassword);
  };

  const doLinkCompany = async selectedCompany => {
    try {
      setLoading(true);

      await putEmployeeInCompany(selectedCompany[0].id || selectedCompany[0][0].id);

      getUser().company = selectedCompany;
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    } finally{
      setLoading(false);
      navigation.push('/home');
    }
  };

  const changeCountry = async (selectedCountry) => {
    setCountry({ selectedValue: selectedCountry })
    let language = countryToLanguage[selectedCountry];
    i18n.changeLanguage(language)
    localStorage.setItem("userSelectedLanguage", language)
  }

  const register = async () => {
    try {
      if (!token) {
        toast.warn('Favor preencher o reCAPTCHA');
        return
      }

      setLoading(true);
      let finalPassword = password;


      if (external) {
        const provider = localStorage.getItem('provider');
        finalPassword = provider;
      }

      await makeRegisterCustomer(
        email,
        cpf,
        identificationDocument,
        fullName,
        mobilePhone,
        country.selectedValue,
        finalPassword,
      );

      const data = await makePasswordLogin(username, finalPassword);

      onSignIn(data);

      await getCompanyByEmail(email).then(companies => {
        let array = []
        if (companies.length > 0) {
          array.push(companies)
          setSelectedCompany(array);
        } else if (companies.length === 0) {
          toast.error("Empresa não encontrada")
        }
      });

      await getTermsConditions(setTerms);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      const errorMessage = error.message;
      const validationMessages = [];

      if (errorMessage.indexOf('email') >= 0) {
        validationMessages.push('Email');
      }

      if (errorMessage.indexOf('country') >= 0) {
        validationMessages.push('País');
      }

      if (
        country.selectedValue === 'BR' &&
        (!cpf || errorMessage.indexOf('cpf') >= 0)
      ) {
        validationMessages.push('CPF');
      }

      if (
        country.selectedValue !== 'BR' &&
        (!identificationDocument ||
          errorMessage.indexOf('identificationDocument') >= 0)
      ) {
        validationMessages.push('Documento de identificação');
      }

      if (errorMessage.indexOf('name') >= 0) {
        validationMessages.push('Nome');
      }

      if (errorMessage.indexOf('mobilePhone') >= 0) {
        validationMessages.push('Telefone');
      }

      if (errorMessage.indexOf('password') >= 0 && !external) {
        validationMessages.push('Senha');
      }

      if (validationMessages.length > 0) {
        toast.warn(
          `Favor preencher os campos obrigatórios:\n- ${validationMessages.join(
            '\n- ',
          )}`,
        );
      } else {
        toast.error(`Erro ao realizar cadastro: ${errorMessage}`);
      }
    }
  };

  const getAllInfo = async () => {
    try {
      setLoading(true);

      await getCustomerCountries(setCountries);

      setCountry({ selectedValue: 'BR' });
      setDisableCpf(cpf && cpf.length > 0);
      setDisableEmail(email && email.length > 0);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (!location || !location.state) {
      navigation.push('/');
    }

    if (authenticated) {
      navigation.push('/home');
    }

    if (authenticated || user || userRef.current) {
      navigation.push('/home');
    }

    getAllInfo();
  }, []);

  useEffect(() => {
    if (terms?.privacy) {
      setShowModalTerms(true);
    } else if (terms?.acceptance_token && selectedCompany[0]?.length > 1) {
      setShowModalCompanies(true);
    } else if (terms?.acceptance_token && selectedCompany.length === 1) {
      doLinkCompany(selectedCompany);
    } else if (terms?.acceptance_token && selectedCompany.length < 1) {
      navigation.push('/home');
    }
  }, [terms, selectedCompany]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <Container>
      <LoaderSpinner loading={loading} />

      <ModalCompanies
        showModalCompany={showModalCompanies}
        setShowModalCompany={val => setShowModalCompanies(val)}
        companies={selectedCompany}
        choosed={val => setSelectedCompany(val)}
      />

      <ModalTermsAndConditions
        showModalTerms={showModalTerms}
        setShowModalTerms={val => setShowModalTerms(val)}
        setLoading={val => setLoading(val)}
        privacy={terms?.privacy}
        service={terms?.service}
        version={terms?.version}
        onCancel={onCancelTerms}
        setTerm={val => setTerms(val)}
      />

      <div className="content-register">
        <span className="login-text">{t("letsGetToKnowEachOther")}</span>

        <input
          type="text"
          className={`inputs ${disableEmail && 'input-disabled'}`}
          placeholder={t("emailPlaceholder")}
          value={email}
          onChange={e => setEmail(e.target.value)}
          disabled={!!disableEmail}
        />

        <div className="input-container-select">
          <select
            value={country.selectedValue}
            onChange={e => changeCountry(e.target.value )}
            className="select-input"
          >
            <option disabled selected>
            {t("selectCountryPlaceholder")}
            </option>
            {countries.map(country => {
              return (
                <option key={country.id} value={country.abbreviation}>
                  {country.name}
                </option>
              );
            })}
          </select>

          <div className="icon-select-input">
            <RiArrowDownSFill size={30} color="#FA9F2A" />
          </div>
        </div>

        {country.selectedValue === 'BR' && (
          <InputMask
            className={`inputs ${disableCpf && 'input-disabled'}`}
            placeholder={t("cpfPlaceholder")}
            disabled={!!disableCpf}
            value={cpf}
            onChange={e => setCpf(e.target.value)}
            maskChar=""
            mask="999.999.999-99"
          />
        )}

        {country.selectedValue !== 'BR' && (
          <input
            type="text"
            className="inputs"
            placeholder={t("identificationDocument")}
            onChange={e => setIdentificationDocument(e.target.value)}
          />
        )}

        <input
          type="text"
          className="inputs"
          placeholder={t("fullNamePlaceholder")}
          defaultValue={fullName}
          onChange={e => setFullName(e.target.value)}
        />

        <InputMask
          className="inputs"
          placeholder={t("phoneNumberPlaceholder")}
          onChange={e => setMobilePhone(e.target.value)}
          maskChar=""
          mask="(99) 99999-9999"
        />

        {!external && (
          <div className="input-password-container">
            <input
              type={hidePassword ? 'password' : 'text'}
              className="inputs"
              placeholder={t("createPasswordPlaceholder")}
              onChange={e => setPassword(e.target.value)}
            />

            <button
              type="button"
              className="border-icon-input"
              onClick={passwordHideAndShow}
            >
              {hidePassword ? (
                <FiEye size={20} color="#FFF" />
              ) : (
                <FiEyeOff size={20} color="#FFF" />
              )}
            </button>
          </div>
        )}

        <button type="button" className="buttom-view" onClick={register}>
          <span className="buttom-text">{t("registerButton")}</span>
        </button>


        <div className="recaptcha">
          <ReCAPTCHA
            sitekey="6Lfy_KoaAAAAAIOD9_hfRhgDYH5VgrIb_PcPmEuo"
            onChange={token => setToken(token)}
          />
        </div>
      </div>
    </Container>
  );
};

export default RegisterCustomer;
