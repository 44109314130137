import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

import { getOrderToGiveFeedback } from "../../../../api/requests/orders/putFeedbackClientApi";
import LoaderSpinner from "../../../../components/LoaderSpinner";
import { ButtonFeedbackContainer } from "./components/ButtonFeedbackContainer";

import { OrderFeedbackContainer } from "./styles";

import logo from "../../../../assets/Logo.svg";

export function OrderFeedback() {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const order_id = query.get("orderId");

  useEffect(() => {
    let isMounted = true;

    async function doGetOrderToFeedback() {
      await getOrderToGiveFeedback(order_id)
        .then((res) => {
          if (isMounted) {
            res.time = moment(res.scheduled_date).format("DD/MM");
            setOrder(res);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          setTimeout(() => {
            history.replace("/home");
          }, 2000);
        })
        .finally(() => {
          sessionStorage.removeItem("deepLink");
          if (isMounted) {
            setLoading(false);
          }
        });
    }
    doGetOrderToFeedback();

    return () => (isMounted = false);
  }, [order_id, history]);

  return (
    <OrderFeedbackContainer>
      <img src={logo} alt="Logo adblock card" />
      {loading ? (
        <LoaderSpinner loading={loading} />
      ) : (
        <>
          <h1>
            E aí, gostou do(a) {order?.service?.name} que você fez no dia{" "}
            {order?.time} com a POSHER?
          </h1>
          <h2>Envie seu feedback agora para a empresa!</h2>
          <ButtonFeedbackContainer order_id={order_id} />
        </>
      )}
    </OrderFeedbackContainer>
  );
}
