import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getTimer } from "../../utils/getTimer";
import { PixTimerContainer } from "./styles";

export function NeedPayTimer({ paymentExpirationMinutes }) {
  const [timeOver, setTimeOver] = useState(false);
  const [actualTime, setActualTime] = useState();
  const history = useHistory();

  useEffect(() => {
    let iterator = localStorage.getItem("pix_timer") | 0;

    if (timeOver) {
      history.replace("/home");
    }

    let interval = setInterval(() => {
      iterator++;
      getTimer(setActualTime, paymentExpirationMinutes, iterator, setTimeOver);
    }, 1000);

    return () => {
      localStorage.removeItem("pix_timer");
      clearInterval(interval);
    };
  }, [timeOver]);

  return (
    <PixTimerContainer>
      <h2>{actualTime ? actualTime : '...'}</h2>
      <h3>MINUTOS</h3>
    </PixTimerContainer>
  );
}
