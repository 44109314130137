import styled from 'styled-components';

export const Container = styled.div`
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1080px !important;
    margin: auto;
    padding-bottom: 50px;
    box-sizing: border-box;
    padding-top: 10px;

    .body-home {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    @media(max-width: 960px) {
        margin-bottom: 50px;
    }

    @media(max-width: 620px) {
        margin-bottom: 20px;
    }
`;

export const LineSeparator = styled.div`
    width: 100%;
    max-width: 900px !important;
    border: 0.1px solid #F2F2F2;
    margin: 50px 5% 50px;
`;

export const ContainerMain = styled.div`
    
`;

export const Divider = styled.div`
    margin-top: 4rem;
`