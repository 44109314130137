import { fetchWithTimeout, header } from './helper/request';
import {
  API_TIME_CLOCK,
  CHECKIN,
  CHECKOUT,
  CURRENT,
  HISTORY,
  TIMES,
} from './helper/api_urls';
import { getSectionsCatchErrors } from './getSectionsCatchErrors';
import { validatePostResponse } from './helper/validateResponse';

const doTimeClockCheckin = async () => {
  const postUrl = `${API_TIME_CLOCK}${TIMES}${CHECKIN}`;
  const body = {
    latitude: null,
    longitude: null,
  };
  await validatePostResponse(
    postUrl,
    'Erro ao fazer a entrada no controle de ponto',
    body,
  );
};

const doTimeClockCheckout = async () => {
  const postUrl = `${API_TIME_CLOCK}${TIMES}${CHECKOUT}`;
  const body = {
    latitude: null,
    longitude: null,
  };
  await validatePostResponse(
    postUrl,
    'Erro ao fazer a saída no controle de ponto',
    body,
  );
};

const deleteTimeClockCurrent = async () => {
  const validationError = false;

  try {
    const { delete_bearer_common } = header();

    const response = await fetchWithTimeout(
      `${API_TIME_CLOCK}${TIMES}`,
      delete_bearer_common,
    );

    if (!response.ok) {
      const responseJson = response.data;
      throw new Error(responseJson.message);
    }
  } catch (error) {
    if (validationError) {
      throw error;
    } else {
      getSectionsCatchErrors(
        error,
        'Erro ao cancelar uma entrada em andamento',
      );
    }
  }
};

const getTimeClockCheckinCurrent = async () => {
  let validationError = false;

  try {
    const { get_bearer_common } = header();

    const response = await fetchWithTimeout(
      `${API_TIME_CLOCK}${TIMES}${CURRENT}`,
      get_bearer_common,
    );

    if (response.status === 200) {
      const responseJson = response.data;
      return responseJson;
    }
    if (response.status === 400) {
      const responseJson = response.data;

      validationError = true;

      throw new Error(responseJson.message);
    } else {
      const data = response.data;

      throw new Error(data.message);
    }
  } catch (error) {
    if (validationError) {
      throw error;
    } else {
      getSectionsCatchErrors(
        error,
        'Erro ao verificar as entradas em andamento',
      );
    }
  }
};

const getTimeClockHistory = async daysSelected => {
  let validationError = false;

  try {
    const { get_bearer_common } = header();

    const response = await fetchWithTimeout(
      `${API_TIME_CLOCK}${TIMES}${HISTORY}?days=${daysSelected}`,
      get_bearer_common,
    );

    if (response.status === 200) {
      const responseJson = response.data;
      return responseJson;
    }
    if (response.status === 400) {
      const responseJson = response.data;

      validationError = true;

      throw new Error(responseJson.message);
    } else {
      const data = response.data;

      throw new Error(data.message);
    }
  } catch (error) {
    if (validationError) {
      throw error;
    } else {
      getSectionsCatchErrors(
        error,
        'Erro ao verificar o histórico do controle de ponto',
      );
    }
  }
};

export {
  doTimeClockCheckin,
  doTimeClockCheckout,
  deleteTimeClockCurrent,
  getTimeClockCheckinCurrent,
  getTimeClockHistory,
};