import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ptBRJson from './translations/ptBR.json'
import enJson from './translations/en.json'
import esJson from './translations/es.json'
import { getDeviceLocaleLanguage } from "./languageUtils";


const detectLocale = (callback) => {
  const storedLocale = localStorage.getItem("userSelectedLanguage");
  const language = storedLocale || getDeviceLocaleLanguage()

  return callback(language)
}

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: detectLocale,
  init: () => { },
  cacheUserLanguage: () => { },
};

i18n
  .use(initReactI18next)
  .use(languageDetector) //Detect last used language
  .init({
    fallbackLng: "ptBR", //Fallback language
    interpolation: {
      escapeValue: false
    },
    resources: {
      "ptBR": ptBRJson,
      "es": esJson,
      "en": enJson
    }
  })

export default i18n;