import React, { useState } from "react";
import { getUser } from "../../../utils/auth";
import { makeChangePassword } from "../../../api/customer";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container } from './styles';
import Header from "../../../components/Header";
import LoaderSpinner from "../../../components/LoaderSpinner";
import Footer from "../../../components/Footer";
import TabButton from "../../../components/TabButton";

const ChangePassword = () => {
  let customer = getUser().customer;
  let username = customer.email;

  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [hideOldPassword, setHideOldPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const navigation = useHistory();

  const updatePassword = async () => {
    let validationMessages = [];

    try {
      setLoading(true);

      await makeChangePassword(username, oldPassword, newPassword);
      toast.success("Senha atualizada com sucesso", { autoClose: 3000 });

      setLoading(false);

      navigation.push("/home");
    } catch (error) {
      setLoading(false);
      let errorMessage = error.message;

      if (errorMessage.indexOf("oldPassword") >= 0) {
        validationMessages.push("Senha atual");
      }

      if (errorMessage.indexOf("newPassword") >= 0) {
        validationMessages.push("Nova senha");
      }

      if (validationMessages.length > 0) {
        toast.warn(
          "Favor preencher os campos obrigatórios:\n- " +
          validationMessages.join("\n- ")
        );
      } else {
        toast.error(error.message);
      }
    }
  };

  const oldPasswordHideAndShow = () => {
    setHideOldPassword(!hideOldPassword);
  };
  const newPasswordHideAndShow = () => {
    setHideNewPassword(!hideNewPassword);
  };

  return (
    <Container>
      <Header screen="user" orderType="corporate" />
      <LoaderSpinner loading={loading} />

      <div className="background-body">

        <div className="content-change-password" id="bodyChangePassword">
          <div className="title-change-password">
            <span>Troque sua senha informando a senha atual</span>
            <span>e a nova</span>
          </div>

          <div className="container-input">
            <input
              className="inputs"
              placeholder="senha atual"
              type={hideOldPassword ? 'password' : 'text'}
              onChange={e => setOldPassword(e.target.value)}
            />

            <button type="button" className="border-icon-input" onClick={oldPasswordHideAndShow}>
              {hideOldPassword ?
                <FiEye size={20} color="#FFF" />
                :
                <FiEyeOff size={20} color="#FFF" />
              }
            </button>
          </div>

          <div className="container-input">
            <input
              className="inputs"
              placeholder="nova senha"
              type={hideNewPassword ? 'password' : 'text'}
              onChange={e => setNewPassword(e.target.value)}
            />

            <button type="button" className="border-icon-input" onClick={newPasswordHideAndShow}>
              {hideNewPassword ?
                <FiEye size={20} color="#FFF" />
                :
                <FiEyeOff size={20} color="#FFF" />
              }
            </button>
          </div>

          <button type="button" className="buttom-view" onClick={updatePassword}>
            <span>atualizar senha</span>
          </button>
        </div>

        <div className="footer-service-list">
          <div className="line-separator"></div>
          <Footer />
        </div>
      </div>

      <TabButton screen="account" />
    </Container>
  );
};

export default ChangePassword;
