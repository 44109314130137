import React, { useState } from 'react';
import Modal from 'react-modal';
import { putAcceptMessage } from '../../../api/messages';
import LoaderSpinner from '../../../components/LoaderSpinner/index';
import './styles.css';

const ModalNextMessages = ({ showModalNext, setShowModalNext, message }) => {

    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const verifyAcceptance = () => {
        if (message && !message.require_acceptance) {
            return true
        } else {
            return checked
        }
    }

    const doAcceptMessage = async () => {
        if (message && message.require_acceptance) {
            setLoading(true)
            await putAcceptMessage(message.id)
            setLoading(false)
        }
        setShowModalNext(false)
    }

    return (
        <div>
            <LoaderSpinner loading={loading} />
            <Modal
                isOpen={showModalNext}
                onRequestClose={() => { checked && setShowModalNext(false) }}
                overlayClassName="modal-overlay"
                className="modal-content next-messages"
                ariaHideApp={false}
            >
                <div className="content-body">
                    <div dangerouslySetInnerHTML={{ __html: message?.message }}></div>
                    {message?.require_acceptance &&
                        <div className='checkbox-content'>
                            <label>
                                <input
                                    type="checkbox"
                                    className='checkbox-input'
                                    checked={checked}
                                    onChange={() => { setChecked(!checked) }}
                                />
                                <span className="checkmark" onClick={() => { setChecked(!checked) }}></span>
                                Estou de acordo
                            </label>
                        </div>
                    }
                </div>


                <button className={verifyAcceptance() ? 'close-button' : 'close-button-disabled'} disabled={!verifyAcceptance()} type="type" onClick={() => { verifyAcceptance() && doAcceptMessage() }}>
                    <span>fechar</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalNextMessages;