import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { getCompanyById, getCompanyByEmail, putEmployeeInCompany } from '../../../api/companies';
import ModalCompanies from '../../../components/Modals/ModalCompanies';
import { getUser, onSignIn } from '../../../utils/auth';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../components/Header';
import { Container } from './styles';
import LoaderSpinner from '../../../components/LoaderSpinner';
import Footer from '../../../components/Footer';
import TabButton from '../../../components/TabButton';
import ModalCompaniesGroup from '../../../components/Modals/ModalCompaniesGroup';
import { refreshToken } from '../../../api/login'

const LinkCompany = () => {
    const [loading, setLoading] = useState(false);
    const [codeCompany, setCodeCompany] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState({});
    const [companiesGroups, setCompaniesGroups] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const navigation = useHistory();

    const doSearchByCode = async () => {
        try {
            setLoading(true);
            let array = [];
            
            await getCompanyById(codeCompany).then(data => {
                if (data.length > 0) {
                    array.push(data); 
                    setShowModal(true);
                    setCompaniesGroups(array);
                } else {
                    toast.error("Nenhuma empresa encontrada através deste código")
                }
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message)
        }
    }

    const doSearchByEmail = async () => {
        try {
            setLoading(true);
            
            await getCompanyByEmail(email).then(data => {
                let array = [];
                if (data.length > 0) {
                    array.push(data); 
                    setShowModal(true);
                    setCompaniesGroups(array);
                } else if (data.length === 0) {
                    toast.error("Empresa não encontrada")
                }
            });

            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }

    const doLinkCompany = async (company) => {
        try {
            setLoading(true);
            await putEmployeeInCompany(company[0].id);

            let data = await refreshToken()
            onSignIn(data.user)

            setLoading(false);

            navigation.push("/linkedCompany", { company: company[0] });
        } catch (error) {
            setLoading(false);
            toast.error(error.message)
        }
    }

    useEffect(() => {
        if (company.length === 1) {
            doLinkCompany(company)
        }
    }, [company]);

    return (
        <Container>
            <Header screen="user" orderType="corporate" />
            <LoaderSpinner loading={loading} />

            <ModalCompanies
                showModalCompany={showModal}
                setShowModalCompany={val => setShowModal(val)}
                companies={companiesGroups}
                choosed={val => setCompany(val)}
            />

            <ModalCompaniesGroup
                choosed={val => setCompany(val)}
            />

            <div className="background-body">

                <div className="content-link-company">
                    <div className="header-message">
                        <span className="message">Para vincular sua conta a uma empresa é super tranquilo.</span>
                        <span className="message">Escolha uma das formas abaixo:</span>
                    </div>

                    <div className="container-options">
                        <div className="option-balloon">
                            <div className="container-text">
                                <span className="text-option">OPÇÃO 1: </span>
                                <span className="text-option-content">Já tem um código POSHER?</span>
                            </div>
                            <span className="obs-option">obs.: Seu RH poderá informar esse código.</span>

                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="digite o código de sua empresa"
                                    value={codeCompany}
                                    onChange={e => setCodeCompany(e.target.value)}
                                />
                                <div className="border-icon-input">
                                    <FaArrowLeft size={15} color="#FA9F2A" />
                                </div>
                            </div>

                            <button
                                type="button"
                                disabled={codeCompany !== '' ? false : true}
                                className={codeCompany !== '' ? "button-actived" : "button-disabled"}
                                onClick={doSearchByCode}
                            >
                                <span className="text-button">confirmar</span>
                            </button>
                        </div>

                        <div className="option-balloon">
                            <div className="container-text">
                                <span className="text-option">OPÇÃO 2: </span>
                                <span className="text-option-content">Informe seu email corporativo</span>
                            </div>
                            <span className="obs-option">Você usou um email pessoal. Se preferir, use o corporativo para identificarmos sua empresa.</span>

                            <div className="input-container">
                                <input
                                    type="text"
                                    className="input"
                                    placeholder="Insira seu email corporativo"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <div className="border-icon-input">
                                    <FaArrowLeft size={15} color="#FA9F2A" />
                                </div>
                            </div>

                            <button
                                type="button"
                                disabled={email !== '' ? false : true}
                                className={email !== '' ? "button-actived" : "button-disabled"}
                                onClick={doSearchByEmail}
                            >
                                <span>confirmar</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="footer-service-list">
                    <div className="line-separator"></div>
                    <Footer />
                </div>
            </div>

            <TabButton screen="account" />
        </Container>
    );
}

export default LinkCompany;