import React from 'react';
import Modal from 'react-modal';
import './styles.css';
import InputMask from 'react-input-mask';
import { FiCreditCard } from 'react-icons/fi';

const ModalAddNewCard = ({ showModalNewCard, setShowModalNewCard, setNumber, setHolderName, setExpirationMonthYear, setSecurityCode, clicked }) => {
    const doCloseModal = (option) => {
        clicked(option);
        setShowModalNewCard(false);
    }

    return (
        <div>
            <Modal
                isOpen={showModalNewCard}
                onRequestClose={() => doCloseModal(false)}
                overlayClassName="modal-overlay add-new-card"
                className="modal-content add-new-card"
                ariaHideApp={false}
            >
                <button className="cancel-button" type="button" onClick={() => doCloseModal(false)}>
                    <span>x</span>
                </button>

                <div className="header-modal">
                    <span className="subtitle-header">adicionar novo</span>
                    <span className="title-header">cartão de crédito</span>
                </div>

                <div className="content-body">
                    <div className="input-container">
                        <label>Número do Cartão</label>
                        <InputMask
                            maskChar=""
                            mask="9999 9999 9999 9999"
                            className="inputs"
                            onChange={e => setNumber(e.target.value)}
                        />
                    </div>

                    <div className="input-container name-input">
                        <label>Nome (como impresso no cartão)</label>
                        <input
                            type="text"
                            className="inputs"
                            onChange={e => setHolderName(e.target.value)}
                        />
                    </div>

                    <div className="input-container wrapper">
                        <div className="input-container first-input">
                            <label>Data de Vencimento</label>
                            <InputMask
                                maskChar=""
                                mask="99/99"
                                placeholder="MM/YY"
                                className="inputs"
                                onChange={e => setExpirationMonthYear(e.target.value)}
                            />
                        </div>

                        <div className="input-container">
                            <label>CVV</label>
                            <InputMask
                                maskChar=""
                                mask="999"
                                className="inputs"
                                onChange={e => setSecurityCode(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='card-charge-message'>
                        Faremos uma cobrança para validar o cartão no valor de R$1,23, que será estornada em seguida. Fique tranquilo que esse valor não será cobrado.
                    </div>
                </div>

                <button className="accept-button" type="type" onClick={() => doCloseModal(true)}>
                    <FiCreditCard size={20} color="#FFF" />
                    <span>adicionar cartão</span>
                </button>
            </Modal>
        </div>
    );
}

export default ModalAddNewCard;