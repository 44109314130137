import React, { useEffect, useState } from 'react';
import { getHighLightedOffers } from '../../../../api/service_offer';
import { toast } from 'react-toastify';
import { useResponsiveValue } from '@theme-ui/match-media';
import ItemsCarousel from 'react-items-carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ButtonsOffer from '../../../../components/ButtonsOffer';
import { Container, ButtonsContainer } from './styles';

const AvailableNow = ({ address, orderType, title }) => {
  const [allHighLighted, setAllHighLighted] = useState([]);
  const numberOfCards = useResponsiveValue([3, 3, 3]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const searchOffers = async () => {
    try {
      await getHighLightedOffers(setAllHighLighted, orderType);
    } catch (error) {
      toast.error(error.message);
      setAllHighLighted([]);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      searchOffers();
    }

    return () => { isMounted = false; };
  }, []);

  return (
    <Container>
      {allHighLighted.length > 0 &&
        <div className="container-view">
          <span className="title">{title}</span>
          <span className="subtitle">Aproveite o embalo, agende mais um serviço.</span>

          <div className="carousel">
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={numberOfCards}
              gutter={20}
              leftChevron={
                <button className="button-left">
                  <FaChevronLeft size={12} />
                </button>
              }
              rightChevron={
                <button className="button-right">
                  <FaChevronRight size={12} />
                </button>
              }
              outsideChevron={false}
              infiniteLoop={allHighLighted.length > numberOfCards ? true : false}
              alwaysShowChevrons={allHighLighted.lenght > numberOfCards ? true : false}
            >

              {allHighLighted.map((offer, offerIndex) => {
                return (
                  <ButtonsContainer key={offerIndex}>
                    <ButtonsOffer offer={offer} card="checkout" address={address} />
                  </ButtonsContainer>
                )
              })}

            </ItemsCarousel>
          </div>
        </div>
      }
    </Container>
  );
}

export default AvailableNow;