import React, { useState } from 'react';
import { FiCalendar, FiClock, FiMap } from 'react-icons/fi';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import PaymentView from '../../../views_2/ServiceOrder/components/PaymentView';
import LoaderSpinner from '../../LoaderSpinner';
import Moment from 'moment-timezone';
import './styles.css';
import { formatCurrencyBRL } from '../../../utils/utils';
import { putPaymentOrder } from '../../../api/service_orders';

const ModalReSendPayment = ({ showModalResend, setShowModalResend, order }) => {
    const [methodPayment, setMethodPayment] = useState({});
    const [messageError, setMessageError] = useState('');
    const [loading, setLoading] = useState(false);

    const doPayment = async () => {
        try {
            setLoading(true);

            const resp = await putPaymentOrder(order.id, methodPayment.method, methodPayment.card);

            if (resp === 200) {
                toast.success("Pagamento feito com sucesso!");
                setMessageError('');
                setShowModalResend(false);
            } else {
                setMessageError(resp.message);
            }

            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    return (
        <div>
            <Modal
                isOpen={showModalResend}
                overlayClassName="modal-overlay resend"
                className="modal-content resend"
                ariaHideApp={false}
            >
                <LoaderSpinner loading={loading} />
                <div className="header-modal">
                    <span className="subtitle-header">pagamento recusado!</span>
                    <span className="title-header">O pagamento do serviço "<span>{order.service.name}</span>" foi recusado!</span>
                </div>

                <div className="content">
                    <div className="order">
                        <img className="img" src={order.service.thumb_url} />

                        <div className="price">
                            <span className="sign-price">R$ <span>{formatCurrencyBRL(order.total_value).substring(2)}</span></span>
                        </div>

                        <div className="about-order">
                            <div className="about-order-container">
                                <span className="order-type">{order.service.name}aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</span>

                                <div className="order-info">
                                    <FiCalendar size={14} color="#000" />
                                    <span className="text-order-info">{Moment(order.scheduled_date).format('DD/MM/YYYY')}</span>
                                </div>

                                <div className="order-info">
                                    <FiClock size={14} color="#000" />
                                    <span className="text-order-info">{Moment(order.scheduled_date).tz("America/Sao_Paulo").format('HH:mm')}</span>
                                </div>

                                <div className="order-info">
                                    <FiMap size={14} color="#000" />
                                    <span className="text-order-info">{order.address_description}</span>
                                </div>
                            </div>
                            <div className="container-professional">
                                <div className="professional">
                                    <img className="professional-img" src={order.professional.picture_url} />

                                    <div className="professional-about">
                                        <span className="professional-text">Profissional:</span>
                                        <span className="professional-name">{order.professional.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="title-select">
                        <span className="text-select">SELECIONE A FORMA DE PAGAMENTO <span>(Apenas cartão)</span></span>
                    </div>

                    <div className="choose-payment">
                        <span className="pay-title">PAGAMENTO</span>

                        <PaymentView
                            offer={order}
                            hasDiscount={false}
                            orderType={order.order_type}
                            setCheckedPaymentMethod={setMethodPayment}
                            checkedPaymentMethod={methodPayment}
                            setLoading={setLoading}
                        />

                        {messageError !== '' &&
                            <div className="message-warning">
                                <span>{messageError}</span>
                            </div>
                        }
                    </div>

                    <button
                        type="button"
                        onClick={doPayment}
                        disabled={methodPayment.card !== null ? false : true}
                        className={methodPayment.card !== null ? "button-active" : "button-disabled"}
                    >
                        <span>realizar pagamento</span>
                    </button>
                </div>
            </Modal>
        </div >
    );
}

export default ModalReSendPayment;